import { createContext, useContext } from 'react';

import { useLocation } from 'react-router';

import { History, Location } from 'history';

interface LayoutRouteProps {
  path: string;
  title: string;
}

export const routeData: LayoutRouteProps[] = [
  {
    path: '/customer/marketing-preferences',
    title: 'Marketing Preferences',
  },
  {
    path: '/customer/overview',
    title: 'Overview',
  },
  {
    path: '/customer/profile',
    title: 'Profile',
  },
  {
    path: '/version-history',
    title: 'Version history',
  },
  {
    path: `/payment-plan`,
    title: 'Payment Plan',
  },
  {
    path: `/settlement`,
    title: 'Settlement',
  },
  {
    path: `/direct-debit`,
    title: 'Direct Debit',
  },
  {
    path: `/payments`,
    title: 'Direct Debit',
  },
  {
    path: `/balance/details`,
    title: 'Balance Details',
  },
  {
    path: `/balance`,
    title: 'Balance',
  },
  {
    path: `/arrears`,
    title: 'Arrears',
  },
  {
    path: `/transactions`,
    title: 'transactions',
  },
  {
    path: '/top-up',
    title: 'Top Up',
  },
];

interface LayoutContextState {
  title: string;
}

interface LayoutContextProviderProps {
  children: React.ReactNode;
}

const INITIAL_CONTEXT_STATE: LayoutContextState = {
  title: '',
};

const LayoutContext = createContext(INITIAL_CONTEXT_STATE);

export const useLayoutContext = () => useContext(LayoutContext);

export const LayoutContextProvider = ({ children }: LayoutContextProviderProps) => {
  const location = useLocation();

  const routeMatch = routeData.find((route) => routeMatchesLocation(route, location));

  const title = routeMatch?.title || INITIAL_CONTEXT_STATE.title;

  return <LayoutContext.Provider value={{ title }}>{children}</LayoutContext.Provider>;
};

const routeMatchesLocation = (
  route: LayoutRouteProps,
  location: Location<History.UnknownFacade>,
) => {
  return location.pathname.includes(route.path);
};
