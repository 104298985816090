export interface FormattableAddress {
  houseName?: string;
  houseNumber?: string;
  flat?: string;
  street: string;
  street2?: string;
  townOrCity: string;
  county: string;
  postcode: string;
}

export const singleLineAddressString = (address: FormattableAddress) =>
  joinAddressParts(address, ', ');

const joinAddressParts = (address: FormattableAddress, delimiter: string) => {
  return [
    address.flat,
    address.houseName,
    address.houseNumber ? `${address.houseNumber} ${address.street}` : address.street,
    address.street2,
    address.townOrCity,
    address.county,
    address.postcode,
  ]
    .filter((part) => Boolean(part))
    .join(delimiter);
};
