import { useRef, useState } from 'react';
import * as React from 'react';

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Box, getColor, getFontSize, TextField } from '@sequensis/stylish-core';
import styled from 'styled-components';

import { EditableFieldAdornment } from './EditableFieldAdornment';

export interface EditableFieldProps {
  label: string;
  defaultValue?: string;
  fieldType?: FieldTypeProps;
  icon?: IconDefinition;
  placeholder?: string;
  onSaveClick?(currentValue: string): void;
  onAddClick?(currentValue: string): void;
  testId?: string;
  isLoading?: boolean;
  name: string;
  error?: string;
  isEditing?: boolean;
  isMultiline?: boolean;
  onEditClick?(): void;
  ariaLabel?: string;
}

export type FieldTypeProps = 'locked' | 'editable';

export const EditableField = ({
  defaultValue = '',
  label,
  fieldType,
  placeholder,
  onSaveClick,
  onAddClick,
  testId,
  isLoading,
  name,
  error,
  isEditing = true,
  isMultiline,
  onEditClick,
  ariaLabel,
}: EditableFieldProps & React.InputHTMLAttributes<HTMLInputElement>) => {
  const _inputRef = useRef<HTMLInputElement | null>(null);
  const [value, setValue] = useState(defaultValue);

  const handleEditClick = () => {
    onEditClick && onEditClick();
    _inputRef.current && _inputRef.current.focus();
  };

  const handleAddClick = () => {
    onAddClick && onAddClick(value);
  };

  const handleSaveClick = () => {
    onSaveClick && onSaveClick(value);
  };

  return (
    <Box marginBottom={3}>
      <TextField
        inputOverride={isMultiline ? <TextArea rows={3} /> : undefined}
        label={label}
        placeholder={placeholder}
        value={value}
        data-testid={testId}
        readOnly={isLoading || (fieldType && (fieldType === 'locked' || !isEditing))}
        id={testId}
        inputRef={_inputRef}
        error={error}
        name={name}
        rightAdornment={
          <EditableFieldAdornment
            loading={isLoading}
            editing={isEditing}
            addDisabled={!value.trim()}
            fieldType={fieldType}
            testId={testId}
            ariaLabel={ariaLabel}
            onSaveClick={handleSaveClick}
            onEditClick={handleEditClick}
            onAddClick={handleAddClick}
          />
        }
        onChange={(e) => setValue(e.target.value)}
      />
    </Box>
  );
};

const TextArea = styled.textarea`
  resize: none;
  padding: 1rem 0;
  flex-grow: 1;
  height: 100%;
  width: 100%;
  border: 0;
  text-align: left;
  font-size: ${getFontSize('lg')};
  font-family: inherit;
  background: none;

  vertical-align: text-bottom;
  &:focus-visible {
    outline-offset: 0px;
    outline: none;
  }
  &:disabled {
    color: ${getColor('oakbrookNavyTint')};
  }
`;
