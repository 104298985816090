import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom-v5-compat';

import { faClock, faPencil } from '@fortawesome/pro-regular-svg-icons';
import { useFeatureFlag } from '@sequensis/react-config';
import { Alert, Flex, getColor, Icon, Paragraph, Text } from '@sequensis/stylish-core';
import styled from 'styled-components';

import { analyticsClickEvent } from 'src/components/Analytics';
import { ButtonLink } from 'src/components/ButtonLink';
import { useNextPaymentIsBeforeSpecifiedDays } from 'src/hooks/useNextPaymentIsBeforeSpecifiedDays';
import {
  getDirectDebitIsPendingSetup,
  getNextRepaymentAmount,
  getPaymentMethodIsDirectDebit,
} from 'src/store/Account/selectors';
import { formatCondensedDate } from 'src/utils/formatDate';
import { formatCurrency } from 'src/utils/formatNumber';

const StyledAlert = styled(Alert)`
  background-color: ${getColor('white')};
`;

const ManualNextPayment = () => {
  const repaymentAmount = useSelector(getNextRepaymentAmount);
  const [isNextPaymentInProgress, nextRepaymentDate] =
    useNextPaymentIsBeforeSpecifiedDays();
  const isPaymentMethodDirectDebit = useSelector(getPaymentMethodIsDirectDebit);

  const isDirectDebitPendingSetup = useSelector(getDirectDebitIsPendingSetup);

  const directDebitFeatureFlag = useFeatureFlag('directDebitActivation');

  const showSetupDirectDebit =
    directDebitFeatureFlag && nextRepaymentDate && !isPaymentMethodDirectDebit;

  const showNextPaymentInProgressAlert =
    isPaymentMethodDirectDebit && isNextPaymentInProgress && !isDirectDebitPendingSetup;

  return (
    <>
      {nextRepaymentDate && (
        <>
          <Paragraph fontWeight="bold" textAlign="left" marginTop={5} fontSize="small">
            Your next payment
          </Paragraph>
          <StyledAlert
            marginTop={1}
            marginBottom={showNextPaymentInProgressAlert ? 1 : 3}
            backgroundColor="white"
          >
            <Flex justifyContent="space-between" alignItems="center">
              <div>
                <Paragraph>
                  <Text data-testid="direct-debit-repayment-amount">
                    {formatCurrency(repaymentAmount)}
                  </Text>
                  &nbsp;on
                  <Text data-testid="direct-debit-repayment-day">
                    &nbsp;{formatCondensedDate(nextRepaymentDate)}
                  </Text>
                </Paragraph>
              </div>
              <Link
                to="../payments"
                onClick={() =>
                  analyticsClickEvent('AccountSummary', 'next-payment-change-button')
                }
              >
                <Icon icon={faPencil} size="lg" color="primary" maxHeight={24} />
              </Link>
            </Flex>
          </StyledAlert>
          {showNextPaymentInProgressAlert && (
            <Alert
              variant="warning"
              marginBottom={1}
              spacing="slim"
              title="Next Payment in progress"
              data-testid="payment-in-progress-warning"
              icon={faClock}
            />
          )}
          {isDirectDebitPendingSetup && (
            <Alert
              variant="positive"
              marginBottom={1}
              spacing="slim"
              data-testid="direct-debit-pending-warning"
              icon={faClock}
            >
              Your Direct Debit is being setup
            </Alert>
          )}
        </>
      )}

      <ButtonLink
        navigational
        to="../make-payment"
        data-testid="make-payment-button"
        onClick={() =>
          analyticsClickEvent('AccountSummary', 'next-payment-make-payment-button')
        }
      >
        Pay now
      </ButtonLink>
      {showSetupDirectDebit && (
        <ButtonLink
          marginTop={1}
          to="../payments/direct-debit/setup"
          variant="secondary"
          data-testid="direct-debit-set-up-button"
          onClick={() =>
            analyticsClickEvent(
              'AccountSummary',
              'next-payment-direct-debit-set-up-button',
            )
          }
        >
          Set up Direct Debit
        </ButtonLink>
      )}
      <ButtonLink
        variant="secondary"
        to="../transactions"
        data-testid="view-transaction-history-button"
        onClick={(event) => event.stopPropagation()}
        marginTop={3}
        marginBottom={1}
      >
        View Transaction History
      </ButtonLink>
    </>
  );
};

export default ManualNextPayment;
