import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { Card } from '@sequensis/stylish-core';
import { PageHeader } from '@sequensis/stylish-layouts';

import { analyticsClickEvent } from 'src/components/Analytics';
import { getCurrentAddress } from 'src/store/Customer/selectors';
import { AddressProps } from 'src/types/CustomerProps';

import { AddressDisplay } from './components/AddressDisplay';
import { EmailAddress, TelephoneNumbers } from './ContactDetails';

export const CustomerProfile = () => {
  const currentAddress = useSelector(getCurrentAddress);
  const currentAddressProps = currentAddress as AddressProps;
  const history = useHistory();

  return (
    <>
      <PageHeader title="Profile" />

      <Card>
        <AddressDisplay
          showLabel={true}
          showResidentialStatus={true}
          address={currentAddressProps}
          onEditClick={() => {
            analyticsClickEvent('CustomerDetailsAddress', 'edit-address-button');
            history.push('/customer/address/edit');
          }}
        />
        <EmailAddress />
        <TelephoneNumbers />
      </Card>
    </>
  );
};

export default CustomerProfile;
