import { FirstNamePlayback, Playback } from 'src/screens/TopUp';
import { CustomerProps } from 'src/types/CustomerProps';

export function mapFirstNamePlayback(
  customer?: CustomerProps,
): Playback<FirstNamePlayback> {
  if (customer) {
    return {
      playback: {
        firstName: customer.firstName,
      },
    };
  } else {
    return {
      playback: undefined,
      isLoading: true,
    };
  }
}
