import { FixedTermLoanOffer } from '@sequensis/creditapplication-reactsdk';

import { Playback } from 'src/screens/TopUp';
import { TopUpLoanPlayback } from 'src/screens/TopUp/HardSearch';
import { AllAccountProps } from 'src/types/AccountProps';

export function mapHardSearchLoanPlayback(
  offer?: FixedTermLoanOffer,
  account?: AllAccountProps,
): Playback<TopUpLoanPlayback> {
  if (offer && account && offer.apr) {
    return {
      playback: {
        extraBorrowingAmount: offer.minimumAmount,
        totalAmountRepayable: offer.totalAmountRepayable,
        paymentsCount: offer.term,
        repaymentAmount: offer.monthlyRepaymentAmount,
        currentLoanApr: account.accountDetails.annualPercentageRate,
        topUpLoanApr: offer.apr * 100,
      },
    };
  } else {
    return {
      playback: undefined,
      isLoading: true,
    };
  }
}
