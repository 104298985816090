export enum HttpStatusCode {
  Unauthorized = 401,
  Forbidden = 403,
  UnprocessableEntity = 422,
  OK = 200,
  Created = 201,
  InternalServerError = 500,
}

export type ReferralDocumentType = 'BankStatement' | 'Payslip';

export interface DocumentToSubmit {
  documentType: ReferralDocumentType;
  uploadUrl: string;
  document: File;
  submitted: boolean;
}

export interface DocumentSubmissionResponse {
  status: HttpStatusCode;
  documentToSubmit: DocumentToSubmit;
}

export interface UploadedDocumentsContainerProps {
  documentIsSubmitting: boolean;
  setDocumentIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
  documentsToSubmit: any;
  onUpload: any;
  onComplete: any;
  onUploadSuccess: any;
  applicationId: string;
}

export interface DocumentTipsProps {
  step: number;
  documentName: Lowercase<string>;
  supplyTips: string[];
  generalTips: string[];
  goodExampleImageUrl: string;
  badExampleImageUrl: string;
  navigateToStep: (step: number) => void;
}
