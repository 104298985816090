import { createAsyncAction } from 'typesafe-actions';

import { FetchAccountRequest } from 'src/api/account/types';
import { AllAccountProps } from 'src/types/AccountProps';

export enum ActionTypes {
  FETCH_ACCOUNT = '@ACCOUNT/FETCH_REQUEST',
  FETCH_ACCOUNT_SUCCESS = '@ACCOUNT/FETCH_SUCCESS',
  FETCH_ACCOUNT_FAIL = '@ACCOUNT/FETCH_FAIL',
}

export const fetchAccount = createAsyncAction(
  ActionTypes.FETCH_ACCOUNT,
  ActionTypes.FETCH_ACCOUNT_SUCCESS,
  ActionTypes.FETCH_ACCOUNT_FAIL,
)<FetchAccountRequest, AllAccountProps, string>();
