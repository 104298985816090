import { useSelector } from 'react-redux';

import {
  Box,
  Card,
  Flex,
  ProgressBar,
  ProgressBarVariant,
  Spaced,
  Text,
} from '@sequensis/stylish-core';

import {
  getAmountPaid,
  getBalanceAmount,
  getInterestAmount,
  getTotalAmountOwed,
} from 'src/store/Account/selectors';
import { formatCurrency } from 'src/utils/formatNumber';

interface DetailItemProps {
  value: number;
  maxValue: number;
  label: string;
  testId?: string;
  variant: ProgressBarVariant;
}

const DetailItem = ({ label, variant, value, maxValue, testId }: DetailItemProps) => (
  <Box>
    <Flex alignItems="center" justifyContent="space-between" marginBottom={1}>
      <span data-testid={`${testId}-label`}>{label}</span>
      <Text
        fontWeight="bold"
        fontFamily="heading"
        fontSize="large"
        data-testid={`${testId}-value`}
      >
        {formatCurrency(value, { stripTrailingZeros: false })}
      </Text>
    </Flex>
    <ProgressBar percent={(value / maxValue) * 100} variant={variant} />
  </Box>
);

export const BalanceCard = () => {
  const totalAmountOwed = useSelector(getTotalAmountOwed);
  const balance = useSelector(getBalanceAmount);
  const interestAmount = useSelector(getInterestAmount);
  const amountPaid = useSelector(getAmountPaid);

  return (
    <Card heading="Your balance" marginY={2}>
      <Spaced marginBottom={3}>
        <DetailItem
          label="Amount paid"
          value={amountPaid}
          maxValue={totalAmountOwed}
          variant="positive"
          testId="amount-paid"
        />
        <DetailItem
          label="Total charge for credit"
          value={interestAmount}
          maxValue={totalAmountOwed}
          variant="secondary"
          testId="total-charge-for-credit"
        />
        <DetailItem
          label="You owe"
          value={balance}
          maxValue={totalAmountOwed}
          variant="primary"
          testId="amount-owed"
        />
      </Spaced>
    </Card>
  );
};
