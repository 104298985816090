import { useEffect, useState } from 'react';

import { useAuth } from '@sequensis/react-auth';
import { useConfig } from '@sequensis/react-config';
import { useAsyncCallback } from '@sequensis/react-hooks';
import * as yup from 'yup';

import {
  deleteTelephoneNumber,
  updateTelephoneNumber,
} from 'src/api/customer/customerApi';
import { TelephoneNumberTypes } from 'src/api/customer/types';
import { analyticsClickEvent } from 'src/components/Analytics';
import { FeedbackAlert } from 'src/components/Delighted';
import { EditableField, EditableFieldProps } from 'src/components/Field/EditableField';

import { phoneNumberValidationErrorMessage } from './TelephoneNumberSchema';
import { Status, TelephonePendingAlert } from './TelephonePendingAlert';

interface TelephoneNumberFieldProps extends EditableFieldProps {
  telephoneNumber: string;
  telephoneNumberSchema: yup.StringSchema<
    string | undefined,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Record<string, any>,
    string | undefined
  >;
  telephoneNumberType: TelephoneNumberTypes;
  onNumberChangeCompletion(success: boolean): void;
  ariaLabel?: string;
}

export const TelephoneNumberField = ({
  telephoneNumber,
  name,
  testId,
  label,
  telephoneNumberSchema,
  telephoneNumberType,
  onNumberChangeCompletion,
  ariaLabel,
}: TelephoneNumberFieldProps) => {
  const {
    customerManagementApi: { url: customerManagementApiUrl },
  } = useConfig();
  const { getToken, customerId = '' } = useAuth();

  const [updateTelephoneNumberState, executeUpdateTelephoneNumber] =
    useAsyncCallback(updateTelephoneNumber);

  const [deleteTelephoneNumberState, executeDeleteTelephoneNumber] =
    useAsyncCallback(deleteTelephoneNumber);

  const [hasValidationError, setHasValidationError] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [pendingAlertState, setPendingAlertState] = useState<Status | null>(null);

  const errorMessage = hasValidationError ? phoneNumberValidationErrorMessage : undefined;

  const validatePhoneNumber = (phoneNumber: string) => {
    return telephoneNumberSchema.isValidSync(phoneNumber);
  };

  const handleUpdateTelephoneNumber = async (newValue: string) => {
    const authToken = await getToken();

    const isValid = validatePhoneNumber(newValue);

    setHasValidationError(!isValid);

    if (!isValid) return;

    if (newValue === '') {
      analyticsClickEvent(
        'CustomerDetailsPhoneNumber',
        `delete-number-${telephoneNumberType}`,
      );
      await executeDeleteTelephoneNumber(
        customerManagementApiUrl,
        authToken,
        customerId,
        telephoneNumberType,
      );
    } else {
      analyticsClickEvent(
        'CustomerDetailsPhoneNumber',
        `save-number-${telephoneNumberType}`,
      );
      await executeUpdateTelephoneNumber(
        customerManagementApiUrl,
        authToken,
        customerId,
        telephoneNumberType,
        { telephoneNumber: newValue },
      );
    }
  };

  useEffect(() => {
    if (updateTelephoneNumberState.status === 'success') {
      onNumberChangeCompletion(true);
      setIsEditing(false);
      setPendingAlertState('success');
    }

    if (updateTelephoneNumberState.status === 'error') {
      onNumberChangeCompletion(false);
      setPendingAlertState('error');
    }
  }, [updateTelephoneNumberState.status, onNumberChangeCompletion]);

  useEffect(() => {
    if (deleteTelephoneNumberState.status === 'success') {
      onNumberChangeCompletion(true);
      setIsEditing(false);
      setPendingAlertState('success');
    }

    if (deleteTelephoneNumberState.status === 'error') {
      onNumberChangeCompletion(false);
      setPendingAlertState('error');
    }
  }, [deleteTelephoneNumberState.status, onNumberChangeCompletion]);

  const handleAddTelephoneNumber = async (newValue: string) => {
    const authToken = await getToken();

    const isValid = validatePhoneNumber(newValue);

    setHasValidationError(!isValid);

    if (!isValid) return;

    if (newValue === '') return;

    analyticsClickEvent(
      'CustomerDetailsPhoneNumber',
      `add-number-${telephoneNumberType}`,
    );

    await executeUpdateTelephoneNumber(
      customerManagementApiUrl,
      authToken,
      customerId,
      telephoneNumberType,
      { telephoneNumber: newValue },
    );
  };

  const isLoading =
    updateTelephoneNumberState.status === 'loading' ||
    deleteTelephoneNumberState.status === 'loading';

  return (
    <>
      <EditableField
        fieldType={telephoneNumber ? 'editable' : undefined}
        defaultValue={telephoneNumber}
        placeholder="Add an extra phone number"
        testId={testId}
        label={label}
        ariaLabel={ariaLabel}
        name={name}
        isLoading={isLoading}
        error={errorMessage}
        onSaveClick={handleUpdateTelephoneNumber}
        onAddClick={handleAddTelephoneNumber}
        isEditing={isEditing}
        onEditClick={() => {
          setIsEditing(true);
          analyticsClickEvent(
            'CustomerDetailsPhoneNumber',
            `edit-number-${telephoneNumberType}`,
          );
        }}
      />

      {pendingAlertState ? (
        <TelephonePendingAlert label={label} status={pendingAlertState} />
      ) : null}

      {pendingAlertState === 'success' ? (
        <FeedbackAlert mt={2} feedbackQueryString="Site=PhoneAdd" />
      ) : null}
    </>
  );
};
