import { useState } from 'react';

import { Card, Paragraph, Spaced, Text } from '@sequensis/stylish-core';
import { PageHeader } from '@sequensis/stylish-layouts';

import { BankAccount } from 'src/api/account/types';
import { BankDetailProps } from 'src/types/CustomerProps';

import { AddBankAccountForm } from './AddBankAccountForm';
import { DirectDebitAuthorisation } from './DirectDebitAuthorisation';
import { SelectBankAccountForm } from './SelectBankAccountForm';

interface ChooseBankAccountProps {
  onBankAccountChosen(bankDetails: BankAccount | BankDetailProps): void;
}

export const ChooseBankAccount = ({ onBankAccountChosen }: ChooseBankAccountProps) => {
  const handleContinueClick = (bankDetails?: BankAccount) => {
    bankDetails && onBankAccountChosen(bankDetails);
  };

  const [showNewAccount, setShowNewAccount] = useState(false);
  const handleAddBankAccountClicked = () => {
    setShowNewAccount(true);
  };

  const [userIsAccountHolder, setUserIsAccountHolder] = useState(false);
  const handleUserIsAccountHolderChange = (isChecked: boolean) =>
    setUserIsAccountHolder(isChecked);

  const [userIsAuthorised, setUserIsAuthorised] = useState(false);
  const handleUserIsAuthorisedChange = (isChecked: boolean) =>
    setUserIsAuthorised(isChecked);

  const canContinue = userIsAccountHolder && userIsAuthorised;

  return (
    <>
      <PageHeader title="Choose your preferred bank account" />
      <Spaced marginBottom={4}>
        <Card>
          <Paragraph>We will use your bank account to:</Paragraph>
          <Text as="ul">
            <Text as="li" paddingBottom={0}>
              send more money when you borrow
            </Text>
            <Text as="li">collect repayments via Direct Debit</Text>
          </Text>
        </Card>
        <Card>
          <Spaced marginBottom={4} excludeLast>
            {showNewAccount ? (
              <AddBankAccountForm
                onSubmit={handleContinueClick}
                hasAuthorisedDirectDebit={canContinue}
              >
                <DirectDebitAuthorisation
                  userIsAccountHolder={userIsAccountHolder}
                  userIsAuthorised={userIsAuthorised}
                  onUserIsAccountHolderChange={handleUserIsAccountHolderChange}
                  onUserIsAuthorisedChange={handleUserIsAuthorisedChange}
                />
              </AddBankAccountForm>
            ) : (
              <SelectBankAccountForm
                onSubmit={handleContinueClick}
                hasAuthorisedDirectDebit={canContinue}
                onAddBankAccountClicked={handleAddBankAccountClicked}
              >
                <DirectDebitAuthorisation
                  userIsAccountHolder={userIsAccountHolder}
                  userIsAuthorised={userIsAuthorised}
                  onUserIsAccountHolderChange={handleUserIsAccountHolderChange}
                  onUserIsAuthorisedChange={handleUserIsAuthorisedChange}
                />
              </SelectBankAccountForm>
            )}
          </Spaced>
        </Card>
      </Spaced>
    </>
  );
};
