import { useState } from 'react';

import {
  Alert,
  Box,
  Button,
  Card,
  Checkbox,
  Flex,
  Paragraph,
  Text,
} from '@sequensis/stylish-core';
import styled from 'styled-components';

import { DocumentUpload } from '../components/DocumentsContainer';
import { documentCheckboxLabel } from '../data';
import { truncateMiddle } from '../helperFunctions';

const CheckYourDocument = ({ documentType, document, onUpload }: any) => {
  const [recentlyDated, setRecentlyDated] = useState<boolean>(false);
  const [file, setFile] = useState<File>();

  if (!document) return null;

  return (
    <Card
      heading={
        <Paragraph fontSize="3xl" lineHeight="3xl">
          Check your document
        </Paragraph>
      }
    >
      <Paragraph mt={3}>Please check and confirm the following:</Paragraph>

      <Box as="ul" paddingLeft={4}>
        <li>
          <Paragraph>
            filename of the document you uploaded to make sure it is the correct one
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            any confirmation checkboxes below - if the information in the document does
            not match these, then the document will be rejected and there could be a delay
            on you receiving your loan money
          </Paragraph>
        </li>
      </Box>

      <Paragraph mt={3}>
        Once everything is ok, tap &apos;I&apos;m happy with this&apos;.
      </Paragraph>
      <Paragraph mt={3}>
        If you want to upload a new document, use the &apos;Upload a different
        document&apos; button.
      </Paragraph>

      <Paragraph mt={3} mb={3} fontWeight="medium">
        Document filename
      </Paragraph>

      <DocumentPreviewContainer variant="info">
        <Flex justifyContent="center" width="100%">
          <Text fontWeight="medium">
            {truncateMiddle(file?.name || document.name, 20)}
          </Text>
        </Flex>
      </DocumentPreviewContainer>

      <Paragraph mt={3} mb={4} fontWeight="medium">
        Please confirm
      </Paragraph>

      <Checkbox
        label={documentCheckboxLabel[documentType as keyof typeof documentCheckboxLabel]}
        data-testid="recently-dated-checkbox"
        checked={recentlyDated}
        onChange={(event) => setRecentlyDated(event.currentTarget.checked)}
      />

      <Button
        variant="primary"
        mt={4}
        mb={3}
        fullWidth
        data-testid="confirm-document-button"
        disabled={!recentlyDated}
        onClick={() => {
          onUpload(file || document);
        }}
      >
        <Paragraph>I&apos;m happy with this</Paragraph>
      </Button>

      <DocumentUpload
        onDocumentAccepted={(file) => {
          setFile(file);
        }}
      >
        <Button key="cancel" variant="secondary" fullWidth>
          <Paragraph>Upload a different document</Paragraph>
        </Button>
      </DocumentUpload>
    </Card>
  );
};

const DocumentPreviewContainer = styled(Alert)`
  border-radius: 2rem;
`;

export default CheckYourDocument;
