import {
  addMonths,
  differenceInCalendarDays,
  format,
  getDate,
  getMonth,
  lastDayOfMonth,
  setDate,
  setMonth,
} from 'date-fns';
import { pipe, range } from 'ramda';

import { PaymentGraphDataPoint } from 'src/types/PaymentGraph';

import isUndefined from './isUndefined';

export const monthAndYearUntilDebtFree = (months: number) => {
  const date = addMonths(Date.now(), months);
  return format(date, 'MMM yyyy');
};

export const remainingMonthsUntilDebtFreeString = (months: number) => {
  return `${months} ${months === 1 ? 'month' : 'months'}`;
};

export const calculateNextRepaymentDate = (dayOfMonth: number) =>
  pipe(
    (d: Date) =>
      setMonth(d, !hasDayOfMonthPassed(dayOfMonth, d) ? getMonth(d) : getMonth(d) + 1),
    setDayOfMonthClipped(dayOfMonth),
  )(new Date(Date.now()));

export const calculateDaysBetweenNowAndNextRepayment = (
  repaymentDate: string | undefined,
) =>
  isUndefined(repaymentDate)
    ? ''
    : differenceInCalendarDays(new Date(repaymentDate), Date.now());

export const getPaymentGraphMonths = (numberOfMonths: number): PaymentGraphDataPoint[] =>
  numberOfMonths < 12
    ? getPaymentGraphMonthsForLessThanTwelve(numberOfMonths)
    : getPaymentGraphMonthsForMoreThanTwelve(numberOfMonths);

const getPaymentGraphMonthsForLessThanTwelve = (numberOfMonths: number) => {
  const months = range(0, numberOfMonths + 2);
  return months.map((month) => ({
    name: month,
    amount: month <= 1 ? numberOfMonths : numberOfMonths - month + 1,
  }));
};

const getPaymentGraphMonthsForMoreThanTwelve = (numberOfMonths: number) => {
  const numberOfSteps = Math.floor(numberOfMonths / 6);
  const amountToStepDown = numberOfMonths / numberOfSteps;

  const months = range(0, numberOfSteps + 1);

  const paymentMonths = months.map((month, index) => ({
    name: month,
    amount: numberOfMonths - amountToStepDown * index,
  }));

  return paymentMonths;
};

const hasDayOfMonthPassed = (dayOfMonth: number, date: Date) =>
  getDate(date) > dayOfMonth;
const setDayOfMonthClipped = (dayOfMonth: number) => (date: Date) =>
  setDate(date, Math.min(dayOfMonth, getDate(lastDayOfMonth(date))));
