const Halo = (props: { children?: React.ReactNode }) => (
  <svg
    width="140"
    height="140"
    viewBox="0 0 140 140"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="70" cy="70" r="48" transform="rotate(-180 70 70)" fill="white" />
    <g filter="url(#filter1)">
      <circle
        cx="70"
        cy="70"
        r="48"
        transform="rotate(-180 70 70)"
        stroke="url(#gradient1)"
        strokeWidth="4"
      />
    </g>
    <circle
      cx="70"
      cy="70"
      r="46"
      transform="rotate(-180 70 70)"
      stroke="url(#gradient2)"
      strokeWidth="4"
    />
    {props.children}
    <defs>
      <filter
        id="filter1"
        x="0"
        y="0"
        width="140"
        height="140"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur stdDeviation="10" result="effect1_foregroundBlur" />
      </filter>
      <linearGradient
        id="gradient1"
        x1="151.6"
        y1="152.56"
        x2="-29.5041"
        y2="54.3055"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0200FF" />
        <stop offset="1" stopColor="#00FFDA" />
      </linearGradient>
      <linearGradient
        id="gradient2"
        x1="151.6"
        y1="152.56"
        x2="-29.5041"
        y2="54.3055"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0200FF" />
        <stop offset="1" stopColor="#00FFDA" />
      </linearGradient>
    </defs>
  </svg>
);

export { Halo };
