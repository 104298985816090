import { AnchorHTMLAttributes } from 'react';

import { componentStyles, Text, TypeProps } from '@sequensis/stylish-core';
import styled from 'styled-components';

const anchorStyles = componentStyles('anchor');

const StyledAnchor = styled(Text).attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  text-decoration: underline;
  text-underline-offset: 1px;
  ${anchorStyles};
`;

export const A = ({
  children,
  ...rest
}: TypeProps & AnchorHTMLAttributes<HTMLAnchorElement>) => (
  <StyledAnchor {...rest}>{children}</StyledAnchor>
);

A.defaultProps = {
  as: 'a',
};
