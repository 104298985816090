import { MultipartComponentTheme } from '@sequensis/stylish-core';

export const DEFAULT_STICKY_CONTROL_STYLING: MultipartComponentTheme = {
  baseStyle: {
    stickyControl: {
      backgroundColor: 'white',
      boxShadow: 'dropShadow',
    },
    page: {
      marginY: 1,
      boxShadow: 'dropShadow',
    },
    document: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    overlay: {
      display: 'flex',
      alignContent: 'space-between',
      flexWrap: 'wrap',
    },
  },
};
