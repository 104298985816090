import ReactGA from 'react-ga';
import ScriptTag from 'react-script-tag';

import { useConfig } from '@sequensis/react-config';

import { useAnalyticsCookieContext } from '../Analytics/AnalyticsCookieController';

declare global {
  interface Window {
    CookieControl: CookieControlProps;
  }
}

interface CookieControlProps {
  open: () => void;
  load: (e: unknown) => void;
}

export const CookieControl = () => {
  const {
    cookieControl: { apiKey: cookieApiKey, apiUrl: cookieApiUrl },
    brochurewareUrl: brochurewareUrl,
    analytics: { googleAnalyticsId },
  } = useConfig();

  const { refresh } = useAnalyticsCookieContext();

  const config = {
    apiKey: cookieApiKey,
    product: 'PRO_MULTISITE',
    initialState: 'notify',
    position: 'LEFT',
    layout: 'slideout',
    toggleType: 'checkbox',
    notifyDismissButton: false,
    consentCookieExpiry: 365,
    necessaryCookies: ['__cfduid', 'a0*', 'auth0*'],
    rejectButton: false,
    statement: {
      description: 'For more information visit our',
      name: 'Privacy Statement',
      url: `${brochurewareUrl}/privacy-policy`,
      updated: '01/08/2018',
    },
    text: {
      title: 'About cookies on Oakbrook',
      intro:
        'Some of these cookies are essential, while others help us to improve your experience by providing insights into how the site is being used.',
      necessaryTitle: 'Necessary Cookies',
      necessaryDescription:
        'Necessary cookies enable core functionality such as page navigation and access to secure areas. The website cannot function properly without these cookies, and can only be disabled by changing your browser preferences.',
      acceptSettings: 'Accept all cookies',
      rejectSettings: 'Decline all cookies',
    },
    optionalCookies: [
      {
        name: 'analytics',
        label: 'Analytical Cookies',
        recommendedState: 'on',
        lawfulBasis: 'consent',
        description:
          'Analytical cookies help us to improve our website by collecting and reporting information on its usage.',
        cookies: [
          // Google Analytics
          '_gid',
          '_ga',
          '_gat_*',
          // Delighted
          '_delighted_web',
          // LogRocket
          '_lr_*',
          // DataDog
          '_dd_*',
          // Heap
          '_hp2_*',
        ],
        onAccept: () => {
          ReactGA.initialize(googleAnalyticsId);
          refresh();
        },
      },
    ],
    branding: {
      acceptBackground: '#fff',
      acceptText: '#000067',
      fontColor: '#fff',
      fontSizeTitle: '1.125rem',
      fontSize: '0.875rem',
      fontfamily: '"GT Walsheim","Helvetica Neue",sans-serif',
      backgroundColor: '#000067',
      toggleColor: '#000067',
      toggleBackground: '#fff',
      buttonIcon: null,
      buttonIconWidth: '64px',
      buttonIconHeight: '64px',
      removeIcon: true,
      removeAbout: true,
    },
    accessibility: {
      overlay: false,
    },
  };

  const handleCookieBanner = () => {
    window.CookieControl.load(config);
  };

  return (
    <ScriptTag
      src={cookieApiUrl}
      type="text/javascript"
      async={true}
      onLoad={handleCookieBanner}
    />
  );
};
