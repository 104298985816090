import { useEffect, useState } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom-v5-compat';

import { useAuth } from '@sequensis/react-auth';
import { useConfig, useFeatureFlag } from '@sequensis/react-config';
import {
  Box,
  Button,
  Card,
  Column,
  Flex,
  getRadii,
  Grid,
  Heading,
  Paragraph,
  Text,
} from '@sequensis/stylish-core';
import styled from 'styled-components/macro';

import { PoweredByOakbrook } from 'src/components/Logos';
import { DynamicLogo } from 'src/components/Logos/DynamicLogo';

import { FeatureList } from './FeatureList';
import { LoginError } from './LoginError';

const LogoArea = styled(Flex)`
  flex: 1 1 auto;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  row-gap: ${getRadii(1)};
`;

export const Login = () => {
  const { loginWithRedirect, error } = useAuth();
  const config = useConfig();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const [loanReference, setLoanReference] = useState('');
  const showRegistration = useFeatureFlag('showRegistration');

  useEffect(() => {
    const loanReferenceParam = searchParams.get('loanReference');

    if (loanReferenceParam) {
      setLoanReference(loanReferenceParam);
    }
  }, [searchParams]);

  return (
    <Flex
      flexDirection="column"
      justifyContent="flex-start"
      flex="1 1 auto"
      height="100%"
    >
      {error !== null && <LoginError error={error} />}
      <Flex flex="1 1 auto" justifyContent="flex-end" my={5}>
        <PoweredByOakbrook color="primary" />
      </Flex>
      <Card>
        <Grid>
          <Column small={{ width: 10, offset: 1 }}>
            <Heading textAlign="left" color="primary" as="h1" fontSize="mega" marginY={5}>
              Welcome to your online account
            </Heading>
            <LogoArea>
              {config.productConfiguration.products.map(({ baseRoute }) => (
                <div key={baseRoute} style={{ marginRight: 20, height: 50 }}>
                  <DynamicLogo
                    key={baseRoute}
                    productName={baseRoute}
                    isGreyscale={true}
                  />
                </div>
              ))}
            </LogoArea>
            <Button
              navigational
              variant="primary"
              my={6}
              fullWidth
              onClick={() => loginWithRedirect()}
              data-testid="login-button-top"
            >
              Log in
            </Button>
            {showRegistration && (
              <>
                <Paragraph size="lg" fontWeight="bold" mt={7} mb={3}>
                  Haven&apos;t yet registered for your account?
                </Paragraph>
                <Button
                  variant="secondary"
                  fullWidth
                  onClick={() =>
                    navigate({
                      pathname: 'register',
                      search: loanReference ? `?loanReference=${loanReference}` : '',
                    })
                  }
                >
                  Register
                </Button>
              </>
            )}
          </Column>
        </Grid>
      </Card>
      <Flex flex="1 1 auto" alignItems="center" justifyContent="center">
        <Text
          textAlign="left"
          color="primary"
          fontSize="large"
          fontWeight="bold"
          marginTop={7}
          marginBottom={5}
        >
          Everything you need for your Oakbrook account online
        </Text>
      </Flex>
      <FeatureList />
      <Box mx={[0, 7]}>
        <Column small={{ width: 10, offset: 1 }}>
          <Button
            my={6}
            fullWidth={true}
            onClick={() => loginWithRedirect()}
            data-testid="login-button-bottom"
            navigational
          >
            Log in
          </Button>
        </Column>
      </Box>
    </Flex>
  );
};
