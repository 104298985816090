import { Alert, Paragraph, Text } from '@sequensis/stylish-core';

export interface PendingVerificationAlertProps {
  show: boolean;
}

export function PendingVerificationAlert({ show }: PendingVerificationAlertProps) {
  if (!show) return null;

  return (
    <Alert
      icon={true}
      variant="warning"
      data-testid="email-address-confirmation-message"
      marginBottom={4}
    >
      <Text fontWeight="bold">Confirm your email address</Text>
      <Paragraph>
        We&apos;ve sent an email to your new email address to confirm your change.
      </Paragraph>
    </Alert>
  );
}
