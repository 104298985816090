import { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useAuth } from '@sequensis/react-auth';
import { useConfig } from '@sequensis/react-config';

import { fetchAccount } from 'src/store/Account/actions';

import { getAccountId } from './../store/Account/selectors';

export function useRefreshAccount(currentAccountId?: string) {
  const { getToken } = useAuth();
  const dispatch = useDispatch();
  const {
    accountManagementApi: { url: accountManagementApiUrl },
  } = useConfig();

  const accountIdFromStore = useSelector(getAccountId);
  const accountId = currentAccountId ?? accountIdFromStore ?? '';

  const refreshAccount = useCallback(async () => {
    const authToken = await getToken();

    dispatch(
      fetchAccount.request({
        accountId,
        apiUrl: accountManagementApiUrl,
        authToken,
      }),
    );
  }, [dispatch, accountId, getToken, accountManagementApiUrl]);

  return {
    refreshAccount,
  };
}
