import { faPhoneAlt } from '@fortawesome/pro-regular-svg-icons';
import { useConfig } from '@sequensis/react-config';
import { Alert, Text } from '@sequensis/stylish-core';

import { A } from 'src/components/Anchor';
import { useProductSupportUrl } from 'src/hooks/useProductSupportUrl';

export function ContactNotice() {
  const {
    companyDetails: { supportPhoneNumber },
  } = useConfig();

  const { productSupportUrl } = useProductSupportUrl();

  return (
    <Alert title="If you need to contact us" icon={faPhoneAlt} marginTop={1}>
      Call us on <Text fontWeight="bold">{supportPhoneNumber}</Text> or you can{' '}
      <A target="_blank" rel="noopener noreferrer" href={productSupportUrl}>
        send us a message
      </A>
    </Alert>
  );
}
