import { useEffect, useState } from 'react';

import CheckYourDocument from './steps/CheckYourDocument';
import Complete from './steps/Complete';
import ConfirmIncome from './steps/ConfirmIncome';
import DocumentTips from './steps/DocumentTips';
import Requirements from './steps/Requirement';
import {
  MODE_CHECK_YOUR_DOCS,
  MODE_COMPLETE,
  MODE_CONFIRM_INCOME,
  MODE_REQUIREMENTS,
  MODE_TIPS,
} from './data';

const DocumentUpload = ({ requirements, applicationId }: any) => {
  const [mode, setMode] = useState<string>(MODE_REQUIREMENTS);
  const [requirement, setRequirement] = useState<any>();
  const [selectedDocument, setSelectedDocument] = useState<any>();
  const [documentsToSubmit, setDocumentsToSubmit] = useState<any>([]);
  const [file, setFile] = useState<any>({});

  useEffect(() => {
    if (mode === MODE_CONFIRM_INCOME || mode === MODE_REQUIREMENTS)
      setSelectedDocument({});
  }, [mode]);

  const onRequirementTypeClick = (reqtype: any) => {
    setRequirement(requirements.requirements[reqtype]);
    setMode(MODE_CONFIRM_INCOME);
  };

  const onDocumentClick = (document: any) => {
    setSelectedDocument(document);
    setMode(MODE_TIPS);
  };

  const onUploadSuccess = (document: any) => {
    setDocumentsToSubmit((prev: any) => {
      const current = prev;
      const i = current.findIndex(
        (el: any) => el.uploadUrl === document?.documentToSubmit?.uploadUrl,
      );
      current[i].submitted = true;
      return current;
    });
  };

  const onCancel = () => {
    setMode((prev: any) => {
      if (prev === MODE_CONFIRM_INCOME) return MODE_REQUIREMENTS;
      return MODE_CONFIRM_INCOME;
    });
  };

  const onComplete = () => setMode(MODE_COMPLETE);

  const onFileSelected = (document: File) => {
    setFile({
      documentType: selectedDocument.documentType,
      document,
    });
    setMode(MODE_CHECK_YOUR_DOCS);
  };

  const onUpload = (
    document: File,
    documentType: any = selectedDocument?.documentType,
    uploadUrl: any = selectedDocument.uploadUrl,
  ) => {
    setDocumentsToSubmit((prev: any) => [
      ...prev.filter((el: any) => el.documentType !== documentType),
      {
        document,
        documentType,
        uploadUrl,
        submitted: false,
      },
    ]);
    setMode(MODE_CONFIRM_INCOME);
  };

  if (mode === MODE_REQUIREMENTS)
    return <Requirements {...{ requirements, onRequirementTypeClick }} />;

  if (mode === MODE_CONFIRM_INCOME)
    return (
      <ConfirmIncome
        {...{
          requirement,
          documentsToSubmit,
          setDocumentsToSubmit,
          onDocumentClick,
          onUpload,
          onComplete,
          onUploadSuccess,
          applicationId,
          onCancel,
        }}
      />
    );

  if (mode === MODE_CHECK_YOUR_DOCS)
    return <CheckYourDocument {...{ ...file, onUpload }} />;

  if (mode === MODE_TIPS)
    return <DocumentTips {...{ selectedDocument, onCancel, onFileSelected }} />;

  if (mode === MODE_COMPLETE) return <Complete />;

  return null;
};

export const DocumentUploadStep = {
  path: 'document-upload',
  component: DocumentUpload,
  provides: [],
};
