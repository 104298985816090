export const REASON_ID = {
  INTERNAL_GENERAL: `Internal-General`,
  INTERNAL_6MSTREAK: `Internal-6mStreak`,
  AFFORDABILITY_LOANCOMPLETION: `Affordability-LoanCompletion`,
  AFFORDABILITY_GENERAL: `Affordability-General`,
  AFFORDABILITY_INCOMEVERIFICATION: `Affordability-IncomeVerification`,
  BUREAU_GENERAL: `Bureau-General`,
  GAMBLING_GENERAL: `Gambling-General`,
  TECHNICALERROR_GENERAL: `TechnicalError-General`,
};

export const defaultTitle = `We've had a look at your application {name} and we're sorry, but we can't offer you a top up today`;
export const applyAgainMessage = `This doesn't mean we won't be able to offer you a loan in the future if you apply again.`;
export const applyNewData = {
  message: `If you wish to borrow more, please apply for a new loan by `,
  linkText: 'clicking here',
};
export const debtHelpData = {
  message: `If you're struggling with debt and need help, please contact:`,
  linkText: `Step Change`,
  link: 'https://www.stepchange.org/',
};
export const creditDetails = {
  message: `To review and correct any adverse data you believe to be incorrect, please contact the credit bureaux using the details below:`,
  linkText: {
    experian: `Visit Experian`,
    equifax: `Visit Equifax`,
  },
  link: {
    experian: `https://www.experian.co.uk`,
    equifax: `https://www.equifax.co.uk/`,
  },
};

export const messageMap = {
  [REASON_ID.INTERNAL_GENERAL]: [
    `Unfortunately, we can’t offer further borrowing to you at this time.`,
  ],
  [REASON_ID.INTERNAL_6MSTREAK]: [
    `You need to make at least 6 repayments on time and in a row in order to be eligible for a top up loan.`,
  ],
  [REASON_ID.AFFORDABILITY_LOANCOMPLETION]: [
    `You are about to repay your current loan in full therefore a Top Up wouldn't be the best option for you right now.`,
  ],
  [REASON_ID.AFFORDABILITY_GENERAL]: [
    `Unfortunately, your application did not pass our affordability assessment and we are unable to lend more to you at this time.`,
  ],
  [REASON_ID.AFFORDABILITY_INCOMEVERIFICATION]: [
    `Unfortunately, your application did not pass our affordability assessment and we are unable to lend more to you at this time.`,
  ],
  [REASON_ID.BUREAU_GENERAL]: [
    `Based on the information contained in your credit file, we believe it would be inappropriate to lend to you today.`,
  ],
  [REASON_ID.GAMBLING_GENERAL]: [
    `Unfortunately, we can’t offer further borrowing to you as we've identified a high level of gambling activity from the information on your application.`,
  ],
  [REASON_ID.TECHNICALERROR_GENERAL]: [
    `Unfortunately, there has been a technical issue when processing your application. Please try again later. If the problem has been ongoing for more than 48 hours, please contact us here:`,
  ],
};

export const titleMap = {
  [REASON_ID.TECHNICALERROR_GENERAL]:
    'Unfortunately, there has been a technical issue when processing your application.',
};

export const defaultView = {
  showApplyAgainMessage: true,
  showApplyNewMessage: false,
  showDebtHelp: false,
  showCreditDetails: false,
  showContactUs: false,
};

export const viewOverrides = {
  [REASON_ID.AFFORDABILITY_LOANCOMPLETION]: {
    ...defaultView,
    showApplyNewMessage: true,
    showApplyAgainMessage: false,
  },
  [REASON_ID.AFFORDABILITY_GENERAL]: { ...defaultView, showDebtHelp: true },
  [REASON_ID.AFFORDABILITY_INCOMEVERIFICATION]: { ...defaultView, showDebtHelp: true },
  [REASON_ID.BUREAU_GENERAL]: {
    ...defaultView,
    showCreditDetails: true,
    showDebtHelp: true,
  },
  [REASON_ID.TECHNICALERROR_GENERAL]: {
    ...defaultView,
    showContactUs: true,
    showApplyAgainMessage: false,
  },
};
