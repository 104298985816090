import { Flex, Loader, Paragraph } from '@sequensis/stylish-core';

interface LoadingProps {
  message?: string;
  dataTestId?: string;
}

export const Loading = ({ message, dataTestId }: LoadingProps) => {
  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <Loader />
      {message && (
        <Paragraph
          fontSize="large"
          fontWeight="bold"
          textAlign="center"
          marginTop={3}
          data-testid={dataTestId}
        >
          {message}
        </Paragraph>
      )}
    </Flex>
  );
};

export default Loading;
