import { ActionType, createReducer } from 'typesafe-actions';

import { CustomerProps } from 'src/types/CustomerProps';
import { mergeState } from 'src/utils/reduxHelper';

import { fetchCustomer } from './actions';
import * as actions from './actions';

export interface CustomerState {
  customer: CustomerProps | undefined;
  fetching: boolean;
  fetchError: string | undefined;
}

const INITIAL_STATE: CustomerState = {
  customer: undefined,
  fetching: false,
  fetchError: undefined,
};

type CustomerAction = ActionType<typeof actions>;

const reducer = createReducer<CustomerState, CustomerAction>(INITIAL_STATE)
  .handleAction(fetchCustomer.request, state =>
    mergeState(state, { fetching: true, fetchError: undefined }),
  )
  .handleAction(fetchCustomer.failure, (state, action) =>
    mergeState(state, { fetching: false, fetchError: action.payload }),
  )
  .handleAction(fetchCustomer.success, (state, action) =>
    mergeState(state, { fetching: false, customer: action.payload }),
  );

export default reducer;
