import React, { useState } from 'react';

import { Box, Button, Checkbox, Paragraph, Spaced, Text } from '@sequensis/stylish-core';

import { DirectDebitGuaranteeModal } from './DirectDebitGuaranteeModal';

interface DirectDebitAuthorisationProps {
  userIsAccountHolder: boolean;
  userIsAuthorised: boolean;
  onUserIsAccountHolderChange?(isChecked: boolean): void;
  onUserIsAuthorisedChange?(isChecked: boolean): void;
}

export const DirectDebitAuthorisation = ({
  userIsAccountHolder,
  userIsAuthorised,
  onUserIsAccountHolderChange,
  onUserIsAuthorisedChange,
}: DirectDebitAuthorisationProps) => {
  const [showGuarantee, setShowGuarantee] = useState(false);

  const handleUserIsAccountHolderChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    onUserIsAccountHolderChange && onUserIsAccountHolderChange(event.target.checked);
  };

  const handleUserIsAuthorisedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onUserIsAuthorisedChange && onUserIsAuthorisedChange(event.target.checked);
  };

  return (
    <>
      <DirectDebitGuaranteeModal
        isOpen={showGuarantee}
        onClose={() => setShowGuarantee(false)}
      />
      <Spaced marginBottom={4} marginTop={4}>
        <Checkbox
          label="I confirm that I’m the bank account holder"
          checked={userIsAccountHolder}
          onChange={handleUserIsAccountHolderChange}
          id="checkbox-account-holder"
        />
        <Checkbox
          checked={userIsAuthorised}
          onChange={handleUserIsAuthorisedChange}
          label="I confirm that I am the only person required to authorise Direct Debits on this account"
          id="checkbox-authorise"
        />
        <Box>
          <Paragraph>
            Your payments are protected by the
            <Button
              variant="tertiary"
              size="slim"
              onClick={() => setShowGuarantee(true)}
              data-testid="create-directdebit-view-guarantee"
            >
              <Text fontSize="small">Direct Debit Guarantee</Text>
            </Button>
          </Paragraph>
        </Box>
      </Spaced>
    </>
  );
};
