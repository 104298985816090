import { Flex, Loader, StepList, Text } from '@sequensis/stylish-core';

import { useAutoCounter } from 'src/hooks/useAutoCounter';

const LOADING_STEPS = ['Details ', 'Employment ', 'Income', 'Outgoings', 'Affordability'];

interface LoadingStepProps {
  step: string;
  index: number;
  currentStep: number;
}
const LoadingStep = ({ step, index, currentStep }: LoadingStepProps) => {
  const getStepState = (step: string) => {
    if (currentStep < index + 1) return `Check ${step.toLowerCase()}`;
    if (currentStep === index + 1) return `Checking ${step.toLowerCase()}`;
    return `${step} checked`;
  };

  return <Text size="2xl">{getStepState(step)}</Text>;
};

export const SoftSearchLoader = () => {
  const currentStep = useAutoCounter(0, LOADING_STEPS.length + 1, 2500);

  return (
    <>
      <Flex flexWrap="wrap" height="100%">
        <Flex marginLeft="3.625rem" width="100%">
          <StepList
            variant="darkBackground"
            marginBetweenSteps={5}
            currentStep={currentStep}
          >
            {LOADING_STEPS.map((step, index) => (
              <LoadingStep
                key={step}
                step={step}
                index={index}
                currentStep={currentStep}
              />
            ))}
          </StepList>
        </Flex>
        <Flex justifyContent="center" alignSelf="flex-end" width="100%">
          <Loader marginBottom={6} data-testid="soft-search-loader" />
        </Flex>
      </Flex>
    </>
  );
};

export const SoftSearchLoaderStep = {
  path: 'soft-searching',
  component: SoftSearchLoader,
  provides: [],
};
