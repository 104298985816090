import { getColor } from '@sequensis/stylish-core';
import styled from 'styled-components';

const Svg = styled.svg`
  height: 1rem;
`;

const Path = styled.path`
  fill: ${getColor('primary')};
`;

const SlantedBurger = () => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 17">
      <g fill="none" fillRule="evenodd">
        <path d="M-9-16h48v48H-9z" />
        <Path
          fill="#000"
          fill-rule="nonzero"
          d="M28.5 14c.8284271 0 1.5.6715729 1.5 1.5s-.6715729 1.5-1.5 1.5h-19c-.8284271 0-1.5-.6715729-1.5-1.5S8.6715729 14 9.5 14h19zm-4-7c.8284271 0 1.5.6715729 1.5 1.5s-.6715729 1.5-1.5 1.5h-19C4.6715729 10 4 9.3284271 4 8.5S4.6715729 7 5.5 7h19zm-4-7c.8284271 0 1.5.6715729 1.5 1.5S21.3284271 3 20.5 3h-19C.67157288 3 0 2.3284271 0 1.5S.67157288 0 1.5 0h19z"
        />
      </g>
    </Svg>
  );
};

export default SlantedBurger;
