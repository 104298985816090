import { MultipartComponentTheme } from '@sequensis/stylish-core';

export const EXPLAINER_STYLES: MultipartComponentTheme = {
  baseStyle: {
    button: {
      padding: '0',
      paddingBottom: '10px',
      width: '100%',
      height: 'auto',
      display: 'flex',
      justifyContent: 'left',
      alignContent: 'center',
      color: 'primary',
      backgroundColor: 'inherit',
    },
    bubble: {
      position: 'relative',
      border: '3px solid',
      borderColor: 'primaryTint',
      borderRadius: 'large',
      backgroundColor: 'secondaryBg',
      marignTop: 2,
      padding: 2,
    },
    bubbleTag: {
      content: '',
      display: 'block',
      position: 'absolute',
      top: '-2px',
      left: '5rem',
      width: '1.25rem',
      height: '1.25rem',
      transform: 'translate(0, -50%) rotate(45deg)',
      border: '3px solid',
      borderColor: 'primaryTint',
      borderRadius: '0.5rem 0 0 0',
      borderWidth: '3px 0 0 3px',
    },
    text: {
      marginLeft: '1',
      flexGrow: '1',
      textAlign: 'left',
    },
    explainerBox: {},
  },
};
