import { oakbrookTheme } from '@sequensis/stylish-core';

import { finioLoansHubTheme, likelyTheme, oakbrookLoansHubTheme } from 'src/Theme';
import { Product } from 'src/types/ConfigProps';

export const getProductConfigByPath = (
  products: Product[],
  path?: string,
): Product | undefined => products.find((product) => product.baseRoute === path);

export const getProductTheme = (product: string) => {
  switch (product.toLowerCase()) {
    case 'likelyloans':
      return likelyTheme;
    case 'oakbrookloans':
      return oakbrookLoansHubTheme;
    case 'finioloans':
      return finioLoansHubTheme;
    default:
      return oakbrookTheme;
  }
};
