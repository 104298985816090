import NumberFormat from 'react-number-format';

import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import { Redact } from '@sequensis/react-monitoring';
import { Box, Flex, Icon, Paragraph } from '@sequensis/stylish-core';

import { BankAccount } from 'src/api/account/types';

interface BankAccountOverviewProps {
  bankAccount: BankAccount;
}

export const BankAccountOverview = ({ bankAccount }: BankAccountOverviewProps) => (
  <>
    <Flex flexDirection="row" marginY={3}>
      <Icon icon={faCheckCircle} size="lg" color="positive" maxHeight={24} />

      <Box marginLeft={4}>
        <Paragraph> Bank account number:</Paragraph>
        <Redact>
          <Paragraph fontSize="medium">{bankAccount?.accountNumber}</Paragraph>
        </Redact>
      </Box>
    </Flex>
    <Flex flexDirection="row" marginTop={2}>
      <Icon icon={faCheckCircle} size="lg" color="positive" maxHeight={24} />

      <Box marginLeft={4}>
        <Paragraph> Sort code: </Paragraph>
        <Redact>
          <Paragraph fontSize="medium">
            <NumberFormat
              displayType="text"
              format="## - ## - ##"
              value={bankAccount.sortCode}
            />
          </Paragraph>
        </Redact>
      </Box>
    </Flex>
    <Flex flexDirection="row" marginTop={2}>
      <Icon icon={faCheckCircle} size="lg" color="positive" maxHeight={24} />

      <Box marginLeft={4}>
        <Paragraph> Name on the account:</Paragraph>
        <Redact>
          <Paragraph fontSize="medium">{bankAccount?.accountName}</Paragraph>
        </Redact>
      </Box>
    </Flex>
  </>
);
