import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom-v5-compat';

import { Card, Paragraph } from '@sequensis/stylish-core';

import { ButtonLink } from 'src/components/ButtonLink';
import { getArrearsAmount, getIsInPaymentPlan } from 'src/store/Account/selectors';
import { formatCurrency } from 'src/utils/formatNumber';

export type MissedPaymentsProps = {
  className?: string;
};

const MissedPayments = ({ className }: MissedPaymentsProps) => {
  const arrearsAmount = useSelector(getArrearsAmount);
  const isInPaymentPlan = useSelector(getIsInPaymentPlan);
  const location = useLocation();

  return (
    <Card
      data-testid="missed-payments-card"
      heading="Missed payments"
      className={className}
    >
      <Paragraph
        textAlign="center"
        fontSize="large"
        fontWeight="bold"
        fontFamily="heading"
      >
        - {formatCurrency(arrearsAmount)}
      </Paragraph>

      {!isInPaymentPlan && (
        <Paragraph>Make a payment now to minimise impact to your credit file</Paragraph>
      )}

      <ButtonLink
        navigational
        marginTop={1}
        to="../arrears"
        state={{ background: location }}
      >
        View my arrears
      </ButtonLink>
    </Card>
  );
};

export default MissedPayments;
