import { useCallback, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useAuth } from '@sequensis/react-auth';
import { useConfig } from '@sequensis/react-config';
import { useAsyncCallback } from '@sequensis/react-hooks';
import { Alert, Flex, Paragraph, Text } from '@sequensis/stylish-core';

import {
  previewPaymentScheduleChange,
  submitPaymentScheduleChange,
} from 'src/api/account/accountApi';
import { analyticsClickEvent } from 'src/components/Analytics';
import { FeedbackAlert } from 'src/components/Delighted';
import { SuccessDisplay } from 'src/components/SuccessDisplay';
import { usePaymentScheduleContext } from 'src/context/PaymentScheduleContext';
import { useProduct } from 'src/hooks/useProduct';
import { fetchAccount } from 'src/store/Account';
import {
  getAccountId,
  getNextRepaymentAmount,
  getNextRepaymentDate,
  getRepaymentMandateBankDetails,
} from 'src/store/Account/selectors';
import { formatCurrency } from 'src/utils';
import { formatDate, formatDay } from 'src/utils/formatDate';

import { ChoosePaymentDay } from '../Components/ChoosePaymentDay';

import { ConfirmDirectDebitChange } from './ConfirmDirectDebitChange';

const ChangeDirectDebitPaymentDay = () => {
  const dispatch = useDispatch();
  const repaymentDate = useSelector(getNextRepaymentDate);
  const repaymentAmount = useSelector(getNextRepaymentAmount);
  const repaymentDay = parseFloat(formatDay(repaymentDate));
  const accountId = useSelector(getAccountId) ?? '';

  const bankAccount = useSelector(getRepaymentMandateBankDetails);

  const product = useProduct();

  const { fetchPaymentSchedule } = usePaymentScheduleContext();

  const [chosenDayOfMonth, setChosenDayOfMonth] = useState<number>(repaymentDay);

  const {
    accountManagementApi: { url: accountManagementApiUrl },
  } = useConfig();

  const { getToken } = useAuth();

  const refreshAccount = useCallback(async () => {
    const authToken = await getToken();

    dispatch(
      fetchAccount.request({
        accountId,
        apiUrl: accountManagementApiUrl,
        authToken,
      }),
    );
  }, [dispatch, accountId, getToken, accountManagementApiUrl]);

  const [scheduleChangePreviewState, postScheduleChangePreview] = useAsyncCallback(
    previewPaymentScheduleChange,
  );

  const [scheduleChangeState, postScheduleChange] = useAsyncCallback(
    submitPaymentScheduleChange,
  );

  const schedulePreview = scheduleChangePreviewState.result?.responseJson;

  const handlePaymentDayChosen = async (dayOfMonth: number) => {
    setChosenDayOfMonth(dayOfMonth);
    analyticsClickEvent('CustomerDirectDebit', 'edit-directdebit-paymentday-chosen');

    const token = await getToken();

    postScheduleChangePreview(accountManagementApiUrl, accountId, token, {
      dayOfMonth,
    });
  };

  const handleSubmitConfirmation = async () => {
    const token = await getToken();
    analyticsClickEvent('CustomerDirectDebit', 'edit-directdebit-paymentday-confirm');

    postScheduleChange(accountManagementApiUrl, accountId, token, {
      dayOfMonth: chosenDayOfMonth,
    });
  };

  const handleDoneClick = () => {
    analyticsClickEvent('CustomerDirectDebit', 'edit-directdebit-paymentday-complete');
    refreshAccount();
    fetchPaymentSchedule();
  };

  const showPaymentDayChoice = scheduleChangePreviewState.status !== 'success';
  const showConfirmScreen =
    scheduleChangePreviewState.status === 'success' &&
    scheduleChangeState.status !== 'success';

  const feedbackBaseQueryString = `Site=PaymentDay&Product=`;

  return (
    <>
      {showPaymentDayChoice && (
        <ChoosePaymentDay
          confirmButtonTestId="edit-directdebit-choosepaymentday-button"
          onPaymentDayChosen={handlePaymentDayChosen}
          isSubmitting={scheduleChangePreviewState.loading}
          error={
            scheduleChangePreviewState.error &&
            'We were unable to change your payment day. Please try again later.'
          }
        />
      )}
      {showConfirmScreen && schedulePreview && (
        <ConfirmDirectDebitChange
          schedule={schedulePreview}
          bankAccount={bankAccount}
          onConfirm={handleSubmitConfirmation}
          isSubmitting={scheduleChangeState.loading}
          error={
            scheduleChangeState.error &&
            'We were unable to change your payment day. Please try again later.'
          }
        />
      )}
      {scheduleChangeState.status === 'success' && (
        <SuccessDisplay
          title="Your Direct Debit has been updated"
          doneButtonTestId="edit-directdebit-paymentday-button"
          onDoneClick={handleDoneClick}
        >
          <Paragraph marginY={4}>
            We&apos;ve updated your Direct Debit and you should receive your new schedule
            soon.
          </Paragraph>
          <Paragraph fontSize="large" fontWeight="bold">
            Your next payment is due
          </Paragraph>
          <Alert mt={3}>
            <Flex flex="1 1 auto" alignItems="center" justifyContent="space-between">
              <Text>{formatDate(repaymentDate ?? '')}</Text>
              <Text fontSize="large" fontWeight="bold" fontFamily="heading">
                {formatCurrency(repaymentAmount)}
              </Text>
            </Flex>
          </Alert>
          <FeedbackAlert
            feedbackQueryString={`${feedbackBaseQueryString}${product.name}`}
            marginTop={4}
            marginBottom={4}
          />
        </SuccessDisplay>
      )}
    </>
  );
};

export default ChangeDirectDebitPaymentDay;
