interface TelephoneLinkProps {
  number: string;
  className?: string;
}

const TelephoneLink = ({ number, className }: TelephoneLinkProps) => (
  <a className={className} href={`tel: ${number}`}>
    {number}
  </a>
);

export default TelephoneLink;
