import * as Yup from 'yup';

import { BankAccount } from 'src/api/account/types';

const accountNameValidationMessage = 'Enter the name on the account';
const accountNumberValidationMessage = 'Enter a valid number';
const sortCodeValidationMessage = 'Enter a valid sort code';

export const bankAccountValidationSchema: Yup.SchemaOf<BankAccount> = Yup.object().shape({
  accountName: Yup.string().label('Account name').required(accountNameValidationMessage),
  accountNumber: Yup.string()
    .label('Account number')
    .required(accountNumberValidationMessage)
    .matches(/\d{8}/, { message: accountNumberValidationMessage }),
  sortCode: Yup.string()
    .label('Sort Code')
    .required(sortCodeValidationMessage)
    .matches(/\d\d - \d\d - \d\d/, { message: sortCodeValidationMessage }),
});
