import {
  ConfigureOfferForm,
  DirectDebitForm,
  EmploymentForm,
  HardSearchForm,
  IncomeForm,
  SoftSearchForm,
  SpendingForm,
} from 'src/screens/TopUp';

export type EnquiryForm = {
  newResidentialStatus: string;
  currentAccountId: string;
} & ConfigureOfferForm &
  IncomeForm &
  EmploymentForm &
  SpendingForm &
  SoftSearchForm;

export type ApplicationForm = { enquiryId: string; offerId: string } & DirectDebitForm &
  HardSearchForm;

export enum TopUpStage {
  ENQUIRY = 'enquiry',
  SOFT_SEARCH_LOADER = 'soft-search-loader',
  OFFER = 'offer',
  HARD_SEARCH_LOADER = 'hard-search-loader',
  COMPLETE = 'complete',
  DECLINE = 'decline',
  DONE = 'done',
  DOCUMENT_UPLOAD = 'document-upload',
  DOCUMENT_UPLOAD_LOADER = 'document-upload-loader',
  DISBURSAL_DELAY = 'disbursal-delay',
}

export enum EnquiryStatus {
  NOT_SUBMITTED = 'Not Submitted',
  PENDING = 'Pending',
  RESOLVED = 'Resolved',
}

export enum ApplicationStatus {
  NOT_SUBMITTED = 'Not Submitted',
  PENDING = 'Pending',
  RESOLVED = 'Resolved',
  DOCUMENT_UPLOAD = 'Document Upload',
  DONE = 'Done',
  DISBURSAL_DELAY = 'disbursal_delay',
}

export interface TopUpState {
  enquiryStatus: EnquiryStatus;
  offerCount?: number;
  applicationStatus: ApplicationStatus;
}

interface Loads {
  isLoading?: boolean;
}

interface Fails {
  error?: string;
}

export interface Playback<TResponse> extends Loads, Fails {
  playback?: TResponse;
}

export interface SubmissionAction<TData> extends Loads, Fails {
  onAction: (data: TData) => void;
}
