import { Halo } from 'src/components/Halo';

export const ChartIcon = () => (
  <Halo>
    <path
      d="M59.1406 71.7188C59.6094 72.1875 60.4688 72.1875 60.9375 71.7188L65 67.6562L71.5625 74.2969C72.0312 74.7656 72.8906 74.7656 73.3594 74.2969L81.9531 65.7031L84.2969 67.9688C85.4688 69.2188 87.5 68.3594 87.5 66.6406V58.75C87.5 58.125 86.875 57.5 86.25 57.5H78.3594C76.6406 57.5 75.7812 59.5312 77.0312 60.7031L79.2969 63.0469L72.5 69.9219L65.8594 63.2812C65.3906 62.8125 64.5312 62.8125 64.0625 63.2812L58.2812 69.0625C57.8125 69.5312 57.8125 70.3906 58.2812 70.8594L59.1406 71.7188ZM88.75 81.25H53.75V56.25C53.75 55.625 53.125 55 52.5 55H51.25C50.5469 55 50 55.625 50 56.25V82.5C50 83.9062 51.0938 85 52.5 85H88.75C89.375 85 90 84.4531 90 83.75V82.5C90 81.875 89.375 81.25 88.75 81.25Z"
      fill="#000067"
    />
  </Halo>
);
