import { ActionType, createReducer } from 'typesafe-actions';

import { fetchAccount } from 'src/store/Account/actions';
import * as actions from 'src/store/Account/actions';
import { AllAccountProps } from 'src/types/AccountProps';
import { mergeState } from 'src/utils/reduxHelper';

export interface AccountsState {
  accounts: Record<string, AllAccountProps> | undefined;
}

const INITIAL_STATE: AccountsState = {
  accounts: {},
};

type AccountAction = ActionType<typeof actions>;

export const AccountsReducer = createReducer<AccountsState, AccountAction>(
  INITIAL_STATE,
).handleAction(fetchAccount.success, (state, { payload: account }) => {
  const accounts = { ...state.accounts };
  accounts[account.id] = account;
  return mergeState(state, { accounts: accounts });
});
