import { Card, Paragraph } from '@sequensis/stylish-core';
import { PageHeader } from '@sequensis/stylish-layouts';

import { ButtonLink } from 'src/components/ButtonLink';
export const PaymentSuccess = () => {
  return (
    <>
      <PageHeader title="Your payment was successful" />
      <Card>
        <Paragraph mb={1}>Your balance will take around an hour to update.</Paragraph>

        <ButtonLink variant="secondary" to="../balance" mt={3}>
          Return to account summary
        </ButtonLink>
      </Card>
    </>
  );
};

export default PaymentSuccess;
