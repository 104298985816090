import { useEffect } from 'react';

import { useToast } from '@sequensis/stylish-core';
import { ToastOptions } from '@sequensis/stylish-core/dist/typings/components/Toast/Toast.types';

export const useRaiseSubmissionError = (
  error: string | undefined,
  options?: Partial<ToastOptions>,
) => {
  const { raiseToast } = useToast();

  useEffect(() => {
    if (error) raiseToast({ message: error, status: 'error', ...options });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
};
