import { Box, RadioGroup, RadioOption } from '@sequensis/stylish-core';
import { range } from 'ramda';

interface DayPickerProps {
  startDay: number;
  endDay: number;
  testId: string;
  disabled?: boolean;
  value?: number;
  onChange?(currentValue: number): void;
}

export const DayPicker = ({
  startDay,
  endDay,
  disabled,
  value,
  onChange,
  testId,
}: DayPickerProps) => {
  const days = range(startDay, endDay + 1);

  const handleChange = (currentValue: number) => {
    onChange && onChange(currentValue);
  };

  return (
    <Box ml={2}>
      <RadioGroup
        selected={value}
        onChange={handleChange}
        variant="circle"
        data-testid={testId}
        disabled={disabled}
      >
        {days.map((day) => (
          <RadioOption key={day} value={day}>
            {day}
          </RadioOption>
        ))}
      </RadioGroup>
    </Box>
  );
};
