import { Controller, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers';
import {
  Alert,
  Anchor,
  BlockList,
  BlockListItem,
  Box,
  Button,
  Card,
  Checkbox,
  Flex,
  Heading,
  Paragraph,
  Spaced,
  Text,
  useModal,
} from '@sequensis/stylish-core';
import * as yup from 'yup';

import { Redact } from '@sequensis/react-monitoring';
import { ProgressHeader } from 'src/components/ProgressHeader';
import { useRaiseSubmissionError } from 'src/hooks/useRaiseSubmissionError';
import DirectDebitGuaranteeLogo from 'src/images/direct-debit-gurantee.png';
import { Playback, SubmissionAction } from 'src/screens/TopUp/types';
import { formatBankAccountNumber } from 'src/utils/formatBankAccountNumber';
import { formatDayAndSuperscript } from 'src/utils/formatDate';
import { useProduct } from 'src/hooks/useProduct';
import { formatSortCode } from 'src/utils/formatSortCode';
export interface RepaymentDayPlayback {
  repaymentDay: number;
}
export interface BankDetailsPlayback {
  accountNumber: string;
  sortCode: string;
  accountName: string;
}
export interface DirectDebitProps {
  progress: number;
  repaymentDayPlayback: Playback<RepaymentDayPlayback>;
  bankDetailsPlayback: Playback<BankDetailsPlayback>;
  onDirectDebitSubmission: SubmissionAction<DirectDebitForm>;
}
export interface DirectDebitForm {
  accountHolderConfirmation: boolean;
  authorisedConfirmation: boolean;
}

const directDebitFormSchema: yup.SchemaOf<DirectDebitForm> = yup.object().shape({
  accountHolderConfirmation: yup
    .boolean()
    .isTrue('Confirmation that user is account holder is required')
    .required(),
  authorisedConfirmation: yup
    .boolean()
    .isTrue('Confirmation that user is authorised to set up Direct Debits is required')
    .required(),
});

const formatRepaymentDay = (repaymentDay: number) => {
  const date = new Date();
  date.setDate(repaymentDay);
  return `${formatDayAndSuperscript(date)} of each month`;
};

export const DirectDebit = ({
  progress,
  repaymentDayPlayback: {
    error: repaymentDayPlayBackError,
    playback: repaymentDayPlayback,
  },
  bankDetailsPlayback: { error: bankDetailsPlayBackError, playback: bankDetailsPlayback },
  onDirectDebitSubmission: {
    error: directDebitError,
    isLoading: isDirectDebitSubmitLoading,
    onAction: onDirectDebitSubmit,
  },
}: DirectDebitProps) => {
  const {
    copyConfig: {
      tradingName: { plural: productNamePlural },
    },
  } = useProduct();

  const { open } = useModal(
    {
      header: (
        <Flex alignItems="center" justifyContent="space-between">
          <Heading size="xl">Direct Debit Gurantee</Heading>
          <Box
            marginRight={3}
            as="img"
            src={DirectDebitGuaranteeLogo}
            alt="Direct debit guarantee"
          />
        </Flex>
      ),
      content: (
        <Spaced marginBottom={3} excludeLast>
          <Paragraph size="md">
            This guarantee is offered by all banks and building societies that accept
            instructions to pay direct debits.
          </Paragraph>
          <Paragraph size="md">
            If there are any changes to the amount, date or frequency of your direct debit
            {` ${productNamePlural}`} will notify you within 10 working days of your
            account being debited or otherwise agreed. If you request {productNamePlural}{' '}
            to collect a payment, confirmation of the amount and date will be given to you
            at the time of the request.
          </Paragraph>
          <Paragraph size="md">
            If an error is made in the payment of your Direct Debit, by{' '}
            {productNamePlural} or your bank or building society, you are entitled to a
            full and immediate refund of the amount paid from your bank or building
            society.
          </Paragraph>
          <Paragraph size="md">
            If you receive a refund you are not entitled to, you must pay it back when
            {` ${productNamePlural}`} asks you to.
          </Paragraph>
          <Paragraph size="md">
            You can cancel a Direct Debit at any time by simply contacting your bank or
            building society. Written confirmation may be required. Please also notify us.
          </Paragraph>
        </Spaced>
      ),
    },
    [productNamePlural],
  );

  const { control, handleSubmit, errors } = useForm<DirectDebitForm>({
    mode: 'onBlur',
    resolver: yupResolver(directDebitFormSchema),
  });

  useRaiseSubmissionError(directDebitError);

  const hasPlaybackErrors =
    repaymentDayPlayBackError !== undefined || bankDetailsPlayBackError !== undefined;

  return (
    <Spaced marginBottom={4} excludeLast>
      <ProgressHeader
        currentStep="Set up your Direct Debit"
        progress={progress}
        stepText="Step 3/3"
      />
      <Card heading="Your current repayment date">
        <Spaced marginY={3}>
          {repaymentDayPlayback && (
            <BlockList variant="alternating">
              <BlockListItem>
                <Flex justifyContent="space-between" flex="1 1 auto">
                  <Text>On or around</Text>
                  <Text fontWeight="bold">
                    {formatRepaymentDay(repaymentDayPlayback.repaymentDay)}
                  </Text>
                </Flex>
              </BlockListItem>
              <BlockListItem>
                <Paragraph>
                  You can change your repayment date later. Do this by visiting the
                  payments section in your online account.
                </Paragraph>
              </BlockListItem>
            </BlockList>
          )}
          <Heading size="xl">Your bank account</Heading>
          {bankDetailsPlayback && (
            <>
              <Spaced>
                <Redact>
                  <Paragraph size="md">
                    <Text variant="emphasis" fontWeight="bold">
                      Bank account:
                    </Text>{' '}
                    {formatBankAccountNumber(bankDetailsPlayback.accountNumber)}
                  </Paragraph>
                </Redact>
                <Redact>
                  <Paragraph size="md">
                    <Text variant="emphasis" fontWeight="bold">
                      Sort code:{' '}
                    </Text>
                    {formatSortCode(bankDetailsPlayback.sortCode)}
                  </Paragraph>
                </Redact>
                <Redact>
                  <Paragraph size="md">
                    <Text variant="emphasis" fontWeight="bold">
                      Name on account:
                    </Text>{' '}
                    {bankDetailsPlayback.accountName}
                  </Paragraph>
                </Redact>
              </Spaced>
            </>
          )}
          {bankDetailsPlayBackError && (
            <Alert spacing="slim" variant="negative">
              {bankDetailsPlayBackError}
            </Alert>
          )}
        </Spaced>
        <form onSubmit={handleSubmit((data) => onDirectDebitSubmit(data))}>
          <Spaced marginY={5} excludeLast>
            <Controller
              render={({ value, onChange }) => (
                <Checkbox
                  id="account-holder-confirmation-checkbox"
                  name="accountHolderConfirmation"
                  label={
                    <Paragraph size="md" ml={1}>
                      I confirm that I&apos;m the bank account holder
                    </Paragraph>
                  }
                  checked={value}
                  onChange={(e: React.FormEvent<HTMLInputElement>) =>
                    onChange(e.currentTarget.checked)
                  }
                  error={errors.accountHolderConfirmation?.message}
                />
              )}
              name="accountHolderConfirmation"
              control={control}
              defaultValue={false}
            />
            <Controller
              render={({ value, onChange }) => (
                <Checkbox
                  id="authorised-confirmation-checkbox"
                  name="authorisedConfirmation"
                  label={
                    <Paragraph size="md" ml={1}>
                      I confirm that I&apos;m authorised to set up Direct Debits on this
                      account
                    </Paragraph>
                  }
                  checked={value}
                  onChange={(e: React.FormEvent<HTMLInputElement>) =>
                    onChange(e.currentTarget.checked)
                  }
                  error={errors && errors.authorisedConfirmation?.message}
                />
              )}
              name="authorisedConfirmation"
              control={control}
              defaultValue={false}
            />
            <Spaced marginBottom={3} excludeLast>
              <Paragraph size="md">
                Details of your Direct Debit will be included in the welcome pack{' '}
                {"we'll "}
                send you.
              </Paragraph>
              <Paragraph size="md">
                Your payments are protected by the{' '}
                <Anchor onClick={() => open()}>
                  <Paragraph size="lg" variant="emphasis">
                    Direct Debit guarantee
                  </Paragraph>
                </Anchor>
              </Paragraph>
            </Spaced>
            <Button
              data-testid="direct-debitConsent-submit-button"
              fullWidth
              isLoading={isDirectDebitSubmitLoading}
              disabled={hasPlaybackErrors}
            >
              Continue
            </Button>
          </Spaced>
        </form>
      </Card>
    </Spaced>
  );
};

export const DirectDebitStep = {
  path: 'direct-debit',
  component: DirectDebit,
  provides: ['directDebitConsent'],
};
