import { Alert } from '@sequensis/stylish-core';

import { mapTelephoneAlertMessage } from './mapTelephoneAlertMessage';

export type Status = 'success' | 'error';

export interface TelephonePendingAlertProps {
  status: Status;
  label: string;
}

export function TelephonePendingAlert({ status, label }: TelephonePendingAlertProps) {
  const { message, alertType } = mapTelephoneAlertMessage(label, status);

  return (
    <Alert
      icon
      marginBottom={4}
      variant={alertType}
      data-testid="profile-phoneno-edit-address-alert-message"
    >
      {message}
    </Alert>
  );
}
