import { useState } from 'react';

import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { Alert, Box, Flex, getColor, Heading, Icon, Text } from '@sequensis/stylish-core';
import { map, pipe, take } from 'ramda';
import styled from 'styled-components/macro';

import { PaymentScheduleEvent } from 'src/api/account/types';
import { formatDate } from 'src/utils/formatDate';
import { formatCurrency } from 'src/utils/formatNumber';

interface PaymentScheduleViewProps {
  schedule: readonly PaymentScheduleEvent[];
  title?: string;
  className?: string;
}

const StyledIcon = styled(Icon)`
  color: ${getColor('base')};
`;

const PaymentScheduleContainer = styled(Alert)`
  padding-left: 0;
  padding-right: 0;
`;

const PaymentScheduleRowContainer = styled(Flex)`
  &:not(:last-child) {
    border-bottom: 1px solid ${getColor('primaryTint')};
  }
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
  }
`;

const PaymentScheduleToggle = styled.button`
  padding: 0.75rem 1rem;
  width: 100%;
  background: none;

  &:focus,
  &:focus-visible {
    outline: ${getColor('accessibility')} solid 4px;
  }
`;

const PaymentScheduleRow = ({ amount, date }: PaymentScheduleEvent) => (
  <PaymentScheduleRowContainer
    justifyContent="space-between"
    alignItems="center"
    flex="1 1 auto"
    padding={2}
    data-testid="payment-schedule-row"
  >
    <Text>{formatDate(date)}</Text>
    <Text fontSize="large" fontWeight="bold" fontFamily="heading">
      {formatCurrency(amount)}
    </Text>
  </PaymentScheduleRowContainer>
);

export function PaymentScheduleView({
  schedule,
  className,
  title,
}: PaymentScheduleViewProps) {
  const [fullSchedule, setFullSchedule] = useState(false);

  const filteredScheduleRows = 4;

  const filteredSchedule = pipe(
    take(filteredScheduleRows),
    map((p: PaymentScheduleEvent) => (
      <PaymentScheduleRow {...p} key={p.date.toLocaleString()} />
    )),
  )(schedule);

  return (
    <Box className={className} mt={2}>
      {title && (
        <Heading as="h3" fontSize="medium" marginBottom={2}>
          {title}
        </Heading>
      )}
      <PaymentScheduleContainer>
        {fullSchedule
          ? schedule.map((p) => (
              <PaymentScheduleRow {...p} key={p.date.toLocaleString()} />
            ))
          : filteredSchedule}
      </PaymentScheduleContainer>
      {!fullSchedule && schedule.length > filteredScheduleRows && (
        <Box marginTop={3}>
          <PaymentScheduleToggle
            onClick={() => setFullSchedule(true)}
            data-testid="payment-schedule-show-full"
          >
            <Flex justifyContent="space-between" alignItems="center">
              <Text color="base" fontWeight="bold">
                View your full schedule &nbsp;
              </Text>
              <StyledIcon icon={faChevronDown} color="primary" />
            </Flex>
          </PaymentScheduleToggle>
        </Box>
      )}
    </Box>
  );
}
