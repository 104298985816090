import { Controller, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers';
import {
  Anchor,
  Box,
  Button,
  Card,
  Checkbox,
  Flex,
  Heading,
  List,
  ListItem,
  Paragraph,
  Pill,
  Spaced,
  Text,
  useModal,
} from '@sequensis/stylish-core';
import * as yup from 'yup';

import { Explainer } from 'src/components/Explainer';
import { ProgressHeader } from 'src/components/ProgressHeader';
import { useProduct } from 'src/hooks/useProduct';
import { useRaiseSubmissionError } from 'src/hooks/useRaiseSubmissionError';
import { SubmissionAction } from 'src/screens/TopUp/types';

export interface SoftSearchForm {
  termsAndConditionsConsent: boolean;
  softSearchConsent: boolean;
}

export const softSearchValidation: yup.SchemaOf<SoftSearchForm> = yup.object().shape({
  softSearchConsent: yup
    .boolean()
    .equals([true], 'Soft search consent is required to continue'),
  termsAndConditionsConsent: yup
    .boolean()
    .equals([true], 'Terms and Conditions consent is required to continue'),
});

export interface SoftSearchProps {
  progress: number;
  onCompleteFinalCheck: SubmissionAction<SoftSearchForm>;
}

export const SoftSearch = ({
  progress,
  onCompleteFinalCheck: { error, isLoading, onAction: onCompleteFinalCheck },
}: SoftSearchProps) => {
  const formMethods = useForm<SoftSearchForm>({
    mode: 'onBlur',
    resolver: yupResolver(softSearchValidation),
    defaultValues: {
      softSearchConsent: false,
      termsAndConditionsConsent: false,
    },
  });

  const { handleSubmit, errors, control } = formMethods;

  useRaiseSubmissionError(error);

  const {
    copyConfig: {
      tradingName: { plural: productNamePlural, singular: productNameSingular },
      articleName: productNameArticle,
      privacyPolicyUrl,
    },
  } = useProduct();

  const { open } = useModal({
    header: `${productNamePlural} Quotation Terms and Conditions`,
    content: (
      <Spaced marginBottom={5} excludeLast>
        <Spaced marginBottom={3} excludeLast>
          <Heading size="lg">The Service</Heading>
          <Paragraph size="md">
            By completing the form and submitting your details you agree to the Quotation
            Terms and Conditions of {productNamePlural} and you are confirming that you
            have read our Privacy Policy which contains important information about how we
            will use and store your information.
          </Paragraph>
          <List variant="unordered">
            <ListItem>
              <Paragraph size="md">
                The service includes a check of your credit record and of the information
                you have provided, this is used to provide an initial indication of
                eligibility for {productNameArticle} {productNamePlural} product, on a
                credit scoring basis using {productNamePlural}&apos; own credit scoring
                methods and where provided using your bank transactional information.
              </Paragraph>
            </ListItem>
            <ListItem>
              <Paragraph size="md">
                The Service will commence when you submit your information and will
                continue until:
              </Paragraph>
              <List variant="unordered">
                <ListItem>
                  <Paragraph size="md">
                    you become {productNameArticle} {productNamePlural} customer
                  </Paragraph>
                </ListItem>
                <ListItem>
                  <Paragraph size="md">
                    we discover your information is no longer up to date
                  </Paragraph>
                </ListItem>
                <ListItem>
                  <Paragraph size="md">
                    you tell us you no longer want to use the Service
                  </Paragraph>
                </ListItem>
              </List>
            </ListItem>
            <ListItem>
              <Paragraph size="md">
                The credit scoring methods used in the Service are only relevant to{' '}
                {productNamePlural} as other lenders will use different scoring methods.
              </Paragraph>
            </ListItem>
            <ListItem>
              <Paragraph size="md">
                The product suggestion provided by the Service does not constitute an
                offer of credit and you may be declined once a full assessment of your
                application has been completed.
              </Paragraph>
            </ListItem>
          </List>
        </Spaced>
        <Spaced marginBottom={3} excludeLast>
          <Heading size="lg">Use and Storage of Your Information</Heading>
          <Paragraph size="md">
            Please refer to our Privacy Policy for information on what data we will
            collect and how we will use it.
          </Paragraph>
          <Paragraph size="md">
            If you do decide to proceed to make a full application for{' '}
            {productNameArticle} {productNameSingular}, you will be provided with further
            information about how your information will be used and stored, this can be
            found in our Privacy Policy and in our Loan Terms and Conditions.
          </Paragraph>
        </Spaced>
        <Spaced marginBottom={3} excludeLast>
          <Heading size="lg">Sharing your information</Heading>
          <Paragraph size="md">
            The personal information we collect from you will be shared with Credit
            Reference Agencies and fraud prevention agencies. Fraud prevention agencies
            will use it to prevent fraud and money-laundering and to verify your identity.
            If fraud is detected, you could be refused certain services, finance, or
            employment. Further details of how your information will be used by us, Credit
            Reference Agencies, fraud prevention agencies, and your data protection
            rights, can be found in our Privacy Policy.
          </Paragraph>
          <Paragraph size="md">
            For more information on ‘who we may share your data with’ including Credit
            Reference Agencies please refer to our Privacy Policy.
          </Paragraph>
        </Spaced>
        <Spaced marginBottom={3} excludeLast>
          <Heading size="lg">Your Rights</Heading>
          <Paragraph size="md">
            You have rights under General Data Protection Regulation including the right
            to receive a copy of the information we hold about you. Your rights are
            detailed within our Privacy Policy. Please refer to our Privacy Policy for
            more information.
          </Paragraph>
        </Spaced>
      </Spaced>
    ),
  });

  return (
    <Spaced marginBottom={4} excludeLast>
      <ProgressHeader
        currentStep="Soft credit check"
        progress={progress}
        stepText="Step 3/3"
      />
      <Card>
        <Spaced marginBottom={4} excludeLast>
          <Flex justifyContent="space-between" width="100%">
            <Flex alignItems="center">
              <Pill variant="fancy">Important</Pill>
            </Flex>
          </Flex>
          <Heading as="h3" size="xl">
            We’ll run a soft credit check now
          </Heading>
          <Paragraph size="md">
            To check your eligibility for a top up loan, we need to take the information
            you’ve provided so far and perform a soft credit search.
          </Paragraph>
          <Paragraph size="md">
            <Text variant="emphasis">
              This is a quick look at your credit report and won’t affect your credit
              score.
            </Text>
          </Paragraph>
          <Box marginTop={1}>
            <Explainer question="What is a soft search?" marginBottom={0}>
              <Spaced marginBottom={3} excludeLast>
                <Paragraph size="sm">
                  Like its name implies, a soft credit search is a quick look at your
                  credit report and doesn’t affect your credit score. Companies may do
                  them as part of a background check.
                </Paragraph>
                <Paragraph size="sm">
                  A soft search may or may not be recorded on your credit report –{' '}
                  <Text variant="emphasis">
                    but it won’t be visible to other lenders or affect your credit score.
                  </Text>{' '}
                  So you don’t have to worry about how often these are done.
                </Paragraph>
              </Spaced>
            </Explainer>
          </Box>
        </Spaced>
      </Card>
      <Card>
        <Heading size="lg">
          Please read our Terms and Conditions and Privacy Policy below
        </Heading>
        <Spaced marginY={4}>
          <div>
            <Anchor onClick={() => open()}>
              <Paragraph size="lg">Terms and conditions</Paragraph>
            </Anchor>
          </div>
          <div>
            <Anchor href={privacyPolicyUrl} target="_blank" rel="noreferrer">
              <Paragraph size="lg">Privacy policy</Paragraph>
            </Anchor>
          </div>
        </Spaced>
        <form
          onSubmit={handleSubmit(({ softSearchConsent, termsAndConditionsConsent }) =>
            onCompleteFinalCheck({ softSearchConsent, termsAndConditionsConsent }),
          )}
        >
          <Controller
            render={({ value, onChange }) => (
              <Checkbox
                label={
                  <Paragraph size="md" ml={1}>
                    I confirm I have read and accept the Terms and Conditions &amp;
                    Privacy Policy
                  </Paragraph>
                }
                checked={value}
                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                  onChange(e.currentTarget.checked)
                }
                id="terms-and-conditions-checkbox"
                error={errors.termsAndConditionsConsent?.message}
                marginBottom={3}
              />
            )}
            name="termsAndConditionsConsent"
            control={control}
          />
          <Controller
            render={({ value, onChange }) => (
              <Checkbox
                label={
                  <Paragraph size="md" ml={1}>
                    I consent to you running a soft credit search now. This won&apos;t
                    affect my credit file
                  </Paragraph>
                }
                checked={value}
                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                  onChange(e.currentTarget.checked)
                }
                id="soft-search-consent-checkbox"
                error={errors.softSearchConsent?.message}
                marginBottom={3}
              />
            )}
            name="softSearchConsent"
            control={control}
          />
          <Button
            data-testid="soft-search-consent-submit-button"
            fullWidth
            isLoading={isLoading}
            marginTop={4}
          >
            Check my offers
          </Button>
        </form>
      </Card>
    </Spaced>
  );
};

export const SoftSearchStep = {
  path: 'soft-search',
  component: SoftSearch,
  provides: ['softSearchConsent'],
};
