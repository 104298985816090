interface FormatCurrencyOptions extends Intl.NumberFormatOptions {
  stripTrailingZeros?: boolean;
}

export const formatCurrency = (value: number, options?: FormatCurrencyOptions) =>
  new Intl.NumberFormat('en', {
    style: 'currency',
    currency: 'GBP',
    ...options,
  })
    .format(value)
    .replace(options?.stripTrailingZeros === false ? '' : /\D00$/, '');

export const formatCurrencyWithoutSign = (
  value: number,
  options?: FormatCurrencyOptions,
) =>
  new Intl.NumberFormat('en', {
    style: 'decimal',
    minimumFractionDigits: 2,
    ...options,
  })
    .format(value)
    .replace(options?.stripTrailingZeros === false ? '' : /\D00$/, '');

export const formatAPR = (value: number, options?: Intl.NumberFormatOptions) =>
  new Intl.NumberFormat('en', {
    style: 'decimal',
    maximumFractionDigits: 1,
    ...options,
  }).format(value);
