import { useMemo } from 'react';

import { useLocation, useParams } from 'react-router-dom-v5-compat';

import { useConfig } from '@sequensis/react-config';

import { Product } from 'src/types/ConfigProps';
import { getProductConfigByPath } from 'src/utils/productHelper';
import { concat, mergeDeepWithKey } from 'ramda';

/**
 * Use this hook to determine which product is being rendered.
 */
export function useProduct(args?: { shouldThrow: true }): Product;
export function useProduct(args: { shouldThrow: false }): Product | undefined;
export function useProduct({ shouldThrow = true } = {}) {
  const {
    productConfiguration: { products, default: defaultProduct },
  } = useConfig();

  // Build an array of known product base routes
  const knownBaseRoutes = useMemo(
    () => products.map((product) => product.baseRoute),
    [products],
  );

  // Retrieve both the product URL param and the full pathname
  const { product: productParam } = useParams<{ product?: string }>();
  const { pathname } = useLocation();

  // Determine what our base route is
  const baseRoute = useMemo(() => {
    // If we found a route param, use that rather than parsing the URL
    if (productParam) return productParam;

    // We didn't get a route param, so let's try work it out manually
    const baseRouteFromPathname = pathname.replace(/^\//g, '').split('/')[0];

    // Check that the path we found is a recognized base route before returning it
    return knownBaseRoutes.includes(baseRouteFromPathname)
      ? baseRouteFromPathname
      : undefined;
  }, [productParam, pathname, knownBaseRoutes]);

  if (shouldThrow && !baseRoute) {
    throw new Error(
      "useProduct() couldn't return a product as no baseRoute could be determined.",
    );
  }

  // Use the base route to find the relevant product
  // Merge the product with the defaults, favouring the product's settings
  const product = useMemo(() => {
    const product = getProductConfigByPath(products, baseRoute);

    return (
      product &&
      (mergeDeepWithKey(
        (k, l, r) => (k == 'productIds' ? concat(l, r) : r),
        defaultProduct,
        product,
      ) as Product)
    );
  }, [products, baseRoute, defaultProduct]);

  return product;
}
