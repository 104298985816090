import { useDropzone } from 'react-dropzone';

import { Box, componentStyles, ComponentTheme } from '@sequensis/stylish-core';
import { useToast } from '@sequensis/stylish-core';
import styled from 'styled-components';

const DEFAULT_CONTAINER_THEME: ComponentTheme<never> = {
  baseStyle: {
    borderRadius: '1rem',
    border: '2px solid',
    borderColor: 'secondary',
    backgroundColor: 'secondaryBg',
    padding: '1rem',
  },
};

const containerComponentStyle = componentStyles(
  'documentsContainer',
  DEFAULT_CONTAINER_THEME,
);

export const DocumentsContainer = styled(Box)`
  ${containerComponentStyle}
`;

export interface DocumentUploadProps {
  children: React.ReactNode;
  disabled?: boolean;
  onDocumentAccepted: (file: File) => void;
}

export const DocumentUpload = ({
  children,
  disabled,
  onDocumentAccepted,
}: DocumentUploadProps) => {
  const { raiseToast } = useToast();

  const onDropRejected = () => {
    raiseToast({
      status: 'error',
      message:
        'The file you uploaded was either too large, not in a file format we accept, or you have attempted to upload too many files',
      duration: 10_000,
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    noClick: disabled,
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
      'image/gif': ['.gif'],
      'application/pdf': ['.pdf'],
    },
    maxSize: 8_388_608, // 8MB
    maxFiles: 1,
    onDropAccepted: (files: any) => onDocumentAccepted(files[0]),
    onDropRejected,
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} data-testid="document-upload" />
      {children}
    </div>
  );
};
