import { faMoneyBillWave, faUmbrellaBeach } from '@fortawesome/pro-regular-svg-icons';
import { Box, Card, Column, Grid, Icon, Paragraph, Text } from '@sequensis/stylish-core';
import styled from 'styled-components';

import { useAccountSelector } from 'src/hooks/useAccountSelector';
import { getCurrentRepaymentBreak } from 'src/store/Accounts';
import { formatCurrency, formatDate } from 'src/utils';

export const RepaymentBreakStatus = () => {
  const currentRepaymentBreak = useAccountSelector(getCurrentRepaymentBreak);

  return (
    <Card heading="Repayment break status" mb={3}>
      <Paragraph mt={3}>You are currently on a repayment break</Paragraph>

      <IconPoint
        icon={<Icon icon={faUmbrellaBeach} size="2x" />}
        text={
          <>
            Your next payment is due on the{' '}
            <Text fontWeight="bold" fontSize="large">
              {formatDate(currentRepaymentBreak!.endDate)}
            </Text>
          </>
        }
      />
      <IconPoint
        icon={<Icon icon={faMoneyBillWave} size="2x" />}
        text={
          <>
            Your next payment amount is for{' '}
            <Text fontWeight="bold" fontSize="large">
              {formatCurrency(currentRepaymentBreak!.adjustedRepaymentAmount)}
            </Text>
          </>
        }
      />
    </Card>
  );
};

const IconPoint = (props: { icon: React.ReactNode; text: React.ReactNode | string }) => (
  <Box mt={4}>
    <CenteredGrid>
      <Column
        extraSmall={{ width: 2 }}
        small={{ width: 2 }}
        medium={{ width: 1 }}
        large={{ width: 1 }}
      >
        {props.icon}
      </Column>
      <Column>
        <Paragraph ml={2}>{props.text}</Paragraph>
      </Column>
    </CenteredGrid>
  </Box>
);

const CenteredGrid = styled(Grid)`
  align-items: center;
`;
