import { useSelector } from 'react-redux';

import { faClock } from '@fortawesome/pro-regular-svg-icons';
import { useConfig, useFeatureFlag } from '@sequensis/react-config';
import { Alert, Box } from '@sequensis/stylish-core';

import { ButtonLink } from 'src/components/ButtonLink';
import { getDirectDebitIsPendingSetup } from 'src/store/Account/selectors';

import BalanceDoughnut from './BalanceDoughnut/BalanceDoughnut';

interface CurrentBalanceProps {
  onClick?(): void;
  displayMissedPayments: boolean;
  displayBorrowMore: boolean;
  displaySettleMyLoan: boolean;
  className?: string;
}

const CurrentBalance = ({
  displayMissedPayments = false,
  displayBorrowMore = true,
  displaySettleMyLoan = true,
  onClick,
  className,
}: CurrentBalanceProps) => {
  const { likelyLoansOnlineAccountServicingUrl } = useConfig();

  const settlementsEnabled = useFeatureFlag('enableSettlements');
  const isDirectDebitPendingSetup = useSelector(getDirectDebitIsPendingSetup);

  return (
    <Box className={className} data-testid="current-balance-card" marginY={2}>
      {isDirectDebitPendingSetup && (
        <Alert
          variant="info"
          marginBottom={20}
          spacing="slim"
          data-testid="direct-debit-pending-warning"
          icon={faClock}
        >
          Your Direct Debit is being setup
        </Alert>
      )}
      <BalanceDoughnut onClick={onClick} />
      {displayMissedPayments ? (
        <ButtonLink to="../make-payment" navigational marginTop={3} marginX={3}>
          Make a payment
        </ButtonLink>
      ) : (
        <>
          {displaySettleMyLoan ? (
            settlementsEnabled ? (
              <ButtonLink
                data-testid="settle-my-loan-button"
                to="../settlement"
                navigational
                marginTop={3}
                marginBottom={1}
                marginX={3}
              >
                Settle my loan
              </ButtonLink>
            ) : (
              <ButtonLink
                to={`${likelyLoansOnlineAccountServicingUrl}/#/login}`}
                navigational
                marginTop={3}
                marginBottom={1}
                marginX={3}
              >
                Settle my loan
              </ButtonLink>
            )
          ) : (
            <></>
          )}
          {displayBorrowMore && (
            <ButtonLink
              navigational
              to={`${likelyLoansOnlineAccountServicingUrl}/#/login`}
              onClick={(event) => event.stopPropagation()}
              marginX={3}
            >
              Top up my loan
            </ButtonLink>
          )}
        </>
      )}
    </Box>
  );
};

export default CurrentBalance;
