import { filter, pipe, sort } from 'ramda';

import { AccountHeadline } from 'src/api/account/types';

import { Product } from './../types/ConfigProps';

const sortByDate = sort<AccountHeadline>(
  (a, b) =>
    new Date(b.activatedAt ?? b.createdAt).getTime() -
    new Date(a.activatedAt ?? a.createdAt).getTime(),
);

const mapAccountHeadline = (accounts: AccountHeadline[], product: Product) =>
  pipe(
    sortByDate,
    filter(
      (account: AccountHeadline) =>
        product.productIds.includes(account.productId) &&
        // Ignore 'New' Accounts, such as Top Up accounts
        // which were never finalised.
        account.status !== 'New',
    ),
  )(accounts);

export const getLoansByProduct = (accounts: AccountHeadline[], products: Product[]) =>
  products.reduce(
    (result, product) => ({
      ...result,
      ...{ [product.baseRoute]: mapAccountHeadline(accounts, product) },
    }),
    {} as Record<string, AccountHeadline[]>,
  );
