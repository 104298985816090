import { PageHeader } from '@sequensis/stylish-layouts';

import { useProduct } from 'src/hooks/useProduct';

import { BalanceCard, DetailCard, RemainingCard } from './Components';

export const BalanceDetails = () => {
  const {
    balance: { showProgressBarsOnBalanceDetail },
  } = useProduct();

  return (
    <>
      <PageHeader title="About your loan" />
      <DetailCard />
      <RemainingCard />
      {showProgressBarsOnBalanceDetail === 'true' && <BalanceCard />}
    </>
  );
};
