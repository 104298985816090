import { Flex, Loader, StepList, Text } from '@sequensis/stylish-core';

import { useAutoCounter } from 'src/hooks/useAutoCounter';

interface LoadingStepProps {
  index: number;
  currentStep: number;
}

const stepText = ['Generate Documents', 'Verify Income', 'Analyse Report', 'Get Results'];
const currentStepText = [
  'Generating Documents',
  'Verifying Income',
  'Analysing Report',
  'Getting Results',
];
const completedStepText = [
  'Documents Generated',
  'Income Verified',
  'Report Analysed',
  'Result',
];

const LoadingStep = ({ index, currentStep }: LoadingStepProps) => {
  const getStepState = (i: number) => {
    if (currentStep < index + 1) return stepText[i];
    if (currentStep === index + 1) return currentStepText[i];
    return completedStepText[i];
  };

  return <Text size="2xl">{getStepState(index)}</Text>;
};

export const IncomeVerificationLoader = () => {
  const currentStep = useAutoCounter(0, stepText.length + 1, 5000);

  return (
    <>
      <Flex flexWrap="wrap" height="100%">
        <Flex marginLeft="3.625rem" width="100%" mb={4}>
          <StepList
            variant="darkBackground"
            marginBetweenSteps={5}
            currentStep={currentStep}
          >
            {stepText.map((step, index) => (
              <LoadingStep key={step} index={index} currentStep={currentStep} />
            ))}
          </StepList>
        </Flex>
        <Flex justifyContent="center" alignSelf="flex-end" width="100%" mt={4}>
          <Loader marginBottom={6} data-testid="soft-search-loader" />
        </Flex>
      </Flex>
    </>
  );
};
