import { useSearchParams } from 'react-router-dom-v5-compat';

import { Flex, Heading, Loader } from '@sequensis/stylish-core';
import { PageHeader } from '@sequensis/stylish-layouts';

import { PoweredByOakbrookLogo } from 'src/components/PoweredByOakbrookLogo';

export const HardSearchLoader = () => {
  const [searchParams] = useSearchParams();

  return (
    <>
      <PageHeader rightAdornment={<></>} />
      <Flex flexWrap="wrap" height="100%">
        <Flex justifyContent="right" marginTop={5} marginRight={3} width="100%">
          <PoweredByOakbrookLogo variant="white" />
        </Flex>
        <Flex justifyContent="center" width="100%">
          <Heading size="xl" color="white" fontSize="lg" lineHeight="lg">
            {searchParams.has('resume-topup')
              ? 'Loading your Top up application'
              : 'Running hard credit check'}
          </Heading>
        </Flex>
        <Flex justifyContent="center" alignSelf="flex-end" width="100%">
          <Loader marginBottom={5} data-testid="hard-search-loader" />
        </Flex>
      </Flex>
    </>
  );
};

export const HardSearchLoaderStep = {
  path: 'hard-searching',
  component: HardSearchLoader,
  provides: [],
};
