import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom-v5-compat';

import { MisthoForm, useMistho } from '@mistho/react';
import { Provider } from '@mistho/react/lib/browser';
import { GetApplicationByCustomerIdResponse } from '@sequensis/creditapplication-reactsdk';
import {
  Box,
  Button,
  Card,
  Heading,
  List,
  ListItem,
  Paragraph,
} from '@sequensis/stylish-core';

import Loading from '../../Loading';
import { consentData } from '../data';

type FormProps = {
  transaction_id: string;
  slug: string;
  applicationDetails: GetApplicationByCustomerIdResponse;
  onLoginSuccess: () => void;
};

export const Form = ({
  transaction_id,
  slug,
  applicationDetails,
  onLoginSuccess,
}: FormProps) => {
  const [provider, setProvider] = useState<Provider | null>(null);
  const [showHeader, setShowHeader] = useState(true);
  const navigate = useNavigate();
  const brand = applicationDetails?.selectedOffer?.brand
    ?.toLocaleLowerCase()
    ?.includes('finio')
    ? 'finio'
    : 'oakbrook';

  const mistho = useMistho((state) => state);

  useEffect(() => {
    mistho
      .getSingleProvider({
        transaction_id,
        slug,
      })
      .then((res) => {
        setProvider(res);
      });
  }, []);

  if (!provider) return <Loading />;

  return (
    <Card className={`mistho-form-wrapper mistho-form-wrapper-${brand}`}>
      {showHeader && (
        <>
          <Heading as="h3" mb={2}>
            Login to verify your income instantly
          </Heading>
          <Paragraph mb={4}>Login via {provider.name}</Paragraph>
        </>
      )}
      <MisthoForm
        transactionID={transaction_id}
        provider={provider}
        streamingDimensions={{
          width: 0,
          height: 0,
        }}
        onComplete={onLoginSuccess}
        onFail={onLoginSuccess}
        renderConsent={(answer) => {
          setShowHeader(false);
          return (
            <Box>
              <Heading as="h3" mb={3}>
                Consent to share your data
              </Heading>
              {consentData.map((el) =>
                el.type === 'consent' ? (
                  <Box
                    key={el.category}
                    marginBottom={3}
                    backgroundColor="baseBg"
                    padding={3}
                  >
                    <Paragraph fontWeight="bold" fontSize="lg">
                      {el.category}
                    </Paragraph>
                    <Box padding={3}>
                      <List variant="unordered">
                        {el.data.map((e, index) => (
                          <ListItem key={index}>{e}</ListItem>
                        ))}
                      </List>
                    </Box>
                  </Box>
                ) : (
                  <Box key={el.category} marginBottom={2}>
                    <Paragraph fontWeight="bold" fontSize="lg">
                      {el.category}
                    </Paragraph>
                    <Paragraph paddingLeft={3}>{el.data[0]}</Paragraph>
                  </Box>
                ),
              )}
              <Button
                variant="primary"
                fullWidth
                mb={4}
                mt={2}
                onClick={() => {
                  answer(true);
                  setShowHeader(true);
                }}
              >
                Allow & continue
              </Button>
              <Button
                variant="secondary"
                mb={4}
                fullWidth
                onClick={() => {
                  answer(false);
                  setShowHeader(false);
                }}
              >
                Do not consent
              </Button>
            </Box>
          );
        }}
        renderConsentRejection={
          <Box>
            <Heading as="h3" mb={4}>
              We need your consent
            </Heading>
            <Paragraph mb={4}>
              In order to complete your application and release your loan, we will need
              your consent to access your income data.
            </Paragraph>
            <Button fullWidth onClick={() => navigate(-1)}>
              Try again
            </Button>
          </Box>
        }
      />
    </Card>
  );
};
