import badBankStatement from './assets/bank_statement_bad.png';
import goodBankStatement from './assets/bank_statement_good.png';
import badPaySlip from './assets/payslip_bad.png';
import goodPaySlip from './assets/payslip_good.png';

export const requirementDisplayName = {
  ProofOfIncome: 'Your income',
};

export const documentDisplayName = {
  Payslip: 'Payslip',
  BankStatement: 'Bank statement',
};

const payslipsupplyTips = [
  'Your payslip must show your name and personal details.',
  'Please provide your most recent payslip, dated within the last 40 days.',
  'We can accept your payslip as a PDF file, a photo image of a paper document, or a screenshot of your payslip.',
  'We require your payslip to be fully visible, unedited, and showing the full document.',
];

const payslipgeneralTips = [
  "If you're taking a photo, put your document on a flat surface and make sure to capture the full document in the image, with all four corners of the page in the image",
  "Ensure that you have good lighting when taking a photo and that the page isn't dark or unclear",
  "Take a clear image, that can be easily read and isn't blurry",
  'We accept JPEG, PNG, PDF or GIF files. File size must be less than 8mb',
];

const bankstatementsupplyTips = [
  'Your bank statement must show your name, address, bank details (sort code and account number) and income transactions.',
  'Your bank statement must be dated within the last 40 days.',
  'We require the bank statement for the account that your income is paid into. If this is multiple accounts, then please provide bank statements for each account that your income is paid into.',
  'We can accept your bank statement as a PDF file, a photo image of a paper document, or a screenshot of your bank statement, if it shows all the required information above.',
];

const bankstatementgeneralTips = [
  "If you're taking a photo, put your document on a flat surface and make sure to capture the full document in the image, with all four corners of the page in the image",
  "Ensure that you have good lighting when taking a photo and that the page isn't dark or unclear",
  "Take a clear image, that can be easily read and isn't blurry",
  "We cannot accept a transaction list, or any bank statement doesn't show your full name and information.",
  'We cannot accept a screenshot or image of your mobile or online banking.',
  'We accept JPEG, PNG, PDF or GIF files. File size must be less than 8mb',
];

export const supplyTipsMap = {
  Payslip: payslipsupplyTips,
  BankStatement: bankstatementsupplyTips,
};

export const generalTipsMap = {
  Payslip: payslipgeneralTips,
  BankStatement: bankstatementgeneralTips,
};

export const badImageMap = {
  Payslip: badPaySlip,
  BankStatement: badBankStatement,
};

export const goodImageMap = {
  Payslip: goodPaySlip,
  BankStatement: goodBankStatement,
};

export const MODE_REQUIREMENTS = 'requirements';
export const MODE_CONFIRM_INCOME = 'confirm-income';
export const MODE_TIPS = 'tips';
export const MODE_COMPLETE = 'complete';
export const MODE_CHECK_YOUR_DOCS = 'check-your-docs';

export const documentCheckboxLabel = {
  Payslip: 'My payslip is dated within the last 40 days',
  BankStatement: 'My bank statement is dated within the last 40 days',
};
