import * as React from 'react';

import { useConfig, useFeatureFlag } from '@sequensis/react-config';
import {
  Column,
  Container,
  Flex,
  Grid,
  Heading,
  Paragraph,
} from '@sequensis/stylish-core';

interface MaintenanceGuardProps {
  children: React.ReactNode;
}

export function MaintenanceGuard({ children }: MaintenanceGuardProps) {
  const { supportHelpdesk } = useConfig();
  const maintenanceModeEnabled = useFeatureFlag('enableMaintenanceMode');

  if (!maintenanceModeEnabled) return <>{children}</>;

  return (
    <Flex flexDirection="column" justifyContent="center" flex="1 1 auto" height="100%">
      <Container>
        <Grid>
          <Column medium={{ width: 8, offset: 2 }} large={{ width: 6, offset: 3 }}>
            <Heading>It&apos;s not you, it&apos;s us</Heading>
            <Paragraph>
              We’re currently down for scheduled maintenance and expect to be back up and
              running in a couple of hours. Thanks for your patience.
            </Paragraph>
            <Paragraph>
              If you need to get in touch, please{' '}
              <a
                href={`${supportHelpdesk}/requests/new`}
                rel="noopener noreferrer"
                // eslint-disable-next-line react/jsx-no-target-blank
                target="_blank"
              >
                contact us here
              </a>
              .
            </Paragraph>
          </Column>
        </Grid>
      </Container>
    </Flex>
  );
}
