import { useSelector } from 'react-redux';

import { getCurrentAccountIdFromProduct } from 'src/store/Accounts';

import { useProduct } from './useProduct';

export function useCurrentAccountId() {
  const product = useProduct();

  return useSelector(getCurrentAccountIdFromProduct(product));
}
