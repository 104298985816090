import { Controller, FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers';
import {
  Box,
  Button,
  Card,
  Paragraph,
  RadioGroup,
  RadioOption,
  Spaced,
} from '@sequensis/stylish-core';
import { range } from 'ramda';
import * as yup from 'yup';

import { Explainer } from 'src/components/Explainer';
import { ProgressHeader } from 'src/components/ProgressHeader';
import { useRaiseSubmissionError } from 'src/hooks/useRaiseSubmissionError';
import { SubmissionAction } from 'src/screens/TopUp/types';

export type EmploymentStatus = 'Employed' | 'SelfEmployed' | 'Retired' | 'Unemployed';

export interface EmploymentForm {
  employmentStatus?: EmploymentStatus;
  employmentTenure?: number;
}

const isEmployed = (status?: EmploymentStatus) => {
  return status !== undefined && ['Employed', 'SelfEmployed'].includes(status);
};

const employmentFormEmployedSchema: yup.SchemaOf<EmploymentForm> = yup.object().shape({
  employmentStatus: yup.mixed<EmploymentStatus>().label('Employment status').required(),
  employmentTenure: yup
    .number()
    .typeError('Employment tenure is a required field')
    .label('Employment tenure')
    .min(0)
    .max(99)
    .required(),
});

const employmentFormUnemployedSchema: yup.SchemaOf<EmploymentForm> = yup.object().shape({
  employmentStatus: yup.mixed<EmploymentStatus>().label('Employment status').required(),
  employmentTenure: yup.number(),
});

const employmentFormSchema = yup.lazy((employment: EmploymentForm) =>
  isEmployed(employment.employmentStatus)
    ? employmentFormEmployedSchema
    : employmentFormUnemployedSchema,
);

export interface EmploymentProps {
  progress: number;
  onEmploymentClick: SubmissionAction<EmploymentForm>;
}

export const Employment = ({
  progress,
  onEmploymentClick: { error, isLoading, onAction: onEmploymentSubmit },
}: EmploymentProps) => {
  const useFormMethods = useForm<EmploymentForm>({
    mode: 'onChange',
    resolver: yupResolver(employmentFormSchema),
  });

  const { control, handleSubmit, errors, watch } = useFormMethods;

  useRaiseSubmissionError(error);

  return (
    <Spaced marginBottom={4} excludeLast>
      <ProgressHeader currentStep="Employment" progress={progress} stepText="Step 2/3" />
      <Card>
        <FormProvider {...useFormMethods}>
          <form
            onSubmit={handleSubmit((data: EmploymentForm) => onEmploymentSubmit(data))}
            noValidate={true}
          >
            <Spaced marginBottom={4} excludeLast>
              <>
                <Controller
                  render={({ value, onChange }) => (
                    <RadioGroup
                      name="employmentStatus"
                      selected={value}
                      variant="vertical"
                      onChange={onChange}
                      error={errors.employmentStatus?.message}
                      label="Employment status"
                    >
                      <RadioOption
                        data-testid="employment-status-employed"
                        value="Employed"
                      >
                        Employed (full-time or part-time)
                      </RadioOption>
                      <RadioOption
                        data-testid="employment-status-selfemployed"
                        value="SelfEmployed"
                      >
                        Self employed
                      </RadioOption>
                      <RadioOption
                        data-testid="employment-status-retired"
                        value="Retired"
                      >
                        Retired but receiving a pension
                      </RadioOption>
                      <RadioOption
                        data-testid="employment-status-unemployed"
                        value="Unemployed"
                      >
                        Unemployed but receiving benefits
                      </RadioOption>
                    </RadioGroup>
                  )}
                  name="employmentStatus"
                  control={control}
                  defaultValue={undefined}
                />
                <Box marginTop={1}>
                  <Explainer question="I have more than one income?">
                    <Paragraph fontSize="sm">
                      If you have multiple sources of income, please select the employment
                      status that provides you with the majority of your income.
                    </Paragraph>
                  </Explainer>
                </Box>
              </>
              {isEmployed(watch('employmentStatus')) && (
                <>
                  <Controller
                    render={({ value, onChange }) => (
                      <RadioGroup
                        selected={value}
                        variant="vertical"
                        onChange={onChange}
                        error={errors.employmentTenure?.message}
                        label="How many years have you worked here?"
                      >
                        {range(1, 6).map((tenure) => (
                          <RadioOption
                            data-testid={`employment-tenure-${tenure}`}
                            key={tenure}
                            value={tenure}
                          >
                            {`${tenure}${tenure == 5 ? '+' : ''} years`}
                          </RadioOption>
                        ))}
                      </RadioGroup>
                    )}
                    name="employmentTenure"
                    control={control}
                    defaultValue={undefined}
                  />
                </>
              )}
              <Button
                data-testid="employment-submission-button"
                fullWidth
                isLoading={isLoading}
                marginTop={3}
                type="submit"
              >
                Continue
              </Button>
            </Spaced>
          </form>
        </FormProvider>
      </Card>
    </Spaced>
  );
};

export const EmploymentStep = {
  path: 'employment',
  component: Employment,
  provides: ['employmentStatus', 'employmentTenure'],
};
