import './progressHeader.scss';

import { useFeatureFlag } from '@sequensis/react-config';
import { Paragraph } from '@sequensis/react-core';
import { Donut } from '@sequensis/stylish-core';
import { PageHeader } from '@sequensis/stylish-layouts';

export const ProgressHeader = ({
  currentStep,
  progress: progress,
  stepText,
}: {
  currentStep: string;
  progress?: number;
  stepText?: string;
}): JSX.Element => {
  const useSteps = useFeatureFlag('useSteps');
  const getRightAdornment = () => {
    if (useSteps)
      return (
        <div className="align-steps-mobile">
          <Paragraph color="white">{stepText}</Paragraph>
        </div>
      );

    if (progress)
      return (
        <Donut progress={progress} radius={26} strokeWidth={10} glowBlur={0} animate />
      );

    return <></>;
  };

  return <PageHeader title={currentStep} rightAdornment={getRightAdornment()} />;
};
