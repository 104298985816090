import { Flex, Text } from '@sequensis/stylish-core';
import styled from 'styled-components';

import { CalendarIcon } from './CalendarIcon';
import { ChartIcon } from './ChartIcon';
import { SpeechBubbleIcon } from './SpeechBubbleIcon';

interface IconContainerProps {
  left?: boolean;
  right?: boolean;
}

const IconContainer = styled.div<IconContainerProps>`
  margin-left: ${({ left }) => left && '-22px'};
  margin-right: ${({ right }) => right && '-22px'};
`;

export const FeatureList = () => (
  <>
    <Flex px={[0, 4]} flexDirection="row" alignItems="center">
      <IconContainer left={true}>
        <ChartIcon />
      </IconContainer>
      <Text
        fontWeight="bold"
        color="primary"
        fontSize="medium"
        textAlign="left"
        verticalAlign=""
      >
        Keep on top of your loan wherever you are
      </Text>
    </Flex>
    <Flex px={[0, 4]} flexDirection="row" alignItems="center">
      <IconContainer left={true}>
        <CalendarIcon />
      </IconContainer>
      <Text
        fontWeight="bold"
        color="primary"
        fontSize="medium"
        textAlign="left"
        verticalAlign=""
      >
        Change your details at anytime
      </Text>
    </Flex>
    <Flex px={[0, 4]} flexDirection="row" alignItems="center">
      <IconContainer left={true}>
        <SpeechBubbleIcon />
      </IconContainer>
      <Text
        fontWeight="bold"
        color="primary"
        fontSize="medium"
        textAlign="left"
        verticalAlign=""
      >
        Get help and support whenever you need it
      </Text>
    </Flex>
  </>
);
