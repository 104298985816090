import { useState } from 'react';

import { Alert, Button, Card, Paragraph } from '@sequensis/stylish-core';
import { PageHeader } from '@sequensis/stylish-layouts';

import { DayPicker } from 'src/components/DayPicker';

import { ChangePaymentDayGuard } from './ChangePaymentDayGuard';

interface ChoosePaymentDayProps {
  onPaymentDayChosen(paymentDay: number): void;
  isSubmitting?: boolean;
  error?: string | null;
  dayPickerTestId?: string;
  confirmButtonTestId?: string;
}

const isDayInRange = (repaymentDay: number) => repaymentDay > 0 && repaymentDay <= 28;

export function ChoosePaymentDay({
  onPaymentDayChosen,
  isSubmitting,
  error,
  dayPickerTestId = '',
  confirmButtonTestId = '',
}: ChoosePaymentDayProps) {
  const [selectedRepaymentDay, setSelectedRepaymentDay] = useState<number>();

  const handleChange = (newDay: number) => {
    setSelectedRepaymentDay(newDay);
  };

  const shouldSubmitBeDisabled =
    selectedRepaymentDay === undefined ? true : !isDayInRange(selectedRepaymentDay);

  const handleSubmit = () => {
    if (selectedRepaymentDay && !isSubmitting) onPaymentDayChosen(selectedRepaymentDay);
  };

  return (
    <>
      <PageHeader title="Your payment day" />

      <Card marginBottom={3}>
        <Paragraph>We suggest choosing a day soon after you get paid.</Paragraph>
      </Card>

      <Card marginBottom={6}>
        <ChangePaymentDayGuard>
          <DayPicker
            disabled={isSubmitting}
            testId={dayPickerTestId}
            startDay={1}
            endDay={28}
            value={selectedRepaymentDay}
            onChange={handleChange}
          />
        </ChangePaymentDayGuard>

        {error && (
          <Alert icon={true} variant="negative" title="Try again later">
            {error}
          </Alert>
        )}
        <Button
          mt={4}
          type="submit"
          variant="primary"
          fullWidth={true}
          onClick={handleSubmit}
          isLoading={isSubmitting}
          data-testid={confirmButtonTestId}
          disabled={shouldSubmitBeDisabled}
        >
          Continue
        </Button>
      </Card>
    </>
  );
}
