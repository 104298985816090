import { Auth0Provider, AuthUser, AzureProvider } from '@sequensis/react-auth';
import { useConfig, useFeatureFlag } from '@sequensis/react-config';

interface AuthProviderProps {
  children: React.ReactNode;
  onRedirectCallback: (appState: any) => void;
  onAuthenticated: (user: AuthUser) => void;
  redirectUri: string;
}

/**
 * Temporary auth wrapper to switch between Azure and Auth0 while we migrate
 */
export function FeatureFlaggedAuthProvider({
  children,
  redirectUri,
  onRedirectCallback,
  onAuthenticated,
}: AuthProviderProps) {
  const useAzureAuth = useFeatureFlag('useAzureAuth');
  const config = useConfig();

  return useAzureAuth ? (
    <AzureProvider
      authority={config.azureAuth.authority}
      domain={config.azureAuth.domain}
      clientId={config.azureAuth.clientId}
      redirectUri={redirectUri}
      defaultLogoutRedirect={redirectUri}
      onRedirectCallback={onRedirectCallback}
      onAuthenticated={onAuthenticated}
    >
      {children}
    </AzureProvider>
  ) : (
    <Auth0Provider
      domain={config.auth0.domain}
      client_id={config.auth0.clientId}
      redirect_uri={redirectUri}
      onRedirectCallback={onRedirectCallback}
      defaultLogoutRedirect={redirectUri}
      audience={config.auth0.audience}
      onAuthenticated={onAuthenticated}
    >
      {children}
    </Auth0Provider>
  );
}
