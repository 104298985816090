import { ReactNode } from 'react';

import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { Card, Flex, Halo, Icon } from '@sequensis/stylish-core';
import { PageHeader } from '@sequensis/stylish-layouts';

import { ButtonLink } from 'src/components/ButtonLink';
import { useProduct } from 'src/hooks/useProduct';

interface SuccessDisplayProps {
  title: string;
  children: ReactNode;
  onDoneClick(): void;
  doneButtonTestId?: string;
}

export function SuccessDisplay({
  title,
  onDoneClick,
  children,
  doneButtonTestId,
}: SuccessDisplayProps) {
  const product = useProduct();

  return (
    <>
      <PageHeader title={title} />

      <Card>
        <Flex width="100%" justifyContent="center">
          <Halo>
            <Icon icon={faCheck} size="3x" />
          </Halo>
        </Flex>
        {children}
        <ButtonLink
          onClick={onDoneClick}
          to={`/${product.baseRoute}/balance`}
          data-testid={doneButtonTestId}
          navigational
        >
          Return to my account
        </ButtonLink>
      </Card>
    </>
  );
}
