import { useState } from 'react';

import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import { faCalendarDay, faChartLine, faPencil } from '@fortawesome/pro-solid-svg-icons';
import { Redact } from '@sequensis/react-monitoring';
import {
  Alert,
  Box,
  Button,
  Card,
  EmptyCell,
  Flex,
  Heading,
  Icon,
  List,
  ListItem,
  Loader,
  Paragraph,
  Pill,
  Spaced,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Text,
} from '@sequensis/stylish-core';

import { ProgressHeader } from 'src/components/ProgressHeader';
import { useRaiseSubmissionError } from 'src/hooks/useRaiseSubmissionError';
import { formatAPR, formatCurrency } from 'src/utils';

import { FirstNamePlayback } from '../LegalDocuments/LegalDocuments';
import { Playback, SubmissionAction } from '../types';
export interface LoanDetails {
  apr: number;
  balance: number;
  balanceIncludingInterest: number;
  monthlyRepayment: number;
  remainingPayments: number;
}
export interface OfferLoanPlayback {
  currentLoan: LoanDetails;
  topUpLoan: LoanDetails;
}
export interface OfferProps {
  progress: number;
  firstNamePlayback: Playback<FirstNamePlayback>;
  offerLoanPlayback: Playback<OfferLoanPlayback>;
  onNewQuote: SubmissionAction<void>;
  onContinueWithApplication: SubmissionAction<void>;
}

export const Offer = ({
  progress,
  firstNamePlayback: { playback: firstNamePlayback },
  offerLoanPlayback: { playback: offerLoanPlayback, error: offerLoanPlaybackError },
  onNewQuote: {
    error: newQuoteError,
    isLoading: isNewQuoteLoading,
    onAction: onNewQuote,
  },
  onContinueWithApplication: {
    error: continueWithApplicationError,
    isLoading: isContinueWithApplicationLoading,
    onAction: onContinueWithApplication,
  },
}: OfferProps) => {
  const hasPlaybackErrors = offerLoanPlaybackError !== undefined;

  const [width, setWidth] = useState(window.innerWidth);

  window.addEventListener('resize', function (event) {
    setWidth(window.innerWidth);
  });

  useRaiseSubmissionError(newQuoteError);
  useRaiseSubmissionError(continueWithApplicationError);

  return (
    <Spaced marginBottom={4} excludeLast>
      <ProgressHeader
        currentStep="Your Top Up loan offer"
        progress={progress}
        stepText="Step 3/3"
      />
      <Redact>
        <>
          {!offerLoanPlayback && (
            <Card mb={5}>
              {' '}
              <Loader my={5} />{' '}
            </Card>
          )}
          <Card
            heading={`Great news${
              firstNamePlayback?.firstName ? ` ${firstNamePlayback.firstName}` : ''
            }, here’s your top up loan offer:`}
          >
            {offerLoanPlayback && (
              <>
                <Box textAlign="center" marginY={5} paddingY={1} data-dd-privacy="allow">
                  <Paragraph size="lg">You’re borrowing an extra</Paragraph>
                  <Heading size="3xl" marginBottom={3}>
                    {formatCurrency(
                      offerLoanPlayback.topUpLoan.balance -
                        offerLoanPlayback.currentLoan.balance,
                    )}
                  </Heading>
                  <Paragraph size="lg" marginBottom={3} data-dd-privacy="allow">
                    This will make your new total loan amount
                    <Heading size="3xl" marginBottom={3} data-dd-privacy="allow">
                      {formatCurrency(offerLoanPlayback.topUpLoan.balance)}
                    </Heading>
                  </Paragraph>
                  <Button
                    fullWidth
                    size="slim"
                    variant="secondary"
                    leftIcon={faPencil}
                    isLoading={isNewQuoteLoading}
                    onClick={() => onNewQuote()}
                    data-testid="get-a-new-quote"
                    data-dd-privacy="allow"
                  >
                    Get a new Quote
                  </Button>
                </Box>
                <Box marginY={5} paddingY={1}>
                  <List variant="icon">
                    <ListItem leftAdornment={<Icon icon={faCheckCircle} />}>
                      <Paragraph size="md" data-dd-privacy="allow">
                        <Text variant="emphasis" data-dd-privacy="allow">
                          {offerLoanPlayback.topUpLoan.remainingPayments} monthly
                        </Text>{' '}
                        repayments
                      </Paragraph>
                    </ListItem>
                    <ListItem leftAdornment={<Icon icon={faCheckCircle} />}>
                      {offerLoanPlayback.currentLoan.apr !==
                      offerLoanPlayback.topUpLoan.apr ? (
                        <Flex width="100%">
                          <Paragraph
                            size="md"
                            marginRight={
                              width <= 426 ? (width <= 320 ? '-2rem' : '4rem') : '9.5rem'
                            }
                            data-dd-privacy="allow"
                          >
                            <del>{formatAPR(offerLoanPlayback.currentLoan.apr)}%</del>
                            <Text variant="emphasis">
                              {` ${formatAPR(offerLoanPlayback.topUpLoan.apr)}%`} APR
                            </Text>
                          </Paragraph>
                          {offerLoanPlayback.currentLoan.apr >
                          offerLoanPlayback.topUpLoan.apr ? (
                            <Pill size="sm" variant="fancy">
                              Price Drop
                            </Pill>
                          ) : (
                            <Pill size="sm" variant="fancy">
                              Price Change
                            </Pill>
                          )}
                        </Flex>
                      ) : (
                        <Paragraph size="md" marginRight="1.5rem" data-dd-privacy="allow">
                          <Text variant="emphasis">
                            {` ${formatAPR(offerLoanPlayback.topUpLoan.apr)}%`} APR
                          </Text>
                        </Paragraph>
                      )}
                    </ListItem>
                    <ListItem>
                      {offerLoanPlayback.currentLoan.apr <
                      offerLoanPlayback.topUpLoan.apr ? (
                        <Text variant="emphasis">
                          Note: APR is higher than the APR on your current loan.
                        </Text>
                      ) : (
                        ''
                      )}
                    </ListItem>
                  </List>
                </Box>
                <Table variant="default" marginBottom={3} data-dd-privacy="allow">
                  <TableHead data-dd-privacy="allow">
                    <TableRow>
                      <EmptyCell />
                      <TableHeader center scope="col" data-dd-privacy="allow">
                        <Icon icon={faCalendarDay} size="2x" mb={2} />
                        <Paragraph size="sm">Current loan breakdown</Paragraph>
                      </TableHeader>
                      <TableHeader center scope="col" data-dd-privacy="allow">
                        <Icon icon={faChartLine} size="2x" mb={2} />
                        <Paragraph size="sm">New loan breakdown</Paragraph>
                      </TableHeader>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableHeader scope="row" data-dd-privacy="allow">
                        <Paragraph size="md" data-dd-privacy="allow">
                          Left to repay
                        </Paragraph>
                      </TableHeader>
                      <TableCell center>
                        <Paragraph size="md">
                          {formatCurrency(offerLoanPlayback.currentLoan.balance)}
                        </Paragraph>
                      </TableCell>
                      <TableCell center>
                        <Paragraph size="md">
                          {formatCurrency(offerLoanPlayback.topUpLoan.balance)}
                        </Paragraph>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableHeader scope="row" data-dd-privacy="allow">
                        <Paragraph size="md">Left to repay including interest</Paragraph>
                      </TableHeader>
                      <TableCell center>
                        <Paragraph size="md" data-dd-privacy="allow">
                          {formatCurrency(
                            offerLoanPlayback.currentLoan.balanceIncludingInterest,
                          )}
                        </Paragraph>
                      </TableCell>
                      <TableCell center>
                        <Paragraph size="md" data-dd-privacy="allow">
                          {formatCurrency(
                            offerLoanPlayback.topUpLoan.balanceIncludingInterest,
                          )}
                        </Paragraph>
                      </TableCell>
                    </TableRow>
                    <TableRow data-dd-privacy="allow">
                      <TableHeader scope="row">
                        <Paragraph size="md">Interest rate APR</Paragraph>
                      </TableHeader>
                      <TableCell center>
                        <Paragraph size="md" data-dd-privacy="allow">
                          <Text
                            as={
                              offerLoanPlayback.currentLoan.apr >
                              offerLoanPlayback.topUpLoan.apr
                                ? 'del'
                                : undefined
                            }
                          >
                            {formatAPR(offerLoanPlayback.currentLoan.apr)}%
                          </Text>
                        </Paragraph>
                      </TableCell>
                      <TableCell center>
                        <Paragraph size="md" data-dd-privacy="allow">
                          {formatAPR(offerLoanPlayback.topUpLoan.apr)}%
                        </Paragraph>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableHeader scope="row" data-dd-privacy="allow">
                        <Paragraph size="md">Monthly payments</Paragraph>
                      </TableHeader>
                      <TableCell center>
                        <Paragraph size="md" data-dd-privacy="allow">
                          {offerLoanPlayback.currentLoan.remainingPayments}
                        </Paragraph>
                      </TableCell>
                      <TableCell center>
                        <Paragraph size="md" data-dd-privacy="allow">
                          {offerLoanPlayback.topUpLoan.remainingPayments}
                        </Paragraph>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableHeader scope="row" data-dd-privacy="allow">
                        <Paragraph size="md">Monthly payment amount</Paragraph>
                      </TableHeader>
                      <TableCell center>
                        <Paragraph size="md" data-dd-privacy="allow">
                          {formatCurrency(offerLoanPlayback.currentLoan.monthlyRepayment)}
                        </Paragraph>
                      </TableCell>
                      <TableCell center>
                        <Paragraph size="md" data-dd-privacy="allow">
                          {formatCurrency(offerLoanPlayback.topUpLoan.monthlyRepayment)}
                        </Paragraph>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </>
            )}
            {offerLoanPlaybackError && (
              <Alert spacing="slim" variant="negative" marginY={3}>
                {offerLoanPlaybackError}
              </Alert>
            )}
            <Paragraph size="sm" data-dd-privacy="allow">
              The total amount you’ll pay back and your monthly repayment amounts may vary
              slightly depending on the repayment date you choose
            </Paragraph>
          </Card>
        </>
      </Redact>
      <Card heading="Your Affordability Check">
        <Spaced marginY={4} excludeLast>
          <Paragraph>
            As part of your application we have carried out an affordability check based
            on what you have told us about:
          </Paragraph>
          <List variant="icon">
            <ListItem leftAdornment={<Icon icon={faCheckCircle} color="green" />}>
              <Paragraph ml={1}>
                <Text variant="emphasis">Employment</Text>
              </Paragraph>
            </ListItem>
            <ListItem leftAdornment={<Icon icon={faCheckCircle} color="green" />}>
              <Paragraph ml={1}>
                <Text variant="emphasis">Income</Text>
              </Paragraph>
            </ListItem>
            <ListItem leftAdornment={<Icon icon={faCheckCircle} color="green" />}>
              <Paragraph ml={1}>
                <Text variant="emphasis">Spend</Text>
              </Paragraph>
            </ListItem>
          </List>
          <Paragraph size="md">
            <Text variant="emphasis">
              If your circumstances change this could affect your ability to afford your
              monthly repayments.
            </Text>
          </Paragraph>
        </Spaced>
      </Card>
      <Button
        fullWidth
        disabled={hasPlaybackErrors}
        isLoading={isContinueWithApplicationLoading}
        onClick={() => onContinueWithApplication()}
        data-testid="continue-with-application"
      >
        Continue
      </Button>
    </Spaced>
  );
};

export const OfferStep = {
  path: 'offer',
  component: Offer,
  provides: [],
};
