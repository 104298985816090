import { post } from '@sequensis/fetch';
import urljoin from 'url-join';

export const createUserFromDetails = (
  usersServiceBaseUrl: string,
  body: CreateUserFromDetailsRequest,
) =>
  post<CreateUserFromDetailsRequest, void>(
    urljoin(usersServiceBaseUrl, 'users:create-customer-from-details'), // check this is correct when api is implemented
    body,
  );

export interface CreateUserFromDetailsRequest {
  loanReference: string;
  dateOfBirth: Date;
  postCode: string;
  emailAddress: string;
}
