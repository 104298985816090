import { To, useHref, useLinkClickHandler } from 'react-router-dom-v5-compat';

import { Button, ButtonProps, getColor } from '@sequensis/stylish-core';
import styled from 'styled-components/macro';

const Anchor = styled.a`
  text-align: center;

  &:focus,
  &:focus-visible {
    outline: none;

    & > * {
      outline: ${getColor('accessibility')} solid 4px;
    }
  }
`;

interface ButtonLinkProps {
  to: To;
  state?: unknown;
  replace?: boolean;
  newTab?: boolean;
  children?: React.ReactNode;
  onClick?(e: React.MouseEvent<HTMLAnchorElement>): void;
  debug?: boolean;
  'data-testid'?: string;
  'aria-label'?: string;
}

export const ButtonLink = ({
  to,
  state,
  replace = false,
  newTab = false,
  children,
  onClick,
  'data-testid': testId,
  'aria-label': ariaLabel,
  ...rest
}: ButtonLinkProps & ButtonProps) => {
  const isExternal =
    typeof to === 'string' && (to.startsWith('http://') || to.startsWith('https://'));

  const href = useHref(to);

  const routerClickHandler = useLinkClickHandler(to, {
    replace,
    state,
  });

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    onClick?.(event);

    if (!event.defaultPrevented && !isExternal) {
      routerClickHandler(event);
    }
  };

  return (
    <Anchor
      href={isExternal ? (to as string) : href}
      target={newTab ? '_blank' : '_self'}
      onClick={handleClick}
      data-testid={testId}
      aria-label={ariaLabel}
    >
      <Button as="div" {...rest}>
        {children}
      </Button>
    </Anchor>
  );
};
