import { isDate, parse } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import * as Yup from 'yup';

import { RegistrationFormData } from './RegistrationFormData';

const postcodeRegex = new RegExp(
  '^(([gG][iI][rR] {0,}0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$',
);
const emailAddressRegex = new RegExp(/^\S+@\S+\.\S+$/);

const postcodeMessage = 'Valid postcode required';
const emailAddressMessage = 'Valid email address required';

const stripTimezone = (_: Date, originalValue: string) =>
  new Date(originalValue + 'T00:00:00.000Z');

function parseDateString(_: Date, originalValue: string) {
  if (originalValue === '') return undefined;

  const parsedDate = isDate(originalValue)
    ? originalValue
    : zonedTimeToUtc(parse(originalValue, 'dd/MM/yyyy', new Date()), '');

  return parsedDate;
}

export const registrationFormValidationSchema: Yup.SchemaOf<RegistrationFormData> =
  Yup.object().shape({
    postCode: Yup.string()
      .label('Postcode')
      .required(postcodeMessage)
      .matches(postcodeRegex, { message: postcodeMessage }),
    emailAddress: Yup.string()
      .label('Email address')
      .required(emailAddressMessage)
      .matches(emailAddressRegex, { message: emailAddressMessage }),
    dateOfBirth: Yup.date()
      .label('Date of birth')
      .required()
      .transform(stripTimezone)
      .transform(parseDateString)
      .min(new Date(1900, 0, 1), 'Date of birth can not be before 01/01/1900') // strictly no 19th century loanees
      .max(new Date(), 'Date of birth can not be greater than today')
      .typeError('Date of birth must be a valid date in format dd/mm/yyyy'),
    loanReference: Yup.string()
      .label('Loan reference')
      .required('Loan reference required'),
  });
