import { useRef } from 'react';

import { generateId } from '../utils/generateId';

/**
 * Hook to generate a "unique" id. Should be refactored to use react 18's `useId` once we've upgraded.
 */
export const useGeneratedId = (prefix: string) => {
  const id = useRef(generateId(prefix));

  return (suffix: string) => `${id.current}_${suffix}`;
};
