import { useState } from 'react';

import { faPencil } from '@fortawesome/pro-light-svg-icons';
import { useConfig, useFeatureFlag } from '@sequensis/react-config';
import { Redact } from '@sequensis/react-monitoring';
import {
  Alert,
  Anchor,
  Button,
  Card,
  Flex,
  Heading,
  Icon,
  Paragraph,
  Select,
  Spaced,
  Text,
} from '@sequensis/stylish-core';

import { ProgressHeader } from 'src/components/ProgressHeader';
import { useRaiseSubmissionError } from 'src/hooks/useRaiseSubmissionError';
import { Playback, SubmissionAction } from 'src/screens/TopUp/types';
import { ResidentialStatus } from 'src/types/CustomerProps';
import { formatBankAccountNumber } from 'src/utils/formatBankAccountNumber';
import { formatSortCode } from 'src/utils/formatSortCode';

export interface ApplicationDetailsPlayback {
  currentAddress: string;
  residentialStatus: ResidentialStatus;
  emailAddress: string;
  mobileNumber: string;
  bankAccountNumber: string;
  sortCode: string;
  accountName: string;
}

export interface BeforeYouContinueProps {
  detailsPlayback: Playback<ApplicationDetailsPlayback>;
  onChangeDetailsClick: SubmissionAction<void>;
  onBeforeYouContinueClick: SubmissionAction<{
    newResidentialStatus?: ResidentialStatus;
  }>;
}

const residentialStatusMap: Record<ResidentialStatus, string | null> = {
  HomeOwner: 'Home owner',
  LivingWithParents: 'Living with parents',
  Tenant: 'Tenant',
  Other: 'Other',
  Unset: null,
};

export const BeforeYouContinue = ({
  detailsPlayback: { playback: detailsPlayback, error: detailsPlaybackError },
  onChangeDetailsClick: {
    error: changeDetailsError,
    isLoading: isChangeDetailsLoading,
    onAction: onChangeDetails,
  },
  onBeforeYouContinueClick: {
    error: beforeYouContineError,
    isLoading: isBeforeYouContinueLoading,
    onAction: onContinueClick,
  },
}: BeforeYouContinueProps) => {
  useRaiseSubmissionError(changeDetailsError);
  useRaiseSubmissionError(beforeYouContineError);

  const [newResidentialStatus, setNewResidentialStatus] = useState<ResidentialStatus>();

  const { supportHelpdesk } = useConfig();

  const useSteps = useFeatureFlag('useSteps');

  return (
    <Spaced marginBottom={4} excludeLast>
      <ProgressHeader
        currentStep={useSteps ? 'Personal Details' : ''}
        stepText="Step 1/3"
      />
      <Card heading="Are your details still up to date?">
        <Spaced marginY={4} excludeLast>
          {detailsPlaybackError && (
            <Alert spacing="slim" variant="negative">
              {detailsPlaybackError}
            </Alert>
          )}
          {detailsPlayback && (
            <>
              <div>
                <Heading size="md" as="h3">
                  Current address:
                </Heading>
                <Redact>
                  <Paragraph size="md">{detailsPlayback.currentAddress}</Paragraph>
                </Redact>
              </div>
              <div>
                <Heading size="md" as="h3">
                  Residential status:
                </Heading>
                {!detailsPlayback.residentialStatus ||
                detailsPlayback.residentialStatus === 'Unset' ? (
                  <Select
                    placeholder="Please select"
                    onChange={(event) =>
                      setNewResidentialStatus(
                        event.currentTarget.value as ResidentialStatus,
                      )
                    }
                  >
                    <option value="HomeOwner">{residentialStatusMap['HomeOwner']}</option>
                    <option value="Tenant">{residentialStatusMap['Tenant']}</option>
                    <option value="LivingWithParents">
                      {residentialStatusMap['LivingWithParents']}
                    </option>
                    <option value="Other">{residentialStatusMap['Other']}</option>
                  </Select>
                ) : (
                  <Paragraph size="md">
                    {residentialStatusMap[detailsPlayback.residentialStatus]}
                  </Paragraph>
                )}
              </div>
              <div>
                <Heading size="md" as="h3">
                  Email address:
                </Heading>
                <Redact>
                  <Paragraph size="md">{detailsPlayback.emailAddress}</Paragraph>
                </Redact>
              </div>
              <div>
                <Heading size="md" as="h3">
                  Mobile number:
                </Heading>
                <Redact>
                  <Paragraph size="md">{detailsPlayback.mobileNumber}</Paragraph>
                </Redact>
              </div>
              <div>
                <Button
                  data-testid="before-you-continue-change-details-button"
                  fullWidth
                  variant="secondary"
                  size="slim"
                  marginBottom={1}
                  isLoading={isChangeDetailsLoading}
                  onClick={() => onChangeDetails()}
                >
                  <Icon icon={faPencil} />
                  <Text marginLeft={2} size="md" fontWeight="normal">
                    Change details
                  </Text>
                </Button>
              </div>
              <div>
                <Flex alignItems="center" flexWrap="wrap">
                  <Heading size="md" as="h3" marginRight={1}>
                    Bank account:
                  </Heading>
                  <Redact>
                    <Text>
                      {formatBankAccountNumber(detailsPlayback.bankAccountNumber)}
                    </Text>
                  </Redact>
                </Flex>
                <Flex alignItems="center" flexWrap="wrap">
                  <Heading size="md" as="h3" marginRight={1}>
                    Sort code:
                  </Heading>
                  <Redact>
                    <Text>{formatSortCode(detailsPlayback.sortCode)}</Text>
                  </Redact>
                </Flex>
                <Flex alignItems="center" flexWrap="wrap">
                  <Heading size="md" as="h3" marginRight={1}>
                    Name on account:
                  </Heading>
                  <Redact>
                    <Text>{detailsPlayback.accountName}</Text>
                  </Redact>
                </Flex>
                <Paragraph mt={3} size="sm">
                  If you need to change your bank account please{' '}
                  <Anchor href={supportHelpdesk} target="_blank">
                    get in touch with us
                  </Anchor>{' '}
                  instead.
                </Paragraph>
              </div>
            </>
          )}
        </Spaced>
        <Button
          data-testid="before-you-continue-confirmation-button"
          fullWidth
          variant="primary"
          marginTop={5}
          marginBottom={3}
          disabled={
            detailsPlaybackError !== undefined ||
            ((!detailsPlayback?.residentialStatus ||
              detailsPlayback.residentialStatus === 'Unset') &&
              !newResidentialStatus)
          }
          isLoading={isBeforeYouContinueLoading}
          onClick={() => onContinueClick({ newResidentialStatus })}
        >
          Continue
        </Button>
      </Card>
    </Spaced>
  );
};

export const BeforeYouContinueStep = {
  path: 'before-you-continue',
  component: BeforeYouContinue,
  provides: [],
};
