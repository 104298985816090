import { useState } from 'react';

import { useEventListener } from '@sequensis/react-hooks';
import { getColor } from '@sequensis/stylish-core';
import styled from 'styled-components';
import { padding, PaddingProps } from 'styled-system';

import { PoweredByOakbrook } from 'src/components/Logos/PoweredByOakbrook';
import SlantedBurger from 'src/images/SlantedBurger';
import { focusVisibleStyles } from 'src/Theme/mixins';

export interface MenuBarProps {
  onMenuToggle?: () => void;
}

export const MenuBar = ({ onMenuToggle }: MenuBarProps) => {
  const [pixelsFromTop, setPixelsFromTop] = useState(0);

  useEventListener('scroll', () => {
    setPixelsFromTop(window.pageYOffset);
  });

  const shouldShowShadow = pixelsFromTop ? pixelsFromTop > 0 : false;

  return (
    <Nav pr={3} pl={1} shadow={shouldShowShadow}>
      <NavButton
        tabIndex={0}
        onClick={onMenuToggle}
        data-testid="menu-button-open"
        aria-label="Open menu"
        p={2}
      >
        <SlantedBurger />
      </NavButton>

      <PoweredByOakbrook color="primary" />
    </Nav>
  );
};

const Nav = styled.nav<PaddingProps & { shadow?: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 4.25rem;
  background: ${getColor('white')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: box-shadow 0.5s ease-in-out;
  box-shadow: ${({ shadow, theme }) => (shadow ? theme.shadows.dropShadow : 'none')};
  ${padding}
`;

const NavButton = styled.button<PaddingProps>`
  display: inline-block;
  background: none;
  transition: background 0.2s;
  ${padding}
  ${focusVisibleStyles}
`;
