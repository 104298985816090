import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom-v5-compat';

import { faClock, faPencil } from '@fortawesome/pro-regular-svg-icons';
import { useConfig, useFeatureFlag } from '@sequensis/react-config';
import {
  Alert,
  Card,
  componentStyles,
  Flex,
  getColor,
  Icon,
  Paragraph,
  Text,
} from '@sequensis/stylish-core';
import { PageHeader } from '@sequensis/stylish-layouts';
import styled from 'styled-components';

import { analyticsClickEvent } from 'src/components/Analytics';
import { ButtonLink } from 'src/components/ButtonLink/ButtonLink';
import { useBrandName } from 'src/hooks/useBrandName';
import {
  getAccountReference,
  getDirectDebitIsPendingSetup,
  getNextRepaymentAmount,
  getNextRepaymentDate,
} from 'src/store/Account/selectors';
import { formatDate, formatDayAndSuperscript } from 'src/utils/formatDate';
import { formatCurrency } from 'src/utils/formatNumber';

import { CurrentPaymentSchedule } from '../Components/CurrentPaymentSchedule';

const paymentInformationBoxStyles = componentStyles('paymentInformationBox');

const PaymentInformationBox = styled(Alert)`
  background-color: ${getColor('white')};
  border-style: solid;
  border-width: 2px;
  ${paymentInformationBoxStyles}
`;

export const ManualPaymentManage = () => {
  const repaymentAmount = useSelector(getNextRepaymentAmount);
  const paymentDate = useSelector(getNextRepaymentDate) ?? '';
  const isDirectDebitPendingSetup = useSelector(getDirectDebitIsPendingSetup);
  const { likelyLoansOnlineAccountServicingUrl } = useConfig();
  const accountReference = useSelector(getAccountReference);
  const paymentDueDate = paymentDate ? formatDate(paymentDate) : '';
  const paymentDayOfMonth = paymentDate ? formatDayAndSuperscript(paymentDate) : '';

  const formattedRepaymentAmount = formatCurrency(repaymentAmount);

  const nextPaymentDueDate = `${formattedRepaymentAmount} by ${paymentDueDate}`;
  const formattedPaymentDayOfMonth = `${paymentDayOfMonth} of each month`;

  const allowPaymentDayChangeFlag = useFeatureFlag('allowPaymentDayChange');

  const brandName = useBrandName();

  return (
    <>
      <PageHeader title="Payments" />

      <Card mb={5}>
        {isDirectDebitPendingSetup && (
          <Alert
            marginY={1}
            variant="info"
            data-testid="direct-debit-pending-warning"
            icon={faClock}
          >
            Your Direct Debit is being setup
          </Alert>
        )}
        <Paragraph marginY={2}>
          Keep up with your monthly repayments by making one-off payments before your
          payment due date.
        </Paragraph>
        <Paragraph marginBottom={2}>
          We&apos;ll be called <Text fontWeight="bold">{brandName}</Text> on your
          statements.
        </Paragraph>

        {paymentDueDate && (
          <>
            <Paragraph mt={3} fontWeight="bold" data-testid="manual-monthly-amount-label">
              Next due date
            </Paragraph>
            <PaymentInformationBox
              marginBottom={2}
              data-testid="manual-monthly-amount-value"
            >
              {nextPaymentDueDate}
            </PaymentInformationBox>
          </>
        )}

        <Paragraph mt={3} fontWeight="bold" data-testid="manual-payment-day-label">
          Payment due date
        </Paragraph>
        <PaymentInformationBox mb={3} data-testid="manual-payment-day-value">
          <Flex flex="1 1 auto" justifyContent="space-between">
            {formattedPaymentDayOfMonth}
            {allowPaymentDayChangeFlag ? (
              <>
                <Link to="manual-payment-day">
                  <Icon icon={faPencil} size="lg" color="primary" maxHeight={24} />
                </Link>
              </>
            ) : (
              <a
                href={`${likelyLoansOnlineAccountServicingUrl}/#/loans/${accountReference}/payments`}
              >
                <Icon size="lg" icon={faPencil} color="primary" maxHeight={24} />
              </a>
            )}
          </Flex>
        </PaymentInformationBox>

        {/**
         * As we're in an index route, we counter-intuitively need to
         * climb up twice for this route to work. Will need changing when
         * react-router 6.4 is released.
         *
         * See https://github.com/remix-run/react-router/issues/8350
         */}
        <ButtonLink to="../../make-payment" navigational>
          Pay now
        </ButtonLink>
      </Card>

      {!isDirectDebitPendingSetup && (
        <Card mb={5} heading="Setup a Direct Debit">
          <Paragraph my={3}>
            Never miss your monthly repayment with a Direct Debit
          </Paragraph>
          <ButtonLink
            to="direct-debit/setup"
            navigational
            onClick={() => analyticsClickEvent('DirectDebit', 'direct-debit-set-up')}
          >
            Get started
          </ButtonLink>
        </Card>
      )}

      <CurrentPaymentSchedule />
    </>
  );
};

export default ManualPaymentManage;
