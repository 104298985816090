import { likelyLoansTheme, oakbrookTheme, StylishTheme } from '@sequensis/stylish-core';

/* TODO: Remove the merging of the oakbrookTheme
  the likely theme should only be providing overrides,
  this is a quick "hack" to get the tests running.
*/
export const likelyTheme: StylishTheme = {
  ...oakbrookTheme,
  ...likelyLoansTheme,
  fonts: {
    ...oakbrookTheme.fonts,
  },
  colors: {
    ...oakbrookTheme.colors,
    ...likelyLoansTheme.colors,
  },
  components: {
    ...likelyLoansTheme.components,
    productOverview: {
      baseStyle: {
        card: {
          backgroundColor: 'white',
          boxShadow: 'dropShadow',
        },
        cardText: {
          color: 'backgroundPair',
        },
        balanceAmount: {
          color: 'primary',
        },
      },
    },
    anchor: {
      baseStyle: {
        color: '#0003FF',
        textDecorationColor: '#0003FF',
      },
    },
    paymentInformationBox: {
      baseStyle: {
        borderColor: 'baseTint',
      },
    },

    highlight: {
      baseStyle: {
        color: 'primary',
      },
    },
  },
};
