import { addMonths, format } from 'date-fns';

import isUndefined from './isUndefined';

// 2021-01-02 -> 2nd
export const formatDayAndSuperscript = (date: string | Date | undefined) =>
  isUndefined(date) ? '' : format(new Date(date), 'do');

// 2021-01-02 -> January
export const formatMonthInText = (date: string | Date | undefined) =>
  isUndefined(date) ? '' : format(new Date(date), 'MMMM');

// 2021-01-02 -> 2
export const formatDay = (date: string | Date | undefined) =>
  isUndefined(date) ? '' : format(new Date(date), 'd');

// 2021-01-02 -> 2nd January 2021
export const formatDate = (date: string | Date) => format(new Date(date), 'do MMMM yyyy');

// 2021-01-02 -> 2nd Jan 2021
export const formatCondensedDate = (date: string | Date) =>
  format(new Date(date), 'do MMM yyyy');

// 2021-01-02 -> 01/02/2021
export const formatNumericDate = (date: string | Date) =>
  format(new Date(date), 'dd/MM/yyyy');

// 2021-01-02 -> February
export const getMonthAfterDate = (nextRepaymentDate: string) => {
  const followingMonth = addMonths(new Date(nextRepaymentDate), 1);
  const formattedMonth = formatMonthInText(followingMonth);

  return formattedMonth;
};

// 2021-01-02 -> 2nd Jan
export const formatShortDate = (date: string | Date) => format(new Date(date), 'do MMM');
