import { useLayoutEffect, useRef } from 'react';

import { useSelector } from 'react-redux';
import { useKey } from 'react-use';

import {
  faArrowCircleLeft,
  faComments,
  faTimes,
} from '@fortawesome/pro-regular-svg-icons';
import { useAuth } from '@sequensis/react-auth';
import { useConfig } from '@sequensis/react-config';
import { Redact } from '@sequensis/react-monitoring';
import { Box, Flex, getColor, Heading, Icon } from '@sequensis/stylish-core';
import { Navigation } from '@sequensis/stylish-layouts';
import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components/macro';
import { space, SpaceProps } from 'styled-system';

import { getFullName } from 'src/store/Customer/selectors';
import { focusVisibleStyles } from 'src/Theme/mixins';

import { AccountListNavigation } from './components/AccountListNavigation';
import { CustomerNavigation } from './components/CustomerNavigation';

interface FlyOutNavigationProps {
  isOpen?: boolean;
  handleClose(): void;
}

export const FlyOutNavigation = ({ isOpen, handleClose }: FlyOutNavigationProps) => {
  const fullName = useSelector(getFullName);
  const { supportHelpdesk } = useConfig();
  const { logout } = useAuth();

  const closeButtonRef = useRef<HTMLButtonElement | null>(null);
  const logoutButtonRef = useRef<HTMLButtonElement | null>(null);
  const navRef = useRef<HTMLElement | null>(null);

  useKey('Escape', () => (isOpen ? handleClose() : null), {}, [isOpen]);

  useLayoutEffect(() => {
    if (isOpen) focusCloseButton();
  }, [isOpen]);

  const focusCloseButton = () => {
    closeButtonRef.current && closeButtonRef.current.focus();
  };

  const focusLogoutButton = () => {
    logoutButtonRef.current && logoutButtonRef.current.focus();
  };

  const wrapFocus = (event: React.FocusEvent<HTMLButtonElement>) => {
    if (
      isOpen &&
      event.relatedTarget &&
      navRef.current &&
      !navRef.current.contains(event.relatedTarget as Node)
    ) {
      (closeButtonRef.current == event.target ? focusLogoutButton : focusCloseButton)();
    }
  };

  return (
    <>
      <AnimatePresence initial={false}>
        {isOpen ? (
          <NavContainer
            key="nav"
            width={7.5}
            initial={{ x: '-100%' }}
            exit={{ x: '-100%' }}
            animate={{ x: 0 }}
            transition={{ ease: 'easeInOut' }}
            position="fixed"
          >
            <Nav ref={navRef} p={3}>
              <Flex alignItems="center" justifyContent="space-between" mb={2}>
                {/* Extra div stops bearbones giving the Heading margins */}
                <div>
                  <Redact>
                    <Heading as="h3" color="backgroundPair" py={1}>
                      {fullName}
                    </Heading>
                  </Redact>
                </div>

                <CloseButton
                  tabIndex={0}
                  ref={closeButtonRef}
                  onClick={handleClose}
                  data-testid="close-menu"
                  aria-label="Close menu"
                  onBlur={wrapFocus}
                  p={1}
                  m={0}
                >
                  <StyledIcon icon={faTimes} color="backgroundPair" />
                </CloseButton>
              </Flex>

              <CustomerNavigation />

              <AccountListNavigation />

              <Navigation.Section aria-label="Miscellaneous">
                <Navigation.List>
                  <Navigation.Item
                    icon={faComments}
                    path={supportHelpdesk}
                    title="Help & support"
                    externalLink
                  />
                  <Navigation.Item
                    icon={faArrowCircleLeft}
                    path="/logout"
                    title="Logout"
                    onClick={(e) => {
                      e.preventDefault();
                      localStorage.removeItem('chaserData');
                      logout();
                    }}
                  />
                </Navigation.List>
              </Navigation.Section>
            </Nav>
          </NavContainer>
        ) : null}

        {isOpen ? (
          <Dimmer
            key="dimmer"
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ ease: 'easeInOut' }}
            onClick={handleClose}
            onKeyDown={handleClose}
          />
        ) : null}
      </AnimatePresence>
    </>
  );
};

const Dimmer = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 101;
  background: rgba(0, 0, 0, 0.7);
  cursor: pointer;
`;

const NavContainer = styled(motion(Box))`
  z-index: 102;
  top: 0;
  left: 0;
  bottom: 0;
  overflow-y: scroll;
`;

const Nav = styled.nav<SpaceProps>`
  background: ${getColor('background')};
  min-height: 100%;
  ${space}
`;

const CloseButton = styled.button<SpaceProps>`
  ${focusVisibleStyles};
  border: none;
  ${space}
  transition: background .2s;
  aspect-ratio: 1;
  display: block;

  &:hover {
    background: rgba(0, 0, 0, 0.25);
  }
`;

const StyledIcon = styled(Icon)`
  display: block;
  width: 1.5rem !important;
  height: 1.5rem !important;
`;
