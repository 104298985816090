import { useState } from 'react';

import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';
import { useAuth } from '@sequensis/react-auth';
import { useConfig } from '@sequensis/react-config';
import {
  Button,
  Card,
  Column,
  Flex,
  Grid,
  Heading,
  Icon,
  Paragraph,
} from '@sequensis/stylish-core';

import { A } from 'src/components/Anchor';
import { PoweredByOakbrook } from 'src/components/Logos';
import { useGetProductConfig } from 'src/hooks/useGetProductConfig';

type Error = 'VerificationLockedOut' | 'VerificationFailed' | 'CustomerAlreadyRegistered';
type SetupErrorProps = {
  error: string;
  onTryAgainClick: () => void;
};

const errorHeadings: Record<Error, string> = {
  VerificationLockedOut: "Couldn't find an account",
  VerificationFailed: "Couldn't find an account",
  CustomerAlreadyRegistered: 'Account already exists',
};

const oakbrookProductId = 'Oakbrook';
const finioProductId = 'FinioLoans';

export const SetupError = ({ error, onTryAgainClick }: SetupErrorProps) => {
  const [loading, setLoading] = useState(false);
  const { loginWithRedirect } = useAuth();
  const {
    companyDetails: { supportPhoneNumber },
  } = useConfig();
  const oakbrookConfig = useGetProductConfig(oakbrookProductId);
  const finioConfig = useGetProductConfig(finioProductId);

  const onLoginClick = () => {
    setLoading(true);
    loginWithRedirect();
  };

  return (
    <Flex
      flexDirection="column"
      justifyContent="flex-start"
      flex="1 1 auto"
      height="100%"
    >
      <Flex flex="1 1 auto" justifyContent="flex-end" my={5}>
        <PoweredByOakbrook color="primary" />
      </Flex>
      <Card>
        <Grid>
          <Column small={{ width: 10, offset: 1 }}>
            <Flex justifyContent="center">
              <Icon size="3x" icon={faExclamationCircle} />
            </Flex>
            <Heading textAlign="left" color="primary" as="h2" marginY={5}>
              {errorHeadings.hasOwnProperty(error)
                ? errorHeadings[error as Error]
                : 'Something went wrong'}
            </Heading>

            {error === 'CustomerAlreadyRegistered' && (
              <>
                <Paragraph mb={2}>
                  Our records show that we already have an online account setup with those
                  details.
                </Paragraph>
                <Paragraph mb={4}>
                  Please use the login button to view your account.
                </Paragraph>
                <Button
                  navigational
                  fullWidth
                  isLoading={loading}
                  disabled={loading}
                  onClick={() => onLoginClick()}
                >
                  Login
                </Button>
              </>
            )}

            {(error === 'VerificationLockedOut' || error === 'VerificationFailed') && (
              <>
                <Paragraph mb={2}>
                  Sorry but we couldn&apos;t find an account matching these details.
                </Paragraph>
                {error === 'VerificationFailed' && (
                  <Paragraph mb={2}>
                    Please check the details you entered and try again.
                  </Paragraph>
                )}
                <Paragraph mb={2}>
                  If the details are correct then please contact us on:
                </Paragraph>
                {oakbrookConfig?.contactUs && (
                  <Paragraph>
                    For Oakbrook Loans, click{' '}
                    <A href={oakbrookConfig?.contactUs} target="_blank" rel="noreferrer">
                      here
                    </A>
                  </Paragraph>
                )}
                {finioConfig?.contactUs && (
                  <Paragraph>
                    For Finio Loans, click{' '}
                    <A href={finioConfig?.contactUs} target="_blank" rel="noreferrer">
                      here
                    </A>
                  </Paragraph>
                )}
                {!oakbrookConfig?.contactUs && !finioConfig?.contactUs && (
                  <Paragraph fontWeight={700}>{supportPhoneNumber}</Paragraph>
                )}
                {error === 'VerificationFailed' && (
                  <Button mt={4} fullWidth onClick={onTryAgainClick}>
                    Try again
                  </Button>
                )}
              </>
            )}
          </Column>
        </Grid>
      </Card>
    </Flex>
  );
};
