import { useSelector } from 'react-redux';

import { useDebounced, useMount } from '@sequensis/react-hooks';
import { Box, Card, Loader } from '@sequensis/stylish-core';
import { isEmpty } from 'ramda';

import { usePaymentScheduleContext } from 'src/context/PaymentScheduleContext';
import { getPaymentMethodIsDirectDebit } from 'src/store/Account/selectors';

import { PaymentScheduleView } from './PaymentScheduleView';

export const CurrentPaymentSchedule = () => {
  const { fetchPaymentSchedule, paymentScheduleState } = usePaymentScheduleContext();
  const isPaymentMethodDirectDebit = useSelector(getPaymentMethodIsDirectDebit);

  const response = paymentScheduleState.result;
  const debouncedLoading = useDebounced(paymentScheduleState.loading, 200);

  useMount(async () => {
    fetchPaymentSchedule();
  });

  const scheduledPayments = !response?.responseJson?.scheduleEvents
    ? []
    : response.responseJson.scheduleEvents;

  const noScheduledPayments = paymentScheduleState.result && isEmpty(scheduledPayments);

  return noScheduledPayments || paymentScheduleState.status == 'not-requested' ? null : (
    <Card
      marginBottom={5}
      heading={
        isPaymentMethodDirectDebit
          ? 'Your Direct Debit schedule'
          : 'Your payment schedule'
      }
    >
      {paymentScheduleState.loading || !paymentScheduleState.result ? (
        debouncedLoading && (
          <Box marginY={5}>
            <Loader />
          </Box>
        )
      ) : (
        <PaymentScheduleView schedule={scheduledPayments} />
      )}
    </Card>
  );
};
