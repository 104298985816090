import { useEffect, useRef, useState } from 'react';

import { useHistory, useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom-v5-compat';

import {
  GetApplicationByCustomerIdResponse,
  GetApplicationResponse,
  useFetchApplication,
  useFinaliseApplication,
  useResetApplicationAccount,
} from '@sequensis/creditapplication-reactsdk';
import { useConfig } from '@sequensis/react-config';
import { ThemeProvider } from 'styled-components';

import { useGetProductConfig } from 'src/hooks/useGetProductConfig';
import { getProductTheme } from 'src/utils/productHelper';

import Loading from '../Loading';
import { mapFirstNamePlayback, mapLegalDocumentsPlayback } from '../TopUp/mappers';

import { DONE, SIGN_CONTRACT, steps, UPDATED_OFFER } from './data';
import { Done } from './Done';
import { LegalDocuments } from './LegalDocuments';
import { Offer } from './Offer';

export const ResignContract = () => {
  const location = useLocation();
  const history = useHistory();
  const navigate = useNavigate();

  const [mode, setMode] = useState<string>(steps[0]);
  const [isResetApplicationCalled, setIsResetApplicationCalled] = useState(false);
  const [loading, setLoading] = useState(true);

  const applicationDetails = useRef(location.state as GetApplicationByCustomerIdResponse);

  const brand = applicationDetails?.current?.selectedOffer?.brand
    ?.toString()
    .replace(/ /g, '');
  const productConfig = useGetProductConfig(brand || '');

  const {
    topUps: { pollIntervalMs },
  } = useConfig();

  useEffect(() => {
    localStorage.removeItem('redirectUrl');
  }, []);

  useEffect(() => {
    if (!location.state) navigate('/');
    history.replace({ state: null });

    if (applicationDetails.current?.status === 'AwaitingResettlement')
      resetAccountApplication.mutate(applicationDetails.current.id);
    else setLoading(false);
  }, [applicationDetails]);

  useFetchApplication(
    isResetApplicationCalled ? applicationDetails.current.id : undefined,
    {
      onSuccess: (data: GetApplicationResponse) => {
        if (data?.status !== 'AwaitingContractSignatures') return;

        applicationDetails.current = data;
        setLoading(false);
      },
      onError: () => {
        navigate('/error', { replace: true });
      },
      refetchInterval: (data) => {
        // Wait for all status to be Awaiting Contract Signatures before going to next step
        return data?.status === 'AwaitingContractSignatures' ? false : pollIntervalMs;
      },
    },
  );

  const resetAccountApplication = useResetApplicationAccount({
    onSuccess: () => {
      setIsResetApplicationCalled(true);
    },
    onError: () => {
      navigate('/error', { replace: true });
    },
  });

  const finaliseApplication = useFinaliseApplication({
    onSuccess: () => goToNextStep(),
  });

  const onDoneClick = () => {
    navigate('/');
  };

  const goToNextStep = () => {
    setMode((prev) => steps[steps.indexOf(prev) + 1]);
  };

  if (productConfig === undefined) {
    console.warn(
      `Failed to find a product configuration for productId "${applicationDetails?.current?.selectedOffer?.brand}" in ApplicationUnderReviewCardController`,
    );
    return null;
  }
  const productTheme = getProductTheme(productConfig.baseRoute);

  const render = () => {
    if (loading) return <Loading />;

    if (mode === UPDATED_OFFER)
      return (
        <Offer
          selectedOffer={applicationDetails.current.selectedOffer}
          onContinueClick={goToNextStep}
          product={productConfig.baseRoute}
        />
      );

    if (mode === SIGN_CONTRACT)
      return (
        <LegalDocuments
          firstNamePlayback={mapFirstNamePlayback(
            (applicationDetails?.current as any)?.primaryBorrower?.personalDetails,
          )}
          legalDocumentsPlayback={mapLegalDocumentsPlayback(
            applicationDetails?.current?.contracts,
          )}
          onLegalDocsSubmit={{
            onAction: () => {
              applicationDetails?.current.id &&
                finaliseApplication.mutate(applicationDetails?.current.id);
            },
            isLoading: finaliseApplication.isLoading,
          }}
        />
      );

    if (mode === DONE)
      return <Done onDoneClick={onDoneClick} productConfig={productConfig} />;

    return null;
  };

  return <ThemeProvider theme={productTheme}>{render()}</ThemeProvider>;
};
