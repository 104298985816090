import { Card, Paragraph } from '@sequensis/stylish-core';

export type CurrentPaymentPlanProps = {
  className?: string;
};

export default function CurrentPaymentPlan({ className }: CurrentPaymentPlanProps) {
  return (
    <Card
      className={className}
      data-testid="current-paymentplan-card"
      heading="Your payment plan"
    >
      <Paragraph>Your payment plan has been setup</Paragraph>
    </Card>
  );
}
