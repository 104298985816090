import { GetApplicationByCustomerIdResponse } from '@sequensis/creditapplication-reactsdk';
import { useConfig } from '@sequensis/react-config';
import { Card, Heading, Spaced } from '@sequensis/stylish-core';

import { ProviderCard } from './ProviderCard';

type InformationProps = {
  applicationDetails: GetApplicationByCustomerIdResponse;
  onProceedClick: (slug: string) => void;
};

export const Others = ({ applicationDetails, onProceedClick }: InformationProps) => {
  const { mistho } = useConfig();

  const slugOptions =
    (applicationDetails as any)?.primaryBorrower?.employmentDetails?.employmentStatus ===
    'SelfEmployed'
      ? mistho.selfemployed
      : mistho.salaried;
  const otherSlugs = slugOptions.others;

  return (
    <Card paddingBottom={0}>
      <Heading as="h3" mb={4}>
        Other options
      </Heading>
      <Spaced mb={4}>
        {['default', ...otherSlugs].map((slug) => (
          <ProviderCard key={slug} slug={slug} onProceedClick={onProceedClick} />
        ))}
      </Spaced>
    </Card>
  );
};
