import { useSelector } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom-v5-compat';

import { Location } from 'history';

import { getPaymentMethodIsDirectDebit } from 'src/store/Account/selectors';

import ChangeDirectDebitPaymentDay from './DirectDebit/ChangeDirectDebitPaymentDay';
import { DirectDebitGettingStarted } from './DirectDebit/DirectDebitGettingStarted';
import DirectDebitManage from './DirectDebit/DirectDebitManage';
import { ChangeManualPaymentDay } from './Manual/ChangeManualPaymentDay';
import ManualPaymentManage from './Manual/ManualPaymentManage';

export const Payments = () => {
  const location = useLocation();
  const state = location.state as { background?: Location } | undefined;

  const isPaymentMethodDirectDebit = useSelector(getPaymentMethodIsDirectDebit);

  return (
    <>
      <Routes location={state?.background ?? location}>
        <Route
          index
          element={
            isPaymentMethodDirectDebit ? <DirectDebitManage /> : <ManualPaymentManage />
          }
        />
        <Route path="payment-day" element={<ChangeDirectDebitPaymentDay />} />
        <Route path="direct-debit/setup" element={<DirectDebitGettingStarted />} />
        <Route path="manual-payment-day" element={<ChangeManualPaymentDay />} />
      </Routes>

      {state?.background && (
        <Routes>
          <Route path="payment-day" element={<ChangeDirectDebitPaymentDay />} />
          <Route path="manual-payment-day" element={<ChangeManualPaymentDay />} />
        </Routes>
      )}
    </>
  );
};
