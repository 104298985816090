import { useEffect, useState } from 'react';

import { Controller, FormProvider, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useSearchParams } from 'react-router-dom-v5-compat';

import { yupResolver } from '@hookform/resolvers';
import { useConfig } from '@sequensis/react-config';
import { useAsyncCallback } from '@sequensis/react-hooks';
import {
  Box,
  Button,
  Card,
  Column,
  Flex,
  Grid,
  Heading,
  Paragraph,
  TextField,
} from '@sequensis/stylish-core';

import { createUserFromDetails } from 'src/api/user/userApi';
import { PoweredByOakbrook } from 'src/components/Logos';

import { RegistrationFormData } from './RegistrationFormData';
import { registrationFormValidationSchema } from './RegistrationFormSchema';
import { SetupError } from './SetupError';
import { SetupUser } from './SetupUser';

export const Register = () => {
  const [mode, setMode] = useState<'error' | 'setup' | ''>('');
  const [emailAddress, setEmailAddress] = useState('');
  const [searchParams] = useSearchParams();

  const formMethods = useForm<RegistrationFormData>({
    mode: 'onChange',
    resolver: yupResolver(registrationFormValidationSchema),
  });

  const { handleSubmit, control, formState, errors } = formMethods;

  const [createUserState, createUser] = useAsyncCallback(createUserFromDetails);

  useEffect(() => {
    if (createUserState.status === 'success') {
      setMode('setup');
      setEmailAddress(formMethods.getValues('emailAddress'));
    }
    if (createUserState.status == 'error') setMode('error');
  }, [createUserState]);

  const {
    usersServiceApi: { url: usersServiceApiUrl },
  } = useConfig();

  const handleFormSubmit = (data: RegistrationFormData) => {
    if (data.dateOfBirth && data.emailAddress && data.loanReference && data.postCode)
      createUser(usersServiceApiUrl, data);
  };

  const onTryAgainClick = () => {
    setMode('');
  };

  if (mode === 'setup') return <SetupUser emailAddress={emailAddress} />;

  if (mode === 'error')
    return (
      <SetupError
        error={(createUserState.error as any)?.errors?.[0]?.type || ''}
        onTryAgainClick={onTryAgainClick}
      />
    );

  return (
    <Flex
      flexDirection="column"
      justifyContent="flex-start"
      flex="1 1 auto"
      height="100%"
    >
      <Flex flex="1 1 auto" justifyContent="flex-end" my={5}>
        <PoweredByOakbrook color="primary" />
      </Flex>
      <Card marginBottom={24}>
        <Grid>
          <Column small={{ width: 10, offset: 1 }}>
            <Heading textAlign="left" color="primary" as="h2" marginY={5}>
              Register for an online account
            </Heading>

            <Paragraph fontWeight="lighter" lineHeight="spacey">
              To register, we need to find your loan account using the requested details
              below.
            </Paragraph>

            <FormProvider {...formMethods}>
              <Flex
                as="form"
                flexDirection="column"
                justifyContent="space-between"
                flex="1 1 auto"
                onSubmit={handleSubmit(handleFormSubmit)}
              >
                <Box mt={4}>
                  <Controller
                    name="loanReference"
                    control={control}
                    defaultValue={searchParams.get('loanReference') ?? ''}
                    as={
                      <TextField
                        label="Enter your loan reference"
                        error={
                          formState.touched.loanReference &&
                          errors?.loanReference?.message
                        }
                        helperText="This can be found in your welcome email and any other correspondence from us"
                        placeholder="Loan reference"
                      />
                    }
                  />
                </Box>

                <Box mt={4}>
                  <Controller
                    name="postCode"
                    control={control}
                    defaultValue=""
                    as={
                      <TextField
                        label="Enter your postcode"
                        error={formState.touched.postCode && errors?.postCode?.message}
                        placeholder="Postcode"
                      />
                    }
                  />
                </Box>

                <Box mt={4}>
                  <Controller
                    name="dateOfBirth"
                    control={control}
                    defaultValue=""
                    as={
                      <TextField
                        label="Enter your date of birth"
                        error={
                          formState.touched.dateOfBirth && errors?.dateOfBirth?.message
                        }
                        placeholder="dd/mm/yyyy"
                        inputOverride={
                          <NumberFormat
                            mask={['d', 'd', 'm', 'm', 'y', 'y', 'y', 'y']}
                            placeholder="dd/mm/yyyy"
                            format="##/##/####"
                          />
                        }
                      />
                    }
                  />
                </Box>

                <Box mt={4}>
                  <Controller
                    name="emailAddress"
                    error={
                      formState.touched.emailAddress && errors?.emailAddress?.message
                    }
                    control={control}
                    defaultValue=""
                    as={
                      <TextField
                        label="Enter your email address"
                        helperText="This must be the same one used when applying for your loan"
                        placeholder="email@address.co.uk"
                      />
                    }
                  />
                </Box>

                <Button
                  mt={6}
                  fullWidth={true}
                  disabled={formState.isValid == false || createUserState.loading}
                  isLoading={createUserState.loading}
                  data-testid="register-btn"
                >
                  Submit
                </Button>
              </Flex>
            </FormProvider>
          </Column>
        </Grid>
      </Card>
    </Flex>
  );
};
