import { ActionType, createReducer } from 'typesafe-actions';

import { AllAccountProps } from 'src/types/AccountProps';
import { mergeState } from 'src/utils/reduxHelper';

import { fetchAccount } from './actions';
import * as actions from './actions';

export interface AccountState {
  account: AllAccountProps | undefined;
  fetching: boolean;
  fetchError: string | undefined;
}

const INITIAL_STATE: AccountState = {
  fetching: false,
  fetchError: undefined,
  account: undefined,
};

type AccountAction = ActionType<typeof actions>;

const reducer = createReducer<AccountState, AccountAction>(INITIAL_STATE)
  .handleAction(fetchAccount.request, (state) =>
    mergeState(state, { fetching: true, fetchError: undefined }),
  )
  .handleAction(fetchAccount.failure, (state, action) =>
    mergeState(state, { fetching: false, fetchError: action.payload }),
  )
  .handleAction(fetchAccount.success, (state, action) =>
    mergeState(state, { fetching: false, account: action.payload }),
  );

export default reducer;
