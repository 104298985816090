import { Suspense } from 'react';

import { Outlet } from 'react-router-dom-v5-compat';

import { ShimV5Props } from 'src/components/AppLayout/V5RouteLayoutShim';

import { Loading } from '../screens/Loading';

export const LazyRouteLayout = ({ v5Shim, children }: ShimV5Props) => (
  <Suspense fallback={<Loading />}>{v5Shim && children ? children : <Outlet />}</Suspense>
);
