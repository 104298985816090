import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom-v5-compat';

import { getAccountStatus } from '../store/Account/selectors';
import { AccountStatus } from '../types/AccountProps';

export const ActiveAccountGuard = () => {
  const isActiveAccount = useSelector(getAccountStatus) === AccountStatus.Active;

  return isActiveAccount ? <Outlet /> : <Navigate to="/customer/overview" />;
};
