import { lazy } from 'react';

import { Navigate, Route, Routes } from 'react-router-dom-v5-compat';

import { LazyRouteLayout } from 'src/routes/LazyRouteLayout';

const Overview = lazy(
  () => import(/* webpackChunkName: "overview" */ 'src/screens/Overview'),
);

const Documents = lazy(
  () => import(/* webpackChunkName: "documents" */ 'src/screens/Documents'),
);

const MarketingPreferences = lazy(
  () =>
    import(
      /* webpackChunkName: "marketing-preferences" */ 'src/screens/MarketingPreferences'
    ),
);

const CustomerProfile = lazy(
  () => import(/* webpackChunkName: "customer-profile" */ './Profile'),
);

const AddAddress = lazy(
  () => import(/* webpackChunkName: "add-address" */ './components/AddAddress'),
);

export const Customer = () => (
  <Routes>
    <Route element={<LazyRouteLayout />}>
      <Route index element={<Navigate to="overview" replace />} />

      <Route path="overview" element={<Overview />} />

      <Route path="documents" element={<Documents />} />

      <Route path="marketing-preferences" element={<MarketingPreferences />} />

      <Route path="profile" element={<CustomerProfile />} />

      <Route path="address/edit" element={<AddAddress />} />
    </Route>
  </Routes>
);
