import { ReactNode } from 'react';

import { useSelector } from 'react-redux';

import { Alert } from '@sequensis/stylish-core';
import { add, sub } from 'date-fns';

import { useProductSupportUrl } from 'src/hooks/useProductSupportUrl';
import { getNextRepaymentDate } from 'src/store/Account/selectors';
import { formatDate } from 'src/utils/formatDate';
import isUndefined from 'src/utils/isUndefined';

export interface ChangePaymentDayGuardProps {
  children: ReactNode;
}

export function ChangePaymentDayGuard({ children }: ChangePaymentDayGuardProps) {
  const { productSupportUrl } = useProductSupportUrl();
  const nextRepaymentDateString = useSelector(getNextRepaymentDate);
  const oneMonthFromNow = add(new Date(), { months: 1 });
  const calculateOneMonthBeforeNextPaymentPhrase = (
    nextRepaymentDateString: string | undefined,
  ) =>
    isUndefined(nextRepaymentDateString)
      ? ''
      : 'until ' + formatDate(sub(new Date(nextRepaymentDateString), { months: 1 }));

  const canChangePaymentDay =
    nextRepaymentDateString && new Date(nextRepaymentDateString) <= oneMonthFromNow;

  if (canChangePaymentDay) return <>{children}</>;

  return (
    <Alert
      icon={true}
      variant="warning"
      data-testid="unable-to-choose-paymentday-alert"
      marginTop={3}
    >
      We&apos;re unable to change your payment due date{' '}
      {calculateOneMonthBeforeNextPaymentPhrase(nextRepaymentDateString)}, please return
      then to choose an alternative day. Alternatively, you can{' '}
      <a
        href={productSupportUrl}
        rel="noopener noreferrer"
        // eslint-disable-next-line react/jsx-no-target-blank
        target="_blank"
      >
        contact us here
      </a>
      .
    </Alert>
  );
}
