import { useEffect, useRef, useState } from 'react';

import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { useAuth } from '@sequensis/react-auth';
import { useMount } from '@sequensis/react-hooks';
import {
  Box,
  Button,
  componentPartStyles,
  Flex,
  Icon,
  Loader,
  Modal,
} from '@sequensis/stylish-core';
import { range } from 'ramda';
import styled, { css } from 'styled-components/macro';
import { space, SpaceProps } from 'styled-system';

import { DEFAULT_STICKY_CONTROL_STYLING } from './legalDocumentStyling';

const legalDocumentStyles = (part: string) =>
  componentPartStyles('legalDocument', part, DEFAULT_STICKY_CONTROL_STYLING);

const StyledPage = styled(Page)`
  ${legalDocumentStyles('page')};

  canvas {
    max-width: 100%;
    height: auto !important;
  }
`;

const StyledDocument = styled(Document)`
  ${legalDocumentStyles('document')}
`;

const StickyControl = styled.div<{ placement: 'top' | 'bottom' } & SpaceProps>`
  width: 100%;
  position: sticky;

  ${legalDocumentStyles('stickyControl')}
  ${space}

  ${({ placement }) =>
    placement === 'bottom' &&
    css`
      bottom: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    `}

  ${({ placement }) =>
    placement === 'top' &&
    css`
      top: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `}
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  ${legalDocumentStyles('overlay')}
`;

export const LegalDocument = ({
  location,
  onAccept,
  onClose,
}: {
  location: string;
  onAccept: () => void;
  onClose: () => void;
}) => {
  const [hasReadAllPages, setHasReadAllPages] = useState(false);
  const [numPages, setNumPages] = useState(0);
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    if (pageRef.current === undefined) {
      return;
    }

    const onScrolledIntoView = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setHasReadAllPages(true);
        }
      });
    };

    const observer = new IntersectionObserver(onScrolledIntoView, {
      threshold: 0.25,
      root: null,
    });
    observer.observe(pageRef.current);

    return () => observer.disconnect();
  }, [hasLoaded]);

  const pageRef = useRef<HTMLCanvasElement>();

  const { getToken } = useAuth();

  const [fileInfo, setFileInfo] =
    useState<{ url: URL; httpHeaders: Record<string, string> }>();

  useMount(async () => {
    setFileInfo({
      url: new URL(location),
      httpHeaders: {
        Authorization: `Bearer ${await getToken()}`,
      },
    });
  });

  return (
    <Modal isOpen onClose={onClose} showCloseButton={false}>
      <StyledDocument
        file={fileInfo}
        onLoadSuccess={({ numPages }) => setNumPages(numPages)}
        loading={
          <Flex height="100vh" justifyContent="center" alignContent="center">
            <Loader />
          </Flex>
        }
      >
        {range(0, numPages).map((index) => {
          const isLastPage = numPages - 1 === index;

          return (
            <StyledPage
              key={`pdfview_page_${index + 1}`}
              pageNumber={index + 1}
              renderAnnotationLayer={false}
              className={isLastPage ? 'pdf-last-page' : ''}
              onLoadSuccess={() => (isLastPage ? setHasLoaded(true) : {})}
              canvasRef={(pageCanvas) => {
                if (pageCanvas) pageRef.current = pageCanvas;
              }}
              loading={<Box height="100vh" width="100%" />}
            />
          );
        })}
        {numPages > 0 && (
          <Overlay>
            <StickyControl placement="top" paddingX={[3, 4]} paddingY={1}>
              <Flex justifyContent="space-between">
                <Button
                  variant="tertiary"
                  onClick={onClose}
                  aria-label="Close"
                  size="slim"
                  data-testid="close"
                >
                  <Icon icon={faTimes} size="2x" />
                </Button>
              </Flex>
            </StickyControl>
            <StickyControl placement="bottom" paddingX={[3, 4]} paddingY={1}>
              <Button
                variant="primary"
                fullWidth
                onClick={onAccept}
                disabled={hasReadAllPages === false}
                data-testid="read-and-agree"
              >
                {hasReadAllPages
                  ? 'I have read and agree'
                  : 'Please read the full document'}
              </Button>
            </StickyControl>
          </Overlay>
        )}
      </StyledDocument>
    </Modal>
  );
};
