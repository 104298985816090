import { ReactNode, useMemo } from 'react';

import { StylishTheme } from '@sequensis/stylish-core';
import { mergeDeepRight } from 'ramda';
import { ThemeProvider } from 'styled-components';

import { useProduct } from 'src/hooks/useProduct';
import { getProductTheme } from 'src/utils/productHelper';

import { finioOverride } from './finioOverride';
import { oakbrookOverride } from './oakbrookOverride';

interface RouteBasedThemeProviderProps {
  children: ReactNode;
}

const RouteBasedThemeProvider = ({ children }: RouteBasedThemeProviderProps) => {
  const product = useProduct({ shouldThrow: false });

  const currentTheme = useMemo(() => {
    return getProductTheme(product?.baseRoute ?? '');
  }, [product]);

  const themeOverrides: Record<string, Partial<StylishTheme | undefined>> = {
    ['Oakbrook Loans']: oakbrookOverride,
    ['Finio']: finioOverride,
  };

  return (
    <ThemeProvider
      theme={
        mergeDeepRight(
          currentTheme,
          themeOverrides[currentTheme.name] ?? {},
        ) as StylishTheme
      }
    >
      {children}
    </ThemeProvider>
  );
};

export { RouteBasedThemeProvider };
