import { faExclamation } from '@fortawesome/pro-regular-svg-icons';
import { Button, Card, Flex, Halo, Icon, Spaced } from '@sequensis/stylish-core';

import { useRaiseSubmissionError } from 'src/hooks/useRaiseSubmissionError';
import { Playback, SubmissionAction } from 'src/screens/TopUp/types';

import { FirstNamePlayback } from '../LegalDocuments';
import DeclineReasons from './DeclineReasons';
import { REASON_ID } from './data';

export interface DeclineReasonExplanation {
  reason: string;
  explanation: string;
}

export interface DeclineReasonGroups {
  category: string;
  declineReasonExplanation: DeclineReasonExplanation[];
}

export interface DeclineProps {
  product: string;
  personalDetailsPlayBack: Playback<FirstNamePlayback>;
  onDeclineClick: SubmissionAction<void>;
  declineReasons: string[];
}

export const Decline = ({
  product,
  personalDetailsPlayBack: { playback: personalDetailsPlayBack },
  onDeclineClick: { error, isLoading, onAction: onDeclineClick },
  declineReasons,
}: DeclineProps) => {
  useRaiseSubmissionError(error);

  return (
    <Spaced marginBottom={4} excludeLast>
      <Card>
        <Spaced marginBottom={3} excludeLast>
          <Flex justifyContent="center">
            <Halo variant="error">
              <Icon icon={faExclamation} size="3x" />
            </Halo>
          </Flex>
          <DeclineReasons
            declineReasons={
              declineReasons.length ? declineReasons : [REASON_ID.INTERNAL_GENERAL]
            }
            name={personalDetailsPlayBack?.firstName || ''}
          />
        </Spaced>
      </Card>
      <Button
        data-testid="close-application-confirmation-button"
        fullWidth
        navigational
        isLoading={isLoading}
        onClick={() => onDeclineClick()}
      >
        Close application
      </Button>
    </Spaced>
  );
};

export const DeclineStep = {
  path: 'decline',
  component: Decline,
  provides: [],
};
