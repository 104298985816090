import { useState } from 'react';

import { Controller, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers';
import { Redact } from '@sequensis/react-monitoring';
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Flex,
  Heading,
  Logo,
  Paragraph,
  Pill,
  Spaced,
} from '@sequensis/stylish-core';
import * as yup from 'yup';

import { ProgressHeader } from 'src/components/ProgressHeader';
import { useRaiseSubmissionError } from 'src/hooks/useRaiseSubmissionError';
import { Playback, SubmissionAction } from 'src/screens/TopUp/types';

import { LegalDocument } from './LegalDocument';

export interface LegalDocumentsForm {
  explanationAccept: boolean;
  creditInformationAccept: boolean;
  loanAgreementAccept: boolean;
}

const legalDocsFormSchema: yup.SchemaOf<LegalDocumentsForm> = yup.object().shape({
  explanationAccept: yup
    .boolean()
    .test(
      'explanationAccept',
      'Adequate explanation document must be signed',
      (value) => value === true,
    )
    .required(),
  creditInformationAccept: yup
    .boolean()
    .test(
      'creditInformationAccept',
      'Pre-contract credit information document must be signed',
      (value) => value === true,
    )
    .required(),
  loanAgreementAccept: yup
    .boolean()
    .test(
      'loanAgreementAccept',
      'Fixed sum loan agreement document must be signed',
      (value) => value === true,
    )
    .required(),
});

export interface FirstNamePlayback {
  firstName: string;
}
export interface LegalDocumentsPlayback {
  explanationAcceptPdfLocation: string;
  creditInformationAcceptPdfLocation: string;
  loanAgreementAcceptPdfLocation: string;
}
export interface LegalDocumentsProps {
  progress: number;
  firstNamePlayback: Playback<FirstNamePlayback>;
  legalDocumentsPlayback: Playback<LegalDocumentsPlayback>;
  onLegalDocsSubmit: SubmissionAction<LegalDocumentsForm>;
}

export const LegalDocuments = ({
  progress,
  firstNamePlayback: { playback: firstNamePlayback },
  onLegalDocsSubmit: { error, isLoading, onAction: onLegalDocsSubmit },
  legalDocumentsPlayback: { playback, error: legalDocumentsPlaybackError },
}: LegalDocumentsProps) => {
  const [openPdf, setOpenPdf] = useState<keyof LegalDocumentsForm>();
  const { control, handleSubmit, errors } = useForm<LegalDocumentsForm>({
    mode: 'onBlur',
    resolver: yupResolver(legalDocsFormSchema),
  });

  const hasPlaybackErrors = legalDocumentsPlaybackError !== undefined;

  useRaiseSubmissionError(error);

  return (
    <Spaced marginBottom={4} excludeLast>
      <ProgressHeader
        currentStep="The important legal stuff"
        progress={progress}
        stepText="Step 3/3"
      />
      <Redact>
        <Card>
          {firstNamePlayback && (
            <Heading size="xl">
              Great news {firstNamePlayback.firstName}, you&apos;ve been approved to top
              up your loan. It&apos;s time to finish your application.
            </Heading>
          )}
        </Card>
      </Redact>
      <Card>
        <form
          onSubmit={handleSubmit((data: LegalDocumentsForm) => onLegalDocsSubmit(data))}
        >
          <Flex justifyContent="space-between" width="100%">
            <Flex alignItems="center">
              <Pill variant="fancy">Important</Pill>
            </Flex>
            <Logo variant="dark" width={5} />
          </Flex>
          <Paragraph size="lg" marginTop={3} marginBottom={4}>
            Finish your application by reading the following documents.
          </Paragraph>
          {playback && (
            <Spaced marginY={5} excludeFirst excludeLast>
              {[
                [
                  'explanationAccept',
                  'adequate-explanation',
                  'Click to read and agree to the Adequate explanation',
                  'explanationAcceptPdfLocation',
                ],
                [
                  'creditInformationAccept',
                  'pre-contract-credit-information',
                  'Click to read and agree to the Pre-contract credit information',
                  'creditInformationAcceptPdfLocation',
                ],
                [
                  'loanAgreementAccept',
                  'fixed-sum-loan-agreement',
                  'Click to read and agree to the Fixed sum loan agreement',
                  'loanAgreementAcceptPdfLocation',
                ],
              ].map(([fieldKey, title, label, pdfLocation]) => (
                <Controller
                  key={fieldKey}
                  render={({ value, onChange }) => (
                    <>
                      <Checkbox
                        name={fieldKey}
                        checked={value}
                        onChange={(e: React.FormEvent<HTMLInputElement>) => {
                          if (e.currentTarget.checked) {
                            setOpenPdf(fieldKey as keyof LegalDocumentsForm);
                          } else {
                            onChange(false);
                          }
                        }}
                        label={
                          <Paragraph size="md" ml={1}>
                            {label}
                          </Paragraph>
                        }
                        id={title}
                        error={errors[fieldKey as keyof LegalDocumentsForm]?.message}
                      />
                      {openPdf === fieldKey && (
                        <LegalDocument
                          title={title}
                          location={playback[pdfLocation as keyof LegalDocumentsPlayback]}
                          onAccept={() => {
                            onChange(true);
                            setOpenPdf(undefined);
                          }}
                          onClose={() => setOpenPdf(undefined)}
                        />
                      )}
                    </>
                  )}
                  name={fieldKey as keyof LegalDocumentsForm}
                  control={control}
                  defaultValue={false}
                />
              ))}
            </Spaced>
          )}
          {legalDocumentsPlaybackError && (
            <Alert spacing="slim" variant="negative" marginY={3}>
              {legalDocumentsPlaybackError}
            </Alert>
          )}
          <Button
            fullWidth
            mt={5}
            isLoading={isLoading}
            disabled={hasPlaybackErrors}
            data-testid="send-my-loan"
          >
            Send my loan
          </Button>
        </form>
      </Card>
    </Spaced>
  );
};

export const LegalDocumentsStep = {
  path: 'approved',
  component: LegalDocuments,
  provides: ['explanationAccept', 'creditInformationAccept', 'loanAgreementAccept'],
};
