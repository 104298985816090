import { Button, Card, Paragraph } from '@sequensis/stylish-core';
import { PageHeader } from '@sequensis/stylish-layouts';

interface ServerErrorProps {
  isLoading?: boolean;
  onClick?: () => void;
}

export const ServerError = ({ isLoading, onClick }: ServerErrorProps) => (
  <>
    <PageHeader title="It's not you, it's us" />
    <Card>
      <Paragraph>
        Something went wrong when we tried to fetch your details, this could be due to a
        problem with our services or your internet connection.
      </Paragraph>
      <Paragraph>
        If you keep seeing this message, please try returning later or get in touch for
        anything urgent.
      </Paragraph>
      {onClick ? (
        <Button
          variant="primary"
          fullWidth={true}
          onClick={onClick}
          marginTop={5}
          isLoading={isLoading ?? false}
        >
          Try again
        </Button>
      ) : null}
    </Card>
  </>
);
