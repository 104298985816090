import * as yup from 'yup';

const mobilePhoneNumberRegex = new RegExp(
  /^(\+44\s?7\d{3}|\(?07\d{3}\)?)\s?\d{3}\s?\d{3}$/,
);
const optionalphoneNumberRegex = new RegExp(
  /^$|(0|44|\+44)([1-6][0-9]{8,10}|7[0-9]{9})$/,
);

export const phoneNumberValidationErrorMessage = 'Please enter a valid phone number';

export const mobilePhoneNumberSchema = yup.string().matches(mobilePhoneNumberRegex);
export const optionalPhoneNumberSchema = yup.string().matches(optionalphoneNumberRegex);
