import { useState } from 'react';

import { faFileUpload, faLightbulbOn, faLock } from '@fortawesome/pro-regular-svg-icons';
import {
  Alert,
  Box,
  BoxProps,
  Button,
  Card,
  Flex,
  Halo,
  Icon,
  Paragraph,
  Spaced,
  Text,
} from '@sequensis/stylish-core';

import { DocumentUpload } from '../components/DocumentsContainer';
import {
  badImageMap,
  documentDisplayName,
  generalTipsMap,
  goodImageMap,
  supplyTipsMap,
} from '../data';

const SupplyTipsStep = (props: {
  documentName: string;
  supplyTips: string[];
  onNextClick: () => void;
}) => (
  <Card
    heading={
      <>
        <Flex justifyContent="space-around">
          <Halo>
            <Icon icon={faLightbulbOn} size="3x" />
          </Halo>
        </Flex>
        <Paragraph fontSize="3xl" lineHeight="3xl">
          How to supply your {props.documentName}
        </Paragraph>
      </>
    }
  >
    <Box mt={2}>
      <BulletList bullets={props.supplyTips} />
    </Box>
    <Button variant="primary" mt={6} fullWidth onClick={props.onNextClick}>
      <Paragraph>Next</Paragraph>
    </Button>
    <ProgressDots highlightedDot={0} mt={4} />
  </Card>
);

const GeneralTipsStep = (props: {
  documentName: string;
  generalTips: string[];
  badExampleImageUrl: string;
  goodExampleImageUrl: string;
  onNextClick?: () => void;
}) => (
  <Card
    heading={
      <>
        <img
          src={props.goodExampleImageUrl}
          alt="Example of an acceptable document"
          width="100%"
        />
        <img
          src={props.badExampleImageUrl}
          alt="Example of an unnacceptable document"
          width="100%"
        />
        <Paragraph fontSize="3xl" lineHeight="3xl">
          Tips
        </Paragraph>
      </>
    }
  >
    <BulletList bullets={props.generalTips} />
    <Button variant="primary" mt={6} fullWidth onClick={props.onNextClick}>
      <Paragraph>Next</Paragraph>
    </Button>
    <ProgressDots highlightedDot={1} mt={4} />
  </Card>
);

const UploadTimeStep = ({ onCancel, onFileSelected }: any) => (
  <Card
    heading={
      <>
        <Flex justifyContent="space-around">
          <Halo>
            <Icon icon={faFileUpload} size="3x" />
          </Halo>
        </Flex>
        <Paragraph fontSize="3xl" lineHeight="3xl">
          Time to upload your document
        </Paragraph>
      </>
    }
  >
    <Paragraph mt={3} mb={3}>
      Use the &apos;upload a document&apos; button to choose an image or document from
      your device.
    </Paragraph>

    <Alert
      variant="info"
      icon={faLock}
      spacing="slim"
      title="Your data is safe and secure"
    >
      <Spaced mb={3} excludeLast>
        <Paragraph>
          We are committed to protecting your personal and financial information.
        </Paragraph>
        <Paragraph>
          Once we&apos;ve received your information, we use strict procedures and security
          features to prevent unauthorised access, alteration, disclosure or deletion.
        </Paragraph>
      </Spaced>
    </Alert>

    <DocumentUpload onDocumentAccepted={onFileSelected}>
      <Button variant="primary" mt={3} fullWidth>
        <Paragraph>
          <Icon icon={faFileUpload} mr={2} />
          Upload a document
        </Paragraph>
      </Button>
    </DocumentUpload>
    <Button key="cancel" variant="secondary" marginTop={3} fullWidth onClick={onCancel}>
      <Paragraph>Cancel</Paragraph>
    </Button>
    <ProgressDots highlightedDot={2} mt={4} />
  </Card>
);

const ProgressDots = (props: BoxProps & { highlightedDot: number }) => (
  <Flex {...props} justifyContent="center" alignItems="center">
    <ProgressDot highlighted={props.highlightedDot === 0} />
    <ProgressDot highlighted={props.highlightedDot === 1} />
    <ProgressDot highlighted={props.highlightedDot === 2} />
  </Flex>
);

const ProgressDot = (props: { highlighted: boolean }) => (
  <Box
    backgroundColor={props.highlighted ? 'secondary' : 'baseTint'}
    width={props.highlighted ? '1rem' : '0.5rem'}
    height={props.highlighted ? '1rem' : '0.5rem'}
    marginX={3}
    style={{ borderRadius: '50%' }}
  />
);

const BulletList = (props: { bullets: string[] }) => (
  <ul style={{ listStyleType: 'disc', listStyle: 'inside', padding: 0 }}>
    {props.bullets.map((bullet, i) => (
      <Box mt={1} key={i}>
        <li>
          <Text>{bullet}</Text>
        </li>
      </Box>
    ))}
  </ul>
);

const DocumentTips = ({ selectedDocument, ...props }: any) => {
  const [stepNum, setStepNum] = useState(1);
  const { documentType } = selectedDocument || {};
  const supplyTipsProps = {
    documentName: documentDisplayName[documentType as keyof typeof documentDisplayName],
    supplyTips: supplyTipsMap[documentType as keyof typeof supplyTipsMap],
  };

  const generaTipsProps = {
    documentName: documentDisplayName[documentType as keyof typeof documentDisplayName],
    generalTips: generalTipsMap[documentType as keyof typeof generalTipsMap],
    badExampleImageUrl: badImageMap[documentType as keyof typeof badImageMap],
    goodExampleImageUrl: goodImageMap[documentType as keyof typeof goodImageMap],
  };

  return (
    <>
      {stepNum === 1 && (
        <SupplyTipsStep {...supplyTipsProps} onNextClick={() => setStepNum(2)} />
      )}
      {stepNum === 2 && (
        <GeneralTipsStep {...generaTipsProps} onNextClick={() => setStepNum(3)} />
      )}
      {stepNum === 3 && <UploadTimeStep {...props} />}
    </>
  );
};

export default DocumentTips;
