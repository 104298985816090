import { useSelector } from 'react-redux';

import { useConfig } from '@sequensis/react-config';

import { ApplicationState } from 'src/store';
import { getCurrentAccountId } from 'src/store/Accounts';

import { useProduct } from './useProduct';

export const useAccountSelector = <T>(
  selector: (accountId: string) => ({ accountsState }: ApplicationState) => T,
) => {
  const product = useProduct();
  const {
    productConfiguration: { products },
  } = useConfig();

  const accountId = useSelector(getCurrentAccountId(product.baseRoute, products)) ?? '';

  return useSelector(selector(accountId));
};
