import { Controller, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers';
import {
  Box,
  Button,
  Card,
  NumericField,
  Paragraph,
  RadioGroup,
  RadioOption,
  Spaced,
  Text,
} from '@sequensis/stylish-core';
import * as yup from 'yup';

import { Explainer } from 'src/components/Explainer';
import { ProgressHeader } from 'src/components/ProgressHeader';
import { useRaiseSubmissionError } from 'src/hooks/useRaiseSubmissionError';
import { SubmissionAction } from 'src/screens/TopUp/types';

export interface IncomeForm {
  incomeAmount?: number;
  incomeFrequency?: 'Weekly' | 'Monthly';
  benefitAmount?: number;
  benefitFrequency?: 'Weekly' | 'Monthly';
  otherAmount?: number;
  otherFrequency?: 'Weekly' | 'Monthly';
}

function numberInRangeTest(max: number, value?: number) {
  if (value !== undefined) return value >= 0 && value <= max;
  return false;
}

const incomeFormSchema = (
  incomeMax: number,
  benefitMax: number,
  otherMax: number,
): yup.SchemaOf<IncomeForm> =>
  yup.object().shape({
    incomeAmount: yup
      .number()
      .typeError('Income amount is required')
      .required('Income amount is required')
      .test('range', `Income amount must be between £0 and £${incomeMax}`, (value) =>
        numberInRangeTest(incomeMax, value),
      ),
    incomeFrequency: yup
      .mixed()
      .label('Income frequency')
      .when('incomeAmount', {
        is: (incomeAmount: string) => parseInt(incomeAmount) > 0,
        then: (schema) => schema.required(),
      }),
    benefitAmount: yup
      .number()
      .typeError('Benefit income amount is required')
      .required('Benefit income amount is required')
      .test(
        'range',
        `Benefit income amount must be between £0 and £${benefitMax}`,
        (value) => numberInRangeTest(benefitMax, value),
      ),
    benefitFrequency: yup
      .mixed()
      .label('Benefit income frequency')
      .when('benefitAmount', {
        is: (benefitAmount: string) => parseInt(benefitAmount) > 0,
        then: (schema) => schema.required(),
      }),
    otherAmount: yup
      .number()
      .typeError('Other income amount is required')
      .required('Other income amount is required')
      .test('range', `Other income amount must be between £0 and £${otherMax}`, (value) =>
        numberInRangeTest(otherMax, value),
      ),
    otherFrequency: yup
      .mixed()
      .label('Other income frequency')
      .when('otherAmount', {
        is: (otherAmount: string) => parseInt(otherAmount) > 0,
        then: (schema) => schema.required(),
      }),
  });

export interface IncomeProps {
  progress: number;
  onIncomeSubmit: SubmissionAction<IncomeForm>;
  incomeMax?: number;
  benefitMax?: number;
  otherMax?: number;
}

export const Income = ({
  progress,
  onIncomeSubmit: { onAction: onIncomeSubmit, error, isLoading },
  incomeMax = Infinity,
  benefitMax = incomeMax,
  otherMax = incomeMax,
}: IncomeProps) => {
  const { handleSubmit, control, errors, watch } = useForm<IncomeForm>({
    mode: 'onBlur',
    resolver: yupResolver(incomeFormSchema(incomeMax, benefitMax, otherMax)),
  });

  const incomeAmountWatch = watch('incomeAmount');
  const benefitAmountWatch = watch('benefitAmount');
  const otherAmountWatch = watch('otherAmount');

  // Tried using the yup validator for this but couldn't get an error
  // on the root object to display / stop submission.
  // Also for some reason the watches are strings here.
  const noIncomeProvided =
    parseInt(incomeAmountWatch as any as string) === 0 &&
    parseInt(benefitAmountWatch as any as string) === 0 &&
    parseInt(otherAmountWatch as any as string) === 0;

  useRaiseSubmissionError(error);

  return (
    <Spaced marginBottom={4} excludeLast>
      <ProgressHeader currentStep="Income" progress={progress} stepText="Step 2/3" />
      <Card heading="We just need to check how much you earn again, to make sure a top up loan is affordable for you">
        <form onSubmit={handleSubmit((data: IncomeForm) => onIncomeSubmit(data))}>
          <Spaced marginBottom={5} excludeLast>
            <Box marginTop={4}>
              <label htmlFor="incomeAmount">
                <Paragraph size="md" marginBottom={1}>
                  <Text variant="emphasis">
                    How much money do you get from your own employment or pensions, after
                    tax and National Insurance have been taken off?
                  </Text>
                </Paragraph>
              </label>
              <Paragraph size="sm">
                Please enter the amount you regularly receive into your bank account.
              </Paragraph>
              <Controller
                render={({ value, onChange }) => (
                  <NumericField
                    leftAdornment={
                      <Text size="lg" variant="emphasis">
                        £
                      </Text>
                    }
                    id="incomeAmount"
                    name="incomeAmount"
                    value={value}
                    onChange={onChange}
                    error={errors.incomeAmount?.message}
                    placeholder="000"
                    data-testid="income-amount"
                    data-heap-redact-text
                    maxLength={10}
                  />
                )}
                name="incomeAmount"
                control={control}
                defaultValue={undefined}
              />
              <Box marginTop={1}>
                <Explainer question="What should I include here?">
                  <Paragraph size="sm">
                    Please enter the net amount that you regularly receive (after any
                    deductions such as tax and national insurance). If you are self
                    employed enter the amount that you pay yourself. If you have multiple
                    sources of income please provide the total.
                  </Paragraph>
                </Explainer>
              </Box>
            </Box>
            {incomeAmountWatch! > 0 && (
              <Box>
                <Controller
                  render={({ value, onChange }) => (
                    <RadioGroup
                      name="incomeFrequency"
                      label="How often are you paid this amount"
                      selected={value}
                      onChange={onChange}
                      variant="bar"
                      error={errors.incomeFrequency?.message}
                    >
                      <RadioOption value="Weekly" data-testid="income-frequency-weekly">
                        Weekly
                      </RadioOption>
                      <RadioOption value="Monthly" data-testid="income-frequency-monthly">
                        Monthly
                      </RadioOption>
                    </RadioGroup>
                  )}
                  name="incomeFrequency"
                  control={control}
                  defaultValue={undefined}
                />
              </Box>
            )}
            <Box>
              <label htmlFor="benefitAmount">
                <Paragraph size="md" marginBottom={1}>
                  <Text variant="emphasis">
                    If you yourself receive benefits, how much do you get?
                  </Text>
                </Paragraph>
              </label>
              <Paragraph size="sm">
                Please enter the amount that only you regularly receive for all benefits
                you claim.
              </Paragraph>
              <Controller
                render={({ value, onChange }) => (
                  <NumericField
                    leftAdornment={
                      <Text size="lg" variant="emphasis">
                        £
                      </Text>
                    }
                    id="benefitAmount"
                    name="benefitAmount"
                    value={value}
                    onChange={onChange}
                    error={errors.benefitAmount?.message}
                    placeholder="0"
                    data-testid="benefit-amount"
                    data-heap-redact-text
                    maxLength={10}
                  />
                )}
                name="benefitAmount"
                control={control}
                defaultValue={undefined}
              />
            </Box>
            {benefitAmountWatch! > 0 && (
              <Box>
                <Controller
                  render={({ value, onChange }) => (
                    <RadioGroup
                      name="benefitFrequency"
                      label="How often are you paid this amount"
                      selected={value}
                      onChange={onChange}
                      variant="bar"
                      error={errors.benefitFrequency?.message}
                    >
                      <RadioOption value="Weekly" data-testid="benefit-frequency-weekly">
                        Weekly
                      </RadioOption>
                      <RadioOption
                        value="Monthly"
                        data-testid="benefit-frequency-monthly"
                      >
                        Monthly
                      </RadioOption>
                    </RadioGroup>
                  )}
                  name="benefitFrequency"
                  control={control}
                  defaultValue={undefined}
                />
              </Box>
            )}
            <Box>
              <label htmlFor="otherAmount">
                <Paragraph size="md" marginBottom={1}>
                  <Text variant="emphasis">
                    Do you receive any other income? If so, how much do you receive after
                    any deductions?
                  </Text>
                </Paragraph>
              </label>
              <Paragraph size="sm">
                Please enter the amount you regularly receive
              </Paragraph>
              <Controller
                render={({ value, onChange }) => (
                  <NumericField
                    id="otherAmount"
                    name="otherAmount"
                    leftAdornment={
                      <Text size="lg" variant="emphasis">
                        £
                      </Text>
                    }
                    value={value}
                    onChange={onChange}
                    error={errors.otherAmount?.message}
                    data-testid="other-amount"
                    data-heap-redact-text
                    placeholder="0"
                    maxLength={10}
                  />
                )}
                name="otherAmount"
                control={control}
                defaultValue={undefined}
              />
              <Box marginTop={1}>
                <Explainer question="What should I include here?">
                  <Paragraph size="sm">
                    Please enter the net amount for any other income that you regularly
                    receive. This could include things such as rental income, investments,
                    income from lodgers, etc.
                  </Paragraph>
                </Explainer>
              </Box>
            </Box>
            {otherAmountWatch! > 0 && (
              <Box>
                <Controller
                  render={({ value, onChange }) => (
                    <RadioGroup
                      name="otherFrequency"
                      label="How often are you paid this amount"
                      selected={value}
                      onChange={onChange}
                      variant="bar"
                      error={errors.otherFrequency?.message}
                    >
                      <RadioOption value="Weekly" data-testid="other-frequency-weekly">
                        Weekly
                      </RadioOption>
                      <RadioOption value="Monthly" data-testid="other-frequency-monthly">
                        Monthly
                      </RadioOption>
                    </RadioGroup>
                  )}
                  name="otherFrequency"
                  control={control}
                  defaultValue={undefined}
                />
              </Box>
            )}
            <Button
              fullWidth
              isLoading={isLoading}
              disabled={noIncomeProvided}
              data-testid="income-continue-confirmation-button"
            >
              Continue
            </Button>
          </Spaced>
        </form>
      </Card>
    </Spaced>
  );
};

export const IncomeStep = {
  path: 'income',
  component: Income,
  provides: [
    'incomeAmount',
    'incomeFrequency',
    'benefitAmount',
    'benefitFrequency',
    'otherAmount',
    'otherFrequency',
  ],
};
