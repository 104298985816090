import { Controller, useForm } from 'react-hook-form';

import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import { yupResolver } from '@hookform/resolvers';
import {
  Alert,
  Box,
  Button,
  Card,
  Checkbox,
  Flex,
  Heading,
  Icon,
  List,
  ListItem,
  Logo,
  Paragraph,
  Pill,
  Spaced,
  Text,
} from '@sequensis/stylish-core';
import * as yup from 'yup';

import { Explainer } from 'src/components/Explainer';
import { ProgressHeader } from 'src/components/ProgressHeader';
import { useRaiseSubmissionError } from 'src/hooks/useRaiseSubmissionError';
import { Playback, SubmissionAction } from 'src/screens/TopUp/types';
import { formatAPR, formatCurrency } from 'src/utils';

export interface HardSearchForm {
  hardSearchConsent: boolean;
}

export const hardSearchValidation: yup.SchemaOf<HardSearchForm> = yup.object().shape({
  hardSearchConsent: yup
    .boolean()
    .equals([true], 'Consent is required to complete the final check'),
});

export interface TopUpLoanPlayback {
  extraBorrowingAmount: number;
  totalAmountRepayable: number;
  paymentsCount: number;
  repaymentAmount: number;
  currentLoanApr: number;
  topUpLoanApr: number;
}

export interface HardSearchProps {
  progress: number;
  loanPlayback: Playback<TopUpLoanPlayback>;
  onCompleteFinalCheck: SubmissionAction<HardSearchForm>;
}

export const HardSearch = ({
  progress,
  loanPlayback: { playback, error: loanDetailsPlayBackError },
  onCompleteFinalCheck: { error, isLoading, onAction: onCompleteFinalCheck },
}: HardSearchProps) => {
  const formMethods = useForm<HardSearchForm>({
    mode: 'onBlur',
    resolver: yupResolver(hardSearchValidation),
    defaultValues: {
      hardSearchConsent: false,
    },
  });

  const { handleSubmit, errors, control } = formMethods;

  const hasPlaybackErrors = loanDetailsPlayBackError !== undefined;
  useRaiseSubmissionError(error);

  return (
    <Spaced marginBottom={4} excludeLast>
      <ProgressHeader
        currentStep="Almost there..."
        progress={progress}
        stepText="Step 3/3"
      />
      <Card>
        <Spaced marginBottom={3} excludeLast>
          <Flex justifyContent="space-between" width="100%">
            <Flex alignItems="center">
              <Pill variant="fancy">Important</Pill>
            </Flex>
            <Logo variant="dark" width={5} />
          </Flex>
          <Heading as="h3" size="xl">
            Your top up loan details
          </Heading>
          {playback && (
            <List variant="icon">
              <ListItem leftAdornment={<Icon icon={faCheckCircle} color="secondary" />}>
                <Paragraph size="md">
                  Borrowing an extra
                  <Text variant="emphasis">{` ${formatCurrency(
                    playback.extraBorrowingAmount,
                  )}`}</Text>
                </Paragraph>
              </ListItem>
              <ListItem leftAdornment={<Icon icon={faCheckCircle} color="secondary" />}>
                <Paragraph size="md">
                  Repaying in total
                  <Text variant="emphasis">{` ${formatCurrency(
                    playback.totalAmountRepayable,
                  )}`}</Text>
                </Paragraph>
              </ListItem>
              <ListItem leftAdornment={<Icon icon={faCheckCircle} color="secondary" />}>
                <Paragraph size="md">
                  Making<Text variant="emphasis">{` ${playback.paymentsCount}`}</Text>{' '}
                  repayments of
                  <Text variant="emphasis">{` ${formatCurrency(
                    playback.repaymentAmount,
                  )}`}</Text>
                </Paragraph>
              </ListItem>
              <ListItem leftAdornment={<Icon icon={faCheckCircle} color="secondary" />}>
                {playback.currentLoanApr > playback.topUpLoanApr ? (
                  <Flex width="100%">
                    <Paragraph size="md" marginRight="1.5rem">
                      <del>{formatAPR(playback.currentLoanApr)}%</del>
                      <Text variant="emphasis" color="secondary">
                        {` ${formatAPR(playback.topUpLoanApr)}%`} APR
                      </Text>
                    </Paragraph>
                    <Pill size="sm" variant="fancy">
                      Price Drop
                    </Pill>
                  </Flex>
                ) : (
                  <Paragraph size="md" marginRight="1.5rem">
                    <Text variant="emphasis">
                      {` ${formatAPR(playback.topUpLoanApr)}%`} APR
                    </Text>
                  </Paragraph>
                )}
              </ListItem>
            </List>
          )}
          {loanDetailsPlayBackError && (
            <Alert spacing="slim" variant="negative">
              {loanDetailsPlayBackError}
            </Alert>
          )}
        </Spaced>
      </Card>
      <Card heading="We'll run a hard credit check now">
        <Spaced marginY={3} excludeLast>
          <Paragraph size="md">
            {`We've`} checked your affordability, and now we need to run a full search of
            your credit report.
          </Paragraph>
          <Paragraph size="md" variant="emphasis">
            This check will be visible on your credit report and can be seen by other
            lenders.
          </Paragraph>
          <Box marginTop={1}>
            <Explainer question="What is a hard credit check?" modal>
              <Paragraph size="md" marginBottom={3}>
                A hard search is when a company requests a copy of your full credit
                report.
              </Paragraph>
              <Paragraph size="md">
                Unlike soft searches, a hard credit search will leave a mark on your
                credit report. So if {`you've`} applied for credit, other lenders will see
                it if they do a credit search. Typically, any credit or loan application
                will stay on your report for 12 months. So {`it's`} important not to make
                too many applications for credit over a short time period, or to reapply
                right away if &nbsp;{`you're`} declined. However, an occasional hard
                credit check {`isn't `}
                likely to greatly affect your credit score.
              </Paragraph>
            </Explainer>
          </Box>
          <form
            onSubmit={handleSubmit(({ hardSearchConsent }) =>
              onCompleteFinalCheck({ hardSearchConsent }),
            )}
          >
            <Controller
              render={({ value, onChange }) => (
                <Checkbox
                  label={
                    <Paragraph size="md" ml={1}>
                      I consent for you to run a hard credit check which may affect my
                      credit score
                    </Paragraph>
                  }
                  checked={value}
                  onChange={(e: React.FormEvent<HTMLInputElement>) =>
                    onChange(e.currentTarget.checked)
                  }
                  id="hard-search-consent-checkbox"
                  error={errors.hardSearchConsent?.message}
                  marginBottom={3}
                />
              )}
              name="hardSearchConsent"
              control={control}
            />
            <Button
              data-testid="hard-search-consent-submit-button"
              fullWidth
              isLoading={isLoading}
              disabled={hasPlaybackErrors}
            >
              Run hard credit check
            </Button>
          </form>
        </Spaced>
      </Card>
    </Spaced>
  );
};

export const HardSearchStep = {
  path: 'hard-search',
  component: HardSearch,
  provides: ['hardSearchConsent'],
};
