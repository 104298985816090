import { useState } from 'react';

import { Controller, useForm } from 'react-hook-form';

import { faPoundSign } from '@fortawesome/pro-regular-svg-icons';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { yupResolver } from '@hookform/resolvers';
import { useFeatureFlag } from '@sequensis/react-config';
import {
  Box,
  Button,
  Card,
  Heading,
  Icon,
  List,
  ListItem,
  Paragraph,
  Select,
  Slider,
  Spaced,
  TextField,
} from '@sequensis/stylish-core';
import styled from 'styled-components';
import * as yup from 'yup';

import { ProgressHeader } from 'src/components/ProgressHeader';
import { useRaiseSubmissionError } from 'src/hooks/useRaiseSubmissionError';
import { SubmissionAction } from 'src/screens/TopUp/types';
import { formatAPR } from 'src/utils';

const FlexLabel = styled.label`
  display: flex;
  align-items: center;
`;

export interface ConfigureOfferForm {
  loanAmount: number;
  loanTerm: number;
  loanPurpose: string;
}

export interface ConfigureOfferProps {
  onConfigureOfferSubmit: SubmissionAction<ConfigureOfferForm>;
  loanAmount: {
    min: number;
    max: number;
    defaultValue: number;
  };
  loanTerm: {
    min: number;
    max: number;
    default: number;
  };
  representativeApr: number;
  getLoanTerm: (loanAmt: number) => { min?: number; max?: number };
}

interface ConfigureOfferFormSchema {
  loanAmount: string;
  loanTerm: string;
  loanPurpose: string;
}

const ConfirmOfferFormSchema: (
  minAmount: number,
  maxAmount: number,
  minTerm: number,
  maxTerm: number,
) => yup.SchemaOf<ConfigureOfferFormSchema> = (minAmount, maxAmount, minTerm, maxTerm) =>
  yup.object().shape({
    loanAmount: yup
      .string()
      .label('Loan amount')
      .required('Loan amount is required')
      .test(
        'range',
        `Loan amount must be between £${minAmount} and £${maxAmount}`,
        (value?: string) => {
          const asNumber = value ? parseInt(value) : NaN;

          return asNumber >= minAmount && asNumber <= maxAmount;
        },
      ),
    loanTerm: yup
      .string()
      .label('Loan term')
      .required()
      .test(
        'valid',
        `Loan term must be between ${minTerm} and ${maxTerm} months`,
        (value?: string) =>
          !!value && parseInt(value) >= minTerm && parseInt(value) <= maxTerm,
      ),
    loanPurpose: yup.string().label('Loan purpose').required(),
  });

export const ConfigureOffer = ({
  loanAmount: { min: minAmount, max: maxAmount, defaultValue: defaultAmount },
  loanTerm: { min: minTerm, max: maxTerm, default: defaultTerm },
  representativeApr: representativeApr,
  onConfigureOfferSubmit: {
    isLoading: isSubmissionLoading,
    error: submissionError,
    onAction: onConfigureOfferSubmit,
  },
  getLoanTerm,
}: ConfigureOfferProps) => {
  const [loanTerm, setLoanTerm] = useState({
    min: minTerm,
    max: maxTerm,
  });
  const { control, handleSubmit, errors, register, setValue, getValues } =
    useForm<ConfigureOfferFormSchema>({
      mode: 'onBlur',
      resolver: yupResolver(
        ConfirmOfferFormSchema(minAmount, maxAmount, loanTerm.min, loanTerm.max),
      ),
    });

  const useSteps = useFeatureFlag('useSteps');

  useRaiseSubmissionError(submissionError);

  function ensureWithinMinMax(value: number): number {
    return Math.min(maxAmount, Math.max(minAmount, value));
  }

  const loanAmountStep = 50;
  minAmount = Math.ceil(minAmount / loanAmountStep) * loanAmountStep;
  maxAmount = Math.floor(maxAmount / loanAmountStep) * loanAmountStep;
  defaultAmount = ensureWithinMinMax(defaultAmount);

  const onLoanAmountChange = (val: string | number) => {
    const { min, max } = getLoanTerm(+val);
    if (!(min && max)) return;

    setLoanTerm(() => ({ min, max }));

    const loanTermValue = getValues('loanTerm');
    if (+loanTermValue < min) setValue('loanTerm', String(min));
    if (+loanTermValue > max) setValue('loanTerm', String(max));
  };

  return (
    <Spaced marginBottom={4} excludeLast>
      <ProgressHeader currentStep={useSteps ? 'Top Up Amount' : ''} stepText="Step 1/3" />
      <Card>
        <form
          data-dd-privacy="allow"
          onSubmit={handleSubmit(({ loanTerm, loanAmount, loanPurpose }) =>
            onConfigureOfferSubmit({
              loanTerm: parseInt(loanTerm),
              loanAmount: parseInt(loanAmount),
              loanPurpose,
            }),
          )}
        >
          <label htmlFor="loanAmount" id="loanAmountLabel">
            <Heading size="xl" marginBottom={3}>
              I want to borrow an extra
            </Heading>
          </label>
          <Controller
            render={({ value, onChange }) => (
              <Spaced marginBottom={5}>
                <TextField
                  value={value}
                  id="loanAmount"
                  inputMode="numeric"
                  onChange={(e) => {
                    let val = e.target.value;
                    if (!isNaN(+value)) val = e.target.value.replace(/\D/g, '');
                    onChange(val);
                    onLoanAmountChange(val);
                  }}
                  name="loanAmount"
                  leftAdornment={<Icon icon={faPoundSign} />}
                  error={errors.loanAmount?.message}
                  maxLength={10}
                />
                <Slider
                  aria-labelledby="loanAmountLabel"
                  name="loanAmount"
                  min={minAmount}
                  max={maxAmount}
                  value={value === '' ? minAmount : ensureWithinMinMax(value)}
                  step={loanAmountStep}
                  onChange={(val) => {
                    onChange(val);
                    onLoanAmountChange(val);
                  }}
                />
              </Spaced>
            )}
            name="loanAmount"
            control={control}
            defaultValue={defaultAmount}
          />
          <Box marginTop={5} marginBottom={5}>
            <Controller
              render={({ value, onChange }) => (
                <Spaced marginBottom={5}>
                  <FlexLabel>
                    <label htmlFor="loanTerm" id="loanTermLabel">
                      <Heading size="xl" marginRight={3}>
                        Over
                      </Heading>
                    </label>
                    <Box width={4}>
                      <TextField
                        value={value}
                        id="loanTerm"
                        inputMode="numeric"
                        onChange={(e) => {
                          const valueAsNumber = parseInt(e.target.value);

                          if (!isNaN(valueAsNumber)) {
                            onChange(e.target.value.replace(/\D/g, ''));
                          }

                          if (e.target.value === '') {
                            onChange(e.target.value);
                          }
                        }}
                        name="loanTerm"
                        error={errors.loanTerm?.message}
                        maxLength={5}
                      />
                    </Box>
                    <Heading size="xl" marginLeft={3}>
                      months
                    </Heading>
                  </FlexLabel>
                  <Slider
                    aria-labelledby="loanTermLabel"
                    name="loanTerm"
                    min={loanTerm.min}
                    max={loanTerm.max}
                    value={
                      value === '' ? minTerm : Math.min(maxTerm, Math.max(minTerm, value))
                    }
                    onChange={onChange}
                  />
                </Spaced>
              )}
              name="loanTerm"
              control={control}
              defaultValue={defaultTerm}
            />
          </Box>
          <Box marginTop={5} marginBottom={5}>
            <Spaced>
              <FlexLabel>
                <label htmlFor="loanPurpose" id="loanPurposeLabel">
                  <Heading size="md">Loan purpose:</Heading>
                </label>
              </FlexLabel>
              <Select
                name="loanPurpose"
                error={errors.loanPurpose?.message}
                inputRef={register}
                defaultValue={undefined}
                placeholder="Please select"
              >
                <option value="HomeImprovement">Home improvements</option>
                <option value="DebtConsolidation">Debt consolidation</option>
                <option value="CarPurchase">Car purchase</option>
                <option value="Holiday">Holiday</option>
                <option value="Wedding">Wedding</option>
                <option value="Other">Other</option>
              </Select>
            </Spaced>
          </Box>
          <Paragraph size="lg" marginBottom={3} marginTop={5}>
            With a top up loan
          </Paragraph>
          <List variant="icon">
            <ListItem leftAdornment={<Icon icon={faCheck} color="green" />}>
              <Paragraph>
                Representative {formatAPR(representativeApr * 100)}% APR
              </Paragraph>
            </ListItem>
            <ListItem leftAdornment={<Icon icon={faCheck} color="green" />}>
              <Paragraph>No hidden fees, ever</Paragraph>
            </ListItem>
            <ListItem leftAdornment={<Icon icon={faCheck} color="green" />}>
              <Paragraph>Instant decision</Paragraph>
            </ListItem>
            <ListItem leftAdornment={<Icon icon={faCheck} color="green" />}>
              <Paragraph>Money could be in your bank account within 10 minutes</Paragraph>
            </ListItem>
          </List>
          <Button
            data-testid="chose-this-loan-submission-button"
            fullWidth
            isLoading={isSubmissionLoading}
            mt={5}
          >
            Choose this loan
          </Button>
        </form>
      </Card>
    </Spaced>
  );
};

export const ConfigureOfferStep = {
  path: 'configure-offer',
  component: ConfigureOffer,
  provides: ['loanAmount', 'loanTerm'],
};
