import { useCallback } from 'react';

import { useDispatch } from 'react-redux';

import { useAuth } from '@sequensis/react-auth';
import { useConfig } from '@sequensis/react-config';

import { fetchCustomer } from 'src/store/Customer/actions';

export function useRefreshCustomer() {
  const { getToken, customerId = '' } = useAuth();
  const dispatch = useDispatch();
  const {
    customerManagementApi: { url: customerManagementApiUrl },
  } = useConfig();

  return {
    refreshCustomer: useCallback(async () => {
      const authToken = await getToken();

      dispatch(
        fetchCustomer.request({
          customerId,
          apiUrl: customerManagementApiUrl,
          authToken,
        }),
      );
    }, [dispatch, customerId, getToken, customerManagementApiUrl]),
  };
}
