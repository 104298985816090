export interface CustomerProps {
  firstName: string;
  lastName: string;
  emailAddress: string;
  telephoneNumbers: TelephoneNumbers[];
  address: {
    current: AddressProps;
    previous?: AddressProps[];
  };
  emailForVerification: EmailForVerification | null;
  bankDetails: readonly BankDetailProps[];
  accounts: readonly CustomerAccount[];
  communicationPreferences?: CommunicationPreferences;
  dateOfBirth: string | Date;
}

export type CommunicationPreferenceChannel = 'email' | 'sms' | 'post';

export interface CommunicationPreferences {
  marketing?: CommunicationPreferenceChannel[];
}
export interface CustomerAccount {
  id: string;
  friendlyRef: string;
  accountType: 'RevolvingCredit' | 'LikelyLoans' | 'Loan';
  productId: string;
  productTier: string;
}

export interface TelephoneNumbers {
  type: TelephoneNumberTypes;
  number: string;
}

export enum TelephoneNumberTypes {
  Home = 'Home',
  Mobile = 'Mobile',
  Work = 'Work',
}

export enum EmailForVerificationStatus {
  Unset = 'Unset',
  Pending = 'Pending',
}

export interface EmailForVerification {
  emailAddress: string;
  status: EmailForVerificationStatus;
}

export interface AddressProps {
  houseName?: string;
  houseNumber?: string;
  flat?: string;
  street: string;
  street2?: string;
  townOrCity: string;
  county: string;
  postcode: string;
  movedInDate?: string | Date;
  movedOutDate?: string | Date;
  residentialStatus?: ResidentialStatus;
}

export interface BankDetailProps {
  id: string;
  accountName: string;
  accountNumber: string;
  sortCode: string;
  createdAt: string;
}

export type ResidentialStatus =
  | 'Unset'
  | 'HomeOwner'
  | 'Tenant'
  | 'LivingWithParents'
  | 'Other';
