export function mapAdjacentTuples<T>(list: readonly T[]): ReadonlyArray<[T, T]> {
  const adjacentTuples: Array<[T, T]> = [];

  if (list.length < 2) return adjacentTuples;

  for (let i = 0; i < list.length - 1; i++) {
    adjacentTuples.push([list[i], list[i + 1]]);
  }

  return adjacentTuples;
}
