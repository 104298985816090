import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { Card, Flex, Halo, Icon, Paragraph, Text } from '@sequensis/stylish-core';

const Complete = ({ isMisthoJourney }: { isMisthoJourney?: boolean }) => (
  <Card
    heading={
      <>
        <Flex justifyContent="space-around">
          <Halo>
            <Icon icon={faCheck} size="3x" />
          </Halo>
        </Flex>
        <Text fontSize="3xl" lineHeight="3xl">
          Thanks, we&rsquo;ll be in touch.
        </Text>
      </>
    }
  >
    <Paragraph fontSize="xl" lineHeight="xl" fontWeight="bold" mt={3}>
      {isMisthoJourney
        ? `Your income documents are now with our team for review.`
        : `Your documents are now with our team for review.`}
    </Paragraph>
    <Paragraph mt={3}>
      We&rsquo;ll review these alongside your application and contact you with an update
      on the progress of your application.
    </Paragraph>
    <Paragraph mt={3} fontWeight="bold">
      You don&rsquo;t need to keep this window open, we&rsquo;ll contact you{' '}
      {isMisthoJourney ? 'shortly ' : 'within 1-2 working days '}
      with the update.
    </Paragraph>
  </Card>
);

export default Complete;
