export const truncateMiddle = (string: string, toLength: number) => {
  if (string.length <= toLength) return string;

  const separator = '...';

  const toLengthIncSep = toLength - separator.length;

  return (
    string.substring(0, toLengthIncSep / 2) +
    separator +
    string.substring(string.length - toLengthIncSep / 2)
  );
};
