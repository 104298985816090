import {
  faFileAlt,
  faHome,
  faPaperPlane,
  faUserCircle,
} from '@fortawesome/pro-regular-svg-icons';
import { Navigation } from '@sequensis/stylish-layouts';

import { ModuleRouteProps } from 'src/types/ModuleRouteProps';

const customerRoutes: ModuleRouteProps[] = [
  {
    icon: faHome,
    path: '/customer/overview',
    title: 'Home',
    testId: 'your-overview',
  },
  {
    icon: faUserCircle,
    path: '/customer/profile',
    title: 'Profile',
    testId: 'your-details',
  },
  {
    icon: faPaperPlane,
    path: '/customer/marketing-preferences',
    title: 'Marketing & Support',
    testId: 'marketing-preferences',
  },
  {
    icon: faFileAlt,
    path: '/customer/documents',
    title: 'Documents',
    testId: 'your-documents',
  },
];

export const CustomerNavigation = () => {
  return (
    <Navigation.Section aria-label="Account">
      <Navigation.List>
        {customerRoutes.map((route) => (
          <Navigation.Item key={route.path} {...route} />
        ))}
      </Navigation.List>
    </Navigation.Section>
  );
};
