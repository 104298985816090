import { Box, Paragraph } from '@sequensis/stylish-core';

import { ButtonLink } from 'src/components/ButtonLink';
import { MisthoIcon } from 'src/images/MisthoIcon';

import { providerCardData } from '../data';

type ProviderCardProps = {
  slug: string;
  onProceedClick: (slug: string) => void;
};

export const ProviderCard = ({ slug, onProceedClick }: ProviderCardProps) => {
  if (!providerCardData.hasOwnProperty(slug)) return null;

  const { question, info, buttonText } =
    providerCardData[slug as keyof typeof providerCardData];

  return (
    <Box backgroundColor="baseBg" padding={3} style={{ borderRadius: '1rem' }} mb={4}>
      <Paragraph size="md" fontWeight="bold">
        {question}
      </Paragraph>
      <Paragraph mt={4} mb={3} size="sm">
        {info} <MisthoIcon />
      </Paragraph>
      <ButtonLink fullWidth navigational to="" onClick={() => onProceedClick(slug)}>
        {buttonText}
      </ButtonLink>
    </Box>
  );
};
