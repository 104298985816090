import { ReactNode, useState } from 'react';

import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';

import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons';
import { useFeatureFlag } from '@sequensis/react-config';
import { Redact } from '@sequensis/react-monitoring';
import {
  Box,
  Button,
  Flex,
  Paragraph,
  RadioGroup,
  RadioOption,
  Text,
} from '@sequensis/stylish-core';

import { BankDetails } from 'src/api/customer/types';
import { getRepaymentMandateBankDetails } from 'src/store/Account/selectors';
import { getBankAccounts } from 'src/store/Customer/selectors';
import { BankDetailProps } from 'src/types/CustomerProps';
import { formatBankAccountNumber } from 'src/utils/formatBankAccountNumber';

interface BankAccountGroupProps {
  currentBankAccount: BankDetailProps | undefined;
  onOptionClick(bankDetails: BankDetailProps): void;
}

interface LabelValueProps {
  label: string;
  children: ReactNode;
  testId?: string;
}

const RedactedLabelContent = ({ label, testId, children }: LabelValueProps) => {
  return (
    <Flex alignItems="baseline" marginBottom={1}>
      <Text fontWeight="bold" data-testid={`${testId}-label`} marginRight={2}>
        {label}:
      </Text>

      <Redact>
        <span>{children}</span>
      </Redact>
    </Flex>
  );
};

const BankAccountGroup = ({
  currentBankAccount,
  onOptionClick,
}: BankAccountGroupProps) => {
  const bankAccounts = useSelector(getBankAccounts) ?? [];

  if (bankAccounts.length > 1) {
    return (
      <>
        <Paragraph mb={2}>Saved bank accounts</Paragraph>
        <RadioGroup
          name="bank accounts"
          selected={currentBankAccount?.accountNumber}
          onChange={(accountNumber) => {
            const account = bankAccounts.find(
              (bankAccount) => bankAccount.accountNumber === accountNumber,
            );
            account && onOptionClick(account);
          }}
        >
          {bankAccounts.map((bankAccount, index) => (
            <RadioOption key={index} value={bankAccount.accountNumber}>
              {formatBankAccountNumber(bankAccount.accountNumber)}
            </RadioOption>
          ))}
        </RadioGroup>
      </>
    );
  }

  if (bankAccounts.length === 1) {
    const bankAccount = bankAccounts[0];
    return (
      <>
        <Box>
          <RedactedLabelContent label="Bank account">
            {formatBankAccountNumber(bankAccount.accountNumber)}
          </RedactedLabelContent>
          <RedactedLabelContent label="Sort code">
            <NumberFormat
              displayType="text"
              format="## - ## - ##"
              value={bankAccount.sortCode}
            />
          </RedactedLabelContent>
          <RedactedLabelContent label="Name on account">
            {bankAccount.accountName}
          </RedactedLabelContent>
        </Box>
      </>
    );
  }

  return <></>;
};

interface SelectBankAccountFormProps {
  onAddBankAccountClicked(): void;
  onSubmit(bankDetails: BankDetails): void;
  hasAuthorisedDirectDebit: boolean;
  children: React.ReactNode;
}

export const SelectBankAccountForm = ({
  onAddBankAccountClicked,
  onSubmit,
  hasAuthorisedDirectDebit,
  children,
}: SelectBankAccountFormProps) => {
  const repaymentMandateBankDetails = useSelector(getRepaymentMandateBankDetails);
  const [currentBankAccount, setCurrentBankAccount] = useState(
    repaymentMandateBankDetails,
  );

  const handleSubmit = () => {
    currentBankAccount && onSubmit(currentBankAccount);
  };

  const handleBankAccountSelected = (bankDetails: BankDetails) => {
    setCurrentBankAccount(bankDetails);
  };

  const allowAddBankAccount = useFeatureFlag('allowAddBankAccount');

  const hasBankAccountSelected = Boolean(currentBankAccount);

  return (
    <>
      <BankAccountGroup
        currentBankAccount={currentBankAccount}
        onOptionClick={handleBankAccountSelected}
      />
      {allowAddBankAccount && (
        <Button
          mt={2}
          variant="secondary"
          fullWidth={true}
          onClick={onAddBankAccountClicked}
          leftIcon={faPlusCircle}
        >
          <Text fontSize={{ xs: 'small', sm: 'medium' }}>Add new bank account</Text>
        </Button>
      )}
      {children}
      <Button
        variant="primary"
        fullWidth={true}
        disabled={!hasAuthorisedDirectDebit || !hasBankAccountSelected}
        onClick={handleSubmit}
      >
        Continue
      </Button>
    </>
  );
};
