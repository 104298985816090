import { useEffect, useMemo, useState } from 'react';

import { faClock } from '@fortawesome/pro-regular-svg-icons';
import {
  Alert,
  Box,
  Button,
  Card,
  Flex,
  Heading,
  Paragraph,
  Spaced,
  Text,
} from '@sequensis/stylish-core';
import { PageHeader } from '@sequensis/stylish-layouts';
import { differenceInDays } from 'date-fns';
import { any, map, pipe } from 'ramda';

import {
  BankAccount,
  PaymentSchedule,
  PaymentScheduleEvent,
} from 'src/api/account/types';
import { ContactNotice } from 'src/components/ContactNotice';
import { useNextPaymentIsBeforeSpecifiedDays } from 'src/hooks/useNextPaymentIsBeforeSpecifiedDays';
import { mapAdjacentTuples } from 'src/utils/mapAdjecentTuples';

import { BankAccountOverview } from '../Components/BankAccountOverview';
import { DirectDebitGuaranteeModal } from '../Components/DirectDebitGuaranteeModal';
import { PaymentScheduleView } from '../Components/PaymentScheduleView';

interface ConfirmChangeProps {
  schedule: PaymentSchedule;
  bankAccount: BankAccount | undefined;
  onConfirm(): void;
  isSubmitting: boolean;
  error?: string | null;
}

const SEQUENTIAL_PAYMENT_WARNING_DAYS = 14;

const anySchedulesWithinWarningDistance = pipe(
  map((se: PaymentScheduleEvent) => new Date(se.date)),
  mapAdjacentTuples,
  any(([a, b]) => differenceInDays(b, a) <= SEQUENTIAL_PAYMENT_WARNING_DAYS),
);

export function ConfirmDirectDebitChange({
  schedule,
  bankAccount,
  onConfirm,
  isSubmitting,
  error,
}: ConfirmChangeProps) {
  const [showGuarantee, setShowGuarantee] = useState(false);

  const [isNextPaymentInProgress] = useNextPaymentIsBeforeSpecifiedDays();

  const scheduleEvents = schedule.scheduleEvents;

  const showScheduleWarning = useMemo(
    () => anySchedulesWithinWarningDistance(scheduleEvents),
    [scheduleEvents],
  );

  const handleConfirm = () => {
    if (!isSubmitting) {
      onConfirm();
    }
  };

  useEffect(() => {
    // Scroll to the top of the page whenever this component is rendered.
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <DirectDebitGuaranteeModal
        isOpen={showGuarantee}
        onClose={() => setShowGuarantee(false)}
      />

      <PageHeader title="Final step, check your Direct Debit instruction" />

      <Flex flexDirection="column" justifyContent="space-between" flex="1 1 auto">
        <Spaced marginBottom={3}>
          <Card>
            <PaymentScheduleView schedule={scheduleEvents} />
          </Card>
          <Card>
            <Heading as="h3">We&apos;ll take payment from</Heading>
            {bankAccount && <BankAccountOverview bankAccount={bankAccount} />}
          </Card>
          <Card>
            {showScheduleWarning && (
              <Alert
                icon={faClock}
                variant="warning"
                data-testid="edit-directdebit-closepayments-warning"
              >
                Your next 2 payments are scheduled to be within 2 weeks of each other
              </Alert>
            )}
            <Paragraph marginBottom={2}>
              Your Direct Debit instruction will be confirmed to you by email within 5
              working days.
            </Paragraph>

            <Box>
              <Paragraph>
                Your payments are protected by the
                <Button
                  variant="tertiary"
                  size="slim"
                  onClick={() => setShowGuarantee(true)}
                  data-testid="edit-directdebit-view-guarantee"
                >
                  <Text fontSize="small">Direct Debit Guarantee</Text>
                </Button>
              </Paragraph>
            </Box>
            {error && (
              <Alert icon={true} variant="negative" title="Try again later" marginTop={2}>
                {error}
              </Alert>
            )}
            <Button
              marginY={2}
              type="submit"
              variant="primary"
              fullWidth={true}
              onClick={handleConfirm}
              isLoading={isSubmitting}
              data-testid="edit-directdebit-confirm-button"
            >
              Confirm Direct Debit
            </Button>
            {isNextPaymentInProgress && (
              <Alert
                variant="warning"
                data-testid="payment-in-progress-notice"
                icon={true}
                title="You have a payment in progress"
                marginTop={3}
              >
                Any changes you make will take effect after your payment has completed
              </Alert>
            )}
            <ContactNotice />
          </Card>
        </Spaced>
      </Flex>
    </>
  );
}
