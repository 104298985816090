import { ReactNode } from 'react';

import { useSelector } from 'react-redux';

import { Card, Flex, Text } from '@sequensis/stylish-core';
import { format } from 'date-fns';

import {
  getAccountReference,
  getAccountStartDate,
  getAnnualPercentageRate,
  getLoanAmount,
  getProductLength,
} from 'src/store/Account/selectors';
import { formatAPR, formatCurrency } from 'src/utils/formatNumber';

interface AccountItemProps {
  value: ReactNode;
  label: string;
  testId?: string;
  isLarge?: boolean;
}

const AccountDetailItem = ({ label, value, testId, isLarge }: AccountItemProps) => (
  <Flex alignItems="baseline" marginBottom={1}>
    {isLarge ? (
      <Text
        fontWeight="bold"
        fontSize="large"
        fontFamily="heading"
        data-testid={`${testId}-label`}
        marginRight={2}
      >
        {label}:
      </Text>
    ) : (
      <Text data-testid={`${testId}-label`} marginRight={2}>
        {label}:
      </Text>
    )}

    <Text
      fontWeight="bold"
      fontSize="large"
      fontFamily="heading"
      data-testid={`${testId}-value`}
    >
      {value}
    </Text>
  </Flex>
);

export const DetailCard = () => {
  const accountReference = useSelector(getAccountReference);
  const startDate = useSelector(getAccountStartDate);
  const productLength = useSelector(getProductLength);
  const apr = useSelector(getAnnualPercentageRate);
  const loanAmount = useSelector(getLoanAmount);

  return (
    <Card mb={2}>
      <AccountDetailItem
        label="Loan ID"
        value={accountReference}
        testId="account-reference"
        isLarge={true}
      />
      <AccountDetailItem
        label="Started"
        value={format(startDate, 'MMMM yyyy')}
        testId="start-date"
      />
      <AccountDetailItem
        label="Loan amount"
        value={formatCurrency(loanAmount, { stripTrailingZeros: true })}
        testId="loan-amount"
      />
      <AccountDetailItem label="APR" value={`${formatAPR(apr)}%`} testId="apr" />
      <AccountDetailItem
        label="Term"
        value={`${productLength} months`}
        testId="length-of-loan"
      />
    </Card>
  );
};
