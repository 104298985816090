import './mistho.scss';

import { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom-v5-compat';

import { MisthoProvider } from '@mistho/react';
import { Provider } from '@mistho/react/lib/browser';
import { GetApplicationByCustomerIdResponse } from '@sequensis/creditapplication-reactsdk';

import { defaultroute, providerCardData } from './data';
import { Form } from './Components/Form';
import { Information } from './Components/Information';
import { Others } from './Components/Others';
import { ProvidersList } from './Components/ProvidersList';
import { Result } from './Components/Result';

type MisthoControllerProps = {
  applicationDetails: GetApplicationByCustomerIdResponse;
};

export const MisthoController = ({ applicationDetails }: MisthoControllerProps) => {
  const transaction_id = (applicationDetails as any)?.transactionId || '';

  const [selectedSlug, setSelectedSlug] = useState<string | null>(null);
  const [pagemode, setPageMode] = useState<string | undefined>('');

  const navigate = useNavigate();

  const { mode } = useParams<{ mode?: string }>();

  useEffect(() => {
    setPageMode(mode);
  }, [mode]);

  useEffect(() => {
    if (pagemode === 'form' && mode === 'provider-list')
      navigate(`${defaultroute}${pagemode}`, { replace: true });
    if (pagemode && mode !== pagemode) navigate(`${defaultroute}${pagemode}`);
  }, [pagemode]);

  useEffect(() => {
    if (mode !== 'form') setSelectedSlug(null);
  }, [mode]);

  const onProceedClick = (slug: string) => {
    if (slug === 'default') {
      setPageMode(`provider-list`);
      return;
    }
    if (!providerCardData.hasOwnProperty(slug)) return;

    setSelectedSlug(slug);
    setPageMode('form');
  };

  const onProviderClick = (provider: Provider) => {
    setSelectedSlug(provider.slug);
    setPageMode('form');
  };

  if (mode === 'info')
    return (
      <Information
        applicationDetails={applicationDetails}
        onProceedClick={onProceedClick}
      />
    );

  if (mode === 'provider-list')
    return (
      <MisthoProvider>
        <ProvidersList transaction_id={transaction_id} onClick={onProviderClick} />
      </MisthoProvider>
    );

  if (mode === 'others')
    return (
      <Others applicationDetails={applicationDetails} onProceedClick={onProceedClick} />
    );

  if (mode === 'form' && selectedSlug)
    return (
      <MisthoProvider>
        <Form
          transaction_id={transaction_id}
          applicationDetails={applicationDetails}
          slug={selectedSlug}
          onLoginSuccess={() => setPageMode('result')}
        />
      </MisthoProvider>
    );

  if (pagemode === 'result') return <Result applicationDetails={applicationDetails} />;

  return null;
};
