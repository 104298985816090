import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import {
  Box,
  Button,
  Card,
  Flex,
  Heading,
  Icon,
  List,
  ListItem,
  Paragraph,
  Pill,
  Spaced,
  Text,
} from '@sequensis/stylish-core';

import { DynamicLogo } from 'src/components/Logos/DynamicLogo';
import { formatAPR, formatCurrency } from 'src/utils/formatNumber';

export const Offer = ({
  selectedOffer,
  onContinueClick,
  product,
}: {
  selectedOffer: any;
  onContinueClick: () => void;
  product: string;
}) => (
  <Spaced marginBottom={4} excludeLast>
    <Card>
      <Spaced marginBottom={3} excludeLast>
        <Flex justifyContent="space-between" alignItems="center" mb={3}>
          <Pill variant="fancy">Important</Pill>
          <Box width={5}>
            <DynamicLogo isGreyscale={false} useDarkText productName={product} />
          </Box>
        </Flex>
        <Heading as="h3" size="xl">
          Your top up loan details
        </Heading>
        <List variant="icon">
          <ListItem leftAdornment={<Icon icon={faCheckCircle} color="secondary" />}>
            <Paragraph size="md">
              Borrowing an extra
              <Text variant="emphasis">{` ${formatCurrency(
                selectedOffer?.amount,
              )}`}</Text>
            </Paragraph>
          </ListItem>
          <ListItem leftAdornment={<Icon icon={faCheckCircle} color="secondary" />}>
            <Paragraph size="md">
              Repaying in total
              <Text variant="emphasis">{` ${formatCurrency(
                selectedOffer?.totalAmountRepayable,
              )}`}</Text>
            </Paragraph>
          </ListItem>
          <ListItem leftAdornment={<Icon icon={faCheckCircle} color="secondary" />}>
            <Paragraph size="md">
              Making<Text variant="emphasis">{` ${selectedOffer?.termInMonths}`}</Text>{' '}
              repayments of
              <Text variant="emphasis">{` ${formatCurrency(
                selectedOffer?.monthlyRepaymentAmount,
              )}`}</Text>
            </Paragraph>
          </ListItem>
          <ListItem leftAdornment={<Icon icon={faCheckCircle} color="secondary" />}>
            <Paragraph size="md" marginRight="1.5rem">
              <Text variant="emphasis">
                {` ${formatAPR(selectedOffer?.apr * 100)}%`} APR
              </Text>
            </Paragraph>
          </ListItem>
        </List>
        <Button
          mt={4}
          onClick={() => onContinueClick()}
          as="div"
          style={{ textAlign: 'center' }}
        >
          Continue
        </Button>
      </Spaced>
    </Card>
  </Spaced>
);
