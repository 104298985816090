import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom-v5-compat';

import { faClock, faPencil } from '@fortawesome/pro-regular-svg-icons';
import { useConfig, useFeatureFlag } from '@sequensis/react-config';
import {
  Alert,
  Card,
  componentStyles,
  Flex,
  getColor,
  Icon,
  Paragraph,
  Text,
} from '@sequensis/stylish-core';
import { PageHeader } from '@sequensis/stylish-layouts';
import styled from 'styled-components';

import { useBrandName } from 'src/hooks/useBrandName';
import { useNextPaymentIsBeforeSpecifiedDays } from 'src/hooks/useNextPaymentIsBeforeSpecifiedDays';
import {
  getAccountReference,
  getBankAccountNumber,
  getDirectDebitIsPendingSetup,
  getNextRepaymentAmount,
} from 'src/store/Account/selectors';
import { formatDayAndSuperscript } from 'src/utils/formatDate';
import { formatCurrency } from 'src/utils/formatNumber';

import { CurrentPaymentSchedule } from '../Components/CurrentPaymentSchedule';

const paymentInformationBoxStyles = componentStyles('paymentInformationBox');

const PaymentInformationBox = styled(Alert)`
  background-color: ${getColor('white')};
  border-style: solid;
  border-width: 2px;
  ${paymentInformationBoxStyles}
`;

export const DirectDebitManage = () => {
  const [isNextPaymentInProgress, nextRepaymentDate] =
    useNextPaymentIsBeforeSpecifiedDays();
  const { likelyLoansOnlineAccountServicingUrl } = useConfig();

  const repaymentAmount = useSelector(getNextRepaymentAmount);
  const paymentDayOfMonth = formatDayAndSuperscript(nextRepaymentDate);
  const bankAccountNumber = useSelector(getBankAccountNumber);
  const accountReference = useSelector(getAccountReference);

  const formattedPaymentDayOfMonth = `${paymentDayOfMonth} of each month`;

  const allowPaymentDayChangeFlag = useFeatureFlag('allowPaymentDayChange');
  const allowBankAccountChangeFlag = useFeatureFlag('allowBankAccountChange');

  const isDirectDebitPendingSetup = useSelector(getDirectDebitIsPendingSetup);

  const brandName = useBrandName();

  return (
    <>
      <PageHeader title="Direct Debit" />

      <Card mb={5}>
        {isDirectDebitPendingSetup && (
          <Alert
            variant="info"
            marginBottom={2}
            spacing="slim"
            data-testid="direct-debit-pending-warning"
            icon={faClock}
          >
            Your Direct Debit is being setup
          </Alert>
        )}
        <Paragraph marginBottom={2}>
          We&apos;ll take repayments by Direct Debit each month from your nominated bank
          account.
        </Paragraph>
        <Paragraph marginBottom={2}>
          We&apos;ll be called <Text fontWeight="bold">{brandName}</Text> on your
          statement.
        </Paragraph>

        <Paragraph data-testid="direct-debit-monthly-amount-label">
          Monthly Amount
        </Paragraph>
        <PaymentInformationBox
          marginBottom={2}
          data-testid="direct-debit-monthly-amount-value"
        >{`${formatCurrency(repaymentAmount)} per month`}</PaymentInformationBox>

        <Paragraph data-testid="direct-debit-payment-day-label">
          Payment day (on or around)
        </Paragraph>
        <PaymentInformationBox mb={3} data-testid="direct-debit-payment-day-value">
          <Flex flex="1 1 auto" justifyContent="space-between">
            {formattedPaymentDayOfMonth}
            {allowPaymentDayChangeFlag ? (
              <Link to="payment-day">
                <Icon icon={faPencil} size="lg" color="primary" maxHeight={24} />
              </Link>
            ) : (
              <a
                href={`${likelyLoansOnlineAccountServicingUrl}/#/loans/${accountReference}/payments`}
              >
                <Icon size="lg" icon={faPencil} color="primary" maxHeight={24} />
              </a>
            )}
          </Flex>
        </PaymentInformationBox>

        <Paragraph data-testid="direct-debit-bank-account-label">Bank Account</Paragraph>
        <PaymentInformationBox>
          <Flex flex="1 1 auto" justifyContent="space-between">
            <Text data-testid="direct-debit-bank-account-value">{`**** ${bankAccountNumber}`}</Text>
            {allowBankAccountChangeFlag && (
              <Link to="direct-debit/setup">
                <Icon icon={faPencil} size="lg" maxHeight={24} />
              </Link>
            )}
          </Flex>
        </PaymentInformationBox>

        {isNextPaymentInProgress && (
          <Alert
            variant="warning"
            data-testid="payment-in-progress-notice"
            icon={faClock}
            title="You have a payment in progress"
            marginTop={3}
            marginBottom={3}
          >
            Any changes you make will take effect after your payment has completed
          </Alert>
        )}
      </Card>
      <CurrentPaymentSchedule />
    </>
  );
};

export default DirectDebitManage;
