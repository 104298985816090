import { useMemo } from 'react';

import { faClock } from '@fortawesome/pro-regular-svg-icons';
import {
  Alert,
  Box,
  Button,
  Card,
  Flex,
  Paragraph,
  Spaced,
} from '@sequensis/stylish-core';
import { PageHeader } from '@sequensis/stylish-layouts';
import { differenceInDays } from 'date-fns';
import { any, map, pipe } from 'ramda';

import { PaymentSchedule, PaymentScheduleEvent } from 'src/api/account/types';
import { mapAdjacentTuples } from 'src/utils/mapAdjecentTuples';

import { PaymentScheduleView } from '../Components/PaymentScheduleView';

interface ConfirmChangeProps {
  schedule: PaymentSchedule;
  onConfirm(): void;
  isSubmitting: boolean;
  error?: string | null;
}

const SEQUENTIAL_PAYMENT_WARNING_DAYS = 14;

const anySchedulesWithinWarningDistance = pipe(
  map((se: PaymentScheduleEvent) => new Date(se.date)),
  mapAdjacentTuples,
  any(([a, b]) => differenceInDays(b, a) <= SEQUENTIAL_PAYMENT_WARNING_DAYS),
);

export function ConfirmManualPaymentChange({
  schedule,
  onConfirm,
  isSubmitting,
  error,
}: ConfirmChangeProps) {
  const scheduleEvents = schedule.scheduleEvents;

  const showScheduleWarning = useMemo(
    () => anySchedulesWithinWarningDistance(scheduleEvents),
    [scheduleEvents],
  );

  const handleConfirm = () => {
    if (!isSubmitting) {
      onConfirm();
    }
  };

  return (
    <>
      <PageHeader title="Confirmation of payment day change" />
      <Card>
        <Flex flexDirection="column" justifyContent="space-between" flex="1 1 auto">
          <Box>
            <Spaced marginBottom={3}>
              <PaymentScheduleView
                schedule={scheduleEvents}
                title="Your Payment Schedule"
              />
              {showScheduleWarning && (
                <Alert
                  icon={faClock}
                  variant="warning"
                  data-testid="edit-manual-payments-warning"
                >
                  Your next 2 payments are scheduled to be within 2 weeks of each other
                </Alert>
              )}
              <Paragraph>
                Your payment day change will be confirmed to you by email within 5 working
                days.
              </Paragraph>
            </Spaced>
          </Box>
          <Box>
            {error && (
              <Alert icon={true} variant="negative" title="Try again later" marginTop={2}>
                {error}
              </Alert>
            )}
            <Button
              marginTop={4}
              type="submit"
              variant="primary"
              fullWidth={true}
              onClick={handleConfirm}
              isLoading={isSubmitting}
              data-testid="edit-manual-confirm-button"
            >
              Confirm payment day
            </Button>
          </Box>
        </Flex>
      </Card>
    </>
  );
}
