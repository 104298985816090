import { createAsyncAction } from 'typesafe-actions';

import { FetchCustomerRequest } from 'src/api/customer/types';
import { CustomerProps } from 'src/types/CustomerProps';

export enum ActionTypes {
  FETCH_CUSTOMER = '@CUSTOMER/FETCH_REQUEST',
  FETCH_CUSTOMER_SUCCESS = '@CUSTOMER/FETCH_SUCCESS',
  FETCH_CUSTOMER_FAIL = '@CUSTOMER/FETCH_FAIL',
}

export const fetchCustomer = createAsyncAction(
  ActionTypes.FETCH_CUSTOMER,
  ActionTypes.FETCH_CUSTOMER_SUCCESS,
  ActionTypes.FETCH_CUSTOMER_FAIL,
)<FetchCustomerRequest, CustomerProps, string>();
