import { useMemo } from 'react';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { faCalendarTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth } from '@sequensis/react-auth';
import { useConfig } from '@sequensis/react-config';
import { useAsyncCallback, useDebounced, useMount } from '@sequensis/react-hooks';
import { Heading, Loader, Modal, Paragraph, Text } from '@sequensis/stylish-core';

import { getMissedPayments } from 'src/api/account/accountApi';
import { MissedTransaction } from 'src/api/account/types';
import { analyticsClickEvent } from 'src/components/Analytics';
import { ButtonLink } from 'src/components/ButtonLink';
import {
  getAccountId,
  getArrearsAmount,
  getIsEligibleForPaymentPlan,
} from 'src/store/Account/selectors';
import { formatCurrency } from 'src/utils/formatNumber';

import { MissedPaymentsTable } from './MissedPaymentsTable';

export const Arrears = () => {
  const history = useHistory();
  const {
    accountManagementApi: { url: accountApiUrl },
  } = useConfig();
  const { getToken } = useAuth();
  const [state, execute] = useAsyncCallback(getMissedPayments);
  const debouncedLoading = useDebounced(state.loading, 200);
  const response = state.result;

  const arrearsAmount = useSelector(getArrearsAmount);
  const accountId = useSelector(getAccountId);

  const isEligibleForPaymentPlan = useSelector(getIsEligibleForPaymentPlan);

  const missedPayments = useMemo(() => {
    if (!response?.responseJson?.transactions) return [];
    return response.responseJson.transactions as MissedTransaction[];
  }, [response]);

  useMount(async () => {
    const authToken = await getToken();
    execute(accountApiUrl, accountId || '', authToken);
  });

  return (
    <Modal isOpen onClose={() => history.push('balance')}>
      <Heading color="primary" marginBottom={3}>
        <Text marginRight={3}>
          <FontAwesomeIcon icon={faCalendarTimes} />
        </Text>
        Missed payments
      </Heading>
      <Paragraph
        fontSize="2xl"
        fontWeight="bold"
        fontFamily="heading"
        textAlign="center"
        data-testid="arrears-balance"
        mb={2}
      >
        - {formatCurrency(arrearsAmount)}
      </Paragraph>
      {state.loading ? (
        debouncedLoading && (
          <>
            <Loader my={5} />
          </>
        )
      ) : (
        <MissedPaymentsTable missedPayments={[...missedPayments]} />
      )}
      <Paragraph marginBottom={3}>
        Make a payment now to minimise impact to your credit file.
      </Paragraph>
      <Paragraph>
        If you’re struggling find out how we can help you get back on track.
      </Paragraph>
      <ButtonLink
        variant="primary"
        to="../make-payment"
        navigational
        marginTop={5}
        marginBottom={3}
        data-testid="arrears-make-payment-button"
        onClick={() => analyticsClickEvent('Arrears', 'arrears-make-payment-button')}
      >
        Make a payment
      </ButtonLink>
      {isEligibleForPaymentPlan && (
        <ButtonLink
          to="../payment-plan/struggling-to-pay"
          variant="secondary"
          data-testid="arrears-trouble-repaying-button"
          onClick={() =>
            analyticsClickEvent('Arrears', 'arrears-trouble-repaying-button')
          }
        >
          Get back on track
        </ButtonLink>
      )}
    </Modal>
  );
};

export default Arrears;
