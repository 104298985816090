import { useSelector } from 'react-redux';

import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Donut, Flex, getColor, getRadii, Paragraph } from '@sequensis/stylish-core';
import styled from 'styled-components/macro';
import { space, SpaceProps } from 'styled-system';

import { Highlight } from 'src/components/Highlight';
import { useAccountBalanceProgress } from 'src/hooks/useAccountBalanceProgress';
import { useCurrentAccountId } from 'src/hooks/useCurrentAccountId';
import { useNextPaymentIsBeforeSpecifiedDays } from 'src/hooks/useNextPaymentIsBeforeSpecifiedDays';
import {
  getBalanceAmount,
  getIsOnRepaymentBreak,
  getNextRepaymentAmount,
} from 'src/store/Accounts';
import { formatShortDate } from 'src/utils/formatDate';
import { formatCurrency } from 'src/utils/formatNumber';

const P = styled(Paragraph)`
  &&& {
    padding-top: 0px;
  }
  white-space: pre;
`;

const BalanceDonutSurface = styled.button<SpaceProps>`
  display: flex;
  justify-content: center;
  cursor: pointer;
  background: none;
  width: 100%;

  ${space}

  &:focus, &:focus-visible {
    outline: none;

    & > * {
      border-radius: ${getRadii(1)};
      outline: ${getColor('accessibility')} solid 6px;
      outline-offset: 4px;
    }
  }
`;

const StyledIcon = styled(FontAwesomeIcon)`
  color: ${getColor('backgroundPair')};
`;

interface BalanceDoughnutProps {
  onClick?(): void;
}

export default function BalanceDoughnut({ onClick }: BalanceDoughnutProps) {
  const accountId = useCurrentAccountId() ?? '';
  const accountBalance = useSelector(getBalanceAmount(accountId));
  const repaymentAmount = useSelector(getNextRepaymentAmount(accountId));
  const isOnRepaymentBreak = useSelector(getIsOnRepaymentBreak(accountId));
  const [, nextRepaymentDate] = useNextPaymentIsBeforeSpecifiedDays();

  const progress = useAccountBalanceProgress(accountId);

  return (
    <BalanceDonutSurface
      data-testid="balance-donut-surface"
      onClick={onClick}
      marginBottom={4}
    >
      <Donut radius={140} progress={progress} strokeWidth={15}>
        <P textAlign="center" color="backgroundPair" fontSize="small">
          Balance
        </P>
        <Highlight
          forwardedAs="p"
          fontSize="page"
          textAlign="center"
          color="secondary"
          fontWeight="bold"
          data-testid="balance-value"
        >
          £&nbsp;{accountBalance.toFixed(2)}
        </Highlight>

        {isOnRepaymentBreak ? (
          <>
            <P color="backgroundPair" textAlign="center" fontSize="tiny">
              Repayment break
            </P>
            <P
              color="backgroundPair"
              textAlign="center"
              data-testid="repayment-due"
              fontWeight="bold"
            >
              - On Hold -
            </P>
          </>
        ) : (
          <>
            {nextRepaymentDate && (
              <>
                <P color="backgroundPair" textAlign="center" fontSize="tiny">
                  Next repayment due
                </P>
                <P
                  color="backgroundPair"
                  textAlign="center"
                  data-testid="repayment-due"
                  fontWeight="bold"
                >
                  {formatCurrency(repaymentAmount)} by{' '}
                  {formatShortDate(nextRepaymentDate)}
                </P>
              </>
            )}
          </>
        )}

        <Flex justifyContent="center" marginTop={5}>
          <StyledIcon icon={faChevronRight} size="lg" />
        </Flex>
      </Donut>
    </BalanceDonutSurface>
  );
}
