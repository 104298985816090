import { createSelector } from 'reselect';

import { ApplicationState } from 'src/store';
import { Product } from 'src/types/ConfigProps';
import isUndefined from 'src/utils/isUndefined';
import { getProductConfigByPath } from 'src/utils/productHelper';

export const getAllAccounts = ({ accountsState }: ApplicationState) =>
  accountsState.accounts;

export const getAccount =
  (accountId: string) =>
  ({ accountsState }: ApplicationState) =>
    accountsState.accounts && accountsState.accounts[accountId];

export const getPaymentMethodIsDirectDebit =
  (accountId: string) =>
  ({ accountsState }: ApplicationState) =>
    accountsState.accounts &&
    accountsState.accounts[accountId]?.accountDetails.paymentMethod === 'Direct Debit';

export const getProductIsComplete =
  (accountId: string) =>
  ({ accountsState }: ApplicationState) =>
    accountsState.accounts &&
    isUndefined(
      accountsState.accounts[accountId]?.paymentSchedulePosition.nextPaymentDueDate,
    );

export const getProductIsPaidOff =
  (accountId: string) =>
  ({ accountsState }: ApplicationState) => {
    const account = accountsState.accounts && accountsState.accounts[accountId];
    return account ? account.balance.currentBalance <= 0 : false;
  };

export const getIsInPaymentPlan =
  (accountId: string) =>
  ({ accountsState }: ApplicationState) =>
    accountsState.accounts && accountsState.accounts[accountId]?.isInPaymentPlan;

export const getIsEligibleForPaymentPlan = (accountId: string) =>
  createSelector(
    getProductIsPaidOff(accountId),
    getProductIsComplete(accountId),
    (isPaidOff, isComplete) => !isPaidOff && !isComplete,
  );

export const getCanHaveRepaymentBreaks =
  (accountId: string) =>
  ({ accountsState }: ApplicationState) =>
    accountsState.accounts &&
    Boolean(accountsState.accounts[accountId]?.featureFlags.canHaveRepaymentBreaks);

export const getIsOnRepaymentBreak =
  (accountId: string) =>
  ({ accountsState }: ApplicationState) =>
    accountsState.accounts &&
    Boolean(accountsState.accounts[accountId]?.repaymentBreaks.current);

export const getAccountProduct =
  (accountId: string) =>
  ({ accountsState }: ApplicationState) =>
    accountsState.accounts && accountsState.accounts[accountId]?.product.productId;

export const getAccountFetched =
  (accountId: string) =>
  ({ accountsState }: ApplicationState) =>
    accountsState.accounts && Boolean(accountsState.accounts[accountId]);

export const getAccountStatus =
  (accountId: string) =>
  ({ accountsState }: ApplicationState) =>
    accountsState.accounts && accountsState.accounts[accountId]?.status;

export const getAccountReference =
  (accountId: string) =>
  ({ accountsState }: ApplicationState) =>
    accountsState.accounts && accountsState.accounts[accountId]?.reference;

export const getIsInArrears =
  (accountId: string) =>
  ({ accountsState }: ApplicationState) =>
    accountsState.accounts
      ? accountsState.accounts[accountId]?.balance.arrears > 0
      : false;

export const getCurrentAccountId =
  (pathArea: string, products: Product[]) =>
  ({ accountsState }: ApplicationState) => {
    if (accountsState.accounts === undefined) {
      return undefined;
    }

    const currentProduct = getProductConfigByPath(products, pathArea);
    if (currentProduct === undefined) {
      return undefined;
    }

    const accountStateEntries = Object.entries(accountsState.accounts);
    return accountStateEntries.find((account) =>
      currentProduct?.productIds.includes(account[1].product.productId),
    )?.[0];
  };

export const getCurrentAccountIdFromProduct =
  (product?: Product) =>
  ({ accountsState }: ApplicationState) => {
    if (accountsState.accounts === undefined) return undefined;

    const accountStateEntries = Object.entries(accountsState.accounts);
    return accountStateEntries.find((account) =>
      product?.productIds.includes(account[1].product.productId),
    )?.[0];
  };

export const getLoanAmount =
  (accountId: string) =>
  ({ accountsState }: ApplicationState) =>
    accountsState.accounts
      ? accountsState.accounts[accountId]?.accountDetails.loanAmount
      : 0;

export const getBalanceAmount =
  (accountId: string) =>
  ({ accountsState }: ApplicationState) =>
    accountsState.accounts
      ? accountsState.accounts[accountId]?.balance.currentBalance
      : 0;

export const getTotalAmountOwed =
  (accountId: string) =>
  ({ accountsState }: ApplicationState) =>
    accountsState.accounts ? accountsState.accounts[accountId]?.balance.startBalance : 0;

export const getCreditLimit =
  (accountId: string) =>
  ({ accountsState }: ApplicationState) =>
    accountsState.accounts
      ? accountsState.accounts[accountId]?.accountDetails.creditLimit
      : 0;

export const getNextRepaymentAmount =
  (accountId: string) =>
  ({ accountsState }: ApplicationState) =>
    accountsState.accounts
      ? accountsState.accounts[accountId]?.paymentSchedulePosition.nextPaymentDueAmount
      : 0;

export const getNextRepaymentDate =
  (accountId: string) =>
  ({ accountsState }: ApplicationState) =>
    accountsState.accounts &&
    accountsState.accounts[accountId]?.paymentSchedulePosition.nextPaymentDueDate;

export const getCurrentRepaymentBreak =
  (accountId: string) =>
  ({ accountsState }: ApplicationState) =>
    accountsState.accounts && accountsState.accounts[accountId]?.repaymentBreaks.current;

export const getHasActivationFailed =
  (accountId: string) =>
  ({ accountsState }: ApplicationState) =>
    accountsState.accounts &&
    (accountsState.accounts[accountId]?.activation?.failures?.length ?? 0) > 0;

export const getAccountPayeeId =
  (accountId: string) =>
  ({ accountsState }: ApplicationState) =>
    accountsState.accounts && accountsState.accounts[accountId]?.funder.payeeId;

export const getArrearsAmount =
  (accountId: string) =>
  ({ accountsState }: ApplicationState) =>
    accountsState.accounts ? accountsState.accounts[accountId]?.balance.arrears : 0;

export const getProductId = ({ accountState }: ApplicationState) =>
  accountState?.account?.product?.productId;

export const getIsCAIS = ({ accountState }: ApplicationState) =>
  accountState?.account?.isCAIS;

export const gethasNoticeOfDefault = ({ accountState }: ApplicationState) =>
  accountState?.account?.hasNoticeOfDefault;
