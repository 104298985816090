import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom-v5-compat';

import { faClock, faPencil } from '@fortawesome/pro-regular-svg-icons';
import { Alert, Flex, getColor, Icon, Paragraph, Text } from '@sequensis/stylish-core';
import styled from 'styled-components';

import { analyticsClickEvent } from 'src/components/Analytics';
import { ButtonLink } from 'src/components/ButtonLink';
import { useNextPaymentIsBeforeSpecifiedDays } from 'src/hooks/useNextPaymentIsBeforeSpecifiedDays';
import {
  getDirectDebitIsPendingSetup,
  getNextRepaymentAmount,
} from 'src/store/Account/selectors';
import { formatCondensedDate } from 'src/utils/formatDate';
import { formatCurrency } from 'src/utils/formatNumber';

const StyledAlert = styled(Alert)`
  background-color: ${getColor('white')};
`;

const DirectDebitNextPayment = () => {
  const repaymentAmount = useSelector(getNextRepaymentAmount);
  const [isNextPaymentInProgress, nextRepaymentDate] =
    useNextPaymentIsBeforeSpecifiedDays();

  const isDirectDebitPendingSetup = useSelector(getDirectDebitIsPendingSetup);

  const showNextPaymentInProgressAlert =
    isNextPaymentInProgress && !isDirectDebitPendingSetup;

  return (
    <>
      <Paragraph fontWeight="bold" textAlign="left" marginTop={5} fontSize="small">
        Your Direct Debit
      </Paragraph>
      <StyledAlert
        marginTop={1}
        marginBottom={isDirectDebitPendingSetup || showNextPaymentInProgressAlert ? 1 : 3}
        backgroundColor="white"
        role="presentation"
      >
        <Flex justifyContent="space-between" alignItems="center">
          <div>
            <Paragraph>
              Your
              <Text data-testid="direct-debit-repayment-amount">
                &nbsp;{formatCurrency(repaymentAmount)}&nbsp;
              </Text>
              Direct Debit will be taken on or around
              <Text data-testid="direct-debit-repayment-day">
                &nbsp;{formatCondensedDate(nextRepaymentDate)}&nbsp;
              </Text>
            </Paragraph>
          </div>
          <Link
            aria-label="Change Next Payment"
            to="../payments"
            onClick={() =>
              analyticsClickEvent('AccountSummary', 'next-payment-change-button')
            }
          >
            <Icon icon={faPencil} size="lg" color="primary" maxHeight={24} />
          </Link>
        </Flex>
      </StyledAlert>
      {showNextPaymentInProgressAlert && (
        <Alert
          variant="warning"
          marginBottom={1}
          spacing="slim"
          title="Next Payment in progress"
          data-testid="payment-in-progress-warning"
          icon={faClock}
        />
      )}
      <ButtonLink
        navigational
        to="../make-payment"
        data-testid="make-payment-button"
        onClick={() =>
          analyticsClickEvent('AccountSummary', 'next-payment-make-payment-button')
        }
      >
        Make an extra payment
      </ButtonLink>
      <ButtonLink
        variant="secondary"
        to="../transactions"
        data-testid="view-transaction-history-button"
        marginTop={3}
        marginBottom={1}
      >
        View Transaction History
      </ButtonLink>
    </>
  );
};

export default DirectDebitNextPayment;
