import { memo, useEffect } from 'react';
import * as React from 'react';

import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import { useLocation } from 'react-router';

import { useAuth } from '@sequensis/react-auth';
import { useConfig } from '@sequensis/react-config';
import { useMount } from '@sequensis/react-hooks';

import { isDevelopment } from 'src/utils/environment';

export interface AnalyticsProps {
  children: React.ReactNode;
}

const Analytics = ({ children }: AnalyticsProps) => {
  const {
    analytics: { tagManagerId: googleTagManagerId },
  } = useConfig();
  const { customerId } = useAuth();

  const tagManagerArgs = {
    gtmId: googleTagManagerId,
  };

  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [location]);

  useMount(() => {
    if (!isDevelopment) {
      TagManager.initialize(tagManagerArgs);
    }

    customerId && window.heap && window.heap.identify(customerId);
  });

  return <>{children}</>;
};

export default memo(Analytics);
