import ReactGA from 'react-ga';

export const analyticsClickEvent = (category: string, label: string) => {
  if (process.env.REACT_APP_TEST === 'true') {
    return;
  }
  ReactGA.event({
    category: category,
    action: 'Click',
    label: label,
  });
};
