export enum AccountStatus {
  Activating = 'Activating',
  Active = 'Active',
  Closed = 'Closed',
  Closing = 'Closing',
  InArrears = 'InArrears',
  New = 'New',
}

export type AllAccountProps = BaseAccountProps &
  FixedTermLoanAccountProps &
  RevolvingCreditAccountProps;

export interface BaseAccountProps {
  id: string;
  reference: string;
  activatedAt: string;
  status: AccountStatus;
  isInPaymentPlan: boolean;
  accountDetails: BaseAccountDetails;
  balance: BaseBalance;
  funder: Funder;
  product: Product;
  activation: Activation;
  repaymentBreaks: RepaymentBreaks;
  featureFlags: AccountFeatureFlags;
  isCAIS?: boolean;
  hasNoticeOfDefault?: boolean;
}

export interface Activation {
  failures: ActivationFailure[];
}

export interface ActivationFailure {
  stage: string;
  error: string;
  failedAt: string;
}

export interface Product {
  productId: string;
}

export interface Funder {
  payeeId: string;
}

export interface BaseAccountDetails {
  annualPercentageRate: number;
  regularRepaymentAmount: number;
  repaymentMandateSource: 'ManagedDirectDebit' | 'PlatformDirectDebit';
}

export interface BaseBalance {
  appliedInterest: number;
  arrears: number;
}

export interface FixedTermLoanAccountProps extends BaseAccountProps {
  accountDetails: FixedTermLoanAccountDetails;
  balance: FixedTermLoanBalance;
  paymentSchedulePosition: PaymentSchedulePosition;
  numberOfPayments: number;
}

export interface FixedTermLoanAccountDetails extends BaseAccountDetails {
  loanAmount: number;
  paymentMethod: 'Direct Debit' | 'Manual Payment';
  repaymentMandateDetails: RepaymentMandateDetails;
}

export interface FixedTermLoanBalance extends BaseBalance {
  currentBalance: number;
  startBalance: number;
  amountPaid: number;
}

export interface PaymentSchedulePosition {
  numberOfPaymentsRemaining: number;
  nextPaymentDueAmount: number;
  nextPaymentDueDate?: string;
}

export interface RepaymentMandateDetails {
  accountNumber: string;
  repaymentMandateStatus:
    | 'Registered'
    | 'Cancelled'
    | 'PendingRegistration'
    | 'PendingSubmission';
}

export interface RevolvingCreditAccountProps extends BaseAccountProps {
  accountDetails: RevolvingCreditAccountDetails;
  balance: RevolvingCreditBalance;
}

export interface RevolvingCreditAccountDetails extends BaseAccountDetails {
  creditLimit: number;
  repaymentFrequency: RepaymentFrequency;
}

export interface RevolvingCreditBalance extends BaseBalance {
  total: number;
  accruedInterest: number;
  availableAmount: number;
  principal: number;
}

export interface RepaymentFrequency {
  dayOfMonth: number;
}

export interface RepaymentBreaks {
  current?: RepaymentBreak;
  previous: RepaymentBreak[];
}

export interface RepaymentBreak {
  id: string;
  lmsId: string;
  endDate: string;
  adjustedRepaymentAmount: number;
  takenUp: boolean;
}

export interface AccountFeatureFlags {
  canHaveRepaymentBreaks: boolean;
}
