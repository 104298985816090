import { EditableField } from 'src/components/Field/EditableField';
import { singleLineAddressString } from 'src/utils';

export interface DisplayableAddress {
  houseName?: string;
  houseNumber?: string;
  flat?: string;
  street: string;
  street2?: string;
  townOrCity: string;
  county: string;
  postcode: string;
  movedInDate?: string | Date;
  movedOutDate?: string | Date;
  residentialStatus?: string;
}

export interface AddressDisplayProps {
  address: DisplayableAddress;
  showLabel?: boolean;
  showResidentialStatus?: boolean;
  onEditClick?(): void;
}

const formatAddress = (address: DisplayableAddress) => {
  let addressString = singleLineAddressString(address);

  const { residentialStatus } = address;

  if (!!residentialStatus && residentialStatus != 'Unset') {
    let residentialStatusString = '';

    switch (residentialStatus) {
      case 'HomeOwner':
        residentialStatusString = 'Home Owner';
        break;
      case 'LivingWithParents':
        residentialStatusString = 'Living with Parents';
        break;
      case 'Tenant':
      case 'Other':
        residentialStatusString = residentialStatus;
        break;
    }

    addressString += `\n\nResidential Status: ${residentialStatusString}`;
  }

  return addressString;
};

export function AddressDisplay({ address, onEditClick, showLabel }: AddressDisplayProps) {
  return (
    <EditableField
      fieldType="editable"
      defaultValue={formatAddress(address)}
      testId="address-display"
      ariaLabel="address"
      label={showLabel ? 'Current address' : ''}
      name="address-display"
      onEditClick={onEditClick}
      isMultiline={true}
      isEditing={false}
    />
  );
}
