import { createContext, ReactNode, useContext } from 'react';

import { useSelector } from 'react-redux';

import { ApiResponse } from '@sequensis/fetch';
import { useAuth } from '@sequensis/react-auth';
import { useConfig } from '@sequensis/react-config';
import { AsyncExecutionState, useAsyncCallback } from '@sequensis/react-hooks';

import { getPaymentSchedule } from 'src/api/account/accountApi';
import { PaymentSchedule } from 'src/api/account/types';
import { getAccountId } from 'src/store/Account/selectors';

interface PaymentScheduleContextState {
  paymentScheduleState: AsyncExecutionState<ApiResponse<PaymentSchedule>>;
  fetchPaymentSchedule(): void;
}

const DEFAULT_PAYMENT_SCHEDULE_CONTEXT_VALUE: PaymentScheduleContextState = {
  paymentScheduleState: {
    result: null,
    error: null,
    errorMessage: null,
    loading: false,
    status: 'not-requested',
  },
  fetchPaymentSchedule: () => console.warn('Out of context'),
};

export const PaymentScheduleContext = createContext<PaymentScheduleContextState>(
  DEFAULT_PAYMENT_SCHEDULE_CONTEXT_VALUE,
);

interface PaymentScheduleContextProviderProps {
  children: ReactNode;
}

export const usePaymentScheduleContext = () => useContext(PaymentScheduleContext);

export const PaymentScheduleContextProvider = ({
  children,
}: PaymentScheduleContextProviderProps) => {
  const {
    accountManagementApi: { url: accountApiUrl },
  } = useConfig();
  const { getToken } = useAuth();
  const accountId = useSelector(getAccountId);

  const [paymentScheduleState, executeGetPaymentSchedule] =
    useAsyncCallback(getPaymentSchedule);

  const fetchPaymentSchedule = async () => {
    const authToken = await getToken();

    if (accountId) {
      executeGetPaymentSchedule(accountApiUrl, accountId, authToken);
    }
  };

  const providerValue = {
    paymentScheduleState,
    fetchPaymentSchedule,
  };

  return (
    <PaymentScheduleContext.Provider value={providerValue}>
      {children}
    </PaymentScheduleContext.Provider>
  );
};
