import { useMemo } from 'react';

import { useConfig } from '@sequensis/react-config';
import {
  Box,
  getColor,
  getFontSize,
  getFontWeight,
  oakbrookTheme,
  Paragraph,
  Text,
} from '@sequensis/stylish-core';
import styled, { ThemeProvider } from 'styled-components/macro';
import { space, SpaceProps } from 'styled-system';

import { PoweredByOakbrook } from 'src/components/Logos/PoweredByOakbrook';
import { useProduct } from 'src/hooks/useProduct';
import { ModuleRouteProps } from 'src/types/ModuleRouteProps';
import { getProductTheme } from 'src/utils/productHelper';

const LogoContainer = styled.div`
  padding-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* TODO: DS-105 replace with scss variable breakpoint */
  @media (min-width: 40rem) {
    justify-content: start;
  }
`;

const FooterUl = styled.ul`
  padding-left: 0;
  list-style: none;
  display: inline-flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  @media (min-width: 40rem) {
    flex: 1;
    padding-bottom: 1rem;
  }
  a,
  span {
    align-items: center;
    cursor: pointer;
    width: 100%;
    text-decoration: underline;
    font-weight: ${getFontWeight(400)};
    font-size: ${getFontSize('tiny')};
    color: ${getColor('backgroundPair')};
  }
`;

const FooterLinkLi = styled.li<SpaceProps>`
  font-weight: ${getFontWeight(400)};
  ${space}
`;

const BrandContainer = styled.div<SpaceProps>`
  background-color: ${getColor('background')};
  color: ${getColor('backgroundPair')};
  ${space}
`;

interface FooterListProps {
  path: string;
  title: string;
}

const FooterListItem = ({ path, title }: FooterListProps) => (
  <FooterLinkLi mr={4} p={0}>
    <a href={path} target="_blank" rel="noopener noreferrer">
      <Text>{title}</Text>
    </a>
  </FooterLinkLi>
);

export const Footer = () => {
  const { onlineAccountTermsUrl, privacyPolicyUrl } = useConfig();

  const footerLinks: ModuleRouteProps[] = [
    {
      path: onlineAccountTermsUrl,
      title: 'Online Account Terms',
    },
    {
      path: privacyPolicyUrl,
      title: 'Privacy Policy',
    },
  ];

  return (
    <Box as="footer" mt="auto">
      <BrandContainer pt={5}>
        <LogoContainer>
          <PoweredByOakbrook color="backgroundPair" />
        </LogoContainer>

        <FooterUl>
          {footerLinks.map((link) => (
            <FooterListItem key={link.title} {...link} />
          ))}
          <FooterLinkLi p={0}>
            <span
              onClick={() => window.CookieControl.open()}
              onKeyPress={() => window.CookieControl.open()}
              role="button"
              tabIndex={0}
            >
              <Text>Cookie settings</Text>
            </span>
          </FooterLinkLi>
        </FooterUl>

        <Paragraph size="sm" marginBottom={1} fontWeight="lighter">
          Oakbrook Loans is trading style of Oakbrook Loans Limited. Registered in England
          and Wales No. 08292621.
        </Paragraph>
        <Paragraph size="sm" marginBottom={1} fontWeight="lighter">
          Likely Loans and Finio Loans are trading styles of Oakbrook Finance Limited.
          Registered in England and Wales No. 07831517.
        </Paragraph>
        <Paragraph size="sm" marginBottom={1} fontWeight="lighter">
          Registered office: The Poynt North, 45 Wollaton Street, Nottingham, NG1
          5FW.&nbsp; Authorised and regulated by the Financial Conduct Authority.
        </Paragraph>
      </BrandContainer>
    </Box>
  );
};

export const BrandAwareFooter = () => {
  const product = useProduct({ shouldThrow: false });

  const theme = useMemo(() => {
    return product ? getProductTheme(product.baseRoute) : oakbrookTheme;
  }, [product]);

  return (
    <ThemeProvider theme={theme}>
      <Footer />
    </ThemeProvider>
  );
};
