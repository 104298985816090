import { parseISO } from 'date-fns';
import { createSelector } from 'reselect';

import { ApplicationState } from 'src/store';
import isUndefined from 'src/utils/isUndefined';

import { getBankAccounts } from '../Customer/selectors';

export const getAccountFetched = (applicationState: ApplicationState) =>
  Boolean(applicationState.accountState.account);

export const getAccountFetching = (applicationState: ApplicationState) =>
  applicationState.accountState.fetching;

export const getAccountFetchError = (applicationState: ApplicationState) =>
  applicationState.accountState.fetchError;

// TODO: Update this to Account status when we can
export const getIsInArrears = ({ accountState }: ApplicationState) =>
  accountState.account ? accountState.account.balance.arrears > 0 : false;

export const getArrearsAmount = ({ accountState }: ApplicationState) =>
  accountState.account ? accountState.account.balance.arrears : 0;

export const getAccountId = ({ accountState }: ApplicationState) =>
  accountState.account?.id;

export const getAccountReference = ({ accountState }: ApplicationState) =>
  accountState.account?.reference;

export const getAccountPayeeId = ({ accountState }: ApplicationState) =>
  accountState.account?.funder.payeeId;

export const getAccountStartDate = ({ accountState }: ApplicationState) =>
  parseISO(accountState.account ? accountState.account.activatedAt : '');

export const getProductIsComplete = ({ accountState }: ApplicationState) =>
  isUndefined(accountState.account?.paymentSchedulePosition.nextPaymentDueDate);

export const getProductIsPaidOff = ({ accountState }: ApplicationState) =>
  accountState.account ? accountState.account.balance.currentBalance <= 0 : false;

export const getProductLength = ({ accountState }: ApplicationState) =>
  accountState.account ? accountState.account.numberOfPayments : 0;

export const getLoanAmount = ({ accountState }: ApplicationState) =>
  accountState.account ? accountState.account.accountDetails.loanAmount : 0;

export const getTotalAmountOwed = ({ accountState }: ApplicationState) =>
  accountState.account ? accountState.account.balance.startBalance : 0;

export const getAnnualPercentageRate = ({ accountState }: ApplicationState) =>
  accountState.account ? accountState.account.accountDetails.annualPercentageRate : 0;

export const getBalanceAmount = ({ accountState }: ApplicationState) =>
  accountState.account ? accountState.account.balance.currentBalance : 0;

export const getAmountPaid = ({ accountState }: ApplicationState) =>
  accountState.account ? accountState.account.balance.amountPaid : 0;

export const getInterestAmount = ({ accountState }: ApplicationState) =>
  accountState.account ? accountState.account.balance.appliedInterest : 0;

export const getPaymentMethodIsDirectDebit = ({ accountState }: ApplicationState) =>
  accountState.account?.accountDetails.paymentMethod === 'Direct Debit';

export const getNextRepaymentAmount = ({ accountState }: ApplicationState) =>
  accountState.account
    ? accountState.account.paymentSchedulePosition.nextPaymentDueAmount
    : 0;

export const getNextRepaymentDate = ({ accountState }: ApplicationState) =>
  accountState.account?.paymentSchedulePosition.nextPaymentDueDate;

export const getnumberOfPaymentsRemaining = ({ accountState }: ApplicationState) =>
  accountState.account
    ? accountState.account.paymentSchedulePosition.numberOfPaymentsRemaining
    : 0;

export const getBankAccountNumber = ({ accountState }: ApplicationState) =>
  accountState.account?.accountDetails.repaymentMandateDetails.accountNumber
    ? accountState.account?.accountDetails.repaymentMandateDetails.accountNumber
    : '';

export const getRepaymentMandateBankDetails = createSelector(
  getBankAccountNumber,
  getBankAccounts,
  (bankAccountNumber, bankAccounts) =>
    bankAccounts &&
    (bankAccounts.find((ba) => ba.accountNumber.endsWith(bankAccountNumber)) ||
      bankAccounts[0]),
);

export const getAmountOwed = ({ accountState }: ApplicationState) =>
  accountState.account
    ? accountState.account.balance.total + accountState.account.balance.accruedInterest
    : 0;

export const getAmountBorrowed = ({ accountState }: ApplicationState) =>
  accountState.account ? accountState.account.balance.principal : 0;

export const getCreditLimit = ({ accountState }: ApplicationState) =>
  accountState.account ? accountState.account.accountDetails.creditLimit : 0;

export const getAvailableAmount = ({ accountState }: ApplicationState) =>
  accountState.account ? accountState.account.balance.availableAmount : 0;

// Remove this when AMS allows repayment of accrued
export const getAmountOwedMinusAccrued = ({ accountState }: ApplicationState) =>
  accountState.account ? accountState.account.balance.total : 0;

export const getTotalInterestAmount = ({ accountState }: ApplicationState) =>
  accountState.account
    ? accountState.account.balance.appliedInterest +
      accountState.account.balance.accruedInterest
    : 0;

export const getRepaymentAmount = ({ accountState }: ApplicationState) =>
  accountState.account ? accountState.account.accountDetails.regularRepaymentAmount : 0;

export const getTotalCreditAndInterestAmount = ({ accountState }: ApplicationState) =>
  accountState.account
    ? accountState.account.accountDetails.creditLimit +
      accountState.account.balance.appliedInterest +
      accountState.account.balance.accruedInterest
    : 0;

export const getAccruedInterestAmount = ({ accountState }: ApplicationState) =>
  accountState.account ? accountState.account.balance.accruedInterest : 0;

export const getRepaymentDate = ({ accountState }: ApplicationState) =>
  accountState.account
    ? accountState.account.accountDetails.repaymentFrequency.dayOfMonth
    : 0;

export const getIsInPaymentPlan = ({ accountState }: ApplicationState) =>
  accountState.account?.isInPaymentPlan;

export const getDirectDebitIsPendingSetup = ({ accountState }: ApplicationState) => {
  const status =
    accountState.account?.accountDetails.repaymentMandateDetails?.repaymentMandateStatus;

  return status === 'PendingRegistration' || status === 'PendingSubmission';
};

export const getIsEligibleForPaymentPlan = createSelector(
  getProductIsPaidOff,
  getProductIsComplete,
  (isPaidOff, isComplete) => !isPaidOff && !isComplete,
);

export const getIsOnRepaymentBreak = ({ accountState }: ApplicationState) =>
  accountState.account?.repaymentBreaks.current !== null;

export const getAccountStatus = ({ accountState }: ApplicationState) =>
  accountState.account?.status;

export const getHasActivationFailed = ({ accountState }: ApplicationState) =>
  (accountState.account?.activation?.failures?.length ?? 0) > 0;

const getActivationFailures = ({ accountState }: ApplicationState) =>
  accountState?.account?.activation?.failures;

export const getActivationFailureDate = createSelector(
  getHasActivationFailed,
  getActivationFailures,
  (hasFailed, failures) => {
    if (!hasFailed || !failures) {
      return null;
    }
    return new Date(failures[0].failedAt);
  },
);

export const getCurrentRepaymentBreak = ({ accountState }: ApplicationState) =>
  accountState.account?.repaymentBreaks.current;

export const getAccountProduct = ({ accountState }: ApplicationState) =>
  accountState.account?.product;
