import { faFileUpload, faLock } from '@fortawesome/pro-regular-svg-icons';
import { Button, Card, Flex, Halo, Icon, Paragraph } from '@sequensis/stylish-core';

import { requirementDisplayName } from '../data';

const Requirements = ({ requirements, onRequirementTypeClick }: any) => (
  <Card
    heading={
      <>
        <Flex justifyContent="space-around">
          <Halo>
            <Icon icon={faFileUpload} size="3x" />
          </Halo>
        </Flex>
        <Paragraph fontSize="3xl" lineHeight="3xl">
          We need some more information
        </Paragraph>
      </>
    }
  >
    <Paragraph mt={4}>
      As part of our checks and to keep you safe from online fraud, we need you to upload
      documents to help us confirm proof of the following things.
    </Paragraph>
    <Paragraph mt={3} fontWeight="bold">
      Confirm proof of...
    </Paragraph>
    {Object.keys(requirements?.requirements || {}).map((requirementType) => {
      return (
        <Button
          key={
            requirementDisplayName[requirementType as keyof typeof requirementDisplayName]
          }
          variant="primary"
          marginTop={3}
          fullWidth
          navigational="forward"
          onClick={() => onRequirementTypeClick(requirementType)}
        >
          <Paragraph>
            {
              requirementDisplayName[
                requirementType as keyof typeof requirementDisplayName
              ]
            }
          </Paragraph>
        </Button>
      );
    })}

    <Paragraph mt={4} fontWeight="bold">
      <Icon icon={faLock} mr={1} />
      Your data is safe and secure
    </Paragraph>
  </Card>
);

export default Requirements;
