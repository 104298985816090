import { AlertType } from '@sequensis/stylish-core';

import { Status } from './TelephonePendingAlert';

export const mapTelephoneAlertMessage = (label: string, status: Status) => {
  const apiStatusToAlertMessage = new Map<Status, string>([
    ['success', `Your ${label.toLowerCase()} number has been updated.`],
    [
      'error',
      `Something went wrong while updating your ${label.toLowerCase()} number, please try again.`,
    ],
  ]);

  const message =
    apiStatusToAlertMessage.get(status) || 'Something went wrong, please try again.';

  const alertType: AlertType = status === 'success' ? 'positive' : 'warning';

  return { message, alertType };
};
