import { useSelector } from 'react-redux';

import { useConfig } from '@sequensis/react-config';

import { getCurrentAccountIdFromProduct, getNextRepaymentDate } from 'src/store/Accounts';
import { calculateDaysBetweenNowAndNextRepayment } from 'src/utils/calculations';

import { useProduct } from './useProduct';

export const useNextPaymentIsBeforeSpecifiedDays = (
  numberOfDays?: number,
): [boolean, string] => {
  const { scheduledPaymentNotificationThresholdDays } = useConfig();
  const product = useProduct();
  const accountId = useSelector(getCurrentAccountIdFromProduct(product)) ?? '';
  const nextRepaymentDate = useSelector(getNextRepaymentDate(accountId)) ?? '';

  const thresholdAmountDays = numberOfDays ?? scheduledPaymentNotificationThresholdDays;

  const daysUntilNextRepayment =
    calculateDaysBetweenNowAndNextRepayment(nextRepaymentDate);

  const isInProgress =
    daysUntilNextRepayment <= thresholdAmountDays && daysUntilNextRepayment >= -5;

  return [isInProgress, nextRepaymentDate];
};
