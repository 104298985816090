import { all, call, fork, put, takeLatest } from 'redux-saga/effects';

import * as customerManagementApi from 'src/api/customer/customerApi';
import { CustomerApiResponse } from 'src/api/customer/types';
import { CustomerProps } from 'src/types/CustomerProps';

import { fetchCustomer } from './actions';

export function* handleFetchRequest(action: ReturnType<typeof fetchCustomer.request>) {
  try {
    const result: CustomerApiResponse = yield call(
      customerManagementApi.fetchCustomer,
      action.payload,
    );

    yield put(fetchCustomer.success(mapApiResponseToStoreType(result)));
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : error.toString();
    yield put(fetchCustomer.failure(errorMessage));
  }
}

export function* watchFetchRequest() {
  yield takeLatest(fetchCustomer.request, handleFetchRequest);
}

export default function* customerSaga() {
  yield all([fork(watchFetchRequest)]);
}

function mapApiResponseToStoreType(response: CustomerApiResponse): CustomerProps {
  return {
    firstName: response.firstName,
    lastName: response.lastName,
    emailAddress: response.emailAddress,
    telephoneNumbers: response.telephoneNumbers,
    address: {
      current: response.address.current,
    },
    emailForVerification: response.emailForVerification,
    accounts: response.accounts,
    bankDetails: response.bankDetails,
    communicationPreferences: response.communicationPreferences,
    dateOfBirth: response.dateOfBirth,
  };
}
