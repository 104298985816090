import { useContext, useMemo, useState } from 'react';
import { createContext } from 'react';

interface AnalyticsCookieContext {
  consented: boolean;
  refresh(): void;
}

const AnalyticsCookieContext = createContext<AnalyticsCookieContext>(
  {} as AnalyticsCookieContext,
);

export const useAnalyticsCookieContext = () => useContext(AnalyticsCookieContext);

type AnalyticsCookieControllerProps = {
  children: (context: AnalyticsCookieContext) => React.ReactNode;
};

/**
 * Retrieves the specified cookie's string value.
 */
const getCookie = (name: string) => {
  const allCookies = document.cookie.split('; ');
  const cookie = allCookies.find((cookie) => cookie.startsWith(`${name}=`));

  return cookie?.replace(`${name}=`, '');
};

/**
 * Determines if the user has consented to our analytical cookies
 */
const hasConsentedToAnalyticalCookies = () => {
  try {
    const cookieString = getCookie('CookieControl');
    const cookie = cookieString ? JSON.parse(cookieString) : {};

    return cookie?.optionalCookies?.analytics === 'accepted';
  } catch (err) {
    console.log('Failed parsing analytical cookie', err);
    return false;
  }
};

export const AnalyticsCookieController = ({
  children,
}: AnalyticsCookieControllerProps) => {
  const [consented, setConsented] = useState(() => hasConsentedToAnalyticalCookies());

  const context = useMemo(
    () => ({
      consented,
      refresh() {
        setConsented(hasConsentedToAnalyticalCookies());
      },
    }),
    [consented],
  );

  return (
    <AnalyticsCookieContext.Provider value={context}>
      {children(context)}
    </AnalyticsCookieContext.Provider>
  );
};
