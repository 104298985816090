import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import {
  Box,
  Card,
  Flex,
  Halo,
  Heading,
  Icon,
  Paragraph,
  Text,
} from '@sequensis/stylish-core';

import { analyticsClickEvent } from 'src/components/Analytics';
import { ButtonLink } from 'src/components/ButtonLink';
import { useGetLoanUrl } from 'src/hooks/useGetLoanUrl';

type LoanPaidOffProps = {
  className?: string;
  productName: string;
};

export const LoanPaidOff = ({ className, productName }: LoanPaidOffProps) => {
  const loanUrl = useGetLoanUrl(productName);

  return (
    <Box className={className}>
      <Heading textAlign="center" color="backgroundPair" marginBottom={4}>
        <Flex width="100%" justifyContent="center">
          <Halo>
            <Icon icon={faCheck} size="2x" />
          </Halo>
        </Flex>
        <Text>You&apos;re paid up!</Text>
      </Heading>
      <Card heading="Take out a new loan">
        <Paragraph>Check your eligiblity for a new loan</Paragraph>
        <ButtonLink
          mt={2}
          data-testid="loan-paid-off-apply-button"
          to={`${loanUrl}/?utm_source=reloan&utm_medium=hub&utm_campaign=button`}
          navigational
          onClick={() => analyticsClickEvent('AccountSummary', 'loan-paid-off-apply')}
        >
          Check my eligibility
        </ButtonLink>
      </Card>
    </Box>
  );
};
