import { lazy, useEffect, useState } from 'react';

import { Switch } from 'react-router';
import { CompatRoute, Navigate, Route, Routes } from 'react-router-dom-v5-compat';

import { useAuth } from '@sequensis/react-auth';

import { AppLayout, LoginLayout, SharedLayout } from 'src/components/AppLayout';
import VulnerabilitySupportCard from 'src/components/VulnerabilitySupportCard';
import { Customer } from 'src/screens/Customer';
import { PageNotFound, ServerError, Timeout } from 'src/screens/Error';
import { Login } from 'src/screens/Login';
import { Mistho } from 'src/screens/Mistho';
import { Register } from 'src/screens/Register';
import { ResignContract } from 'src/screens/ResignContract';

import { LazyRouteLayout } from './LazyRouteLayout';
import { ProductRoutes } from './ProductRoutes';
import { RequiresAuthGuard } from './RequiresAuthGuard';

// Lazy loading the components
const VerifyEmail = lazy(
  () => import(/* webpackChunkName: "verify-email" */ 'src/screens/VerifyEmail'),
);
const VersionHistory = lazy(
  () => import(/* webpackChunkName: "version-history" */ 'src/screens/VersionHistory'),
);

export const AppRoutes = () => {
  // get resume-topup-chaser-comm data from url
  const querySearch = window.location.search;
  const urlParams = new URLSearchParams(querySearch);
  const chaserId = urlParams.get('id');
  const processedBy = urlParams.get('name');
  const chaserType = urlParams.get('type');
  const commType = urlParams.get('ct');
  const isRedirectionPath = localStorage.getItem('isRedirectionUrl');
  if (chaserId && processedBy && chaserType && commType) {
    const chaserBody = { id: chaserId, processedBy, chaserType, commType };
    localStorage.setItem('chaserData', JSON.stringify(chaserBody));
  }
  const { isAuthenticated } = useAuth();
  const [showVulnerabilityPopup, setShowVulnerabilityPopup] = useState(false);

  useEffect(() => {
    setShowVulnerabilityPopup(localStorage.getItem('isSupportCardShown') !== 'true');
  }, []);

  const onCloseVulnerabilityPopup = () => {
    localStorage.setItem('isSupportCardShown', 'true');
    setShowVulnerabilityPopup(false);
  };

  useEffect(() => {
    localStorage.removeItem('isRedirectionUrl');
  }, []);

  return (
    <>
      <VulnerabilitySupportCard
        showContinueButton
        onContinueClick={onCloseVulnerabilityPopup}
        modalProps={{
          isOpen: showVulnerabilityPopup && isAuthenticated,
          onClose: onCloseVulnerabilityPopup,
        }}
      />
      <Switch>
        {/* Any route trees that are using react-router v6 are in here */}
        <CompatRoute path="">
          <Routes>
            <Route element={<SharedLayout />}>
              {/* Index route, shows login or redirects into /customer */}
              <Route element={<LoginLayout />}>
                <Route
                  index
                  element={
                    isAuthenticated ? (
                      <Navigate to={isRedirectionPath || '/customer'} replace />
                    ) : (
                      <Login />
                    )
                  }
                />
                <Route path="register" element={<Register />} />
              </Route>

              <Route element={<AppLayout />}>
                <Route element={<LazyRouteLayout />}>
                  <Route path="verify-email" element={<VerifyEmail />} />
                </Route>

                {/* Authed Routes */}
                <Route element={<RequiresAuthGuard />}>
                  <Route path="customer/*" element={<Customer />} />
                  <Route path=":product/*" element={<ProductRoutes />} />
                  <Route element={<LazyRouteLayout />}>
                    <Route path="version-history" element={<VersionHistory />} />
                    <Route path="timeout" element={<Timeout />} />
                  </Route>
                  {/* Redirects */}
                  <Route
                    path="overview"
                    element={<Navigate to="/customer/overview" replace />}
                  />
                  <Route
                    path="marketing-preferences"
                    element={<Navigate to="/customer/marketing-preferences" replace />}
                  />
                  <Route path="resign-contract" element={<ResignContract />} />
                  <Route path="verify-income/:mode" element={<Mistho />} />
                </Route>

                {/* Generic catch all routes */}
                <Route path="error" element={<ServerError />} />
                <Route path="*" element={<PageNotFound />} />
              </Route>
            </Route>
          </Routes>
        </CompatRoute>
      </Switch>
    </>
  );
};
