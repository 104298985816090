import { useConfig } from '@sequensis/react-config';

import { Product } from 'src/types/ConfigProps';

export const useGetProductConfig = (productId: string): Product | undefined => {
  const config = useConfig();

  return config.productConfiguration.products.find((product) =>
    product.productIds.includes(productId),
  );
};
