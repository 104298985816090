import { useEffect } from 'react';

import { Outlet, useLocation } from 'react-router-dom-v5-compat';

import { useAuth } from '@sequensis/react-auth';

import { Loading } from '../screens/Loading';

export const RequiresAuthGuard = () => {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth();
  const { pathname, search } = useLocation();

  useEffect(() => {
    const targetUrl = pathname + search;
    if (
      targetUrl === '/resign-contract?source=email' ||
      targetUrl === '/verify-income/info?source=email'
    )
      localStorage.setItem('redirectUrl', pathname + search);
    if (loading || isAuthenticated) return;

    loginWithRedirect({
      appState: { targetUrl: pathname + search },
    });
  }, [loading, isAuthenticated, loginWithRedirect, pathname, search]);

  return isAuthenticated ? <Outlet /> : <Loading />;
};
