import { get } from '@sequensis/fetch';
import { TrackJS } from 'trackjs';

import { createEliHeaderWithAuthBearerToken } from 'src/utils/api/headers';

import { EligibilityResponse } from './types';

export const getEligibileProduct = async (
  eligibleProductApiUrl: string,
  customerId: string,
  authToken: string,
) => {
  const { responseJson } = await get<EligibilityResponse>(
    `${eligibleProductApiUrl}/customers/${customerId}`,
    createEliHeaderWithAuthBearerToken(authToken),
  );

  if (!responseJson) {
    TrackJS.console.error('No data for this Customer');
    throw new Error('No data for this Customer');
  }

  return responseJson;
};
