export const DirectDebitLogo = () => (
  <svg width="51" height="51" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.425 32.627h4.858s4.926-.132 4.858 4.228c-.068 3.17-4.723 3.7-4.723 3.7H.425v-7.928zm2.428 6.408h1.553s3.171-.198 3.171-2.643c0-2.444-3.374-2.708-3.374-2.708h-1.35v5.35M.425 42.602h4.858s4.926-.066 4.858 4.228c-.068 3.237-4.723 3.7-4.723 3.7H.425v-7.928zm2.428 6.408h1.553s3.171-.198 3.171-2.642c0-2.445-3.374-2.643-3.374-2.643h-1.35v5.285M11.356 40.554h2.429v-7.993h-2.43v7.993zM15.81 40.554h2.36v-7.993h-2.36v7.993zM25.593 40.554h2.362v-7.993h-2.362v7.993zM45.161 40.554h2.362v-7.993h-2.362v7.993zM37.537 50.53h2.429v-6.078h-2.43v6.078zM24.581 50.596h2.362v-8.72H24.58v8.72zM37.537 43.527h2.429v-1.651h-2.43v1.651z"
      fill="#231F20"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.55 34.08h4.723v-1.52H27.55v1.52zM27.55 40.554h4.723v-1.453H27.55v1.453zM27.213 37.383h4.723V35.93h-4.723v1.453M17.834 32.56h1.821s3.981-.197 3.981 2.115c.068 1.982-2.294 2.114-2.294 2.114s.675.132.945.528c.27.397 1.89 3.237 1.89 3.237h-2.43s-.405-.462-.607-.792c-.135-.33-.608-1.057-.608-1.057s-.337-1.19-1.484-1.19c-.067 0-1.35-.066-1.35-.066v-1.255l1.687-.066s1.822-.066 1.89-1.123c.067-.99-1.687-1.123-1.687-1.123h-1.755l-.54-1.057.54-.264M41.315 34.278l.27-1.387s-1.214-.528-3.306-.528c-2.16 0-5.533 1.123-5.533 4.228 0 3.038 3.576 4.162 5.803 4.162 2.16 0 2.969-.33 2.969-.33l-.27-1.586s-1.012.33-2.632.264c-1.687 0-3.306-1.255-3.306-2.642.067-1.256 1.484-2.643 3.441-2.643 1.485 0 2.564.462 2.564.462M42.327 33.882h8.098v-1.321h-8.098v1.321zM43.272 45.707h6.478v-1.255h-6.478v1.255z"
      fill="#231F20"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49.75 49.142l.067 1.388s-1.147.198-1.889.198-2.632-.397-2.632-2.246v-5.483h2.43v5.086s0 1.19 1.012 1.19c.944 0 1.012-.133 1.012-.133zM26.2 47.623s-.27-3.237 3.78-3.237c3.036 0 3.508 2.51 3.44 3.17 0 .662-.27 3.172-3.778 3.172-3.509 0-3.441-3.105-3.441-3.105zm2.835 1.784c1.417-.066 2.024-1.256 2.024-1.85 0-.529-.27-1.784-2.16-1.784-1.821 0-1.956 1.85-1.956 1.85s0 1.784 2.091 1.784M15.058 48.09c.141.63.616 1.334 2.168 1.383 2.092.066 3.239-.66 3.239-.66v1.452s-2.227.529-3.374.529c-1.08 0-4.386-.198-4.386-3.237 0-2.445 2.7-3.237 4.251-3.237 2.767.066 4.049 1.057 4.049 3.765 0 .056-4.536.017-5.947.004zm.006-1.194h3.782s0-1.387-1.822-1.387c-1.316 0-1.801.728-1.96 1.387M25.474 11.935v16.18s-9.582-1.693-9.582-8.124c0-5.13 7.093-7.39 9.582-8.056zm0-.302c-5.759-.408-18.818 2.235-18.818 8.019 0 3.772 6.915 9.284 18.176 9.43.445 0 13.237-.484 13.04-13.927-.166-11.29-8.798-13.268-11.558-13.612V.855C37.317 1.538 45.97 8.017 45.97 15.88c0 8.318-9.68 15.087-21.583 15.087-11.904 0-21.535-6.77-21.535-15.087S12.484.794 24.388.794c.364 0 .726.006 1.086.019v10.82"
      fill="#231F20"
    />
  </svg>
);
