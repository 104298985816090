import { faPencil, faPlus, faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { faLock } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button } from '@sequensis/stylish-core';

import { FieldTypeProps } from './EditableField';

interface EditableFieldAdornmentProps {
  loading?: boolean;
  editing: boolean;
  addDisabled: boolean;
  fieldType?: FieldTypeProps;
  testId?: string;
  ariaLabel?: string;
  onSaveClick(): void;
  onEditClick(): void;
  onAddClick(): void;
}

export const EditableFieldAdornment = ({
  loading,
  editing,
  fieldType,
  testId,
  ariaLabel,
  addDisabled,
  onSaveClick,
  onEditClick,
  onAddClick,
}: EditableFieldAdornmentProps) => {
  if (loading) {
    return <Loader testId={testId} />;
  }

  if (fieldType === 'locked') {
    return <PadLock testId={testId} />;
  }

  if (fieldType === 'editable') {
    return editing ? (
      <SaveButton testId={testId} onClick={onSaveClick} ariaLabel={ariaLabel} />
    ) : (
      <EditButton testId={testId} onClick={onEditClick} ariaLabel={ariaLabel} />
    );
  }

  return <Add testId={testId} onClick={onAddClick} disabled={addDisabled} />;
};

const Loader = ({ testId }: { testId?: string }) => (
  <Box py={2} my={1} mr={2}>
    <FontAwesomeIcon
      data-testid={`${testId}-spinner`}
      icon={faSpinnerThird}
      size="lg"
      spin={true}
    />
  </Box>
);

const PadLock = ({ testId }: { testId?: string }) => (
  <Box py={2} my={1} mr={2}>
    <FontAwesomeIcon data-testid={`${testId}-lock-button`} icon={faLock} size="lg" />
  </Box>
);

interface EditButtonProps {
  testId?: string;
  ariaLabel?: string;
  onClick(): void;
}

const EditButton = ({ onClick, testId, ariaLabel = '' }: EditButtonProps) => (
  <Button
    variant="tertiary"
    onClick={onClick}
    data-testid={`${testId}-edit-button`}
    size="slim"
    my={1}
    aria-label={`${ariaLabel} edit`}
  >
    <FontAwesomeIcon icon={faPencil} />
  </Button>
);

interface SaveButtonProps {
  testId?: string;
  ariaLabel?: string;
  onClick(): void;
}

const SaveButton = ({ testId, onClick, ariaLabel = '' }: SaveButtonProps) => (
  <Button
    data-testid={`${testId}-save-button`}
    id={`${testId}-save-button`}
    my={1}
    size="slim"
    onClick={onClick}
    aria-label={`${ariaLabel} save`}
  >
    Save
  </Button>
);

interface AddProps {
  disabled: boolean;
  onClick(): void;
  testId?: string;
  ariaLabel?: string;
}

const Add = ({ disabled, onClick, testId, ariaLabel = '' }: AddProps) => {
  return (
    <Button
      disabled={disabled}
      variant="tertiary"
      data-testid={`${testId}-add-button`}
      onClick={onClick}
      size="slim"
      my={1}
      aria-label={`${ariaLabel} add`}
    >
      <FontAwesomeIcon icon={faPlus} />
    </Button>
  );
};
