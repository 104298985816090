import { useSelector } from 'react-redux';

import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { Card, Flex, Icon, Text } from '@sequensis/stylish-core';

import { getnumberOfPaymentsRemaining } from 'src/store/Account/selectors';

export const RemainingCard = () => {
  const remainingRepayments = useSelector(getnumberOfPaymentsRemaining);

  return (
    <Card heading="Remaining payments" marginY={2}>
      <Flex alignItems="center" marginBottom={1}>
        <Text fontWeight="bold" fontSize="large" fontFamily="heading">
          {remainingRepayments} payments to go
        </Text>
      </Flex>
      <Flex alignItems="center" marginBottom={1}>
        Based on your current payment schedule
      </Flex>
      <Flex color="primary" alignItems="center" marginBottom={1}>
        <Icon icon={faInfoCircle} size="sm" mr="2" maxHeight={16} />
        <Text fontWeight="bold">
          You can pay off your loan sooner by paying more each month
        </Text>
      </Flex>
    </Card>
  );
};
