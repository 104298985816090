import { useState } from 'react';

import { useConfig, useFeatureFlag } from '@sequensis/react-config';
import { Paragraph } from '@sequensis/react-core';
import { Alert, Card, Column, Flex, Grid, Heading } from '@sequensis/stylish-core';
import { Auth0SetupUserLoginForm, AzureSetupUserForm } from '@sequensis/users';

import { PoweredByOakbrook } from 'src/components/Logos';

export const SetupUser = ({ emailAddress }: { emailAddress?: string }) => {
  const {
    usersServiceApi: { url: usersServiceApiUrl },
    loginRedirectUrl,
  } = useConfig();
  const useAzureAuth = useFeatureFlag('useAzureAuth');

  const [hasError, setHasError] = useState(false);

  const SetupUserLoginForm = useAzureAuth ? AzureSetupUserForm : Auth0SetupUserLoginForm;

  return (
    <Flex
      flexDirection="column"
      justifyContent="flex-start"
      flex="1 1 auto"
      height="100%"
    >
      <Flex flex="1 1 auto" justifyContent="flex-end" my={5}>
        <PoweredByOakbrook color="primary" />
      </Flex>
      <Card>
        <Grid>
          <Column small={{ width: 10, offset: 1 }}>
            <Heading textAlign="left" color="primary" as="h2" marginY={5}>
              Setup your account
            </Heading>
            <Paragraph>
              Please click below to sign up to your online account and create your
              password
            </Paragraph>
          </Column>
        </Grid>
      </Card>

      <Card mt={3}>
        <Grid>
          <Column small={{ width: 10, offset: 1 }}>
            <SetupUserLoginForm
              serviceUrl={usersServiceApiUrl}
              username={emailAddress ?? ''}
              redirectUri={loginRedirectUrl}
              onError={() => setHasError(true)}
            />

            {hasError && (
              <Alert variant="negative">
                Something went wrong setting your password. Please try again.
              </Alert>
            )}
          </Column>
        </Grid>
      </Card>
    </Flex>
  );
};
