import { useNavigate } from 'react-router-dom-v5-compat';

import { Button } from '@sequensis/stylish-core';
import { PageHeader } from '@sequensis/stylish-layouts';

export const Timeout = () => {
  const navigate = useNavigate();

  return (
    <>
      <PageHeader title="It looks like something has gone wrong." />
      <Button variant="primary" onClick={() => navigate(-1)} marginTop={2} fullWidth>
        Go back
      </Button>
    </>
  );
};
