import {
  ComponentTheme,
  MultipartComponentTheme,
  oakbrookLoansTheme,
  oakbrookTheme,
  StylishTheme,
} from '@sequensis/stylish-core';

const hubComponents: Record<
  string,
  ComponentTheme<string> | MultipartComponentTheme<string>
> = {
  productOverview: {
    baseStyle: {
      card: {
        backgroundColor: 'base',
        boxShadow: 'dropShadow',
      },
      cardText: {
        color: 'white',
      },
      balanceAmount: {
        color: 'backgroundPair',
      },
    },
  },
  anchor: {
    baseStyle: {
      color: 'primary',
      textDecorationColor: 'primary',
    },
  },
  paymentInformationBox: {
    baseStyle: {
      borderColor: 'oakbrookNavyTint',
    },
  },
  highlight: {
    baseStyle: {
      color: 'secondary',
    },
  },
};

/** Until font sizing is made consistent within stylish themes we need to override these in the hub */
export const oakbrookLoansHubTheme: StylishTheme = {
  ...oakbrookTheme,
  ...oakbrookLoansTheme,
  opacities: {
    opaque: '0.5',
  },
  colors: {
    ...oakbrookTheme.colors,
    ...oakbrookLoansTheme.colors,
  },
  fontSizes: oakbrookTheme.fontSizes,
  fontWeights: oakbrookTheme.fontWeights,
  lineHeights: oakbrookTheme.lineHeights,
  components: {
    ...oakbrookLoansTheme.components,
    ...hubComponents,
  },
};
