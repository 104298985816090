import { Halo } from 'src/components/Halo';

export const CalendarIcon = () => (
  <Halo>
    <path
      d="M84.4046 55H80.476V50.9375C80.476 50.4688 79.985 50 79.4939 50H76.2201C75.6472 50 75.2379 50.4688 75.2379 50.9375V55H64.7617V50.9375C64.7617 50.4688 64.2707 50 63.7796 50H60.5058C59.9329 50 59.5236 50.4688 59.5236 50.9375V55H55.5951C53.3853 55 51.6665 56.7188 51.6665 58.75V86.25C51.6665 88.3594 53.3853 90 55.5951 90H84.4046C86.5326 90 88.3332 88.3594 88.3332 86.25V58.75C88.3332 56.7188 86.5326 55 84.4046 55ZM83.9135 86.25H56.0861C55.7588 86.25 55.5951 86.0938 55.5951 85.7812V62.5H84.4046V85.7812C84.4046 86.0938 84.1591 86.25 83.9135 86.25ZM79.5757 70.625C79.9031 70.2344 79.9031 69.6875 79.5757 69.2969L77.6933 67.5C77.3659 67.1094 76.7111 67.1094 76.3019 67.5L67.2171 76.0938L63.6159 72.6562C63.2067 72.2656 62.6338 72.2656 62.2245 72.6562L60.3421 74.375C60.0147 74.7656 60.0147 75.3906 60.3421 75.7031L66.4805 81.6406C66.8897 82.0312 67.5445 82.0312 67.8719 81.6406L79.5757 70.625Z"
      fill="#000067"
    />
  </Halo>
);
