import { ApplicationDetailsPlayback, Playback } from 'src/screens/TopUp';
import { CustomerProps, TelephoneNumberTypes } from 'src/types/CustomerProps';
import { singleLineAddressString } from 'src/utils';
import { BankDetails } from 'src/api/customer/types';

export function mapDetailsPlayback(
  customer?: CustomerProps,
  currentBankAccount?: BankDetails,
): Playback<ApplicationDetailsPlayback> {
  const mobileNumber =
    customer &&
    customer.telephoneNumbers.find((phone) => phone.type == TelephoneNumberTypes.Mobile)
      ?.number;

  if (customer && customer.address.current.residentialStatus && mobileNumber && currentBankAccount) {
    return {
      playback: {
        currentAddress: singleLineAddressString(customer.address.current),
        residentialStatus: customer.address.current.residentialStatus,
        emailAddress: customer.emailAddress,
        mobileNumber: mobileNumber,
        bankAccountNumber: currentBankAccount.accountNumber,
        sortCode: currentBankAccount.sortCode,
        accountName: currentBankAccount.accountName,
      },
    };
  } else {
    return {
      playback: undefined,
      isLoading: true,
    };
  }
}
