import { useSelector } from 'react-redux';

import { Alert, AlertProps, Text } from '@sequensis/stylish-core';

import { A } from 'src/components/Anchor';
import { getAccountId } from 'src/store/Account/selectors';
import { getAccountReference } from 'src/store/Accounts';

type FeedbackAlertProps = AlertProps & {
  feedbackQueryString: string;
};

export const FeedbackAlert = ({ feedbackQueryString, ...rest }: FeedbackAlertProps) => {
  const feedbackUrl = 'https://delighted.com/t/lVqxwl1q?';

  const accountId = useSelector(getAccountId);
  const accountReference = useSelector(getAccountReference(accountId ?? ''));

  const loanRef = accountReference ? `LoanRef=${accountReference}&` : '';

  return (
    <Alert {...rest} marginBottom={4}>
      <Text fontWeight="bold">How easy was this for you today?</Text>
      <br />
      <A
        href={`${feedbackUrl}${loanRef}${feedbackQueryString}`}
        target="_blank"
        rel="noreferrer"
      >
        Let us know what you think,
      </A>
      <Text> there&apos;s only 1 question to answer!</Text>
    </Alert>
  );
};
