import { join, map, pipe } from 'ramda';

/**
 * Extremely simple func to get query params from an object
 */
const queryParams = pipe(
  (obj: {} | undefined) => Object.entries(obj || {}),
  map(([attr, value]) => `${attr}=${value}`),
  join('&'),
  p => p && `?${p}`,
);

export default queryParams;
