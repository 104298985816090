import { Card } from '@sequensis/stylish-core';
import { PageHeader } from '@sequensis/stylish-layouts';

import { ButtonLink } from 'src/components/ButtonLink';

export const PageNotFound = () => {
  return (
    <>
      <PageHeader title="Page not found" />

      <Card>
        <ButtonLink to="/customer/overview" navigational variant="primary">
          Return to home
        </ButtonLink>
      </Card>
    </>
  );
};
