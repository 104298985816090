import { useEffect, useState } from 'react';

export function useAutoCounter(from: number, to: number, delay: number) {
  const [count, setCount] = useState(from);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | undefined;
    if (count < to) {
      timeout = setTimeout(() => setCount((prevCounter) => prevCounter + 1), delay);
    }
    return () => timeout && clearTimeout(timeout);
  }, [count]); // eslint-disable-line react-hooks/exhaustive-deps

  return count;
}
