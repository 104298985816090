import { DocumentSubmissionResponse, DocumentToSubmit } from './documentUpload.types';

export const submitDocumentToUploadUrl = async (documentToSubmit: DocumentToSubmit) => {
  const submitFile = async (uploadUrl: string, document: File) => {
    const data = new FormData();
    data.append('document', document);

    const response = await fetch(uploadUrl, {
      method: 'POST',
      body: data,
    });

    return response.status;
  };

  const status = await submitFile(documentToSubmit.uploadUrl, documentToSubmit.document);

  return {
    status: status,
    documentToSubmit: documentToSubmit,
  } as DocumentSubmissionResponse;
};
