import { useCallback } from 'react';

import { useQuery } from 'react-query';

import { useAuth } from '@sequensis/react-auth';
import { useConfig } from '@sequensis/react-config';
import { eachMonthOfInterval, startOfMonth } from 'date-fns';
import { groupBy, sort } from 'ramda';

import { getTransactions } from 'src/api/account/accountApi';
import { Transaction, TransactionsResponse } from 'src/api/account/types';
import { useCurrentAccountId } from 'src/hooks/useCurrentAccountId';

export interface TransactionsMonthProps {
  title: string;
  isOpen?: boolean;
  isPending?: boolean;
  daysItems: TransactionsDayProps[];
}

export interface TransactionsDayProps {
  date: string;
  items: Transaction[];
}

export const useGetGroupedTransactions = (openMonths: number) => {
  const {
    accountManagementApi: { url: accountApiUrl },
  } = useConfig();
  const { getToken } = useAuth();
  const currentAccountId = useCurrentAccountId() ?? '';

  const getTransactionGroups = useCallback(async () => {
    const token = await getToken();
    const transactions = await getTransactions(accountApiUrl, currentAccountId, token);
    return GroupTransactionMonths(openMonths, transactions?.responseJson);
  }, [accountApiUrl, currentAccountId, getToken, openMonths]);

  return useQuery<TransactionsMonthProps[]>([], getTransactionGroups, {
    keepPreviousData: true,
    refetchInterval: false,
  });
};

const GroupTransactionMonths = (
  openMonths: number,
  transactionsResponse: TransactionsResponse | undefined,
): TransactionsMonthProps[] => {
  if (transactionsResponse?.transactions === undefined) return [];

  const transactions = sort(
    (a, b) => (new Date(a.valueDate) < new Date(b.valueDate) ? 1 : -1),
    transactionsResponse?.transactions as Transaction[],
  );

  const result = [] as TransactionsMonthProps[];
  const pendingTransactionGroup = {
    title: 'Pending',
    isOpen: true,
    isPending: true,
    daysItems: GroupTransactionDays(
      transactions.filter((t) => {
        return t.status === 'Pending';
      }) as Transaction[],
    ),
  };

  const now = new Date();
  const lastMonth = startOfMonth(
    new Date(transactions[transactions.length - 1].valueDate),
  );
  const months = eachMonthOfInterval({ start: lastMonth, end: now });
  months.forEach((month) => {
    const transactionGroup = {
      title: month.toLocaleString('default', { month: 'long', year: 'numeric' }),
      isOpen: result.length >= months.length - openMonths,
      daysItems: GroupTransactionDays(
        transactions.filter((t) => {
          const d = new Date(t.valueDate);
          return (
            t.status !== 'Pending' &&
            d.getFullYear() === month.getFullYear() &&
            d.getMonth() === month.getMonth()
          );
        }) as Transaction[],
      ),
    };
    result.unshift(transactionGroup);
  });

  if (pendingTransactionGroup.daysItems.length > 0) {
    result.unshift(pendingTransactionGroup);
  }

  return result;
};

const GroupTransactionDays = (transactions: Transaction[]): TransactionsDayProps[] => {
  const transactionsByDate = groupBy((tr) => tr.valueDate, transactions);

  return Object.keys(transactionsByDate).reduce((result, key) => {
    result.push({
      date: key,
      items: transactionsByDate[key],
    } as TransactionsDayProps);
    return result;
  }, [] as TransactionsDayProps[]);
};
