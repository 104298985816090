import { Helmet } from 'react-helmet-async';
import { Outlet } from 'react-router-dom-v5-compat';

import { CookieControl } from 'src/components/CookieControl';
import { useLayoutContext } from 'src/context/LayoutContext';

import { ShimV5Props } from './V5RouteLayoutShim';

export const SharedLayout = ({ v5Shim, children }: ShimV5Props) => {
  const { title } = useLayoutContext();

  return (
    <>
      <Helmet>
        <title>{title && `${title} | Online Servicing`}</title>
        <meta name="version" content={process.env.REACT_APP_VERSION} />
      </Helmet>

      <CookieControl />

      {v5Shim && children ? children : <Outlet />}
    </>
  );
};
