import { useSelector } from 'react-redux';

import { Card } from '@sequensis/stylish-core';

import { getPaymentMethodIsDirectDebit } from 'src/store/Account/selectors';

import DirectDebitNextPayment from './DirectDebitNextPayment';
import ManualNextPayment from './ManualNextPayment';

export type NextPaymentProps = {
  className?: string;
};

const NextPayment = ({ className }: NextPaymentProps) => {
  const isPaymentMethodDirectDebit = useSelector(getPaymentMethodIsDirectDebit);

  return (
    <Card data-testid="next-payment-card" heading="Payments" className={className}>
      {isPaymentMethodDirectDebit ? <DirectDebitNextPayment /> : <ManualNextPayment />}
    </Card>
  );
};

export default NextPayment;
