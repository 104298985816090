import { useState } from 'react';

import { Controller, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers';
import {
  Box,
  Button,
  Card,
  NumericField,
  Paragraph,
  RadioGroup,
  RadioOption,
  Select,
  Spaced,
  Text,
} from '@sequensis/stylish-core';
import * as yup from 'yup';
import { RequiredNumberSchema } from 'yup/lib/number';

import { Explainer } from 'src/components/Explainer';
import { ProgressHeader } from 'src/components/ProgressHeader';
import { useRaiseSubmissionError } from 'src/hooks/useRaiseSubmissionError';
import { SubmissionAction } from 'src/screens/TopUp/types';
import { formatCurrency } from 'src/utils';

export interface SpendingForm {
  housing?: number;
  gambling?: number;
  totalOther?: number;
  household?: number;
  food?: number;
  transport?: number;
  clothing?: number;
  other?: number;
  dependants: number;
}

function numberInRangeTest(max: number, value?: number) {
  if (value !== undefined) return value >= 0 && value <= max;
  return false;
}

function requiredAmountValidation(
  title: string,
  max: number,
): RequiredNumberSchema<number | undefined, Record<string, any>> {
  return yup
    .number()
    .typeError(`${title} is required`)
    .required(`${title} is required`)
    .test(
      'range',
      `${title} must be less than or equal to ${formatCurrency(max)}`,
      (value) => numberInRangeTest(max, value),
    );
}

const SpendingFormSchema = (
  breakdownType: 'total' | 'separate',
  {
    housingMax,
    gamblingMax,
    totalOtherMax,
    householdMax,
    foodMax,
    transportMax,
    clothingMax,
    otherMax,
  }: {
    housingMax: number;
    gamblingMax: number;
    totalOtherMax: number;
    householdMax: number;
    foodMax: number;
    transportMax: number;
    clothingMax: number;
    otherMax: number;
  },
): yup.SchemaOf<SpendingForm> => {
  if (breakdownType === 'total') {
    return yup.object().shape({
      housing: requiredAmountValidation('Rent or mortgage amount', housingMax),
      gambling: requiredAmountValidation('Gambling amount', gamblingMax),
      totalOther: requiredAmountValidation('Total other amount', totalOtherMax),
      household: yup.number(),
      food: yup.number(),
      transport: yup.number(),
      clothing: yup.number(),
      other: yup.number(),
      dependants: yup.number().required('Number of dependants is required'),
    });
  } else {
    return yup.object().shape({
      housing: requiredAmountValidation('Rent or mortgage amount', housingMax),
      gambling: requiredAmountValidation('Gambling amount', gamblingMax),
      totalOther: yup.number(),
      household: requiredAmountValidation(
        'Household bill and subscriptions amount',
        householdMax,
      ),
      food: requiredAmountValidation('Food cost amount', foodMax),
      transport: requiredAmountValidation(
        'Transport and travel cost amount',
        transportMax,
      ),
      clothing: requiredAmountValidation(
        'Clothing and personal cost amount',
        clothingMax,
      ),
      other: requiredAmountValidation('Other costs amount', otherMax),
      dependants: yup.number().required('Number of dependants is required'),
    });
  }
};

export interface SpendingProps {
  progress: number;
  onSpendingSubmit: SubmissionAction<SpendingForm>;
  housingMax?: number;
  gamblingMax?: number;
  totalOtherMax?: number;
  householdMax?: number;
  foodMax?: number;
  transportMax?: number;
  clothingMax?: number;
  otherMax?: number;
}

export const Spending = ({
  progress,
  onSpendingSubmit: { onAction: onSpendingSubmit, error, isLoading },
  housingMax = Infinity,
  gamblingMax = housingMax,
  totalOtherMax = housingMax,
  householdMax = housingMax,
  foodMax = housingMax,
  transportMax = housingMax,
  clothingMax = housingMax,
  otherMax = housingMax,
}: SpendingProps) => {
  const [breakdownType, setBreakdownType] = useState<'total' | 'separate'>('total');

  const { handleSubmit, control, errors } = useForm<SpendingForm>({
    mode: 'onBlur',
    resolver: yupResolver(
      SpendingFormSchema(breakdownType, {
        housingMax,
        gamblingMax,
        totalOtherMax,
        householdMax,
        foodMax,
        transportMax,
        clothingMax,
        otherMax,
      }),
    ),
  });

  useRaiseSubmissionError(error);

  return (
    <Spaced marginBottom={4} excludeLast>
      <ProgressHeader currentStep="Spending" progress={progress} stepText="Step 2/3" />
      <Card heading="Now can we check how much you spend">
        <form
          onSubmit={handleSubmit((data: SpendingForm) => onSpendingSubmit(data))}
          noValidate
        >
          <Spaced marginBottom={6} excludeLast>
            <Box marginTop={4}>
              <Controller
                render={({ value, onChange }) => (
                  <NumericField
                    formattingProps={{
                      allowNegative: false,
                    }}
                    leftAdornment={
                      <Text size="lg" variant="emphasis">
                        £
                      </Text>
                    }
                    id="housing"
                    name="housing"
                    value={value}
                    onChange={onChange}
                    error={errors.housing?.message}
                    placeholder="000"
                    data-testid="housing"
                    label="How much do you pay towards rent or mortgage costs per month?"
                    data-heap-redact-text
                    required
                    maxLength={10}
                  />
                )}
                name="housing"
                control={control}
              />
              <Box marginTop={1}>
                <Explainer question="Why are you asking for this?">
                  <Paragraph size="sm">
                    This is to help us assess your affordability for a loan. We only need
                    your housing costs, so {`don't`} include council tax or other bills
                  </Paragraph>
                </Explainer>
              </Box>
            </Box>
            <Controller
              render={({ value, onChange }) => (
                <NumericField
                  formattingProps={{
                    allowNegative: false,
                  }}
                  leftAdornment={
                    <Text size="lg" variant="emphasis">
                      £
                    </Text>
                  }
                  id="gambling"
                  name="gambling"
                  value={value}
                  onChange={onChange}
                  error={errors.gambling?.message}
                  placeholder="0"
                  data-testid="gambling"
                  label="How much do you spend on gambling per month?"
                  data-heap-redact-text
                  required
                  maxLength={10}
                />
              )}
              name="gambling"
              control={control}
            />
            <Box>
              <Paragraph size="md" marginBottom={2}>
                What other monthly costs do you have?
              </Paragraph>
              <Paragraph size="sm" marginBottom={3}>
                This should only include anything essential that you yourself pay for
                (e.g. household bills, food, clothes, childcare and travel)
              </Paragraph>
              <RadioGroup
                name="breakdownType"
                label="How would you like to enter this?"
                selected={breakdownType}
                onChange={setBreakdownType}
                variant="bar"
              >
                <RadioOption value="total" data-testid="total-amount">
                  Total amount
                </RadioOption>
                <RadioOption value="separate" data-testid="separate-amounts">
                  Separate amounts
                </RadioOption>
              </RadioGroup>
              {breakdownType === 'total' && (
                <Box marginTop={3}>
                  <Controller
                    render={({ value, onChange }) => (
                      <NumericField
                        formattingProps={{
                          allowNegative: false,
                        }}
                        leftAdornment={
                          <Text size="lg" variant="emphasis">
                            £
                          </Text>
                        }
                        id="totalOther"
                        name="totalOther"
                        value={value}
                        onChange={onChange}
                        error={errors.totalOther?.message}
                        placeholder="00"
                        data-testid="totalOther"
                        aria-label="What are your total other monthly costs?"
                        data-heap-redact-text
                        required
                        maxLength={10}
                      />
                    )}
                    name="totalOther"
                    control={control}
                  />
                </Box>
              )}
              {breakdownType === 'separate' && (
                <Spaced marginBottom={3} excludeLast>
                  <Box marginTop={3}>
                    <Controller
                      render={({ value, onChange }) => (
                        <NumericField
                          formattingProps={{
                            allowNegative: false,
                          }}
                          leftAdornment={
                            <Text size="lg" variant="emphasis">
                              £
                            </Text>
                          }
                          id="household"
                          name="household"
                          value={value}
                          onChange={onChange}
                          error={errors.household?.message}
                          placeholder="000"
                          data-testid="household"
                          label="How much are your own household bills and subscriptions per month?"
                          data-heap-redact-text
                          required
                          maxLength={10}
                        />
                      )}
                      name="household"
                      control={control}
                      defaultValue={null}
                    />
                    <Box marginTop={1}>
                      <Explainer question="What should this include?">
                        <Paragraph size="sm">
                          This should include electricity, gas, water, internet, council
                          tax, phone, TV, insurance.
                        </Paragraph>
                      </Explainer>
                    </Box>
                  </Box>
                  <Box>
                    <Controller
                      render={({ value, onChange }) => (
                        <NumericField
                          formattingProps={{
                            allowNegative: false,
                          }}
                          leftAdornment={
                            <Text size="lg" variant="emphasis">
                              £
                            </Text>
                          }
                          id="food"
                          name="food"
                          value={value}
                          onChange={onChange}
                          error={errors.food?.message}
                          placeholder="000"
                          data-testid="food"
                          label="How much do you spend on food per month?"
                          data-heap-redact-text
                          required
                          maxLength={10}
                        />
                      )}
                      name="food"
                      control={control}
                    />
                    <Box marginTop={1}>
                      <Explainer question="What should this include?">
                        <Paragraph size="sm">
                          This should include your food and non-alcoholic drinks.
                        </Paragraph>
                      </Explainer>
                    </Box>
                  </Box>
                  <Box>
                    <Controller
                      render={({ value, onChange }) => (
                        <NumericField
                          formattingProps={{
                            allowNegative: false,
                          }}
                          leftAdornment={
                            <Text size="lg" variant="emphasis">
                              £
                            </Text>
                          }
                          id="transport"
                          name="transport"
                          value={value}
                          onChange={onChange}
                          error={errors.transport?.message}
                          placeholder="000"
                          data-testid="transport"
                          label="How much do you spend on transport and travel per month?"
                          data-heap-redact-text
                          required
                          maxLength={10}
                        />
                      )}
                      name="transport"
                      control={control}
                    />
                    <Box marginTop={1}>
                      <Explainer question="What should this include?">
                        <Paragraph size="sm">
                          Please only include money you&apos;ve spent on fuel, trains and
                          buses.
                        </Paragraph>
                      </Explainer>
                    </Box>
                  </Box>
                  <Box>
                    <Controller
                      render={({ value, onChange }) => (
                        <NumericField
                          formattingProps={{
                            allowNegative: false,
                          }}
                          leftAdornment={
                            <Text size="lg" variant="emphasis">
                              £
                            </Text>
                          }
                          id="clothing"
                          name="clothing"
                          value={value}
                          onChange={onChange}
                          error={errors.clothing?.message}
                          placeholder="000"
                          data-testid="clothing"
                          label="How much do you spend on clothing and personal costs per month?"
                          data-heap-redact-text
                          required
                          maxLength={10}
                        />
                      )}
                      name="clothing"
                      control={control}
                    />
                    <Box marginTop={1}>
                      <Explainer question="What should this include?">
                        <Paragraph size="sm">
                          This should include clothing for you and your
                          dependants(including school uniform) toiletries and medical
                          costs such as perscriptions.
                        </Paragraph>
                      </Explainer>
                    </Box>
                  </Box>
                  <Box>
                    <Controller
                      render={({ value, onChange }) => (
                        <NumericField
                          formattingProps={{
                            allowNegative: false,
                          }}
                          leftAdornment={
                            <Text size="lg" variant="emphasis">
                              £
                            </Text>
                          }
                          id="other"
                          name="other"
                          value={value}
                          onChange={onChange}
                          error={errors.other?.message}
                          placeholder="000"
                          data-testid="other"
                          label="Do you have any other monthly costs?"
                          data-heap-redact-text
                          required
                          maxLength={10}
                        />
                      )}
                      name="other"
                      control={control}
                    />
                    <Box marginTop={1}>
                      <Explainer question="What should this include?">
                        <Paragraph size="sm">
                          This should include any other essential costs not already
                          included above, such as childcare or school fees.
                        </Paragraph>
                      </Explainer>
                    </Box>
                  </Box>
                </Spaced>
              )}
            </Box>
            <Controller
              render={({ value, onChange }) => (
                <Select
                  id="dependants"
                  name="dependants"
                  value={value}
                  onChange={(e) => {
                    e.preventDefault();
                    onChange(e);
                  }}
                  error={errors.dependants?.message}
                  placeholder="Please select"
                  data-testid="dependants"
                  label="How many children under 18 or dependants are you responsible for?"
                  data-heap-redact-text
                  required
                >
                  <option data-testid="dependants-0" value={0}>
                    0
                  </option>
                  <option data-testid="dependants-1" value={1}>
                    1
                  </option>
                  <option data-testid="dependants-2" value={2}>
                    2
                  </option>
                  <option data-testid="dependants-3" value={3}>
                    3
                  </option>
                  <option data-testid="dependants-4" value={4}>
                    4
                  </option>
                  <option data-testid="dependants-5+" value={5}>
                    5+
                  </option>
                </Select>
              )}
              name="dependants"
              control={control}
            />
            <Button
              fullWidth
              isLoading={isLoading}
              data-testid="spending-continue-button"
            >
              Continue
            </Button>
          </Spaced>
        </form>
      </Card>
    </Spaced>
  );
};

export const SpendingStep = {
  path: 'spending',
  component: Spending,
  provides: [
    'housing',
    'gambling',
    'total',
    'household',
    'food',
    'transport',
    'clothing',
    'other',
    'dependants',
  ],
};
