import {
  ApplicationContract,
  ApplicationContractType,
} from '@sequensis/creditapplication-reactsdk/dist/typings/api/application';
import { LegalDocumentsPlayback } from '../LegalDocuments';
import { Playback } from 'src/screens/TopUp';

export function mapLegalDocumentsPlayback(
  contracts?: ApplicationContract[],
): Playback<LegalDocumentsPlayback> {
  if (contracts) {
    return {
      playback: {
        explanationAcceptPdfLocation: getContractByType(contracts, 'AdequateExplanation')!
          .document.location,
        creditInformationAcceptPdfLocation: getContractByType(
          contracts,
          'PreContractCreditInformation',
        )!.document.location,
        loanAgreementAcceptPdfLocation: getContractByType(
          contracts,
          'FixedSumLoanAgreement',
        )!.document.location,
      },
      isLoading: false,
    };
  } else {
    return {
      playback: undefined,
      isLoading: true,
    };
  }
}

const getContractByType = (
  contracts: ApplicationContract[],
  type: ApplicationContractType,
) => contracts.find((contract) => contract.type === type);
