import { getColor, getRadii } from '@sequensis/stylish-core';
import { css } from 'styled-components';

export const focusVisibleStyles = css`
  border-radius: ${getRadii(1)};
  border: 2px solid transparent;
  &:hover {
    background-color: ${getColor('primaryBg')};
  }
  &:focus-visible {
    border: 2px solid #333333;
  }
`;
