import { Card, Paragraph } from '@sequensis/stylish-core';
import { PageHeader } from '@sequensis/stylish-layouts';

import { ButtonLink } from 'src/components/ButtonLink';

export const PaymentFailure = () => {
  return (
    <>
      <PageHeader title="We were unable to process your payment" />
      <Card>
        <Paragraph mb={1}>
          You have not been charged for this transaction. Please check your details and
          try again.
        </Paragraph>

        <ButtonLink variant="secondary" to="../make-payment" mt={3}>
          Try again
        </ButtonLink>

        <ButtonLink variant="secondary" to="../balance" mt={3}>
          Return to account summary
        </ButtonLink>
      </Card>
    </>
  );
};

export default PaymentFailure;
