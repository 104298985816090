import { Button, Heading, Modal } from '@sequensis/stylish-core';
import { Box } from '@sequensis/stylish-core';

import { DirectDebitLogo } from 'src/screens/Payments/Components/DirectDebitLogo';

interface DirectDebitGuaranteeModalProps {
  isOpen: boolean;
  onClose(): void;
}

export function DirectDebitGuaranteeModal({
  isOpen,
  onClose,
}: DirectDebitGuaranteeModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      footer={
        <Button
          fullWidth={true}
          onClick={onClose}
          data-testid="directdebit-guarantee-close-button"
        >
          Close
        </Button>
      }
    >
      <Box marginBottom={1}>
        <DirectDebitLogo />
      </Box>
      <Box>
        <Box marginBottom={1}>
          <Heading data-testid="directdebit-guarantee-heading">
            Direct Debit Guarantee
          </Heading>
        </Box>
        <ul>
          <li>
            This Guarantee is offered by all banks and building societies that accept
            instructions to pay Direct Debits.
          </li>
          <li>
            If there are any changes to the amount, date or frequency of your Direct Debit
            Likely Loans will notify you 10 working days in advance of your account being
            debited or as otherwise agreed. If you request Likely Loans to collect a
            payment, confirmation of the amount and date will be given to you at the time
            of the request.
          </li>
          <li>
            If an error is made in the payment of your Direct Debit, by Likely Loans or
            your bank or building society, you are entitled to a full and immediate refund
            of the amount paid from your bank or building society. - If you receive a
            refund you are not entitled to, you must pay it back when Likely Loans asks
            you to.
          </li>
          <li>
            You can cancel a Direct Debit at any time by simply contacting your bank or
            building society. Written confirmation may be required. Please also notify us.
          </li>
        </ul>
      </Box>
    </Modal>
  );
}
