import { Alert, AlertType } from '@sequensis/stylish-core';

type Status = 'success' | 'error';

interface EmailAddressFeedbackAlertProps {
  status: Status;
  label: string;
}

const mapEmailAlertMessage = (label: string, status: Status) => {
  const statusToAlertMessageMap = new Map<Status, string>([
    ['success', `Your ${label.toLowerCase()} has been updated.`],
    [
      'error',
      `Something went wrong while updating your ${label.toLowerCase()}, please try again.`,
    ],
  ]);

  const message =
    statusToAlertMessageMap.get(status) || 'Something went wrong, please try again.';

  const alertType: AlertType = status === 'success' ? 'positive' : 'warning';

  return { message, alertType };
};

export const EmailAddressFeedbackAlert = ({
  status,
  label,
}: EmailAddressFeedbackAlertProps) => {
  const { message, alertType } = mapEmailAlertMessage(label, status);

  return (
    <Alert icon marginBottom={4} variant={alertType}>
      {message}
    </Alert>
  );
};
