import { useSelector } from 'react-redux';

import { Button, Card, Flex, Text } from '@sequensis/stylish-core';

import { analyticsClickEvent } from 'src/components/Analytics';
import { getAccountStartDate, getLoanAmount } from 'src/store/Account/selectors';
import { formatNumericDate } from 'src/utils/formatDate';
import { formatCurrency } from 'src/utils/formatNumber';

interface PreviousLoanProps {
  onClick(): void;
  className?: string;
}

export const PreviousLoan = ({ onClick, className }: PreviousLoanProps) => {
  const previousLoanAmount = useSelector(getLoanAmount);
  const previousLoanStartDate = useSelector(getAccountStartDate);

  return (
    <Card heading="Your previous loan" className={className}>
      <Flex alignItems="center" justifyContent="space-between">
        <Text textAlign="left" data-testid={`previous-loan-label`}>
          Loan amount
          <Text data-testid="previous-loan-start-date" fontSize="small">
            <br />
            Start date: {formatNumericDate(previousLoanStartDate)}
          </Text>
        </Text>
        <Text fontWeight="bold" data-testid="previous-loan-amount" fontSize="large">
          {formatCurrency(previousLoanAmount)}
        </Text>
      </Flex>
      <Button
        variant="secondary"
        fullWidth={true}
        data-testid="previous-loan-details"
        marginTop={3}
        size="slim"
        onClick={() => {
          onClick();
          analyticsClickEvent('AccountSummary', 'previous-loan-details-button');
        }}
      >
        View my details
      </Button>
    </Card>
  );
};
