import { Controller, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers';
import {
  Button,
  Card,
  Paragraph,
  RadioGroup,
  RadioOption,
  Spaced,
} from '@sequensis/stylish-core';
import { range } from 'ramda';
import * as yup from 'yup';

import { ProgressHeader } from 'src/components/ProgressHeader';
import { useRaiseSubmissionError } from 'src/hooks/useRaiseSubmissionError';
import { SubmissionAction } from 'src/screens/TopUp/types';

interface RepaymentDayForm {
  repaymentDay: number;
}

const repaymentDayFormSchema: yup.SchemaOf<RepaymentDayForm> = yup.object().shape({
  repaymentDay: yup.number().required('Repayment day is required'),
});

interface RepaymentDayProps {
  progress: number;
  onRepaymentDayClick: SubmissionAction<RepaymentDayForm>;
}

export const RepaymentDay = ({
  progress,
  onRepaymentDayClick: { error, isLoading, onAction: onRepaymentDaySubmit },
}: RepaymentDayProps) => {
  const { control, handleSubmit, errors } = useForm<RepaymentDayForm>({
    mode: 'onBlur',
    resolver: yupResolver(repaymentDayFormSchema),
  });

  useRaiseSubmissionError(error);

  return (
    <Spaced marginBottom={4} excludeLast>
      <ProgressHeader
        currentStep="Set up your Direct Debit"
        progress={progress}
        stepText="Step 3/3"
      />
      <Card heading="Choose repayment day">
        <Paragraph fontSize="lg" marginTop={3}>
          We suggest choosing a day soon after you get paid.
        </Paragraph>
      </Card>
      <Card heading="Pick your preferred date">
        <form
          onSubmit={handleSubmit((data: RepaymentDayForm) => onRepaymentDaySubmit(data))}
        >
          <Controller
            render={({ value, onChange }) => (
              <RadioGroup
                name="repaymentDay"
                selected={value}
                variant="circle"
                onChange={onChange}
                error={errors.repaymentDay?.message}
              >
                {range(1, 29).map((day) => (
                  <RadioOption
                    data-testid={`repayment-day-option-${day}`}
                    key={day}
                    value={day}
                  >
                    {day.toString()}
                  </RadioOption>
                ))}
              </RadioGroup>
            )}
            name="repaymentDay"
            control={control}
            defaultValue={undefined}
          />
          <Button
            data-testid="repayment-day-submission-button"
            fullWidth
            isLoading={isLoading}
            marginTop={3}
          >
            Continue
          </Button>
        </form>
      </Card>
    </Spaced>
  );
};

export const RepaymentDayStep = {
  path: 'choose-repayment-day',
  component: RepaymentDay,
  provides: ['repaymentDay'],
};
