import { ApplicationState } from 'src/store';
import { Product } from 'src/types/ConfigProps';
import { sort, descend } from 'ramda';

export const getCustomerFetched = (applicationState: ApplicationState) =>
  Boolean(applicationState.customerState.customer);

export const getCustomerFetchError = (applicationState: ApplicationState) =>
  applicationState.customerState.fetchError;

export const getFirstName = ({ customerState }: ApplicationState) =>
  customerState.customer?.firstName;

export const getLastName = ({ customerState }: ApplicationState) =>
  customerState.customer?.lastName;

export const getFullName = (state: ApplicationState) => {
  const firstName = getFirstName(state) ?? '';
  const lastName = getLastName(state) ?? '';

  return `${firstName} ${lastName}`.trim();
};

export const getEmailAddress = ({ customerState }: ApplicationState) =>
  customerState.customer?.emailAddress;

export const getEmailAddressForVerification = ({ customerState }: ApplicationState) =>
  customerState.customer?.emailForVerification;

export const getBankAccounts = ({ customerState }: ApplicationState) =>
  customerState.customer?.bankDetails;

export const getCurrentBankAccount = ({ customerState }: ApplicationState) =>
  customerState.customer && sort(descend(bankDetails => bankDetails.createdAt), customerState.customer.bankDetails)[0];

//TODO: Remove this when we fix CMS showing int prefix
export const getMobileNumber = ({ customerState }: ApplicationState) =>
  customerState.customer?.telephoneNumbers
    .find((t) => t.type === 'Mobile')
    ?.number.replace(/^\+44/, '0');

export const getHomePhoneNumber = ({ customerState }: ApplicationState) =>
  customerState.customer?.telephoneNumbers
    .find((t) => t.type === 'Home')
    ?.number.replace(/^\+44/, '0');

export const getWorkPhoneNumber = ({ customerState }: ApplicationState) =>
  customerState.customer?.telephoneNumbers
    .find((t) => t.type === 'Work')
    ?.number.replace(/^\+44/, '0');

export const getCurrentAddress = ({ customerState }: ApplicationState) =>
  customerState.customer?.address.current;

export const getAccounts = ({ accountsState }: ApplicationState) =>
  accountsState.accounts;

export const getAccountForProduct =
  (product: Product) =>
  ({ accountsState }: ApplicationState) => {
    const accounts = Object.values(accountsState.accounts ?? {});

    return accounts.find((account) =>
      product.productIds.includes(account.product.productId),
    );
  };

export const getCommunicationPreferences = ({ customerState }: ApplicationState) =>
  customerState.customer?.communicationPreferences;

export const getMarketingPreferencesOptedIn = ({ customerState }: ApplicationState) => {
  const marketingPrefs = customerState.customer?.communicationPreferences?.marketing;

  if (marketingPrefs === undefined) {
    return undefined;
  }

  const hasDigitalMarketing = marketingPrefs.some(
    (preference) => preference === 'email' || preference === 'sms',
  );

  return hasDigitalMarketing;
};
