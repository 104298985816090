import { Provider } from '@mistho/react/lib/browser';

export const defaultroute = '/verify-income/';

const createProviderDataObj = (
  question: string,
  info: string,
  buttonText = 'Proceed',
  providerData: Provider | null = null,
) => ({ question, info, buttonText, providerData });

export const providerCardData = {
  hmrcincomeverification: createProviderDataObj(
    '95% of customers choose to validate their income using HMRC (Government Gateway)',
    'Share your data straight from HMRC via our partner',
  ),
  universalcredit: createProviderDataObj(
    "Do you receive benefits but are also employed/self-employed? By using your Universal Credit income verification we'll be able to see a full view of both of your incomes.",
    'Share data via our partner',
  ),
  hmrcselfemployed: createProviderDataObj(
    'Self-employed, do you have access to your Government Gateway ID (HMRC)?',
    'Share data via our HMRC recognized partner',
  ),
  default: createProviderDataObj(
    'Do you have access to your payroll/employer portal?',
    'Share data via our partner',
  ),
};

export const consentData = [
  {
    type: 'consent',
    category: 'Identity Data',
    data: ['First name', 'Last name', 'Address', 'Tax Code', 'National Insurance No.'],
  },
  {
    type: 'consent',
    category: 'Pay Data',
    data: [
      'Salary/income or Benefits',
      'Tax contributions',
      'Employment status',
      'Employer name and address',
    ],
  },
  {
    type: 'info',
    category: 'Permission Based',
    data: ['We only share your data with your explicit consent.'],
  },
  {
    type: 'info',
    category: 'Trusted & Secure',
    data: [
      "Your information is end-to-end encrypted. We don't store or share your credentials.",
    ],
  },
  {
    type: 'info',
    category: 'GDPR compliant',
    data: [
      'We adhere to the highest regulatory standards to keep your employment data safe.',
    ],
  },
];
