import { useConfig } from '@sequensis/react-config';

import { useProduct } from './useProduct';

export function useProductSupportUrl() {
  const { supportHelpdesk } = useConfig();

  const product = useProduct({ shouldThrow: false });

  const baseUrl = product?.supportHelpdesk || supportHelpdesk;

  return {
    productSupportUrl: `${baseUrl}/requests/new`,
  };
}
