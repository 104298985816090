export const oakbrookOverride = {
  components: {
    // Corrections & Tweaks
    collapse: {
      baseStyle: {
        labelSection: {
          color: 'base',
        },
        collapsableSection: {
          color: 'base',
        },
      },
    },
    anchor: {
      baseStyle: {
        cursor: 'pointer',
      },
    },
    paragraph: {
      variants: {
        emphasis: {
          fontWeight: 'bold',
        },
      },
    },
    text: {
      variants: {
        emphasis: {
          fontWeight: 'bold',
        },
      },
    },
    modal: {
      baseStyle: {
        container: {
          contain: 'paint',
        },
      },
    },
    card: {
      baseStyle: {
        heading: {
          fontSize: 'xl',
          lineHeight: 'xl',
        },
      },
    },
    explainer: {
      baseStyle: {
        bubble: {
          borderColor: 'primaryTint',
          backgroundColor: 'primaryBg',
          borderRadius: 1,
        },
        bubbleTag: {
          backgroundColor: 'primaryBg',
        },
      },
    },
    radioButton: {
      baseStyle: {
        optionLabel: {
          flexShrink: 1,
        },
        error: {
          color: 'negative',
        },
      },
    },
    // Custom components
    background: {
      baseStyle: {
        backgroundColor: 'oakbrookNavy',
      },
    },
    poweredByOakbrook: {
      config: {
        color: 'base',
      },
    },
  },
};
