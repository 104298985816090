import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';

import { isDevelopment } from 'src/utils/environment';

import { AccountState } from './Account/reducer';
import accountSaga from './Account/sagas';
import { CustomerState } from './Customer/reducer';
import customerSaga from './Customer/sagas';
import { reducer as AccountReducer } from './Account';
import { AccountsReducer, AccountsState } from './Accounts';
import { reducer as CustomerReducer } from './Customer';

export interface ApplicationState {
  accountState: AccountState;
  accountsState: AccountsState;
  customerState: CustomerState;
}

const rootReducer = () =>
  combineReducers({
    accountState: AccountReducer,
    accountsState: AccountsReducer,
    customerState: CustomerReducer,
  });

export function configureStore(initialState: ApplicationState) {
  const enhancers = [];

  const reduxDevToolsExtension =
    //eslint-disable-next-line
    typeof window && (window as any)['__REDUX_DEVTOOLS_EXTENSION__'];

  if (isDevelopment && reduxDevToolsExtension) {
    enhancers.push(reduxDevToolsExtension());
  }

  const sagaMiddleware = createSagaMiddleware();
  const middleware = [sagaMiddleware];

  const store = createStore(
    rootReducer(),
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers),
  );

  function* rootSaga() {
    yield all([fork(accountSaga)]);
    yield all([fork(customerSaga)]);
  }

  // run saga middleware before returning store
  sagaMiddleware.run(rootSaga);

  return store;
}
