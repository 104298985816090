import { get, post } from '@sequensis/fetch';
import formatISO from 'date-fns/formatISO';
import { TrackJS } from 'trackjs';
import urljoin from 'url-join';

import { getAmsHeaderBuilder } from 'src/utils/api/headers';
import queryParams from 'src/utils/api/queryParams';

import {
  AccountsResponse,
  ActivateDirectDebitRequest,
  AllAccountApiResponse,
  DocumentsResponse,
  FetchAccountRequest,
  PaymentSchedule,
  PaymentScheduleChangeRequest,
  TransactionsResponse,
} from './types';

const getHeaders = (authToken: string) => {
  return getAmsHeaderBuilder().withVersion(2).withBearerToken(authToken).build();
};

export const fetchAccount = async ({
  apiUrl,
  accountId,
  chaos,
  authToken,
}: FetchAccountRequest): Promise<AllAccountApiResponse> => {
  if (chaos && Math.random() < chaos) {
    console.warn('engineered chaos');
    return Promise.reject('Engineered Chaos');
  }

  const { responseJson: account } = await get<AllAccountApiResponse>(
    `${apiUrl}/accounts/${accountId}`,
    getHeaders(authToken),
  );

  if (!account) {
    TrackJS.console.error('The account is missing.');
    throw new Error('The account is missing.');
  }
  return {
    id: accountId,
    reference: account.reference,
    activatedAt: account.activatedAt,
    status: account.status,
    isInPaymentPlan: account.isInPaymentPlan,
    accountDetails: {
      loanAmount: account.accountDetails.loanAmount,
      annualPercentageRate: account.accountDetails.annualPercentageRate,
      creditLimit: account.accountDetails.creditLimit,
      paymentMethod: account.accountDetails.paymentMethod,
      repaymentFrequency: {
        dayOfMonth: account.accountDetails.repaymentFrequency?.dayOfMonth,
      },
      regularRepaymentAmount: account.accountDetails.regularRepaymentAmount,
      repaymentMandateSource: account.accountDetails.repaymentMandateSource,
      repaymentMandateDetails: account.accountDetails.repaymentMandateDetails
        ? {
            accountNumber: account.accountDetails.repaymentMandateDetails?.accountNumber,
            repaymentMandateStatus:
              account.accountDetails.repaymentMandateDetails?.repaymentMandateStatus,
          }
        : undefined,
    },
    featureFlags: {
      canHaveRepaymentBreaks: account.featureFlags.canHaveRepaymentBreaks,
    },
    product: {
      productId: account.product.productId,
    },
    funder: account.funder,
    balance: {
      currentBalance: account.balance.currentBalance,
      startBalance: account.balance.startBalance,
      amountPaid: account.balance.amountPaid,
      total: account.balance.total,
      availableAmount: account.balance.availableAmount,
      principal: account.balance.principal,
      accruedInterest: account.balance.accruedInterest,
      appliedInterest: account.balance.appliedInterest,
      arrears: account.balance.arrears,
    },
    numberOfPayments: account.numberOfPayments,
    paymentSchedulePosition: {
      nextPaymentDueAmount: account.paymentSchedulePosition.nextPaymentDueAmount,
      nextPaymentDueDate: account.paymentSchedulePosition.nextPaymentDueDate,
      numberOfPaymentsRemaining:
        account.paymentSchedulePosition.numberOfPaymentsRemaining,
    },
    activation: {
      failures: account.activation?.failures,
    },
    repaymentBreaks: account.repaymentBreaks,
    isCAIS: account?.isCAIS,
    hasNoticeOfDefault: account?.hasNoticeOfDefault,
  };
};

const getAccountUrl = (amsBaseUrl: string, accountId: string) =>
  urljoin(amsBaseUrl, 'accounts', accountId);

const getAccountDisbursalUrl = (amsBaseUrl: string, disbursalId: string) =>
  urljoin(amsBaseUrl, 'disbursals', disbursalId);

export interface CreateDisbursalRequest {
  amount: string;
}

// TODO: Add bearer tokens when picking up rev cred https://oakbrook.atlassian.net/browse/DS-406
export const createDisbursalRequest = (
  amsBaseUrl: string,
  accountId: string,
  body: CreateDisbursalRequest,
) => post(urljoin(getAccountUrl(amsBaseUrl, accountId), 'disbursal'), body);

/** Duct tape and gutter snakes
 * We should not be calling this, it should be private.
 * We are utilising this for now until AMS and disbursal connections are working
 */
// TODO: Add bearer tokens when picking up rev cred https://oakbrook.atlassian.net/browse/DS-406
export const confirmDisbursalRequest = (amsBaseUrl: string, disbursalId: string) =>
  post(`${getAccountDisbursalUrl(amsBaseUrl, disbursalId)}:confirm`);

export interface RegisterPaymentRequest {
  amount: string;
  reference: string;
  source: string;
  valueDate: string;
}

export const registerPaymentRequest = (
  amsBaseUrl: string,
  accountId: string,
  body: RegisterPaymentRequest,
) => post(`${getAccountUrl(amsBaseUrl, accountId)}:payment`, body);

interface GetTransactionsParams {
  startDate: Date;
  endDate: Date;
}

// TODO: Add bearer tokens when picking up rev cred https://oakbrook.atlassian.net/browse/DS-406
export const getTransactions = (
  amsBaseUrl: string,
  accountId: string,
  authToken: string,
  params?: GetTransactionsParams,
) =>
  get<TransactionsResponse>(
    urljoin(
      getAccountUrl(amsBaseUrl, accountId),
      'transactions',
      params !== undefined
        ? queryParams({
            startDate: formatISO(params.startDate),
            endDate: formatISO(params.endDate),
          })
        : '',
    ),
    getHeaders(authToken),
  );

export const getMissedPayments = (
  amsBaseUrl: string,
  accountId: string,
  authToken: string,
) =>
  get<TransactionsResponse>(
    urljoin(getAccountUrl(amsBaseUrl, accountId), 'missedpayments'),
    getHeaders(authToken),
  );

export const getPaymentSchedule = (
  amsBaseUrl: string,
  accountId: string,
  authToken: string,
) =>
  get<PaymentSchedule>(
    urljoin(getAccountUrl(amsBaseUrl, accountId), 'schedule'),
    getHeaders(authToken),
  );

export const previewPaymentScheduleChange = (
  amsBaseUrl: string,
  accountId: string,
  authToken: string,
  body: PaymentScheduleChangeRequest,
) =>
  post<PaymentScheduleChangeRequest, PaymentSchedule>(
    urljoin(getAccountUrl(amsBaseUrl, accountId), 'schedule:preview'),
    body,
    getHeaders(authToken),
  );

export const submitPaymentScheduleChange = (
  amsBaseUrl: string,
  accountId: string,
  authToken: string,
  body: PaymentScheduleChangeRequest,
) =>
  post<PaymentScheduleChangeRequest, void>(
    urljoin(getAccountUrl(amsBaseUrl, accountId), 'schedule'),
    body,
    getHeaders(authToken),
  );

export const activateDirectDebit = (
  amsBaseUrl: string,
  accountId: string,
  authToken: string,
  body: ActivateDirectDebitRequest,
) =>
  post<ActivateDirectDebitRequest, void>(
    urljoin(getAccountUrl(amsBaseUrl, accountId), 'directdebit:activate'),
    body,
    getHeaders(authToken),
  );

export const getAccounts = (amsBaseUrl: string, customerId: string, authtoken: string) =>
  get<AccountsResponse>(
    `${amsBaseUrl}/accounts?customerId=${customerId}`,
    getHeaders(authtoken),
  );

export const getDocuments = async (
  apiUrl: string,
  accountId: string,
  authtoken: string,
) =>
  get<DocumentsResponse>(
    `${apiUrl}/accounts/${accountId}/documents/`,
    getHeaders(authtoken),
  );

export const getDocument = async (url: string, authtoken: string) =>
  fetch(url, {
    method: 'get',
    headers: {
      Accept: 'application/octet-stream',
      ...getHeaders(authtoken),
      'Content-Type': 'application/octet-stream',
    },
  });
