import { lazy, useMemo } from 'react';

import { useSelector } from 'react-redux';
import {
  Navigate,
  Route,
  Routes,
  useHref,
  useLocation,
} from 'react-router-dom-v5-compat';

import { useFeatureFlag } from '@sequensis/react-config';
import { Location } from 'history';

import { useEligibility } from 'src/hooks/useEligibility';
import { useProduct } from 'src/hooks/useProduct';
import Arrears from 'src/screens/Arrears';
import Balance from 'src/screens/Balance';
import { BalanceDetails } from 'src/screens/BalanceDetail';
import { PageNotFound } from 'src/screens/Error';
import Loading from 'src/screens/Loading';
import PaymentFailure from 'src/screens/MakePayment/PaymentFailure';
import PaymentSuccess from 'src/screens/MakePayment/PaymentSuccess';
import { Payments } from 'src/screens/Payments';
import { Transactions } from 'src/screens/Transactions';
import { getAllAccounts } from 'src/store/Accounts';

import { ActiveAccountGuard } from './ActiveAccountGuard';
import { LazyRouteLayout } from './LazyRouteLayout';

const MakePayment = lazy(
  () => import(/* webpackChunkName: "make-payment" */ 'src/screens/MakePayment'),
);

const TopUp = lazy(() =>
  import(/* webpackChunkName: "top-up" */ 'src/screens/TopUp/TopUp').then((module) => ({
    default: module.TopUpRouter,
  })),
);

const Settlement = lazy(() =>
  import(/* webpackChunkName: "settlement" */ 'src/screens/Settlement').then(
    (module) => ({ default: module.Settlement }),
  ),
);

//These are here for when PaymentPlans have upgraded to router V6
const PaymentPlan = lazy(() =>
  import(/* webpackChunkName: "payment-plan" */ 'src/screens/PaymentPlan').then(
    (module) => ({ default: module.PaymentPlan }),
  ),
);

export const ProductRoutes = () => {
  const location = useLocation();
  const state = location.state as { background?: Location };

  const currentProduct = useProduct();

  const allAccounts = useSelector(getAllAccounts);

  const topUpEligibilityEnabled = useFeatureFlag('topUpEligibilityEnabled');
  const eligibilities = useEligibility(topUpEligibilityEnabled);

  const balanceHref = useHref('balance?accountRefresh');

  // Ensure that the user has an Account or Top Up Eligibility which
  // matches the product in the route.
  const hasLoadedProduct = useMemo(() => {
    if (!currentProduct) {
      return false;
    }

    const hasAccountWithProduct = Object.values(allAccounts || {}).some((account) => {
      return currentProduct.productIds.includes(account.product.productId);
    });

    const hasEligibilityWithProduct = eligibilities.eligibleProducts?.some(
      (product) => product.brand === currentProduct.topUpBrandRequirement,
    );

    return hasAccountWithProduct || hasEligibilityWithProduct;
  }, [currentProduct, allAccounts, eligibilities]);

  if (!currentProduct) {
    return <PageNotFound />;
  }

  if (!hasLoadedProduct) {
    return <Loading />;
  }

  return (
    <>
      <Routes location={state?.background ?? location}>
        <Route element={<LazyRouteLayout />}>
          <Route index element={<Navigate to="balance" replace />} />

          <Route element={<ActiveAccountGuard />}>
            <Route path="balance" element={<Balance />} />
            <Route path="balance/details" element={<BalanceDetails />} />
            <Route
              path="settlement/*"
              element={<Settlement accountRedirectUrl={balanceHref} />}
            />

            <Route
              path="payment-plan/*"
              element={<PaymentPlan accountRedirectUrl={balanceHref} />}
            />

            <Route
              path="make-payment/*"
              element={<MakePayment accountRedirectUrl={balanceHref} />}
            />
            <Route path="payment-success" element={<PaymentSuccess />} />
            <Route path="payment-failure" element={<PaymentFailure />} />
            <Route path="transactions" element={<Transactions />} />

            {topUpEligibilityEnabled ? (
              <Route path="top-up/:accountId/*" element={<TopUp />} />
            ) : null}

            <Route path="payments/*" element={<Payments />} />

            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Route>
      </Routes>

      {state?.background && (
        <Routes>
          <Route path="arrears" element={<Arrears />} />
        </Routes>
      )}
    </>
  );
};
