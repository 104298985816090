import { Flex, List, ListItem, Text } from '@sequensis/stylish-core';

import { MissedTransaction } from 'src/api/account/types';
import { formatCondensedDate } from 'src/utils/formatDate';
import { formatCurrency } from 'src/utils/formatNumber';

interface MissedPaymentsTableProps {
  missedPayments: MissedTransaction[];
}

export function MissedPaymentsTable({ missedPayments }: MissedPaymentsTableProps) {
  return (
    <div data-testid="arrears-missed-payments-table">
      <List variant="block">
        {missedPayments.map((missedPayment) => (
          <ListItem key={missedPayment.id}>
            <Flex flexShrink={0}>
              <Text size="md">{formatCondensedDate(missedPayment.valueDate)}</Text>
            </Flex>
            <Flex justifyContent="end" width="100%">
              <Text size="md" variant="emphasis">
                {formatCurrency(missedPayment.amount)}
              </Text>
            </Flex>
          </ListItem>
        ))}
      </List>
    </div>
  );
}
