import { useAuth } from '@sequensis/react-auth';
import { useConfig } from '@sequensis/react-config';
import { useAsyncCallback, useMount } from '@sequensis/react-hooks';

import { getEligibileProduct } from '../api/eligibility/eligibilityApi';

export const useEligibility = (enabled: boolean) => {
  const { getToken, customerId = '' } = useAuth();
  const {
    eligibilityServiceApi: { url: eligibleProductApiUrl },
  } = useConfig();

  const [eligibilityState, executeGetEligibility] = useAsyncCallback(getEligibileProduct);

  useMount(async () => {
    if (!enabled) return;

    const token = await getToken();

    executeGetEligibility(eligibleProductApiUrl, customerId, token);
  });

  return { eligibleProducts: eligibilityState.result?.eligibleProducts };
};
