import { useEffect, useRef, useState } from 'react';

import { useHistory, useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom-v5-compat';

import { GetApplicationByCustomerIdResponse } from '@sequensis/creditapplication-reactsdk';
import { Box, Card, Flex, Spaced } from '@sequensis/stylish-core';
import { ThemeProvider } from 'styled-components';

import { DynamicLogo } from 'src/components/Logos/DynamicLogo';
import { useGetProductConfig } from 'src/hooks/useGetProductConfig';
import { getProductTheme } from 'src/utils/productHelper';

import Loading from '../Loading';

import { MisthoController } from './MisthoController';

export const Mistho = () => {
  const location = useLocation();
  const history = useHistory();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const applicationDetails = useRef(location.state as GetApplicationByCustomerIdResponse);

  useEffect(() => {
    if (!location.state) navigate('/', { replace: true });

    history.replace({ state: null });

    setLoading(false);
  }, [applicationDetails]);

  const brand = applicationDetails?.current?.selectedOffer?.brand
    ?.toString()
    .replace(/ /g, '');

  const productConfig = useGetProductConfig(brand || '');
  if (productConfig === undefined) {
    console.warn(
      `Failed to find a product configuration for productId "${brand}" in ApplicationUnderReviewCardController`,
    );
    return null;
  }

  const productTheme = getProductTheme(productConfig.baseRoute);

  if (loading) return <Loading />;

  return (
    <ThemeProvider theme={productTheme}>
      <Spaced marginBottom={4} excludeLast>
        <Card variant="branded" data-testid="topup-get-started-card">
          <Spaced marginBottom={3} excludeLast>
            <Flex justifyContent="space-between" alignItems="center" mb={3}>
              <Box width={6}>
                <DynamicLogo isGreyscale={false} productName={productConfig.baseRoute} />
              </Box>
            </Flex>
            <MisthoController applicationDetails={applicationDetails.current} />
          </Spaced>
        </Card>
      </Spaced>
    </ThemeProvider>
  );
};
