import { useCallback } from 'react';

import { useSelector } from 'react-redux';

import { useRefreshCustomer } from 'src/hooks/useRefreshCustomer';
import {
  getHomePhoneNumber,
  getMobileNumber,
  getWorkPhoneNumber,
} from 'src/store/Customer/selectors';
import { TelephoneNumberTypes } from 'src/types/CustomerProps';

import { TelephoneNumberField } from './TelephoneNumberField';
import {
  mobilePhoneNumberSchema,
  optionalPhoneNumberSchema,
} from './TelephoneNumberSchema';

export const TelephoneNumbers = () => {
  const mobilePhoneNumber = useSelector(getMobileNumber);
  const homePhoneNumber = useSelector(getHomePhoneNumber);
  const workPhoneNumber = useSelector(getWorkPhoneNumber);
  const { refreshCustomer } = useRefreshCustomer();

  const handleTelephoneNumberCompletion = useCallback(
    (success: boolean) => {
      if (success) {
        refreshCustomer();
      }
    },
    [refreshCustomer],
  );

  return (
    <>
      <TelephoneNumberField
        telephoneNumber={mobilePhoneNumber ? mobilePhoneNumber : ''}
        testId="mobile-number-field"
        label="Mobile phone"
        ariaLabel="Mobile phone number"
        name="mobilePhoneNumber"
        defaultValue={mobilePhoneNumber}
        telephoneNumberSchema={mobilePhoneNumberSchema}
        onNumberChangeCompletion={handleTelephoneNumberCompletion}
        telephoneNumberType={TelephoneNumberTypes.Mobile}
      />

      <TelephoneNumberField
        telephoneNumber={homePhoneNumber ? homePhoneNumber : ''}
        testId="home-number-field"
        label="Home phone"
        ariaLabel="Home phone number"
        name="homePhoneNumber"
        defaultValue={mobilePhoneNumber}
        telephoneNumberSchema={optionalPhoneNumberSchema}
        onNumberChangeCompletion={handleTelephoneNumberCompletion}
        telephoneNumberType={TelephoneNumberTypes.Home}
      />

      <TelephoneNumberField
        telephoneNumber={workPhoneNumber ? workPhoneNumber : ''}
        testId="work-number-field"
        label="Work phone"
        ariaLabel="Work phone number"
        name="workPhoneNumber"
        telephoneNumberSchema={optionalPhoneNumberSchema}
        onNumberChangeCompletion={handleTelephoneNumberCompletion}
        telephoneNumberType={TelephoneNumberTypes.Work}
      />
    </>
  );
};
