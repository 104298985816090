import { useState } from 'react';

import { faCalendarAlt, faClock } from '@fortawesome/pro-regular-svg-icons';
import {
  faArrowRight,
  faChevronDown,
  faChevronUp,
  faClock as faClockSolid,
  faExclamationCircle,
} from '@fortawesome/pro-solid-svg-icons';
import {
  Alert,
  Button,
  Card,
  Collapse,
  Flex,
  getColor,
  Heading,
  Icon,
  Spaced,
  Text,
} from '@sequensis/stylish-core';
import { PageHeader } from '@sequensis/stylish-layouts';
import styled from 'styled-components';

import { Transaction } from 'src/api/account/types';
import { formatCurrency, formatShortDate } from 'src/utils';

import Loading from '../Loading';

import { TransactionsDayProps, TransactionsMonthProps } from './getTransactions';
import { useGetGroupedTransactions } from '.';

export interface TransactionItemProps {
  item: Transaction;
}

const LineBreak = styled.hr`
  border-top: 1px solid ${getColor('oakbrookNavy')};
`;

export const Transactions = () => {
  const initialMonthCount = 12;
  const transactionGroupState = useGetGroupedTransactions(6);

  const [showAll, setShowAll] = useState(false);
  const onViewAllClick = () => setShowAll(true);

  return (
    <>
      <PageHeader title="Transaction History" />

      <Alert marginBottom={4}>
        <Icon size="1x" icon={faClock} color="primary" marginRight={2} />
        <Text>
          If you have just made a payment please check back in 12 hours to see it appear
          in your history
        </Text>
      </Alert>

      <Card>
        {transactionGroupState.isLoading && <Loading message="Transactions Loading" />}

        {transactionGroupState.error !== null && (
          <Heading marginBottom={5} as="h3">
            No Transactions found.
          </Heading>
        )}

        {transactionGroupState.error === null && !transactionGroupState.isLoading && (
          <Spaced marginY={4}>
            {(showAll
              ? transactionGroupState.data
              : transactionGroupState.data?.slice(0, initialMonthCount)
            )?.map((transactionGroup) => (
              <TransactionsCollapseGroup
                key={transactionGroup.title}
                title={transactionGroup.title}
                isOpen={transactionGroup.isOpen}
                daysItems={transactionGroup.daysItems}
                isPending={transactionGroup.isPending}
              />
            ))}
            {!showAll && (
              <Button
                variant="secondary"
                onClick={onViewAllClick}
                aria-label="view all"
                fullWidth={true}
              >
                View Full History
              </Button>
            )}
          </Spaced>
        )}
      </Card>
    </>
  );
};

const TransactionsCollapseGroup = ({
  title,
  isOpen,
  isPending,
  daysItems,
}: TransactionsMonthProps) => {
  return (
    <>
      <Spaced marginY={3}>
        <Collapse
          label={
            <Text color="base" fontSize="medium" fontWeight="bold">
              <Icon
                icon={isPending ? faClockSolid : faCalendarAlt}
                marginRight={2}
                maxHeight={16}
              />
              {title}
            </Text>
          }
          open={isOpen}
          indicator={(collapseOpen) => (
            <Icon
              color="base"
              size="sm"
              maxHeight={16}
              icon={collapseOpen ? faChevronUp : faChevronDown}
            />
          )}
        >
          {isPending ? (
            <Alert>
              <TransactionsGroup title={title} daysItems={daysItems} />
            </Alert>
          ) : (
            <Spaced my={3}>
              <TransactionsGroup title={title} daysItems={daysItems} />
            </Spaced>
          )}
        </Collapse>
      </Spaced>
      <LineBreak />
    </>
  );
};

const TransactionsGroup = ({ daysItems }: TransactionsMonthProps) => {
  const hasItems = daysItems.length > 0;

  return (
    <>
      {hasItems ? (
        daysItems.map((daysItem) => (
          <TransactionDay
            key={daysItem.date}
            date={daysItem.date}
            items={daysItem.items}
          />
        ))
      ) : (
        <Text>No transactions for this month</Text>
      )}
    </>
  );
};

const TransactionDay = ({ date, items }: TransactionsDayProps) => {
  return (
    <div data-testid={`${date}-container`}>
      <Text fontWeight="bold" data-testid={`${date}-date`}>
        {formatShortDate(date)}
      </Text>
      {items.map((item, index) => (
        <TransactionItem key={index} item={item} />
      ))}
    </div>
  );
};

const TransactionItem = ({ item }: TransactionItemProps) => {
  const isPending = item.status === 'Pending';
  const isDirectDebit = item.transactionType === 'Direct Debit';
  const isPayment = item.transactionType === 'Payment';
  const showIcon =
    !isPending &&
    (isPayment ||
      isDirectDebit ||
      item.transactionType === 'Interest Rebate' ||
      item.transactionType === 'Refund');

  const isFailed = item.status === 'Failed' || item.status === 'Unpaid2ndAttempt';
  const isAttempt = item.status === 'Unpaid1stAttempt';
  const showWarning = isFailed || isAttempt;
  const icon = showWarning ? faExclamationCircle : faArrowRight;
  const color = showWarning ? 'warning' : 'positive';

  const pendingMessage = 'Your payment should complete soon.';
  const attemptMessage =
    "We didn't receive your payment. We will attempt to take the payment again in a few days.";
  const failedMessage = "We didn't receive your payment.";
  const previousMessage =
    'Your balance carried over from your previous loan before you had a Top Up.';

  const message =
    item.transactionType === 'Previous Loan Balance'
      ? previousMessage
      : !isDirectDebit
      ? ''
      : isPending
      ? pendingMessage
      : isAttempt
      ? attemptMessage
      : isFailed
      ? failedMessage
      : '';

  return (
    <Flex flexDirection="column">
      <Flex>
        <Flex alignItems="center" justifyContent="space-between">
          {showIcon && <Icon size="1x" icon={icon} color={color} marginLeft={1} />}
          <Text marginLeft={1} className="transaction-type">
            {item.transactionType}
          </Text>
        </Flex>
        <Flex flex="1 1" alignItems="center" justifyContent="flex-end">
          <Text
            fontSize="large"
            fontWeight="bold"
            padding={1}
            className="transaction-amount"
          >
            {formatCurrency(item.amount, {
              signDisplay: isPayment || isDirectDebit || isPending ? 'auto' : 'always',
            })}
          </Text>
        </Flex>
      </Flex>
      {message !== '' && (
        <Text marginLeft={1} className="transaction-message">
          {message}
        </Text>
      )}
    </Flex>
  );
};
