import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { Box, Card, Text } from '@sequensis/stylish-core';
import styled from 'styled-components/macro';

import {
  getBalanceAmount,
  getnumberOfPaymentsRemaining,
} from 'src/store/Account/selectors';
import {
  monthAndYearUntilDebtFree,
  remainingMonthsUntilDebtFreeString,
} from 'src/utils/calculations';

import { PaymentGraph } from './PaymentGraph';

const UpperCaseText = styled(Text)`
  text-transform: uppercase;
`;

export type RemainingPaymentsProps = {
  className?: string;
};

const RemainingPayments = ({ className }: RemainingPaymentsProps) => {
  const numberOfPaymentsRemaining = useSelector(getnumberOfPaymentsRemaining);
  const balanceAmount = useSelector(getBalanceAmount);

  const monthsRemainingToPayString = useMemo(
    () => remainingMonthsUntilDebtFreeString(numberOfPaymentsRemaining),
    [numberOfPaymentsRemaining],
  );

  const expectedMonthAndYearUntilDebtFree = useMemo(
    () => monthAndYearUntilDebtFree(numberOfPaymentsRemaining),
    [numberOfPaymentsRemaining],
  );

  return (
    <>
      {balanceAmount > 0 && (
        <Card
          data-testid="remaining-payments-card"
          heading={
            numberOfPaymentsRemaining > 1
              ? 'Your debt free date'
              : "You'll be debt free after your next payment"
          }
          className={className}
        >
          <Text fontSize="large">Remaining payments</Text>
          <Text
            fontWeight="bold"
            fontFamily="heading"
            fontSize="large"
            marginLeft={1}
            data-testid="remaining-payments"
          >
            {monthsRemainingToPayString}
          </Text>
          {numberOfPaymentsRemaining > 1 && (
            <Box marginTop={2}>
              <UpperCaseText fontSize="small" fontWeight="bold" color="gray">
                Today
              </UpperCaseText>
              <Box>
                <PaymentGraph
                  numberOfMonths={numberOfPaymentsRemaining}
                  completionDate={expectedMonthAndYearUntilDebtFree}
                />
              </Box>
            </Box>
          )}
        </Card>
      )}
    </>
  );
};

export default RemainingPayments;
