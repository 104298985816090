import { useEffect, useState } from 'react';

import { useMount } from '@sequensis/react-hooks';
import { Button, Flex, Text, useMediaQueryDown, useToast } from '@sequensis/stylish-core';

import registerServiceWorker from 'src/registerServiceWorker';

export function ServiceWorker() {
  const [showReload, setShowReload] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(null);

  const onServiceWorkerUpdate = (registration: ServiceWorkerRegistration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  useMount(() => {
    registerServiceWorker({ onUpdate: onServiceWorkerUpdate });
  });

  const handleUpdate = () => {
    waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
    setShowReload(false);

    // Typescript marks this as deprecated, but it does have an effect in some browsers.
    // See https://developer.mozilla.org/en-US/docs/Web/API/Location/reload#parameters
    window.location.reload(true);
  };

  const { raiseToast } = useToast();
  const isMobile = useMediaQueryDown('md');

  useEffect(() => {
    if (showReload) {
      raiseToast({
        message: (
          <Flex justifyContent="space-between">
            <Text>A new version of the app is available!</Text>
            <Button size="slim" onClick={handleUpdate}>
              Update
            </Button>
          </Flex>
        ),
        width: isMobile ? '100%' : '28rem',
        duration: null,
      });
    }
    // eslint-disable-next-line
  }, [showReload]);

  return null;
}
