import { Outlet } from 'react-router-dom-v5-compat';

import { getColor } from '@sequensis/stylish-core';
import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';

const Wrapper = styled.div`
  background-color: ${getColor('background')};
  min-height: 100%;
`;

const Container = styled.div<SpaceProps>`
  width: 100%;
  max-width: 40rem;
  ${space}
`;

export const LoginLayout = () => (
  <Wrapper>
    <Container px={4} mx="auto">
      <Outlet />
    </Container>
  </Wrapper>
);
