import { FixedTermLoanOffer } from '@sequensis/creditapplication-reactsdk';

import { OfferLoanPlayback, Playback } from 'src/screens/TopUp';
import { AllAccountProps } from 'src/types/AccountProps';

export function mapOfferLoanPlayback(
  offer?: FixedTermLoanOffer,
  account?: AllAccountProps,
): Playback<OfferLoanPlayback> {
  if (offer && account && offer.apr && offer.id) {
    const currentLoan = {
      apr: account.accountDetails.annualPercentageRate,
      balance: offer.settlementAmount,
      balanceIncludingInterest: offer.settlementAmount + offer.interestSaving,
      monthlyRepayment: account.paymentSchedulePosition.nextPaymentDueAmount,
      remainingPayments: account.paymentSchedulePosition.numberOfPaymentsRemaining,
    };
    return {
      playback: {
        currentLoan,
        topUpLoan: {
          apr: offer.apr * 100,
          balance: offer.minimumAmount + currentLoan.balance,
          balanceIncludingInterest: offer.totalAmountRepayable,
          monthlyRepayment: offer.monthlyRepaymentAmount,
          remainingPayments: offer.term,
        },
      },
    };
  } else {
    return {
      playback: undefined,
      isLoading: true,
    };
  }
}
