import { GetApplicationByCustomerIdResponse } from '@sequensis/creditapplication-reactsdk';
import { useConfig } from '@sequensis/react-config';
import { Button, Card, Heading, Paragraph } from '@sequensis/stylish-core';

import { ProviderCard } from './ProviderCard';

type InformationProps = {
  applicationDetails: GetApplicationByCustomerIdResponse;
  onProceedClick: (slug: string) => void;
};

export const Information = ({ applicationDetails, onProceedClick }: InformationProps) => {
  const { mistho } = useConfig();

  const slugOptions =
    (applicationDetails as any)?.primaryBorrower?.employmentDetails?.employmentStatus ===
    'SelfEmployed'
      ? mistho.selfemployed
      : mistho.salaried;
  const preferredSlugs = slugOptions.preferred;

  const onPayrollEmployerClick = () => {
    onProceedClick('default');
  };

  return (
    <>
      <Card>
        <Heading as="h3" mb={3}>
          We need to verify your income before we can release your loan.
        </Heading>
        <Paragraph mb={3}>
          This process is simple and secure and should take no longer than 5 minutes. We
          work with our HMRC recognised provider Mistho, who use state-of-the-art security
          to ensure that your information always remains protected.
        </Paragraph>
      </Card>
      <Card>
        {preferredSlugs.map((slug) => (
          <ProviderCard key={Math.random()} slug={slug} onProceedClick={onProceedClick} />
        ))}
      </Card>
      <Button
        navigational
        variant="secondary"
        onClick={() => onPayrollEmployerClick()}
        fullWidth
        className="white-button"
      >
        Payroll/Employer Portal
      </Button>
    </>
  );
};
