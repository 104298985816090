import { deleteMethod, get, patch, post, put } from '@sequensis/fetch';
import { TrackJS } from 'trackjs';

import { TelephoneNumberTypes } from 'src/types/CustomerProps';
import {
  createCmsHeaderWithAuthBearerToken,
  getCmsHeaderBuilder,
} from 'src/utils/api/headers';

import {
  AddAddressRequest,
  BankDetails,
  CustomerApiResponse,
  FetchCustomerRequest,
  TelephoneNumberBody,
  UpdateCommunicationPreferencesRequest,
  UpdateEmailAddressRequest,
  VerifyEmailAddressRequest,
} from './types';

export const fetchCustomer = async ({
  apiUrl,
  customerId,
  authToken,
}: FetchCustomerRequest): Promise<CustomerApiResponse> => {
  const { responseJson: customer } = await get<CustomerApiResponse>(
    `${apiUrl}/customers/${customerId}`,
    createCmsHeaderWithAuthBearerToken(authToken),
  );

  // if customer response is not received, the execution will end here
  if (!customer) {
    TrackJS.console.error('The customer is missing.');
    throw new Error('The customer is missing.');
  }

  let updatedbankDetails: any;

  // get icenet udpated bank details and on successful response update the above variable with the value or else continue
  try {
    const { responseJson: icenetBankDetails } = await get<BankDetails>(
      `${apiUrl}/customers/getIcenetBankDetails/${customerId}`,
      createCmsHeaderWithAuthBearerToken(authToken),
    );
    updatedbankDetails = icenetBankDetails;
  } catch (error) {
    TrackJS.console.error('Icenet is failing to send bank details');
  }

  return {
    ...customer,
    bankDetails: updatedbankDetails ? [updatedbankDetails] : customer.bankDetails,
  };
};

export const addCustomerAddress = ({
  apiUrl,
  customerId,
  authToken,
  address,
}: AddAddressRequest) =>
  post(
    `${apiUrl}/customers/${customerId}/addresses`,
    address,
    createCmsHeaderWithAuthBearerToken(authToken),
  );

export const updateTelephoneNumber = async (
  apiUrl: string,
  authToken: string,
  customerId: string,
  type: TelephoneNumberTypes,
  body: TelephoneNumberBody,
) =>
  put(
    `${apiUrl}/customers/${customerId}/telephonenumbers/${type}`,
    body,
    createCmsHeaderWithAuthBearerToken(authToken),
  );

export const deleteTelephoneNumber = async (
  apiUrl: string,
  authToken: string,
  customerId: string,
  type: TelephoneNumberTypes,
) =>
  deleteMethod(
    `${apiUrl}/customers/${customerId}/telephonenumbers/${type}`,
    createCmsHeaderWithAuthBearerToken(authToken),
  );

export const updateEmailAddress = ({
  apiUrl,
  authToken,
  customerId,
  emailAddress,
}: UpdateEmailAddressRequest) =>
  post(
    `${apiUrl}/customers/${customerId}/emailaddresschange:request`,
    {
      emailAddress,
    },
    createCmsHeaderWithAuthBearerToken(authToken),
  );

export const verifyEmailAddress = ({
  apiUrl,
  customerId,
  verificationCode,
}: VerifyEmailAddressRequest) =>
  post(`${apiUrl}/customers/${customerId}/emailaddresschange:verify`, {
    verificationCode,
  });

export const updateCommunicationPreferences = ({
  apiUrl,
  authToken,
  customerId,
  body,
}: UpdateCommunicationPreferencesRequest) => {
  const headers = getCmsHeaderBuilder().withBearerToken(authToken).withVersion(2).build();

  return patch(
    `${apiUrl}/customers/${customerId}/communicationpreferences`,
    body,
    headers,
  );
};
