import { useProduct } from 'src/hooks/useProduct';

import { FinioLoansLogo } from './FinioLoansLogo';
import { LikelyLoansLogo } from './LikelyLoansLogo';
import { OakbrookLoansLogo } from './OakbrookLoansLogo';

export interface DynamicLogoProps {
  productName?: string;
  isGreyscale?: boolean;
  useDarkText?: boolean;
}

export function DynamicLogo({
  productName,
  isGreyscale = false,
  useDarkText = false,
}: DynamicLogoProps) {
  const product = useProduct({ shouldThrow: false });

  productName = productName || product?.baseRoute;

  switch (productName) {
    case 'likelyloans':
      return <LikelyLoansLogo isGreyscale={isGreyscale} />;

    case 'oakbrookloans':
      return <OakbrookLoansLogo isGreyscale={isGreyscale} useDarkText={useDarkText} />;

    case 'finioloans':
      return <FinioLoansLogo isGreyscale={isGreyscale} useDarkText={useDarkText} />;

    default:
      return null;
  }
}
