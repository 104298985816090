import {
  Address,
  Agreement,
  EnquiryRequest,
  Expense,
  FixedTermLoan,
  Income,
  PreviousResidency,
} from '@sequensis/creditapplication-reactsdk';

import { ConfigureOfferForm, SoftSearchForm } from 'src/screens/TopUp';
import { AllAccountProps } from 'src/types/AccountProps';
import { AddressProps, CustomerProps } from 'src/types/CustomerProps';

import { EnquiryForm } from '../types';

export function mapEnquiryFormToRequest(
  enquiry: Partial<EnquiryForm>,
  customer: CustomerProps,
  topUpBrandRequirement: string,
  currentAccount: AllAccountProps,
) {
  if (!enquiry.employmentStatus) {
    throw new Error('Employment status should be defined to create an enquiry');
  }

  const request: EnquiryRequest = {
    primaryBorrower: {
      personalDetails: {
        firstName: customer.firstName,
        lastName: customer.lastName,
        dateOfBirth: mapDate(customer.dateOfBirth),
      },
      contactDetails: {
        emailAddress: customer.emailAddress,
        mobilePhoneNumber: customer.telephoneNumbers[0].number,
      },
      employmentDetails: {
        employmentStatus: enquiry.employmentStatus,
        tenure: enquiry.employmentTenure,
      },
      residentialDetails: {
        currentResidency: {
          address: mapAddress(customer.address.current),
          movedInAt: mapDate(customer.address.current.movedInDate),
        },
        currentResidentialStatus:
          enquiry.newResidentialStatus ??
          customer.address.current.residentialStatus ??
          '',
        previousResidencies:
          customer.address.previous == undefined
            ? []
            : mapPreviousResidencies(customer.address.previous),
      },
      financialDetails: {
        income: mapIncome(enquiry),
        expenses: mapExpenses(enquiry),
        numberOfDependants: enquiry.dependants,
      },
      consents: [],
      agreements: mapAgreements(enquiry),
    },
    requirements: {
      brand: topUpBrandRequirement,
      fixedTermLoan: mapFixedLoanTerm(enquiry as ConfigureOfferForm),
    },
    additionalData: {
      topUpAccountId: currentAccount.id,
    },
  };

  return request;
}

function mapDate(date: string | Date | undefined) {
  if (typeof date == 'string') {
    return date;
  }
  if (date == undefined) {
    return '';
  }
  return new Date(date).toISOString();
}

function mapPreviousResidencies(addressProps: AddressProps[]): PreviousResidency[] {
  const addresses: PreviousResidency[] = [];

  addressProps.forEach((previousAddress) => {
    const address: PreviousResidency = {
      movedInAt: mapDate(previousAddress.movedInDate),
      movedOutAt: mapDate(previousAddress.movedOutDate),
      address: mapAddress(previousAddress),
    };
    addresses.push(address);
  });

  return addresses;
}

function mapAddress(address: AddressProps): Address {
  return {
    houseName: address.houseName,
    houseNumber: address.houseNumber,
    flat: address.flat,
    street: address.street,
    street2: address.street2,
    city: address.townOrCity,
    postCode: address.postcode,
  };
}

function mapIncome(enquiry: Partial<EnquiryForm>): Income[] {
  const income: Income[] = [];

  if (enquiry.benefitFrequency != undefined && enquiry.benefitAmount != undefined) {
    income.push({
      frequency: enquiry.benefitFrequency,
      amount: enquiry.benefitAmount,
      type: 'Benefits',
      deductionType: 'Net',
    });
  }
  if (enquiry.incomeFrequency != undefined && enquiry.incomeAmount != undefined) {
    income.push({
      frequency: enquiry.incomeFrequency,
      amount: enquiry.incomeAmount,
      type: 'Employment',
      deductionType: 'Net',
    });
  }
  if (enquiry.otherFrequency != undefined && enquiry.otherAmount != undefined) {
    income.push({
      frequency: enquiry.otherFrequency,
      amount: enquiry.otherAmount,
      type: 'Other',
      deductionType: 'Net',
    });
  }

  return income;
}

function mapExpenses(enquiry: Partial<EnquiryForm>): Expense[] {
  const expenses: Expense[] = [];

  if (enquiry.gambling != undefined) {
    expenses.push({
      frequency: 'Monthly',
      amount: enquiry.gambling,
      type: 'Gambling',
    });
  }
  if (enquiry.clothing != undefined) {
    expenses.push({
      frequency: 'Monthly',
      amount: enquiry.clothing,
      type: 'Personal',
    });
  }
  if (enquiry.housing != undefined) {
    expenses.push({
      frequency: 'Monthly',
      amount: enquiry.housing,
      type: 'Housing',
    });
  }
  if (enquiry.food != undefined) {
    expenses.push({
      frequency: 'Monthly',
      amount: enquiry.food,
      type: 'Food',
    });
  }
  if (enquiry.transport != undefined) {
    expenses.push({
      frequency: 'Monthly',
      amount: enquiry.transport,
      type: 'Travel',
    });
  }
  if (enquiry.household != undefined) {
    expenses.push({
      frequency: 'Monthly',
      amount: enquiry.household,
      type: 'Bills',
    });
  }
  if (enquiry.other != undefined) {
    expenses.push({
      frequency: 'Monthly',
      amount: enquiry.other,
      type: 'Other',
    });
  }
  if (enquiry.totalOther != undefined) {
    expenses.push({
      frequency: 'Monthly',
      amount: enquiry.totalOther,
      type: 'TotalOther',
    });
  }

  return expenses;
}

function mapFixedLoanTerm(offerConfig: ConfigureOfferForm): FixedTermLoan {
  return {
    amount: offerConfig.loanAmount,
    term: offerConfig.loanTerm,
    purpose: offerConfig.loanPurpose,
  };
}

function mapAgreements(enquiry: Partial<SoftSearchForm>): Agreement[] | undefined {
  const agreements: Agreement[] = [];

  if (enquiry.termsAndConditionsConsent) {
    agreements.push({ type: 'TermsAndConditions' });
    agreements.push({ type: 'PrivacyPolicy' });
  }

  if (enquiry.softSearchConsent) {
    agreements.push({ type: 'SoftSearchConsent' });
  }

  return agreements;
}
