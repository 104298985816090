import styled, { keyframes } from 'styled-components';

const fadeInOpacity = keyframes`
to {
  opacity: 1;
}
`;

const PaymentGraphCompletion = styled.g`
  transform: translate(-6rem, -7rem);
  opacity: 0;
  animation: ${fadeInOpacity} 0.2s ease-in-out forwards;
`;

const PaymentGraphCompletionDateContainer = styled.path`
  fill: ${({ theme }) => theme.colors.positive};
`;

const PaymentGraphCompletionLine = styled.path`
  stroke: ${({ theme }) => theme.colors.positive};
  stroke-linecap: round;
  stroke-width: 4;
`;

const PaymentGraphCompletionText = styled.text`
  fill: #ffffff;
  /* TODO: DS-105 replace with scss variable font-weight-bold */
  font-weight: 700;
`;

const PaymentGraphDot = styled.circle`
  fill: ${({ theme }) => theme.colors.positive};
  stroke: ${({ theme }) => theme.colors.positive};
  stroke-width: 4;
  opacity: 0;
  animation: ${fadeInOpacity} 0.2s ease-in-out forwards;
`;

const PaymentGraphCompletionDot = styled.circle`
  fill: #ffffff;
  stroke: ${({ theme }) => theme.colors.positive};
  stroke-width: 4;
  opacity: 0;
  animation: ${fadeInOpacity} 0.2s ease-in-out forwards;
`;

interface DotProps {
  cx?: number;
  cy?: number;
  completionDate?: string;
  value?: number;
  showStep?: boolean;
}

export const Dot = ({ cx, cy, completionDate, value, showStep }: DotProps) => {
  return value === 0 ? (
    <svg x={cx} y={cy}>
      <PaymentGraphCompletion>
        {completionDate && (
          <>
            <PaymentGraphCompletionDateContainer d="M0 0h98.5v24H0l10-11z" />
            <PaymentGraphCompletionLine d="M96.5 10.2045455v94.5909095" />
            <PaymentGraphCompletionText>
              <tspan x="22" y="18">
                {completionDate}
              </tspan>
            </PaymentGraphCompletionText>
          </>
        )}
      </PaymentGraphCompletion>
      <PaymentGraphCompletionDot r="6" />
    </svg>
  ) : (
    <>
      {showStep && (
        <svg x={cx} y={cy}>
          <PaymentGraphDot r="3" />
        </svg>
      )}
    </>
  );
};
