import { useState } from 'react';
import { useEffect } from 'react';

import { Outlet, useLocation } from 'react-router-dom-v5-compat';

import {
  Box,
  Container,
  getBreakpoint,
  getColor,
  Logo,
  oakbrookTheme,
  useMediaQueryUp,
} from '@sequensis/stylish-core';
import { PageHeader } from '@sequensis/stylish-layouts';
import styled, { ThemeProvider } from 'styled-components';
import { space, SpaceProps } from 'styled-system';

import { BrandAwareFooter } from '../Footer';
import { PoweredByOakbrook } from '../Logos';
import { MenuBar } from '../MenuBar';
import { FlyOutNavigation, Navigation } from '../Navigation';
import { HeaderNavigation } from '../Navigation/components/HeaderNavigation';

import { ShimV5Props } from './V5RouteLayoutShim';

export const AppLayout = ({ v5Shim, children }: ShimV5Props) => {
  const inlineMenu = useMediaQueryUp('lg');
  const [menuOpen, setMenuOpen] = useState(false);

  const { pathname } = useLocation();

  useEffect(() => {
    setMenuOpen(false);
    window.scrollTo({ top: 0 });
  }, [pathname]);

  // Lock body scroll when collapsible menu has been opened
  useEffect(() => {
    document.body.style.overflow = menuOpen && !inlineMenu ? 'hidden' : 'auto';
  }, [menuOpen, inlineMenu]);

  return (
    <>
      {inlineMenu ? null : (
        <>
          <ThemeProvider theme={oakbrookTheme}>
            <MenuBar onMenuToggle={() => setMenuOpen(true)} />

            <FlyOutNavigation isOpen={menuOpen} handleClose={() => setMenuOpen(false)} />
          </ThemeProvider>
        </>
      )}

      <Wrapper pb={0} pt={inlineMenu ? 0 : '4.25rem'} px={{ sm: 2, md: 2, lg: 0, xl: 0 }}>
        <StyledContainer>
          <Grid>
            {inlineMenu ? (
              <NavColumn>
                <Navigation />
              </NavColumn>
            ) : null}

            {/**
             * Header is rendered below nav, but positioned to grid row 1.
             * This means tab navigation goes through navigation first,
             * then through the header, before going to the main content.
             **/}
            {inlineMenu ? (
              <Header pt={2} pb={4}>
                <PoweredByOakbrook color="backgroundPair" />

                <HeaderNavigation />
              </Header>
            ) : null}

            <PageColumn>
              <PageHeader.Outlet
                rightAdornment={
                  <Box>
                    <Logo variant="light" />
                  </Box>
                }
              />

              {v5Shim && children ? children : <Outlet />}

              <BrandAwareFooter />
            </PageColumn>
          </Grid>
        </StyledContainer>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div<SpaceProps>`
  background-color: ${getColor('background')};
  display: flex;
  min-height: 100vh;
  ${space}
`;

const StyledContainer = styled(Container)`
  flex: 1 1;
`;

const Grid = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: 1fr;

  @media (min-width: ${getBreakpoint('lg')}) {
    grid-template-rows: auto 1fr;
    // _ ___ ___ ___ _ ___ ___ ___ ___ ___ ___ _
    grid-template-columns: 1fr repeat(3, 3fr) 1fr repeat(6, 3fr) 1fr;
  }

  @media (min-width: ${getBreakpoint('xl')}) {
    // _ _ _ _ _ _ _ _ _ _ _ _
    grid-template-columns: repeat(12, 1fr);
  }
`;

const NavColumn = styled.section`
  grid-column: 0 / span 0;

  @media (min-width: ${getBreakpoint('lg')}) {
    grid-column: 2 / span 3;
  }
`;

const PageColumn = styled.main`
  grid-column: 1 / span 1;
  display: flex;
  flex-direction: column;

  @media (min-width: ${getBreakpoint('lg')}) {
    grid-column: 6 / span 6;
  }
`;

const Header = styled.header<SpaceProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  grid-row: 1 / span 1;

  ${space}

  display: none;

  @media (min-width: ${getBreakpoint('lg')}) {
    display: flex;
    grid-column: 2 / span 10;
  }
`;
