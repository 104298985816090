import { useState } from 'react';

import { faFileInvoice } from '@fortawesome/pro-light-svg-icons';
import { faFileAlt, faLock } from '@fortawesome/pro-regular-svg-icons';
import { Button, Card, Flex, Halo, Icon, Paragraph } from '@sequensis/stylish-core';

import UploadDocuments from '../components/UploadDocuments';
import { documentDisplayName } from '../data';

const ConfirmIncome = ({
  requirement,
  documentsToSubmit,
  onDocumentClick,
  onUpload,
  onComplete,
  onUploadSuccess,
  applicationId,
  onCancel,
}: any) => {
  const [documentIsSubmitting, setDocumentIsSubmitting] = useState(false);

  const getUploadURL = (documentType: string, uploadUrls: string[]) => {
    if (!uploadUrls) return null;
    if (!documentsToSubmit.length) return uploadUrls[0];

    const uploadUrlsInUse = documentsToSubmit
      .filter((document: any) => document.documentType == documentType)
      .map((document: any) => document.uploadUrl);

    const availableUploadUrls = uploadUrls.filter(
      (uploadUrl: string) => !uploadUrlsInUse.includes(uploadUrl),
    );

    return availableUploadUrls.length ? availableUploadUrls[0] : null;
  };

  return (
    <Card
      heading={
        <>
          <Flex justifyContent="space-around">
            <Halo>
              <Icon icon={faFileInvoice} size="3x" />
            </Halo>
          </Flex>
          <Paragraph fontSize="3xl" lineHeight="3xl">
            Confirm income
          </Paragraph>
        </>
      }
    >
      <Paragraph mt={3}>
        There are several options for you to verify your income and this process will only
        take a couple of minutes.
      </Paragraph>
      <Paragraph fontWeight="bold" mt={3}>
        Choose a document to use
      </Paragraph>

      {Object.entries(requirement?.satisfactoryDocuments || {}).map(
        ([documentType, value]: any) => {
          const uploadUrl = getUploadURL(documentType, value.uploadUrls);
          return (
            uploadUrl && (
              <Button
                key={documentType}
                variant="primary"
                mt={3}
                fullWidth
                navigational="forward"
                disabled={documentIsSubmitting}
                onClick={() => onDocumentClick({ documentType, uploadUrl })}
              >
                <Paragraph>
                  <Icon icon={faFileAlt} mr={2} />
                  {documentDisplayName[documentType as keyof typeof documentDisplayName]}
                </Paragraph>
              </Button>
            )
          );
        },
      )}

      <UploadDocuments
        {...{
          documentsToSubmit,
          documentIsSubmitting,
          setDocumentIsSubmitting,
          onUpload,
          onComplete,
          onUploadSuccess,
          applicationId,
        }}
      />

      {!Object.keys(documentsToSubmit).map((documentType) => documentType as any)
        .length && (
        <Button
          key="cancel"
          variant="secondary"
          marginTop={4}
          fullWidth
          onClick={onCancel}
        >
          Cancel
        </Button>
      )}

      <Paragraph mt={4} fontWeight="bold">
        <Icon icon={faLock} mr={1} />
        Your data is safe and secure
      </Paragraph>
    </Card>
  );
};

export default ConfirmIncome;
