import { useContext } from 'react';

import { CartesianGrid, Line, LineChart, ResponsiveContainer } from 'recharts';
import { ThemeContext } from 'styled-components';

import { PaymentGraphDataPoint } from 'src/types/PaymentGraph';
import { getPaymentGraphMonths } from 'src/utils/calculations';

import { Dot } from './PaymentGraphDot';

export interface PaymentGraph {
  numberOfMonths: number;
  completionDate?: string;
}

export const PaymentGraph = ({ completionDate, numberOfMonths }: PaymentGraph) => {
  const themeContext = useContext(ThemeContext);

  const paymentMonths: PaymentGraphDataPoint[] = getPaymentGraphMonths(numberOfMonths);

  const shouldShowStep = numberOfMonths < 12;

  return (
    <div className="chart-container">
      <ResponsiveContainer width="99%" height={150}>
        <LineChart data={paymentMonths}>
          <CartesianGrid horizontal={false} strokeWidth={2} stroke="#EBEBEB" />
          <Line
            type="linear"
            dataKey="amount"
            stroke={themeContext.colors.positive}
            strokeWidth={2}
            dot={<Dot completionDate={completionDate} showStep={shouldShowStep} />}
            activeDot={{ r: 8 }}
            animationDuration={1000}
            animationEasing="ease-in-out"
            animationBegin={1000}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};
