import { useSelector } from 'react-redux';

import { getBalanceAmount, getCreditLimit, getTotalAmountOwed } from '../store/Accounts';

const toFixed = (value: number, digits: number) => parseFloat(value.toFixed(digits));

export const useAccountBalanceProgress = (accountId: string) => {
  const accountBalance = useSelector(getBalanceAmount(accountId));
  const totalAmountOwed = useSelector(getTotalAmountOwed(accountId));
  const creditLimit = useSelector(getCreditLimit(accountId));

  return toFixed(
    Math.max(1 - accountBalance / (totalAmountOwed || creditLimit), 0.01),
    4,
  );
};
