import { useEffect, useState } from 'react';

import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import { useFinaliseReferral } from '@sequensis/creditapplication-reactsdk';
import { Paragraph } from '@sequensis/react-core';
import {
  Anchor,
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  List,
  ListItem,
  Text,
  useToast,
} from '@sequensis/stylish-core';

import { documentDisplayName } from '../data';
import {
  DocumentSubmissionResponse,
  DocumentToSubmit,
  HttpStatusCode,
  UploadedDocumentsContainerProps,
} from '../documentUpload.types';
import { truncateMiddle } from '../helperFunctions';
import { submitDocumentToUploadUrl } from '../serviceCalls';

import { DocumentsContainer, DocumentUpload } from './DocumentsContainer';

const UploadDocuments = ({
  documentIsSubmitting,
  setDocumentIsSubmitting,
  documentsToSubmit,
  onUpload,
  onComplete,
  onUploadSuccess,
  applicationId,
}: UploadedDocumentsContainerProps) => {
  const [unsubmittedDocuments, setUnsubmittedDocuments] = useState([]);

  useEffect(() => {
    setUnsubmittedDocuments(
      documentsToSubmit.filter((document: any) => !document.submitted),
    );
  }, [documentsToSubmit]);

  const finaliseReferral = useFinaliseReferral({
    onSuccess: () => {
      setDocumentIsSubmitting(false);
      onComplete();
    },
  });

  const { raiseToast } = useToast();

  if (!documentsToSubmit.length) return null;

  async function submitDocuments(): Promise<void> {
    setDocumentIsSubmitting(true);
    try {
      const documentSubmissionResponses: DocumentSubmissionResponse[] = [];

      for (const unsubmittedDocument of unsubmittedDocuments) {
        documentSubmissionResponses.push(
          await submitDocumentToUploadUrl(unsubmittedDocument),
        );
      }

      handleDocumentSubmissionResponses(documentSubmissionResponses);
    } catch {
      setDocumentIsSubmitting(false);
      raiseToast({
        status: 'error',
        message: `An unexpected error occured uploading one or more of your documents. 
          Please try again later or get in touch.`,
        duration: 10_000,
      });
    }
  }

  const determineErrorMessageFromStatus = (
    status: number,
    documentToSubmit: DocumentToSubmit,
  ) => {
    switch (status) {
      case 409:
        documentDisplayName[documentToSubmit.documentType];

        return `Failed to upload ${documentToSubmit.document.name}. 
          A ${documentDisplayName} has already been provided as part of your application.`;
      default:
        return `Failed to upload ${documentToSubmit.document.name}. 
          Please try again or get in touch.`;
    }
  };

  const handleDocumentSubmissionResponses = (responses: DocumentSubmissionResponse[]) => {
    responses
      .filter((response) => response.status === HttpStatusCode.OK)
      .forEach(onUploadSuccess);

    const failedDocuments = responses
      .filter((response) => response.status !== HttpStatusCode.OK)
      .map((response) => {
        raiseToast({
          status: 'error',
          message: determineErrorMessageFromStatus(
            response.status,
            response.documentToSubmit,
          ),
          duration: 10_000,
        });

        return response.documentToSubmit;
      });

    if (!failedDocuments.length) {
      finaliseReferral.mutate(applicationId);
    } else {
      setUnsubmittedDocuments(failedDocuments as any);
      setDocumentIsSubmitting(false);
    }
  };

  const handleDocumentAccepted = (documentToSubmit: DocumentToSubmit, document: File) => {
    onUpload(document, documentToSubmit?.documentType, documentToSubmit?.uploadUrl);
  };

  return (
    <DocumentsContainer mt={3}>
      <Heading fontSize="lg">Ready to upload</Heading>
      <Box mt={2}>
        <List variant="icon">
          {unsubmittedDocuments.map((documentToSubmit: any) => (
            <ListItem
              key={documentToSubmit?.document?.name}
              leftAdornment={<Icon icon={faCheckCircle} color="positive" />}
            >
              <Flex justifyContent="space-between" width="100%">
                <Text>{truncateMiddle(documentToSubmit.document.name, 15)}</Text>
                <DocumentUpload
                  disabled={documentIsSubmitting}
                  onDocumentAccepted={(file: any) =>
                    handleDocumentAccepted(documentToSubmit, file)
                  }
                >
                  <Anchor>Change</Anchor>
                </DocumentUpload>
              </Flex>
            </ListItem>
          ))}
        </List>
        <Button
          variant="primary"
          mt={3}
          fullWidth
          isLoading={documentIsSubmitting}
          onClick={() => submitDocuments()}
          style={{ fontSize: '16px' }}
        >
          <Paragraph>Submit documents</Paragraph>
        </Button>
      </Box>
    </DocumentsContainer>
  );
};

export default UploadDocuments;
