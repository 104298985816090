import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import {
  Button,
  Card,
  Collapse,
  Flex,
  Halo,
  Heading,
  Icon,
  List,
  ListItem,
  Paragraph,
  Spaced,
  Text,
} from '@sequensis/stylish-core';
import { useProduct } from 'src/hooks/useProduct';

import { useRaiseSubmissionError } from 'src/hooks/useRaiseSubmissionError';
import { SubmissionAction } from 'src/screens/TopUp/types';

interface DoneProps {
  onDoneClick: SubmissionAction<void>;
}

export const Done = ({
  onDoneClick: { error, isLoading, onAction: onDoneClick },
}: DoneProps) => {
  useRaiseSubmissionError(error);

  const {
    copyConfig: {
      tradingName: { singular: productNameSingular },
    },
  } = useProduct();

  return (
    <Spaced marginBottom={4} excludeLast>
      <Card>
        <Spaced marginBottom={3} excludeLast>
          <Flex justifyContent="center">
            <Halo>
              <Icon icon={faCheck} size="3x" />
            </Halo>
          </Flex>
          <Heading size="2xl" as="h3">
            You&apos;re all done!
          </Heading>
          <Paragraph fontSize="lg">
            <Text variant="emphasis">{"Here's"} what happens next:</Text>
          </Paragraph>
          <List variant="unordered">
            <ListItem>
              <Paragraph size="md">{"You'll"} get your money today</Paragraph>
            </ListItem>
            <ListItem>
              <Paragraph size="md">
                {"We'll"} close your old loan and set up your new loan right away
              </Paragraph>
            </ListItem>
            <ListItem>
              <Paragraph size="md">
                Your Direct Debit will be updated automatically. Please do not cancel your
                existing Direct Debit.
              </Paragraph>
            </ListItem>
          </List>
        </Spaced>
      </Card>
      <Card>
        <Collapse label={<Heading size="lg">What information will you send me?</Heading>}>
          <List variant="unordered">
            <ListItem>
              <Paragraph size="md">
                {"We'll"} send you a welcome pack via email; this includes a copy of your
                Credit Agreement
              </Paragraph>
            </ListItem>
            <ListItem>
              <Paragraph size="md">
                Read through it carefully - it has useful information about your
                {` ${productNameSingular}`} such as {"'how to contact us'"} and your
                Direct Debit Guarantee
              </Paragraph>
            </ListItem>
          </List>
        </Collapse>
      </Card>
      <Button
        data-testid="done-confirmation-button"
        fullWidth
        navigational
        isLoading={isLoading}
        onClick={() => onDoneClick()}
      >
        Done
      </Button>
    </Spaced>
  );
};

export const DoneStep = {
  path: 'done',
  component: Done,
  provides: [],
};
