import { useSelector } from 'react-redux';

import { Button, Card, Heading, Modal, Paragraph } from '@sequensis/stylish-core';

import { useGetProductConfig } from 'src/hooks/useGetProductConfig';
import { getProductId } from 'src/store/Accounts';

import { A } from '../Anchor';

type VulnerabilitySupportCardProps = {
  showContinueButton?: boolean;
  onContinueClick?: () => void;
  modalProps?: {
    isOpen: boolean;
    onClose: () => void;
  };
};

const VulnerabilitySupportCard = ({
  showContinueButton,
  onContinueClick,
  modalProps,
}: VulnerabilitySupportCardProps) => {
  const productId = useSelector(getProductId) || '';
  const productConfig = useGetProductConfig(productId);

  if (!productConfig) return null;

  const render = (
    <>
      <Heading marginBottom={24} marginTop={24}>
        Support
      </Heading>
      <Card>
        <Paragraph color="primary">
          If you need us to support or communicate with you differently, either
          temporarily or for the life of your loan, then we’re here to help.
        </Paragraph>

        <Paragraph color="primary" marginTop={3}>
          Whatever the reason, whether it’s because of your physical or mental health
          needs, or a life event, we are committed to support you.
        </Paragraph>

        <Paragraph marginTop={3}>
          If you wish to inform us of anything, then please&nbsp;
          <A href={productConfig.contactUs} target="_blank" rel="noreferrer">
            contact us
          </A>
          &nbsp;and we’ll work with you to understand your needs and ensure they’re
          respected in future.
        </Paragraph>

        {showContinueButton && (
          <Button marginTop={3} fullWidth onClick={onContinueClick}>
            Continue
          </Button>
        )}
      </Card>
    </>
  );

  if (modalProps)
    return (
      <Modal
        isOpen={modalProps.isOpen}
        showCloseButton={false}
        onClose={modalProps.onClose}
      >
        {render}
      </Modal>
    );

  return render;
};

export default VulnerabilitySupportCard;
