import { useNavigate } from 'react-router-dom-v5-compat';

import { ProviderList } from '@mistho/react';
import { Provider } from '@mistho/react/lib/browser';
import { Button, Card, Heading } from '@sequensis/stylish-core';

type ProviderListProps = {
  transaction_id: string;
  onClick: (provider: Provider) => void;
};

export const ProvidersList = ({ transaction_id, onClick }: ProviderListProps) => {
  const navigate = useNavigate();

  return (
    <>
      <Card className="provider-wrapper" maxWidth={'calc(100vw - 5rem)'}>
        <Heading as="h3" mb={4}>
          Payroll/Employer portal
        </Heading>
        <ProviderList transaction_id={transaction_id} withSearchBar onClick={onClick} />
      </Card>
      <Button
        variant="secondary"
        onClick={() => navigate(-1)}
        fullWidth
        className="white-button"
      >
        Go back
      </Button>
    </>
  );
};
