import { Paragraph } from '@sequensis/stylish-core';
import { A } from 'src/components/Anchor';
import { useProduct } from 'src/hooks/useProduct';
import {
  applyAgainMessage,
  applyNewData,
  creditDetails,
  debtHelpData,
  defaultTitle,
  defaultView,
  messageMap,
  titleMap,
  viewOverrides,
} from './data';
import { useProductSupportUrl } from 'src/hooks/useProductSupportUrl';
import { useGetLoanUrl } from 'src/hooks/useGetLoanUrl';

type DeclineReasonsProps = {
  declineReasons: string[];
  name: string;
};

const DeclineReasons = ({ declineReasons, name }: DeclineReasonsProps) => {
  const { productSupportUrl } = useProductSupportUrl();
  const product = useProduct({ shouldThrow: false });
  const loanURL = useGetLoanUrl(product?.baseRoute || '');

  const title = () => {
    if (titleMap.hasOwnProperty(declineReasons[0])) return titleMap[declineReasons[0]];

    return defaultTitle.replace('{name}', name);
  };

  const getViewData = (key: string) => {
    const viewArr = declineReasons.map((el) => {
      if (viewOverrides.hasOwnProperty(el))
        return viewOverrides[el][key as keyof typeof defaultView];

      return defaultView[key as keyof typeof defaultView];
    });
    return !!viewArr.find((el) => !!el);
  };

  return (
    <>
      <Paragraph fontSize="lg" fontWeight="bold">
        {title()}
      </Paragraph>
      {declineReasons.map((reasonId) => {
        return (
          <>
            {messageMap[reasonId]?.map((message, i) => (
              <Paragraph fontSize="md" key={i}>
                {message}
              </Paragraph>
            ))}
          </>
        );
      })}
      {getViewData('showCreditDetails') && (
        <>
          <Paragraph>{creditDetails.message}</Paragraph>
          <Paragraph marginTop={3}>
            <A href={creditDetails.link.experian} target="_blank" rel="noreferrer">
              {creditDetails.linkText.experian}
            </A>
          </Paragraph>
          <Paragraph marginTop={3}>
            <A href={creditDetails.link.equifax} target="_blank" rel="noreferrer">
              {creditDetails.linkText.equifax}
            </A>
          </Paragraph>
        </>
      )}
      {getViewData('showDebtHelp') && (
        <>
          <Paragraph>{debtHelpData.message}</Paragraph>
          <Paragraph marginTop={3}>
            <A href={debtHelpData.link} target="_blank" rel="noreferrer">
              {debtHelpData.linkText}
            </A>
          </Paragraph>
        </>
      )}
      {getViewData('showApplyNewMessage') && (
        <Paragraph>
          {applyNewData.message}
          <A href={loanURL} target="_blank" rel="noreferrer">
            <b>{applyNewData.linkText}</b>
          </A>
        </Paragraph>
      )}
      {getViewData('showContactUs') && (
        <Paragraph>
          <A href={productSupportUrl} target="_blank" rel="noreferrer">
            Contact Us
          </A>
        </Paragraph>
      )}
      {getViewData('showApplyAgainMessage') && (
        <Paragraph fontSize="md" fontWeight="bold">
          {applyAgainMessage}
        </Paragraph>
      )}
    </>
  );
};

export default DeclineReasons;
