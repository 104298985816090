export class ApiHeaderBuilder {
  #authorization = '';
  #accepts = '';
  #serviceName: string;

  constructor(serviceName: string) {
    this.#serviceName = serviceName;
  }

  withBearerToken(authToken: string): ApiHeaderBuilder {
    this.#authorization = `Bearer ${authToken}`;
    return this;
  }

  withVersion(version: number): ApiHeaderBuilder {
    this.#accepts = `application/vnd.${this.#serviceName}.v${version}+json`;
    return this;
  }

  build(): Record<string, string> {
    const headers: Record<string, string> = {};

    if (this.#authorization) {
      headers['Authorization'] = this.#authorization;
    }

    if (this.#accepts) {
      headers['Accept'] = this.#accepts;
    }

    return headers;
  }
}
export const getCmsHeaderBuilder = () =>
  new ApiHeaderBuilder('CustomerManagementService');
export const getAmsHeaderBuilder = () => new ApiHeaderBuilder('AccountManagementService');
export const getEliHeaderBuilder = () => new ApiHeaderBuilder('EligibilityService');
export const getCreditAppHeaderBuilder = () =>
  new ApiHeaderBuilder('CreditApplicationService');

export const createCmsHeaderWithAuthBearerToken = (authToken: string) =>
  getCmsHeaderBuilder().withBearerToken(authToken).build();

export const createAmsHeaderWithAuthBearerToken = (authToken: string) =>
  getAmsHeaderBuilder().withBearerToken(authToken).build();

export const createEliHeaderWithAuthBearerToken = (authToken: string) =>
  getEliHeaderBuilder().withBearerToken(authToken).build();

export const createCreditAppHeaderWithAuthBearerToken = (authToken: string) =>
  getCreditAppHeaderBuilder().withBearerToken(authToken).build();
