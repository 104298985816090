import { AuthError } from '@sequensis/react-auth';
import { useConfig } from '@sequensis/react-config';
import { Alert, Text } from '@sequensis/stylish-core';

export const LoginError = ({ error }: { error: AuthError }) => {
  const {
    companyDetails: { supportPhoneNumber },
    supportHelpdesk,
  } = useConfig();

  const supportRequestUrl = `${supportHelpdesk}/requests/new`;

  if (error === null) return null;

  return (
    <Alert icon={true} variant="warning" marginBottom={3}>
      <Text>
        An unexpected error occurred whilst logging you in. Please try again, or contact
        us by calling on <Text fontWeight="bold">{supportPhoneNumber}</Text> or you can{' '}
        <a target="_blank" rel="noopener noreferrer" href={supportRequestUrl}>
          send us a message
        </a>
        .
      </Text>
    </Alert>
  );
};
