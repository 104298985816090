import { useSelector } from 'react-redux';

import { Redact } from '@sequensis/react-monitoring';
import { Box, Heading, oakbrookTheme } from '@sequensis/stylish-core';
import { ThemeProvider } from 'styled-components';

import { getFullName } from 'src/store/Customer/selectors';

import { AccountListNavigation } from './components/AccountListNavigation';
import { CustomerNavigation } from './components/CustomerNavigation';

export const Navigation = () => {
  const fullName = useSelector(getFullName);

  return (
    <>
      <aside>
        <Redact>
          <Heading fontSize="lg" color="backgroundPair">
            {fullName}
          </Heading>
        </Redact>
      </aside>

      <ThemeProvider theme={oakbrookTheme}>
        <Box mt={3}>
          <CustomerNavigation />

          <AccountListNavigation />
        </Box>
      </ThemeProvider>
    </>
  );
};
