import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { useAuth } from '@sequensis/react-auth';
import { useConfig, useFeatureFlag } from '@sequensis/react-config';
import { useAsyncCallback } from '@sequensis/react-hooks';

import { updateEmailAddress } from 'src/api/customer/customerApi';
import { analyticsClickEvent } from 'src/components/Analytics';
import { FeedbackAlert } from 'src/components/Delighted';
import { EditableField } from 'src/components/Field';
import { useRefreshCustomer } from 'src/hooks/useRefreshCustomer';
import {
  getEmailAddress,
  getEmailAddressForVerification,
} from 'src/store/Customer/selectors';
import { EmailForVerificationStatus } from 'src/types/CustomerProps';

import { EmailAddressFeedbackAlert } from './EmailAddressFeedbackAlert';
import { emailAddressSchema } from './EmailAddressSchema';
import { PendingVerificationAlert } from './PendingVerificationAlert';

export const EmailAddress = () => {
  const {
    customerManagementApi: { url: customerManagementApiUrl },
  } = useConfig();
  const { getToken, customerId } = useAuth();
  const currentEmailAddress = useSelector(getEmailAddress);
  const addressForVerification = useSelector(getEmailAddressForVerification);
  const { refreshCustomer } = useRefreshCustomer();

  const placeholderAddress =
    addressForVerification?.emailAddress &&
    addressForVerification.status === EmailForVerificationStatus.Pending
      ? addressForVerification.emailAddress
      : currentEmailAddress;

  const [enableFeedbackAlert, setEnableFeedbackAlert] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [hasValidationError, setHasValidationError] = useState(false);

  const [updateEmailAddressStatus, executeUpdateEmailAddress] =
    useAsyncCallback(updateEmailAddress);

  useEffect(() => {
    if (updateEmailAddressStatus.status === 'success') {
      setIsEditing(false);
      refreshCustomer();
      setEnableFeedbackAlert(true);
    }
  }, [updateEmailAddressStatus, refreshCustomer]);

  const handleSave = async (newValue: string) => {
    const authToken = await getToken();

    if (!emailAddressSchema.isValidSync(newValue)) {
      setHasValidationError(true);
      return;
    }

    setHasValidationError(false);

    analyticsClickEvent('CustomerDetailsEmailAddress', 'save-email-address');

    await executeUpdateEmailAddress({
      apiUrl: customerManagementApiUrl,
      authToken,
      customerId: customerId || '',
      emailAddress: newValue,
    });
  };

  const updateEmailAddressFeatureFlag = useFeatureFlag('updateEmailAddress');

  const shouldShowPendingAlert =
    updateEmailAddressStatus.status === 'success' ||
    addressForVerification?.status === EmailForVerificationStatus.Pending;

  return (
    <>
      <EditableField
        fieldType={updateEmailAddressFeatureFlag ? 'editable' : 'locked'}
        defaultValue={placeholderAddress}
        testId="email-address-field"
        ariaLabel="email address"
        label="Email address"
        name="emailAddress"
        error={hasValidationError ? 'Please enter a valid email address' : undefined}
        isLoading={updateEmailAddressStatus.loading}
        isEditing={isEditing}
        onSaveClick={handleSave}
        onEditClick={() => {
          setIsEditing(true);
          analyticsClickEvent('CustomerDetailsEmailAddress', 'edit-email-address');
        }}
      />

      {updateEmailAddressStatus.status === 'success' ||
      updateEmailAddressStatus.status === 'error' ? (
        <EmailAddressFeedbackAlert
          label="Email address"
          status={updateEmailAddressStatus.status}
        />
      ) : null}

      <PendingVerificationAlert show={shouldShowPendingAlert} />

      {enableFeedbackAlert && (
        <FeedbackAlert my={2} feedbackQueryString="Site=EmailAdd" />
      )}
    </>
  );
};
