import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom-v5-compat';

import {
  GetApplicationByCustomerIdResponse,
  useFetchApplication,
  useUpdateMisthoLogin,
} from '@sequensis/creditapplication-reactsdk';
import { useConfig } from '@sequensis/react-config';

import { useGetProductConfig } from 'src/hooks/useGetProductConfig';

import { Done } from '../../ResignContract/Done';
import { Decline } from '../../TopUp';
import Complete from '../../TopUp/DocumentUpload/steps/Complete';

import { IncomeVerificationLoader } from './IncomeVerificationLoader';

type ResultProps = {
  applicationDetails: GetApplicationByCustomerIdResponse;
};

export const Result = ({ applicationDetails }: ResultProps) => {
  const [status, setStatus] = useState<string>();
  const [appId, setAppId] = useState<string>();
  const navigate = useNavigate();
  const brand = applicationDetails?.selectedOffer?.brand?.toString().replace(/ /g, '');
  const productConfig = useGetProductConfig(brand || '');

  const updateMisthoLogin = useUpdateMisthoLogin({
    onSuccess: () => {
      setAppId(applicationDetails.id);
    },
    onError: () => {
      navigate('/error', { replace: true });
    },
  });

  useEffect(() => {
    updateMisthoLogin.mutate(applicationDetails.id);
  }, []);

  const {
    mistho: { pollIntervalMs, timeoutMs },
  } = useConfig();

  useFetchApplication(appId, {
    onSuccess: (data) => {
      if (data?.status === 'Finalised' || data.status.includes('Decline')) {
        setStatus(data.status);
      }
    },
    onError: () => {
      navigate('/error', { replace: true });
    },
    refetchInterval: (data, query) => {
      const hasTimedOut = query.state.dataUpdateCount >= timeoutMs / pollIntervalMs;

      if (hasTimedOut) {
        setStatus(data?.status);
        return false;
      }

      return pollIntervalMs;
    },
  });

  if (!status) return <IncomeVerificationLoader />;

  if (status === 'Finalised')
    return (
      <Done
        onDoneClick={() => navigate('/customer/overview')}
        productConfig={productConfig}
      />
    );

  if (status.toLocaleLowerCase().includes('decline'))
    return (
      <Decline
        product="Top up"
        personalDetailsPlayBack={{
          playback: {
            firstName: (applicationDetails as any)?.primaryBorrower?.personalDetails
              ?.firstName,
          },
        }}
        onDeclineClick={{
          onAction: () => {
            navigate('/customer/overview');
          },
        }}
        declineReasons={['Affordability-General']}
      />
    );

  return <Complete isMisthoJourney />;
};
