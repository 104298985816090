import { useCallback, useEffect } from 'react';

import { QueryClient, QueryClientProvider } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { useAuth } from '@sequensis/react-auth';
import { useConfig } from '@sequensis/react-config';
import { useAsyncCallback } from '@sequensis/react-hooks';
import { oakbrookTheme, TypographyGlobals } from '@sequensis/stylish-core';
import { ThemeProvider } from 'styled-components';

import { getAccounts } from 'src/api/account/accountApi';
import { ServiceWorker } from 'src/components/ServiceWorker';
import { LayoutContextProvider } from 'src/context/LayoutContext';
import { AppRoutes } from 'src/routes';
import Loading from 'src/screens/Loading';
import { fetchAccount } from 'src/store/Account/actions';
import { fetchCustomer } from 'src/store/Customer/actions';
import { getCustomerFetched, getCustomerFetchError } from 'src/store/Customer/selectors';
import { getLoansByProduct } from 'src/utils/filterProduct';

import Analytics from './components/Analytics';
import { PaymentScheduleContextProvider } from './context/PaymentScheduleContext';
import { ServerError } from './screens/Error';
import { getAccountFetchError, getAccountFetching } from './store/Account/selectors';
import { getAllAccounts } from './store/Accounts';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const App = () => {
  const {
    customerManagementApi: { url: customerManagementApiUrl },
    accountManagementApi: { url: accountManagementApiUrl },
    productConfiguration,
  } = useConfig();

  const { loading: isAuthenticating, customerId, getToken } = useAuth();
  const customerFetched = useSelector(getCustomerFetched);

  const customerFetchError = useSelector(getCustomerFetchError);
  const dispatch = useDispatch();

  const [getAccountsState, executeGetAccounts] = useAsyncCallback(getAccounts);
  const accountHeadlines = getAccountsState.result?.responseJson?.accounts;

  const startCustomerFetch = useCallback(async () => {
    if (!customerId) {
      return;
    }

    const authToken = await getToken();

    dispatch(
      fetchCustomer.request({
        customerId,
        apiUrl: customerManagementApiUrl,
        authToken,
      }),
    );
  }, [customerId, customerManagementApiUrl, dispatch, getToken]);

  useEffect(() => {
    startCustomerFetch();
  }, [startCustomerFetch]);

  useEffect(() => {
    const startAccountsFetch = async () => {
      if (!customerId) {
        return;
      }

      const authToken = await getToken();

      executeGetAccounts(accountManagementApiUrl, customerId, authToken);
    };
    startAccountsFetch();
  }, [accountManagementApiUrl, customerId, executeGetAccounts, getToken]);

  const fetchedAccounts = useSelector(getAllAccounts);
  const isAccountFetching = useSelector(getAccountFetching);
  const hasAccountFetchError = useSelector(getAccountFetchError);

  useEffect(() => {
    const startAccountFetch = async () => {
      if (hasAccountFetchError || isAccountFetching || !accountHeadlines) {
        return;
      }

      const authToken = await getToken();

      const loanAccounts = getLoansByProduct(
        accountHeadlines,
        productConfiguration.products,
      );

      const fetchedAccountIds = fetchedAccounts ? Object.keys(fetchedAccounts) : [];

      const product = productConfiguration.products.find(
        (p) =>
          loanAccounts[p.baseRoute].length &&
          !fetchedAccountIds.includes(loanAccounts[p.baseRoute][0].id),
      );

      if (product === undefined) {
        return;
      }

      const accountIdToFetch = loanAccounts[product.baseRoute][0].id;

      if (accountIdToFetch) {
        dispatch(
          fetchAccount.request({
            accountId: accountIdToFetch,
            apiUrl: accountManagementApiUrl,
            authToken,
          }),
        );
      }
    };

    startAccountFetch();
  }, [
    accountManagementApiUrl,
    accountHeadlines,
    dispatch,
    fetchedAccounts,
    getToken,
    hasAccountFetchError,
    isAccountFetching,
    productConfiguration.products,
  ]);

  const customerNotFound = customerFetchError === 'Unable to find the requested account.';

  const handleRetry = () => {
    if (customerFetchError) {
      startCustomerFetch();
    }
  };

  if (customerNotFound) {
    return <ServerError onClick={handleRetry} />;
  }

  const isWaitingForUserData = customerId && !customerFetched;

  if (isAuthenticating || isWaitingForUserData) {
    return <Loading />;
  }

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Analytics>
          <PaymentScheduleContextProvider>
            <LayoutContextProvider>
              <AppRoutes />
            </LayoutContextProvider>
          </PaymentScheduleContextProvider>

          <ThemeProvider theme={oakbrookTheme}>
            <TypographyGlobals />
            <ServiceWorker />
          </ThemeProvider>
        </Analytics>
      </QueryClientProvider>
    </>
  );
};
