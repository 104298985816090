import { useSelector } from 'react-redux';

import { useFeatureFlag } from '@sequensis/react-config';
import { Card, Paragraph } from '@sequensis/stylish-core';

import { analyticsClickEvent } from 'src/components/Analytics';
import { ButtonLink } from 'src/components/ButtonLink';
import { useCurrentAccountId } from 'src/hooks/useCurrentAccountId';
import { getCanHaveRepaymentBreaks } from 'src/store/Accounts';

export type SupportProps = {
  className?: string;
};

const Support = ({ className }: SupportProps) => {
  const currentAccountId = useCurrentAccountId() ?? '';
  const canHaveRepaymentBreaks = useSelector(getCanHaveRepaymentBreaks(currentAccountId));
  const repaymentBreaksEnabled = useFeatureFlag('allowRepaymentBreaks');

  return (
    <>
      {canHaveRepaymentBreaks && repaymentBreaksEnabled && (
        <Card heading="Need some time out?" className={className}>
          <Paragraph mt={2} mb={4}>
            Take a repayment break if you need to. Take up to 2 repayment breaks each year
            at a time that suits you. Ideal to manage your finances over Christmas or when
            you&apos;ve received an unexpected bill. Interest will still be charged during
            a repayment break
          </Paragraph>

          <ButtonLink
            variant="primary"
            navigational
            to="../payment-plan/repayment-break-landing"
            onClick={() => {
              analyticsClickEvent('AccountSummary', 'support-repayment-breaks-button');
            }}
          >
            Repayment breaks
          </ButtonLink>
        </Card>
      )}

      <Card heading="We're here to help" className={className}>
        <Paragraph mt={2} mb={4}>
          Let us know if you ever feel worried about making your repayments or receive
          unexpected bills. If you&apos;re struggling to pay then we&apos;re here to help
        </Paragraph>

        <ButtonLink
          variant="primary"
          navigational
          to="../payment-plan/struggling-to-pay"
          data-testid="struggling-to-pay-card"
          onClick={() => {
            analyticsClickEvent('AccountSummary', 'support-trouble-repaying-button');
          }}
        >
          Struggling to pay
        </ButtonLink>
      </Card>
    </>
  );
};

export default Support;
