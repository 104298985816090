import { useCallback, useEffect, useState } from 'react';
import * as React from 'react';

import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { yupResolver } from '@hookform/resolvers';
import { Button, Flex, NumericField, Spaced, TextField } from '@sequensis/stylish-core';

import { analyticsClickEvent } from 'src/components/Analytics';
import { getFirstName, getLastName } from 'src/store/Customer/selectors';

import { bankAccountValidationSchema } from './BankAccountSchema';

export interface AddBankAccountFormData {
  accountName: string;
  accountNumber: string;
  sortCode: string;
}
interface AddBankAccountFormProps {
  onSubmit?(data: AddBankAccountFormData): void;
  hasAuthorisedDirectDebit: boolean;
  children: React.ReactNode;
}

export const AddBankAccountForm = ({
  onSubmit,
  hasAuthorisedDirectDebit,
  children,
}: AddBankAccountFormProps) => {
  const formMethods = useForm<AddBankAccountFormData>({
    mode: 'onChange',
    resolver: yupResolver(bankAccountValidationSchema),
  });

  const firstName = useSelector(getFirstName);
  const lastName = useSelector(getLastName);

  const [bankAccountNameValue, setBankAccountNameValue] = useState('');
  const [bankAccountNameMatches, setBankAccountNameMatches] = useState(true);

  const { handleSubmit, control, formState, errors } = formMethods;

  const handleFormSubmit = (data: AddBankAccountFormData) => {
    analyticsClickEvent('CustomerDirectDebit', 'add-new-bank-account-complete');
    onSubmit && onSubmit(data);
  };

  const isBankAccountNameMatch = useCallback(() => {
    const bankAccountName = bankAccountNameValue?.trim().split(' ');
    const bankAccountNameInitial = bankAccountName[0].substr(0, 1);
    const bankAccountNameLastName = bankAccountName[bankAccountName.length - 1];

    return (
      bankAccountNameInitial.toLowerCase() === firstName?.substr(0, 1).toLowerCase() &&
      bankAccountNameLastName.toLowerCase() === lastName?.toLowerCase()
    );
  }, [bankAccountNameValue, firstName, lastName]);

  useEffect(() => {
    if (isBankAccountNameMatch()) {
      setBankAccountNameMatches(true);
    }
  }, [bankAccountNameValue, isBankAccountNameMatch]);

  const handleBankAccountNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBankAccountNameMatches(!event.target.value?.trim() || isBankAccountNameMatch());
  };
  const bankAccountNameWarning = bankAccountNameMatches
    ? undefined
    : 'Bank account needs to be in your name';

  return (
    <FormProvider {...formMethods}>
      <Flex
        as="form"
        flexDirection="column"
        justifyContent="space-between"
        flex="1 1 auto"
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <Spaced marginBottom={2}>
          <Controller
            name="accountName"
            control={control}
            defaultValue=""
            render={(field) => (
              <TextField
                label="Name on account"
                name="accountName"
                error={errors?.accountName?.message || bankAccountNameWarning}
                data-testid="add-bank-account-name"
                onChange={(e) => {
                  field.onChange(e);
                  setBankAccountNameValue(e.target.value);
                }}
                onBlur={(e) => {
                  field.onBlur();
                  handleBankAccountNameChange(e);
                }}
              />
            )}
          />
          <Controller
            name="accountNumber"
            control={control}
            defaultValue=""
            as={
              <NumericField
                label="Account number"
                name="accountNumber"
                error={errors?.accountNumber?.message}
                formattingProps={{ format: '########' }}
                data-testid="add-bank-account-number"
              />
            }
          />
          <Controller
            name="sortCode"
            control={control}
            defaultValue=""
            as={
              <NumericField
                label="Sort code"
                name="sortCode"
                error={errors?.sortCode?.message}
                formattingProps={{ format: '## - ## - ##' }}
                data-testid="add-bank-account-sort-code"
              />
            }
          />
        </Spaced>
        {children}
        <Button
          disabled={!formState.isValid || !hasAuthorisedDirectDebit}
          variant="primary"
          fullWidth={true}
        >
          Continue
        </Button>
      </Flex>
    </FormProvider>
  );
};
