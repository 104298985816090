import { all, call, fork, put, takeLatest } from 'redux-saga/effects';

import * as accountManagementApi from 'src/api/account/accountApi';
import { AllAccountApiResponse } from 'src/api/account/types';
import { AllAccountProps } from 'src/types/AccountProps';

import { fetchAccount } from './actions';

export function* handleFetchRequest(action: ReturnType<typeof fetchAccount.request>) {
  try {
    const result: AllAccountApiResponse = yield call(
      accountManagementApi.fetchAccount,
      action.payload,
    );

    yield put(fetchAccount.success(mapApiResponseToStoreType(result)));
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : error.toString();
    yield put(fetchAccount.failure(errorMessage));
  }
}

export function* watchFetchRequest() {
  yield takeLatest(fetchAccount.request, handleFetchRequest);
}

export default function* accountSaga() {
  yield all([fork(watchFetchRequest)]);
}

function mapApiResponseToStoreType(response: AllAccountApiResponse): AllAccountProps {
  return {
    id: response.id,
    status: response.status,
    reference: response.reference,
    activatedAt: response.activatedAt,
    isInPaymentPlan: response.isInPaymentPlan,
    accountDetails: {
      annualPercentageRate: response.accountDetails.annualPercentageRate,
      loanAmount: response.accountDetails.loanAmount,
      creditLimit: response.accountDetails.creditLimit,
      paymentMethod: response.accountDetails.paymentMethod,
      repaymentFrequency: {
        dayOfMonth: response.accountDetails.repaymentFrequency.dayOfMonth,
      },
      regularRepaymentAmount: response.accountDetails.regularRepaymentAmount,
      repaymentMandateSource: response.accountDetails.repaymentMandateSource,
      repaymentMandateDetails: {
        accountNumber:
          response.accountDetails.repaymentMandateDetails?.accountNumber ?? '',
        repaymentMandateStatus:
          response.accountDetails.repaymentMandateDetails?.repaymentMandateStatus ??
          'Registered',
      },
    },
    featureFlags: {
      canHaveRepaymentBreaks: response.featureFlags.canHaveRepaymentBreaks,
    },
    product: {
      productId: response.product.productId,
    },
    funder: response.funder,
    balance: {
      currentBalance: response.balance.currentBalance,
      startBalance: response.balance.startBalance,
      amountPaid: response.balance.amountPaid,
      availableAmount: response.balance.availableAmount,
      total: response.balance.total,
      principal: response.balance.principal,
      accruedInterest: response.balance.accruedInterest,
      appliedInterest: response.balance.appliedInterest,
      arrears: response.balance.arrears,
    },
    numberOfPayments: response.numberOfPayments,
    paymentSchedulePosition: {
      nextPaymentDueAmount: response.paymentSchedulePosition.nextPaymentDueAmount,
      nextPaymentDueDate: response.paymentSchedulePosition.nextPaymentDueDate,
      numberOfPaymentsRemaining:
        response.paymentSchedulePosition.numberOfPaymentsRemaining,
    },
    activation: {
      failures: response.activation.failures,
    },
    repaymentBreaks: response.repaymentBreaks,
    isCAIS: response.isCAIS,
    hasNoticeOfDefault: response.hasNoticeOfDefault,
  };
}
