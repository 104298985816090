import { useSelector } from 'react-redux';

import { useConfig } from '@sequensis/react-config';

import { getAllAccounts } from 'src/store/Accounts';

import { ProductNavigation } from './ProductNavigation';

export const AccountListNavigation = () => {
  const fetchedAccountsState = useSelector(getAllAccounts);
  const fetchedAccounts = fetchedAccountsState ? Object.values(fetchedAccountsState) : [];

  const { productConfiguration } = useConfig();

  const getProductById = (productId: string) => {
    return productConfiguration.products.find((product) =>
      product.productIds.includes(productId),
    );
  };

  return (
    <>
      {fetchedAccounts.map((account) => {
        const product = getProductById(account.product.productId);

        if (!product) return null;

        return <ProductNavigation product={product} key={account.id} />;
      })}
    </>
  );
};
