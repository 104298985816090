import { Halo } from 'src/components/Halo';

export const SpeechBubbleIcon = () => (
  <Halo>
    <path
      d="M86.9464 80.7218C88.8202 78.512 90 75.7292 90 72.6191C90 66.0715 84.6563 60.6697 77.7164 59.7694C75.565 55.0224 70.4295 51.6667 64.4612 51.6667C56.4803 51.6667 50.0263 57.5596 50.0263 64.762C50.0263 67.7903 51.1366 70.573 53.0798 72.8647C51.9694 75.32 50.4426 77.2843 50.4426 77.3662C50.0263 77.8572 49.8875 78.6757 50.0957 79.4123C50.3732 80.067 50.9284 80.5581 51.6224 80.5581C55.3006 80.5581 58.2847 78.8394 60.2973 77.3662C60.9219 77.5298 61.6159 77.6117 62.2405 77.7754C64.4612 82.4406 69.5273 85.7144 75.565 85.7144C76.953 85.7144 78.341 85.5507 79.6596 85.2233C81.6721 86.6965 84.6563 88.3334 88.4038 88.3334C89.0284 88.3334 89.5836 87.9242 89.8612 87.1876C90.0694 86.5328 90 85.7144 89.5142 85.1414C89.5142 85.1414 87.9874 83.1772 86.9464 80.7218ZM59.6727 73.1102L58.4929 74.0105C57.5213 74.8289 56.4803 75.4019 55.5088 75.8111C55.6476 75.4019 55.8558 74.9926 56.064 74.5834L57.1049 72.0462L55.37 70.0001C54.4678 68.9361 53.3574 67.1355 53.3574 64.762C53.3574 59.8513 58.4235 55.5953 64.4612 55.5953C70.4295 55.5953 75.565 59.8513 75.565 64.762C75.565 69.7545 70.4295 73.9287 64.4612 73.9287C63.2814 73.9287 62.1711 73.8468 61.0607 73.5194L59.6727 73.1102ZM84.5869 77.8572L82.8519 79.9034L83.9623 82.4406C84.1011 82.8498 84.3093 83.259 84.5175 83.6682C83.4765 83.259 82.5049 82.6042 81.5333 81.8676L80.2842 80.9673L78.9656 81.3766C77.8552 81.7039 76.6754 81.7858 75.565 81.7858C71.8175 81.7858 68.417 80.1489 66.4044 77.7754C73.4831 76.8751 78.8962 71.3914 78.8962 64.762C78.8962 64.5164 78.8268 64.2709 78.8268 63.9435C83.2683 65.1712 86.6689 68.6087 86.6689 72.6191C86.6689 74.9926 85.4891 76.7932 84.5869 77.8572Z"
      fill="#000067"
    />
  </Halo>
);
