import { useCallback, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { useAuth } from '@sequensis/react-auth';
import { useConfig } from '@sequensis/react-config';
import { useAsyncCallback } from '@sequensis/react-hooks';
import { Paragraph } from '@sequensis/stylish-core';

import {
  previewPaymentScheduleChange,
  submitPaymentScheduleChange,
} from 'src/api/account/accountApi';
import { analyticsClickEvent } from 'src/components/Analytics';
import { SuccessDisplay } from 'src/components/SuccessDisplay';
import { usePaymentScheduleContext } from 'src/context/PaymentScheduleContext';
import { fetchAccount } from 'src/store/Account';
import { getAccountId, getNextRepaymentDate } from 'src/store/Account/selectors';
import { formatDay } from 'src/utils/formatDate';

import { ChoosePaymentDay } from '../Components/ChoosePaymentDay';

import { ConfirmManualPaymentChange } from './ConfirmManualPaymentChange';

export const ChangeManualPaymentDay = () => {
  const history = useHistory();

  const {
    accountManagementApi: { url: accountManagementApiUrl },
  } = useConfig();
  const { getToken } = useAuth();
  const dispatch = useDispatch();
  const accountId = useSelector(getAccountId) ?? '';

  const { fetchPaymentSchedule } = usePaymentScheduleContext();

  const refreshAccount = useCallback(async () => {
    const authToken = await getToken();

    dispatch(
      fetchAccount.request({
        accountId,
        apiUrl: accountManagementApiUrl,
        authToken,
      }),
    );
  }, [dispatch, accountId, getToken, accountManagementApiUrl]);

  const repaymentDate = useSelector(getNextRepaymentDate);
  const repaymentDay = parseFloat(formatDay(repaymentDate));

  const [chosenDayOfMonth, setDayOfMonth] = useState<number>(repaymentDay);

  const [scheduleChangePreviewState, postScheduleChangePreview] = useAsyncCallback(
    previewPaymentScheduleChange,
  );
  const [scheduleChangeState, postScheduleChange] = useAsyncCallback(
    submitPaymentScheduleChange,
  );

  const handlePaymentDayChosen = async (day: number) => {
    setDayOfMonth(day);
    const token = await getToken();
    postScheduleChangePreview(accountManagementApiUrl, accountId, token, {
      dayOfMonth: day,
    });
  };

  const handleSubmitConfirmation = async () => {
    const token = await getToken();

    postScheduleChange(accountManagementApiUrl, accountId, token, {
      dayOfMonth: chosenDayOfMonth,
    });
  };

  const handleDoneClick = () => {
    analyticsClickEvent('CustomerDirectDebit', 'edit-manual-paymentday-complete');
    refreshAccount();
    fetchPaymentSchedule();
    history.goBack();
  };

  const paymentSchedulePreview = scheduleChangePreviewState.result?.responseJson;

  const showDayPicker = scheduleChangePreviewState.status !== 'success';
  const showPreview =
    scheduleChangePreviewState.status === 'success' &&
    scheduleChangeState.status !== 'success';

  return (
    <>
      {showDayPicker && (
        <ChoosePaymentDay
          confirmButtonTestId="edit-manual-choosepaymentday-button"
          dayPickerTestId="manual-payment-day"
          isSubmitting={scheduleChangePreviewState.loading}
          onPaymentDayChosen={handlePaymentDayChosen}
          error={
            scheduleChangePreviewState.error &&
            'We were unable to change your payment day. Please try again later.'
          }
        />
      )}
      {showPreview && paymentSchedulePreview && (
        <ConfirmManualPaymentChange
          schedule={paymentSchedulePreview}
          isSubmitting={scheduleChangeState.loading}
          error={
            scheduleChangeState.error &&
            'We were unable to change your payment day. Please try again later.'
          }
          onConfirm={handleSubmitConfirmation}
        />
      )}
      {scheduleChangeState.status === 'success' && (
        <SuccessDisplay
          title="Your payment day has been updated"
          doneButtonTestId="edit-manual-paymentday-button"
          onDoneClick={handleDoneClick}
        >
          <Paragraph mb={2}>
            We&apos;ve updated your payment day and you should receive your new schedule
            soon.
          </Paragraph>
        </SuccessDisplay>
      )}
    </>
  );
};
