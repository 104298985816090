import { useState } from 'react';

import { faInfoCircle, faTimes } from '@fortawesome/pro-regular-svg-icons';
import {
  Anchor,
  Box,
  componentPartStyles,
  Icon,
  Modal,
  StylishTheme,
  Text,
} from '@sequensis/stylish-core';
import styled from 'styled-components';
import { ColorProps, LayoutProps, SpaceProps } from 'styled-system';

import { EXPLAINER_STYLES } from './explainerStyling';

interface ExplainerProps
  extends SpaceProps<StylishTheme>,
    LayoutProps<StylishTheme>,
    ColorProps<StylishTheme> {
  question: string;
  modal?: boolean;
  children: React.ReactNode;
}

const explainerStyles = (part: string) =>
  componentPartStyles('explainer', part, EXPLAINER_STYLES);

const ExplainerButton = styled.button<{ noBottomPadding: boolean }>`
  ${explainerStyles('button')};

  ${(props) => !props.noBottomPadding && `padding-bottom: 0;`}
`;

const ExplainerText = styled(Text)`
  ${explainerStyles('text')};
`;

const ExplainerBubble = styled(Box)`
  :after {
    content: '';
    ${explainerStyles('bubbleTag')}
  }
  ${explainerStyles('bubble')};
`;

const ExplainerBox = styled.div`
  ${explainerStyles('explainerBox')};
`;

export const Explainer = ({
  question,
  modal = false,
  children,
}: ExplainerProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ExplainerBox>
      <ExplainerButton
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        noBottomPadding={isOpen || modal}
      >
        <span>
          <Icon icon={faInfoCircle} />
        </span>
        <ExplainerText size="sm">
          <Anchor>{question}</Anchor>
        </ExplainerText>
        {isOpen && !modal && <Icon icon={faTimes} />}
      </ExplainerButton>
      {isOpen &&
        (modal ? (
          <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} header={question}>
            {children}
          </Modal>
        ) : (
          <ExplainerBubble>{children}</ExplainerBubble>
        ))}
    </ExplainerBox>
  );
};
