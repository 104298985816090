import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { useAuth } from '@sequensis/react-auth';
import { useConfig, useFeatureFlag } from '@sequensis/react-config';
import { useAsyncCallback } from '@sequensis/react-hooks';
import { Alert, Card, Flex, Heading, Paragraph, Text } from '@sequensis/stylish-core';
import { PageHeader } from '@sequensis/stylish-layouts';

import { activateDirectDebit, getPaymentSchedule } from 'src/api/account/accountApi';
import { ActivateDirectDebitRequest, BankAccount } from 'src/api/account/types';
import { analyticsClickEvent } from 'src/components/Analytics';
import { FeedbackAlert } from 'src/components/Delighted';
import { SuccessDisplay } from 'src/components/SuccessDisplay';
import TelephoneLink from 'src/components/TelephoneLink';
import { useNextPaymentIsBeforeSpecifiedDays } from 'src/hooks/useNextPaymentIsBeforeSpecifiedDays';
import { useProduct } from 'src/hooks/useProduct';
import { useRefreshAccount } from 'src/hooks/useRefreshAccount';
import { ChooseBankAccount } from 'src/screens/Payments/Components/ChooseBankAccount';
import { getAccountId } from 'src/store/Account/selectors';
import { BankDetailProps } from 'src/types/CustomerProps';
import { formatDate } from 'src/utils/formatDate';
import { formatCurrency } from 'src/utils/formatNumber';

import { ConfirmDirectDebitChange } from './ConfirmDirectDebitChange';

const DIRECT_DEBIT_ACTIVATION_PERIOD = 12;

const isBankDetails = (
  bankAccount: BankAccount | BankDetailProps,
): bankAccount is BankDetailProps => {
  return (bankAccount as BankDetailProps).id !== undefined;
};

const Setup = () => {
  const { getToken } = useAuth();
  const {
    accountManagementApi: { url: accountManagementApiUrl },
  } = useConfig();
  const { refreshAccount } = useRefreshAccount();

  const accountId = useSelector(getAccountId) ?? '';
  const product = useProduct();

  const [chosenBankAccount, setChosenBankAccount] = useState<
    BankAccount | BankDetailProps
  >();

  const [isPaymentDueInTwelveDays] = useNextPaymentIsBeforeSpecifiedDays(
    DIRECT_DEBIT_ACTIVATION_PERIOD,
  );

  const [activateDirectDebitState, postActivateDirectDebit] =
    useAsyncCallback(activateDirectDebit);

  const executeActivateDirectDebit = async (request: ActivateDirectDebitRequest) => {
    const token = await getToken();

    postActivateDirectDebit(accountManagementApiUrl, accountId, token, request);
  };

  const [paymentScheduleState, fetchPaymentSchedule] =
    useAsyncCallback(getPaymentSchedule);

  useEffect(() => {
    const executeFetchPaymentSchedule = async () => {
      const token = await getToken();

      fetchPaymentSchedule(accountManagementApiUrl, accountId, token);
    };

    executeFetchPaymentSchedule();
  }, [accountId, accountManagementApiUrl, fetchPaymentSchedule, getToken]);

  const paymentSchedule = paymentScheduleState.result?.responseJson;

  const handleBankAccountChosen = (bankAccount: BankAccount | BankDetailProps) => {
    setChosenBankAccount(bankAccount);
  };

  const handleConfirmation = () => {
    if (chosenBankAccount) {
      const payload = isBankDetails(chosenBankAccount)
        ? { bankAccountId: chosenBankAccount.id }
        : { bankAccount: chosenBankAccount };
      executeActivateDirectDebit(payload);
    }
  };

  const handleDoneClick = () => {
    analyticsClickEvent(
      'CustomerDirectDebit',
      'setup-new-directdebit-paymentday-complete',
    );
  };

  useEffect(() => {
    if (activateDirectDebitState.status === 'success') refreshAccount();
  }, [activateDirectDebitState.status, refreshAccount]);

  const nextPaymentEvent = paymentSchedule && paymentSchedule.scheduleEvents[0];

  const showChooseBankAccount = chosenBankAccount === undefined;
  const showConfirmDirectDebitChange =
    activateDirectDebitState.status !== 'success' && chosenBankAccount !== undefined;
  const showSuccessDisplay = activateDirectDebitState.status === 'success';

  const feedbackBaseQueryString = `Site=DdSetup&Product=`;

  return (
    <Flex flexDirection="column" flex="1 1 auto">
      {showChooseBankAccount && (
        <ChooseBankAccount onBankAccountChosen={handleBankAccountChosen} />
      )}
      {showConfirmDirectDebitChange && paymentSchedule && (
        <ConfirmDirectDebitChange
          bankAccount={chosenBankAccount}
          schedule={paymentSchedule}
          onConfirm={handleConfirmation}
          isSubmitting={activateDirectDebitState.loading}
          error={
            activateDirectDebitState.error &&
            'We were unable to activate your Direct Debit. Please try again later.'
          }
        />
      )}
      {showSuccessDisplay && (
        <SuccessDisplay
          title="Your Direct Debit has been set up"
          doneButtonTestId="setup-new-directdebit-button"
          onDoneClick={handleDoneClick}
        >
          <Paragraph marginBottom={3}>
            We&apos;ve updated your Direct Debit and you should receive your new schedule
            soon.
          </Paragraph>
          <div>
            <Heading as="h3" layoutSize="medium" marginBottom={2}>
              Your next payment is due
            </Heading>
            {nextPaymentEvent && (
              <Alert>
                <Flex flexDirection="row" justifyContent="space-between">
                  <Text>{formatDate(nextPaymentEvent?.date)}</Text>
                  <Text fontFamily="heading" fontWeight="bold" fontSize="large">
                    {formatCurrency(nextPaymentEvent.amount)}
                  </Text>
                </Flex>
              </Alert>
            )}
            {isPaymentDueInTwelveDays && (
              <Paragraph>
                You will need to make a manual payment to avoid falling behind.
              </Paragraph>
            )}
            <FeedbackAlert
              feedbackQueryString={`${feedbackBaseQueryString}${product}`}
              marginTop={4}
              marginBottom={6}
            />
          </div>
        </SuccessDisplay>
      )}
    </Flex>
  );
};

const SupportSetup = () => {
  const {
    companyDetails: { supportPhoneNumber, openingHours },
  } = useConfig();

  return (
    <>
      <PageHeader title="Set up your Direct Debit" />

      <Card>
        <div>
          <Paragraph>
            We’re busy working for you to be able to do this online but we’re not quite
            ready yet.
          </Paragraph>
          <Paragraph marginTop={3}>
            In the meantime get in touch on{' '}
            <Text color="primary" fontWeight="bold">
              <TelephoneLink number={supportPhoneNumber} />
            </Text>{' '}
            {openingHours}
          </Paragraph>
        </div>
      </Card>
    </>
  );
};

export const DirectDebitGettingStarted = () => {
  const directDebitFeatureFlag = useFeatureFlag('directDebitActivation');

  return directDebitFeatureFlag ? <Setup /> : <SupportSetup />;
};
